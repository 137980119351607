import React from 'react';
import {
  number, string
} from 'prop-types';
import {
  ProductPod,
  ProductImage,
  ProductRating,
  PodSection
} from '@thd-olt-component-react/product-pod';
import { ProductHeader } from '@thd-olt-component-react/product-details';
import { Price } from '@thd-olt-component-react/price';
import { extend } from '@thd-nucleus/data-sources';

export const CustomTransactionalPod = ({
  index,
  itemId,
  storeId,
}) => {
  return (
    <>
      <ProductPod
        itemId={itemId}
        storeId={storeId}
        analyticsData={{ parent: 'packages-mini', position: index + 1 }}
        render={(pod) => (
          <>
            <PodSection noPadding>
              <ProductImage
                itemId={pod.itemId}
              />
            </PodSection>
            <PodSection columnAlign>
              <ProductHeader
                brand="inline"
                itemId={pod.itemId}
              />
              <ProductRating
                itemId={pod.itemId}
              />
              <Price
                itemId={pod.itemId}
                large={false}
                hideBadge
                hideSavingsText
              />
            </PodSection>
          </>
        )}
      />
    </>
  );
};

CustomTransactionalPod.displayName = 'CustomTransactionalPod';

CustomTransactionalPod.propTypes = {
  index: number.isRequired,
  itemId: string.isRequired,
  storeId: string.isRequired,
};

CustomTransactionalPod.dataModel = extend(
  ProductPod,
  ProductImage,
  ProductHeader,
  ProductRating,
  Price
);