import React, { useReducer } from 'react';
import { node } from 'prop-types';
import { ComboboxContext, defaultContent } from './ComboboxContext';
import { comboboxMotionReducer } from './ComboboxMotionReducer';

const ComboboxProvider = ({ children }) => {
  const [comboboxMotionState, comboboxMotionDispatch] = useReducer(
    comboboxMotionReducer,
    undefined,
    defaultContent);

  return (
    <ComboboxContext.Provider value={{ comboboxMotionState, comboboxMotionDispatch }}>
      {children}
    </ComboboxContext.Provider>
  );
};

ComboboxProvider.propTypes = {
  children: node
};
ComboboxProvider.defaultProps = {
  children: null
};
ComboboxProvider.displayName = 'ComboBoxProvider';

export { ComboboxProvider };
