import React, { useContext } from 'react';
import { useStoreId } from '@thd-nucleus/experience-context';
import { Col, Row, Carousel } from '@thd-olt-component-react/core-ui';
import { CustomTransactionalPod } from './CustomTransactionalPod';
import { PackagesMiniContext } from '../PackagesMiniContext';

const TransactionalPods = () => {
  const { products } = useContext(PackagesMiniContext);
  const storeId = useStoreId();
  return (
    <Carousel
      breakpoints={{
        sm: { slidesPerGroup: 2, slidesPerView: 2 },
        md: { slidesPerGroup: 2, slidesPerView: 2 },
        lg: { slidesPerGroup: 4, slidesPerView: 4 },
        xl: { slidesPerGroup: 4, slidesPerView: 4 }
      }}
    >
      {products?.map((product, idx) => {
        const { itemId } = product;
        return (
          <Row key={itemId}>
            <Col flatten>
              <CustomTransactionalPod
                itemId={itemId}
                storeId={storeId}
                index={idx}
                key={'carousel' + itemId}
                product={product}
              />
            </Col>
          </Row>
        );
      }
      )}
    </Carousel>
  );
};

TransactionalPods.displayName = 'TransactionalPods';
TransactionalPods.dataModel = CustomTransactionalPod.dataModel;

export { TransactionalPods };