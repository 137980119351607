// Temporary Component to avoid SSR error for fusion accordion
import React from 'react';
import { ProductOverviewAccordionTitle } from '@thd-olt-component-react/product-overview';
import { bool } from 'prop-types';

export const BundleProductOverviewAccordionTitle = (props) => {
  const { disableHeight } = props;
  return <ProductOverviewAccordionTitle disableHeight={disableHeight} />;
};

BundleProductOverviewAccordionTitle.propTypes = {
  disableHeight: bool
};

BundleProductOverviewAccordionTitle.defaultProps = {
  disableHeight: false
};

BundleProductOverviewAccordionTitle.displayName = 'BundleProductOverviewAccordionTitle';
