import { z } from 'zod';
import { AREA, FIXED_WIDTH, FLOORING_DRAWER, VOLUME } from '../constants';

function addAreaRefinements(schema) {
  return schema
    .refine((val) => (val.calcByArea && val.sqFootage > 0) || !val.calcByArea, {
      message: 'Square footage must be greater than 0.',
      path: ['sqFootage'],
    })
    .refine((val) => (!val.calcByArea && val.length > 0) || val.calcByArea, {
      message: 'Length must be greater than 0.',
      path: ['length'],
    })
    .refine((val) => (!val.calcByArea && val.width > 0) || val.calcByArea, {
      message: 'Width must be greater than 0.',
      path: ['width'],
    });
}

const numericString = z
  .union([z.string().nullable(), z.number().nullable()])
  .pipe(z.coerce.number().nullable());

const areaSchema = z.object({
  name: z
    .string()
    .min(3, { message: 'Name must be at least 3 characters long.' })
    .max(99, { message: 'Name must be less than 99 characters long.' }),
  length: numericString,
  width: numericString,
  sqFootage: numericString,
  calcByArea: z.boolean(),
});

const volumeSchema = addAreaRefinements(
  areaSchema.extend({
    height: numericString,
  })
).refine((val) => val.height > 0, {
  message: 'Depth must be greater than 0',
  path: ['height'],
});

// Must refine after extend, because .extend is not valid after .refine
// Should be fixed in zod v4 https://github.com/colinhacks/zod/issues/1147
const refinedAreaSchema = addAreaRefinements(areaSchema);

const getSchemaFromCalculatorType = (calculatorType) => {
  switch (calculatorType) {
  case AREA:
  case FIXED_WIDTH:
  case FLOORING_DRAWER:
    return refinedAreaSchema;
  case VOLUME:
    return volumeSchema;
  default:
    throw new Error('unsupported schema type');
  }
};

export const getCalculatorSchema = (calculatorType) => {
  if (calculatorType === FLOORING_DRAWER) {
    return z.object({
      entries: z.array(getSchemaFromCalculatorType(calculatorType))
    });
  }

  return z.object({
    entries: z.array(getSchemaFromCalculatorType(calculatorType)).min(1, {
      message: 'Must have at least one entry.',
    }),
  });
};

export const getCalculatorResolver = (calculatorType) => {
  return (async (data) => {
    return {
      values: data,
      errors: {}
    };
  });
};

export const getDefaultEntryValue = (
  calculatorType,
  { lineItemName = 'Area', calcByArea = false } = {}
) => {
  switch (calculatorType) {
  case AREA:
  case FIXED_WIDTH:
  case FLOORING_DRAWER:
    return {
      name: lineItemName,
      length: null,
      width: null,
      sqFootage: null,
      calcByArea,
    };
  case VOLUME:
    return {
      name: lineItemName,
      length: null,
      width: null,
      height: 3,
      sqFootage: null,
      calcByArea,
    };
  default:
    throw new Error('unsupported schema type');
  }
};

export const getDefaultCalculatorValue = (calculatorType, entryOptions = {}) => {
  return {
    entries: [getDefaultEntryValue(calculatorType, entryOptions)],
  };
};
