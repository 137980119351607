import React, { useEffect, useRef, useState } from 'react';
import {
  arrayOf, shape, string, bool, func, number
} from 'prop-types';
import { extend } from '@thd-nucleus/data-sources';
import { useConfigService } from '@thd-nucleus/experience-context';
import { LifestyleImageWithHotspot } from './subcomponents/LifestyleImageWithHotspot';
import { HotspotDrawer } from './subcomponents/HotspotDrawer';
import { HOTSPOT_TYPE } from '../constants';
import { getUniqueCollapsedHotspots, triggerAnalytics } from '../helpers';
import './product-hotspots.scss';

/**
 * Lifestyle image with product hotspots found in PIP MediaGallery
 */
const MediaHotspots = ({
  hotspots: visuallySimilarHotspots = [],
  ocrHotspots,
  imageUrl,
  openOverlay,
  onHotspotImageClick,
  mediaGalleryIndex,
}) => {
  const [hotspots, setHotspots] = useState([]);
  const [openFirstTag, setOpenFirstTag] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [open, setOpen] = useState(false);
  const childBoundingBoxRef = useRef(null);

  const onShowFirstTagProduct = () => {
    setOpenFirstTag(true);
  };

  const toggleDrawer = (drawerOpen, index) => {
    setCurrentIndex(index);
    setOpen(drawerOpen);
    if (index === null) {
      setOpenFirstTag(false);
    }
    if (hotspots[index]?.collapsedCount) {
      triggerAnalytics('product-hotspots.click', { target: 'shoppable collapsed' });
    } else if (hotspots[index]) {
      triggerAnalytics('product-hotspots.click', { target: 'shoppable' });
    } else {
      triggerAnalytics('product-hotspots.click');
    }
  };

  useEffect(() => {
    /* istanbul ignore else */
    if (LIFE_CYCLE_EVENT_BUS) {
      LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('product-hotspots.ready');
    }
  }, []);

  useEffect(() => {
    if (openFirstTag) {
      setCurrentIndex(0);
      setOpen(true);
    }
  }, [openFirstTag]);

  useEffect(() => {
    const { width, height } = childBoundingBoxRef?.current?.getBoundingClientRect() || {};
    const collapsedOcrHotspots = getUniqueCollapsedHotspots(ocrHotspots, width, height);

    if (collapsedOcrHotspots.length) {
      setHotspots(collapsedOcrHotspots);
    } else if (visuallySimilarHotspots.length) {
      setHotspots(visuallySimilarHotspots);
    }
  }, []);

  return (
    <>
      <LifestyleImageWithHotspot
        hotspots={hotspots}
        imageUrl={imageUrl}
        tagIndex={currentIndex}
        imgOnclick={() => {
          /* istanbul ignore else */
          if (openOverlay && onHotspotImageClick) {
            onHotspotImageClick();
          }
        }}
        handleClick={toggleDrawer}
        mediaGalleryIndex={mediaGalleryIndex}
        type={HOTSPOT_TYPE.PIP_HOTSPOTS}
        onShowFirstTagProduct={onShowFirstTagProduct}
        ref={childBoundingBoxRef}
      />
      {
        open && currentIndex >= 0 && hotspots[currentIndex] && (
          <HotspotDrawer
            hotspots={hotspots}
            imageUrl={imageUrl}
            open={open}
            mediaGalleryIndex={mediaGalleryIndex}
            currentIndex={currentIndex}
            onCloseDrawer={toggleDrawer}
            setCurrentIndex={setCurrentIndex}
          />
        )
      }
    </>

  );
};

MediaHotspots.displayName = 'MediaHotspots';

MediaHotspots.dataModel = extend(HotspotDrawer);

MediaHotspots.propTypes = {
  /**
   * A list of regular (Visually Similar) product hotspots detected in the lifestyle image
   */
  hotspots: arrayOf(shape({
    coordinate: shape({
      xCoordinate: string,
      yCoordinate: string
    }),
    omsIDs: arrayOf(string)
  })).isRequired,
  /**
   * A list of OCR text hotspots detected in the lifestyle image
   */
  ocrHotspots: arrayOf(shape({
    coordinate: shape({
      xCoordinate: string,
      yCoordinate: string
    }),
    omsIDs: arrayOf(string)
  })),
  /**
   * A link to the CDN-hosted lifestyle image
   */
  imageUrl: string.isRequired,
  /**
   * A flag to determine whether or not to open the overlay
   */
  openOverlay: bool,
  /**
   * An event handler that executes when a user clicks on a product hotspot
   */
  onHotspotImageClick: func,
  /**
   * A zero-based position of the lifestyle image in the list of all media gallery images
   */
  mediaGalleryIndex: number,
};

MediaHotspots.defaultProps = {
  ocrHotspots: [],
  openOverlay: false,
  onHotspotImageClick: null,
  mediaGalleryIndex: 0,
};

export { MediaHotspots };
