import React from 'react';
import { string, arrayOf } from 'prop-types';

const ErrorMessages = ({ errorMessages }) => {
  return errorMessages.map((err, index) => (
    err.split('\n').map((line, lineIndex) => (
      <p key={`${index}-${lineIndex}`}>{line}</p>
    ))
  ));
};

ErrorMessages.propTypes = {
  errorMessages: arrayOf(string)
};

export default ErrorMessages;
