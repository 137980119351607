import {
  CLEAR_PRICING,
  UPDATE_PRICING,
  UPDATE_PRODUCTINFO,
  GET_PRICING,
  THD_PRODUCT_DETAILS_UPDATED,
  GET_PRICING_FROM_THD,
  GET_PRICING_SO,
  GET_PRICING_MSRP,
  GET_PRICING_ERROR,
  GET_PRICING_SUCCESS,
  GET_MSRP_PRICING_SUCCESS,
  GET_SO_PRICING_SUCCESS,
  PRICING_AVAILABLE,
} from './actionTypes';
import { createAction } from '../utils/createAction';

export const getPricing = createAction(GET_PRICING);
export const getPricingFromThd = createAction(GET_PRICING_FROM_THD);
export const getPricingSO = createAction(GET_PRICING_SO);
export const getPricingMsrp = createAction(GET_PRICING_MSRP);
export const getPricingError = createAction(GET_PRICING_ERROR, 'err');
export const getPricingSuccess = createAction(GET_PRICING_SUCCESS);
export const getMsrpPricingSuccess = createAction(GET_MSRP_PRICING_SUCCESS);
export const getSOPricingSuccess = createAction(GET_SO_PRICING_SUCCESS);
export const setPricingAvailable = createAction(PRICING_AVAILABLE);

export const thdProductDetailsUpdated = createAction(THD_PRODUCT_DETAILS_UPDATED);

export const updateProductInfo = ({
  newSkus,
  thdDetails,
  skuPrice,
}) => ({
  type: UPDATE_PRODUCTINFO,
  newSkus,
  thdDetails,
  skuPrice,
});

export const updatePricing = ({
  originalPrice,
  specialPrice,
  isSpecialBuy = false,
  isLowerPrice = false,
  each = true,
  products = [],
}) => ({
  type: UPDATE_PRICING,
  originalPrice,
  specialPrice,
  isSpecialBuy,
  isLowerPrice,
  each,
  products,
});

export const clearPricing = () => ({
  type: CLEAR_PRICING,
  originalPrice: 0,
  specialPrice: 0,
  isSpecialBuy: false,
  isLowerPrice: false,
  each: true,
  products: [],
});
