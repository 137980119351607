import React from 'react';
import { node, bool, string } from 'prop-types';

const PriceContainer = ({ minAdvertisedPrice, price, priceType, children }) => {
  // eslint-disable-next-line max-len
  const dashClass = 'sui-w-3 sui-bg-primary sui-border-solid sui-border-strongest sui-border-b-1 sui-mr-5 sui-ml-auto sui-h-4';
  if (minAdvertisedPrice || !price) {
    return (
      <span className="sui-flex sui-flex-col sui-items-center">
        <span className={dashClass} data-testid={`${priceType}-dash`} />
      </span>
    );
  }
  return (<>{children}</>);
};

PriceContainer.propTypes = {
  children: node.isRequired,
  minAdvertisedPrice: bool,
  price: bool,
  priceType: string.isRequired
};

PriceContainer.defaultProps = {
  minAdvertisedPrice: false.valueOf,
  price: true
};

export default PriceContainer;
