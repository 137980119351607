import React, { useState, useEffect, useRef } from 'react';
import { Typography } from '@one-thd/sui-atomic-components';
import { Store, Delivery } from '@one-thd/sui-icons';
import classNames from 'classnames';
import { bool } from 'prop-types';
import { findStoreHours } from './utils';
import { useMobileBreakpoint } from '../../utils/headerUtils';
import { useDrawerContext } from '../drawer-ui/DrawerContext';
import { HeaderIconTextButton } from '../generic-ui/HeaderIconTextButton';
import { useThemeContext } from '../generic-ui/ThemeContext';
import { headerEvent } from '../../events/header.events';
import { drawerEvent } from '../../events/drawer.listener';
import { StoreAndZipOutOfRange } from './StoreAndZipOutOfRange';

const HeaderStoreAndDelivery = ({ mobileOnly }) => {
  const localizerRef = useRef();
  const drawerStateRef = useRef();
  const zipPopUpRef = useRef(null);
  const storePopUpRef = useRef(null);

  const isMobile = useMobileBreakpoint();

  const { drawerState, drawerDispatch } = useDrawerContext();
  drawerStateRef.current = drawerState;

  const [localStore, setLocalStore] = useState(null);
  const [deliveryZip, setDeliveryZip] = useState(null);
  const [status, storeHours] = findStoreHours(localStore?.storeHours);
  const { inverseTheme } = useThemeContext();

  const handleDeliveryZipClick = () => {
    headerEvent.publish({
      eventName: 'header.header-click',
      payload: { clickPath: 'delivery zip code' }
    });
    const { open, name } = drawerState;
    const dispatchType = (open && name === 'DeliveryZIP') ? 'clear' : 'DeliveryZIP';
    drawerDispatch({ type: dispatchType });
  };

  const handleMyStoreClick = () => {
    headerEvent.publish({
      eventName: 'header.my-store-click',
      payload: { clickPath: 'your store' }
    });
    const { open, name } = drawerState;
    const dispatchType = (open && name === 'MyStore') ? 'clear' : 'MyStore';
    drawerDispatch({ type: dispatchType });
  };

  /* using session storage since state value will reset when page reloads */
  const softLinkZipCodeInit = () => {
    sessionStorage.setItem('deliveryzipReloading', 'true');
  };

  const softLinkStoreInit = () => {
    sessionStorage.setItem('deliveryStoreReloading', 'true');
  };

  const updateDeliveryZip = () => {
    const zipDetails = localizerRef.current.getDeliveryZipcodeDetails();
    setDeliveryZip(zipDetails?.zipcode);
    headerEvent.publish({
      eventName: 'header.delivery-zip-initialized',
      payload: { userZip: zipDetails?.zipcode }
    });
  };

  /**
   * adding below comment so it's not reported in testing coverage,
   * due to Localizer testing limitations
   */
  /* istanbul ignore next  */
  const thdLocalizer = {
    onLocalized: () => {
      updateDeliveryZip();
      setLocalStore(localizerRef.current.getLocalizedStore());
      softLinkStoreInit();
      const { open, name } = drawerStateRef.current;
      if (open && name === 'MyStore') drawerDispatch({ type: 'clear' });
    },

    onDeliveryZipUpdated: () => {
      updateDeliveryZip();
      softLinkZipCodeInit();
      const { open, name } = drawerStateRef.current;
      if (open && name === 'DeliveryZIP') drawerDispatch({ type: 'clear' });
      /**
         * Refreshing page to support zip update on PIP pages/other pages using fulfillment component w/ out listeners
         * the MyCart page does have these listeners, so we're using a temporary solution to not refresh my cart.
        */
      if (window?.location !== undefined && !window?.location?.pathname.includes('/mycart')) window.location.reload();
    }
  };

  useEffect(() => {
    import('Localizer')
      .then((localizer) => {
        localizerRef.current = localizer;

        setLocalStore(localizerRef.current.getLocalizedStore());
        updateDeliveryZip();

        localizer.on(localizer.event.LOCALIZED, () => thdLocalizer.onLocalized());
        localizer.on(localizer.event.DELIVERY_ZIP_UPDATED, () => thdLocalizer.onDeliveryZipUpdated());
      })
      // eslint-disable-next-line no-console
      .catch((error) => console.warn(`Error occurred while loading Localizer - ${error}`));

    headerEvent.listen({
      eventName: 'open_delivery_zip_menu',
      onEvent: () => drawerDispatch({ type: 'DeliveryZIP' })
    });

    drawerEvent.listen({
      eventName: 'launchLocDrawer',
      onEvent: () => drawerDispatch({ type: 'MyStore' })
    });

  }, []);

  const containerClasses = classNames(
    'sui-absolute sui-left-0 sui-right-0 sui-bottom-0',
    'lg:sui-static lg:sui-w-auto print:sui-contents', {
      'sui-hidden lg:sui-inline': mobileOnly === false,
      'sui-inline lg:sui-hidden': mobileOnly === true
    });

  const storeDeliveryElt = { deliveryElt: zipPopUpRef.current, storeElt: storePopUpRef.current };

  const wrapperClasses = [
    'sui-flex sui-items-center sui-justify-between sui-h-12',
    'sui-gap-6 sui-px-4 sui-py-3 lg:sui-p-0 lg:sui-h-auto lg:sui-w-64'
  ].join(' ');

  const storeHoursClass = classNames('sui-max-w-[12.8rem]', {
    'lg:sui-max-w-[7.5rem]': storeHours === 'CLOSED',
    'lg:sui-max-w-[8.9rem]': storeHours !== 'CLOSED'
  });

  return (
    <>
      <div className={containerClasses}>
        <div className={wrapperClasses}>
          <HeaderIconTextButton
            name="MyStore"
            label={localStore ? localStore?.name : 'Select store'}
            textColor={inverseTheme ? 'inverse' : 'primary'}
            icon={Store}
            iconSize="small"
            iconColor={inverseTheme ? 'inverse' : 'primary'}
            activeName={drawerState.name}
            onClick={handleMyStoreClick}
            flexDirection="row"
            customClasses={{
              root: 'lg:sui-w-[16.7rem]',
              text: storeHoursClass
            }}
            data-testid="my-store-button"
            data-component="HeaderMyStore"
            aria-label="Open drawer to view my store"
          >
            <Typography
              ref={zipPopUpRef}
              color={inverseTheme ? 'inverse' : status}
              height="normal"
              weight="bold"
              variant="body-base"
            >
              {storeHours}
            </Typography>
          </HeaderIconTextButton>
          <div ref={storePopUpRef}>
            <HeaderIconTextButton
              name="DeliveryZIP"
              label={deliveryZip || '...'}
              textColor={inverseTheme ? 'inverse' : 'primary'}
              icon={Delivery}
              iconSize="small"
              iconColor={inverseTheme ? 'inverse' : 'primary'}
              activeName={drawerState.name}
              onClick={handleDeliveryZipClick}
              flexDirection="row"
              data-testid="delivery-zip-button"
              data-component="HeaderDeliveryZip"
              aria-label="Open drawer to update zip code"
              style={{ width: '6.9rem' }}
            />
          </div>
        </div>
      </div>
      {(isMobile === mobileOnly) && (
        <StoreAndZipOutOfRange
          currentAnchorElt={storeDeliveryElt}
          zipcodeClick={handleDeliveryZipClick}
          storeClick={handleMyStoreClick}
        />
      )}
    </>
  );
};

HeaderStoreAndDelivery.propTypes = {
  mobileOnly: bool
};
HeaderStoreAndDelivery.defaultProps = {
  mobileOnly: undefined
};
HeaderStoreAndDelivery.dataModel = {};
HeaderStoreAndDelivery.displayName = 'HeaderStoreAndDelivery';

export { HeaderStoreAndDelivery };
