import React from 'react';
import { Typography } from '@one-thd/sui-atomic-components';
import { shape, string } from 'prop-types';

export const ProductCategoryContent = ({ content }) => {
  if (!content || !content?.title || !content?.data) return null;

  return (
    <div data-component="ProductCategoryContent">
      <div>
        <Typography variant="h2">
          {content?.title}
        </Typography>
      </div>
      <div style={{ fontWeight: 'inherit' }}>
        <div dangerouslySetInnerHTML={{ __html: content.data }} />
      </div>
    </div>
  );
};

ProductCategoryContent.propTypes = {
  content: shape({
    title: string,
    data: string
  })
};

ProductCategoryContent.defaultProps = {
  content: null
};
