import React, { useContext } from 'react';
import classNames from 'classnames/bind';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { useTheme } from '@thd-olt-component-react/theme-provider';
import { string, bool, oneOf } from 'prop-types';
import {
  useDataModel,
  params,
  string as stringType,
  shape as shapeType
} from '@thd-nucleus/data-sources';
import { useLifeCycleEventBus } from '@thd-olt-functional/utils';
import { Typography } from '@one-thd/sui-atomic-components';

const ONLINE_EXCLUSIVE = 'Online';

const getLabelProps = (isMobile, compactMobile, labelSize) => {
  const labelVariant = isMobile && !compactMobile ? 'body-base' : labelSize;
  const labelWeight = isMobile && !compactMobile ? 'bold' : 'regular';
  const labelHeight = isMobile && !compactMobile ? 'loose' : 'normal';

  return {
    labelVariant,
    labelWeight,
    labelHeight
  };
};

const getProductInfoBarClasses = (padded, isMobile, showOnMobile, compactMobile, flexDirection, textSize) => {
  return classNames(`sui-flex sui-text-${textSize} sui-flex-wrap`, {
    'sui-px-2': padded && !isMobile,
    'sui-pl-2': padded && isMobile,
    'sui-flex sui-mb-2 sui-ml-2': showOnMobile && compactMobile,
    'sui-flex-col': (showOnMobile && !compactMobile) || flexDirection === 'col',
  });
};

export const ProductInfoBar = ({
  itemId, textSize, flexDirection, padded, showOnMobile, compactMobile
}) => {
  const { configuredItemId } = useLifeCycleEventBus('configurator.stock_product_chosen_event');
  const actualItemId = configuredItemId || itemId;
  const { channel } = useContext(ExperienceContext);
  const theme = useTheme(ProductInfoBar, { itemId, showOnMobile });
  const { data } = useDataModel('product', { variables: { itemId: actualItemId } });
  if (!data?.product) {
    return null;
  }
  const isMobile = channel === 'mobile';
  const { showUpcCode } = theme?.props || {};
  const { product } = data || {};
  const { identifiers, availabilityType } = product || {};
  const {
    modelNumber,
    rentalCategory,
    rentalSubCategory,
    storeSkuNumber,
    specialOrderSku,
    toolRentalSkuNumber,
    upc,
    productType
  } = identifiers || {};
  const { type } = availabilityType || {};
  const isMajorAppliance = productType === 'MAJOR_APPLIANCE';
  const shouldHideStoreSku = (type === ONLINE_EXCLUSIVE && !isMajorAppliance);
  const productInfoBarClasses = getProductInfoBarClasses(
    padded,
    isMobile,
    showOnMobile,
    compactMobile,
    flexDirection,
    textSize
  );
  const labelSize = `body-${textSize}`;

  const {
    labelVariant,
    labelWeight,
    labelHeight
  } = getLabelProps(isMobile, compactMobile, labelSize);

  const componentType = 'h2';

  return (
    <div className="product-info-bar" data-testid="productInfo">
      <div className={productInfoBarClasses} data-component="ProductInfoBar">
        <div className="sui-flex sui-inline-flex sui-mr-2">
          <Typography
            variant={labelVariant}
            component={componentType}
            align="left"
            weight={labelWeight}
            height={labelHeight}
          >
            Internet # <span className="sui-font-normal">{actualItemId}</span>
          </Typography>
        </div>
        {modelNumber
          && (
            <div className="sui-flex sui-inline-flex sui-mr-2">
              <Typography
                variant={labelVariant}
                component={componentType}
                align="left"
                weight={labelWeight}
                height={labelHeight}
              >
                Model # <span className="sui-font-normal">{modelNumber}</span>
              </Typography>
            </div>
          )}
        {upc && showUpcCode
          && (
            <div className="sui-flex sui-inline-flex sui-mr-2">
              <Typography
                variant={labelVariant}
                align="left"
                weight={labelWeight}
                height={labelHeight}
              >
                UPC Code # <span className="sui-font-normal">{upc}</span>
              </Typography>
            </div>
          )}
        {storeSkuNumber && !shouldHideStoreSku
          && (
            <div className="sui-flex sui-inline-flex sui-mr-2">
              <Typography
                variant={labelVariant}
                component={componentType}
                align="left"
                weight={labelWeight}
                height={labelHeight}
              >
                Store SKU # <span className="sui-font-normal">{storeSkuNumber}</span>
              </Typography>
            </div>
          )}
        {specialOrderSku
          && (
            <div className="sui-flex sui-inline-flex sui-mr-2">
              <Typography
                variant={labelVariant}
                component={componentType}
                align="left"
                weight={labelWeight}
                height={labelHeight}
              >
                Store SO SKU #<span className="sui-font-normal">{specialOrderSku}</span>
              </Typography>
            </div>
          )}
        {toolRentalSkuNumber
          && (
            <div className="sui-flex sui-inline-flex sui-mr-2">
              <Typography variant={labelVariant} align="left" weight={labelWeight} height={labelHeight}>
                Rental SKU # <span className="sui-font-normal">{toolRentalSkuNumber}</span>
              </Typography>
            </div>
          )}
        {rentalCategory && rentalSubCategory && (
          <>
            <div className="sui-flex sui-inline-flex sui-mr-2">
              <Typography
                variant={labelVariant}
                component={componentType}
                align="left"
                weight={labelWeight}
                height={labelHeight}
              >
                Rental Category # <span className="sui-font-normal">{rentalCategory}</span>
              </Typography>
            </div>
            <div className="sui-flex sui-inline-flex sui-mr-2">
              <Typography
                variant={labelVariant}
                component={componentType}
                align="left"
                weight={labelWeight}
                height={labelHeight}
              >
                Rental Subcategory # <span className="sui-font-normal">{rentalSubCategory}</span>
              </Typography>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

ProductInfoBar.themeProps = {
  showUpcCode: bool
};

ProductInfoBar.defaultThemeProps = {
  showUpcCode: false
};

ProductInfoBar.displayName = 'ProductInfoBar';

ProductInfoBar.defaultProps = {
  padded: false,
  showOnMobile: false,
  compactMobile: false,
  textSize: 'xs',
  flexDirection: 'row'
};

ProductInfoBar.propTypes = {
  padded: bool,
  itemId: string.isRequired,
  textSize: oneOf(['xs', 'base', 'lg']),
  flexDirection: oneOf(['row', 'col']),
  showOnMobile: bool,
  compactMobile: bool,
};

ProductInfoBar.dataModel = {
  product: params({ itemId: stringType().isRequired(), dataSource: stringType() }).shape({
    itemId: stringType(),
    dataSources: stringType(),
    identifiers: shapeType({
      modelNumber: stringType(),
      storeSkuNumber: stringType(),
      specialOrderSku: stringType(),
      toolRentalSkuNumber: stringType(),
      rentalCategory: stringType(),
      rentalSubCategory: stringType(),
      upc: stringType(),
      productType: stringType(),
    }),
    availabilityType: shapeType({
      type: stringType()
    })
  })
};
