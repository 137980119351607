import React from 'react';
import {
  func, number, arrayOf, shape, string, bool
} from 'prop-types';
import { extend } from '@thd-nucleus/data-sources';
import {
  Drawer,
  DrawerHeader,
  DrawerBody
} from '@one-thd/sui-atomic-components';
import { HotspotDrawerBody } from './HotspotDrawerBody';

const HotspotDrawer = ({
  hotspots, imageUrl, open, mediaGalleryIndex, currentIndex, onCloseDrawer, setCurrentIndex
}) => {
  const closeHandler = () => {
    onCloseDrawer(false, null);
  };

  return (
    <Drawer
      open={open}
      onClose={closeHandler}
      position="right"
    >
      <DrawerHeader
        title="Shop Related Items"
        // eslint-disable-next-line jsx-a11y/aria-role
        role="drawerTitle"
        onClose={closeHandler}
      />
      <DrawerBody>
        <HotspotDrawerBody
          omsIDs={hotspots[currentIndex].omsIDs}
          imageUrl={imageUrl}
          mediaGalleryIndex={mediaGalleryIndex}
          hotspots={hotspots}
          drawerIndex={`drawer-${mediaGalleryIndex}-${currentIndex}`}
          tagIndex={currentIndex}
          updateIndex={(newIndex) => setCurrentIndex(newIndex)}
        />
      </DrawerBody>

    </Drawer>
  );
};

HotspotDrawer.displayName = 'HotspotDrawer';

HotspotDrawer.propTypes = {
  hotspots: arrayOf(shape({
    coordinate: shape({
      xCoordinate: string,
      yCoordinate: string
    }),
    omsIDs: arrayOf(string),
    collapsedCount: number,
  })).isRequired,
  imageUrl: string,
  open: bool,
  mediaGalleryIndex: number,
  currentIndex: number,
  onCloseDrawer: func,
  setCurrentIndex: func
};

HotspotDrawer.defaultProps = {
  imageUrl: '',
  open: false,
  mediaGalleryIndex: 0,
  currentIndex: null,
  onCloseDrawer: null,
  setCurrentIndex: null
};

HotspotDrawer.dataModel = extend(HotspotDrawerBody);

export { HotspotDrawer };
