import {
  useContext, useEffect, useRef, useState
} from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { useLocalStorage } from '@thd-olt-global/thd-storage-utils';
import { useForm, useFieldArray } from 'react-hook-form';
import {
  formatStorageKey,
} from '../helpers/localStorageUtil';
import { INSTALLED_CARPET_SEGMENT } from '../constants';
import { getCoverage, getQuantity } from '../helpers/helpers';
import { track } from '../analytics/analytics';
import {
  getDefaultEntryValue,
  getDefaultCalculatorValue,
  getCalculatorSchema,
} from '../helpers/schema';

const resolveDuplicateNames = (entries) => {
  // Removes existing trailing numbers from the areas array
  const removeTrailingNumber = (entry) => {
    const split = entry.name.trim().split(' ');

    if (+split[split.length - 1]) {
      return {
        ...entry,
        name: split.slice(0, -1).join(' '),
      };
    }
    return {
      ...entry,
      name: entry.name.trim(),
    };
  };
  const entriesWithoutNumbers = entries.map(removeTrailingNumber);

  // Creates an object where each key is a name of an area, and the value is all of the indexes of
  // the areas object that share that name
  const duplicateMap = {};
  for (let i = 0; i < entriesWithoutNumbers.length; i += 1) {
    if (duplicateMap[entriesWithoutNumbers[i].name.toLowerCase()]) {
      duplicateMap[entriesWithoutNumbers[i].name.toLowerCase()].push(i);
    } else {
      duplicateMap[entriesWithoutNumbers[i].name.toLowerCase()] = [i];
    }
  }

  // Going through each unique name in the duplicate map,
  // update each value in the stripped areas array to add trailing numbers back for all duplicate names
  // eslint-disable-next-line no-restricted-syntax
  for (const duplicateName in duplicateMap) {
    if (duplicateMap[duplicateName].length > 1) {
      for (let i = 0; i < duplicateMap[duplicateName].length; i += 1) {
        entriesWithoutNumbers[duplicateMap[duplicateName][i]].name += ' ' + (i + 1);
      }
    }
  }

  return entriesWithoutNumbers;
};

export const useCalculator = ({
  allowAdditionalCoverage,
  calculatorType,
  info,
  l1Label,
  lineItemName,
  onCalculate,
  unitsPerCase,
}) => {
  const mountedRef = useRef(false);
  const { segment } = useContext(ExperienceContext);
  const localStorageKey = formatStorageKey(l1Label);
  const [localStorageValue, setLocalStorageValue] = useLocalStorage(localStorageKey);
  const { form: formLocalStorage, calculate: calculateLocalStorage } = localStorageValue ?? {};
  const deletedItems = useRef({});

  const [calculate, setCalculate] = useState(
    calculateLocalStorage ?? {
      addAdditionalCoverage: true,
      quantity: 0,
      coverage: 0,
      // TODO: implement this into the UI at a later point. Should probably only be used on carpets
      // Currently used to work with GCC Carpet Configurator (which does currently use stairs)
      stairs: '0',
    }
  );
  const { addAdditionalCoverage } = calculate;
  const { control, getValues, reset, handleSubmit } = useForm({
    resolver: zodResolver(getCalculatorSchema(calculatorType)),
    defaultValues:
      formLocalStorage
      ?? getDefaultCalculatorValue(calculatorType, { lineItemName }),
    mode: 'onChange',
  });
  const {
    fields, append, remove, update, insert
  } = useFieldArray({
    control,
    name: 'entries',
  });

  const calculateTotal = (formData) => {
    const sizeValue = +unitsPerCase;
    if (mountedRef.current) {
      let prevCalculate = calculate;
      const { addAdditionalCoverage: prevAddAdditionalCoverage } = prevCalculate;
      let coverage = getCoverage({
        entries: formData.entries,
        calculatorType,
        info,
        isInstalledCarpet: segment === INSTALLED_CARPET_SEGMENT,
      });
      let quantity = getQuantity({
        addAdditionalCoverage: prevAddAdditionalCoverage,
        allowAdditionalCoverage,
        coverage,
        info,
        sizeValue,
      });

      const newCalculate = {
        ...prevCalculate,
        coverage,
        quantity,
      };

      if (onCalculate) {
        onCalculate(newCalculate);
      }

      if (formData.entries.length > 0) {
        setLocalStorageValue({
          form: formData,
          calculate: newCalculate
        });
      } else {
        setLocalStorageValue(null);
      }
      setCalculate(newCalculate);
      track();
    }
  };

  function resolveDuplicateEntryNames() {
    const entries = getValues('entries');
    const resolvedEntries = resolveDuplicateNames(entries);
    if (JSON.stringify(entries) !== JSON.stringify(resolvedEntries)) {
      entries.forEach((entry, index) => {
        if (entry.name !== resolvedEntries[index].name) {
          update(index, {
            ...entry,
            name: resolvedEntries[index].name,
          });
        }
      });
    }
  }

  const onSubmit = async (event) => {
    resolveDuplicateEntryNames();
    let result;
    await handleSubmit((formData) => {
      calculateTotal(formData);
      result = true;
    }, (formData) => {
      if (formData.entries.length === 0) {
        setLocalStorageValue(null);
      }
      result = false;
    })(event);

    return result;
  };

  const addElement = ({ calcByArea }) => {
    append(getDefaultEntryValue(calculatorType, { calcByArea, lineItemName }));
    resolveDuplicateEntryNames();

    setTimeout(() => {
      const inputSections = document.getElementById('calc-input-sections');
      if (inputSections) {
        inputSections.scroll({
          top: inputSections.scrollHeight,
          behavior: 'smooth',
        });
      }
    }, 1);
  };

  const removeInputElement = (index = null) => {
    const entries = getValues('entries');

    if (index !== null) {
      deletedItems.current = {
        ...deletedItems.current,
        [fields[index].id]: {
          index,
          value: entries[index]
        }
      };
    } else {
      deletedItems.current = {
        ...entries.reduce((accum, curr, fieldIndex) => {
          return {
            ...accum,
            [fields[fieldIndex].id]: {
              index: fieldIndex,
              value: curr
            }
          };
        }, {})
      };
    }
    if (index !== null) {
      remove(index);
    } else {
      remove();
    }
    onSubmit();
  };

  const undoRemoveElement = (elementId) => {
    if (elementId !== null) {
      const { index, value } = deletedItems.current[elementId];
      insert(index, value);

      let newItems = {
        ...deletedItems.current
      };
      delete newItems[elementId];

      deletedItems.current = newItems;
    } else {
      const deleted = Object.values(deletedItems.current).map((val) => {
        const value = val.value;
        return {
          ...value
        };
      });

      reset({
        entries: deleted
      });
      deletedItems.current = {};
    }
    onSubmit();
  };

  const updateElement = (selectedIndex, value) => {
    // const result = getCalculatorSchema(calculatorType).safeParse({
    //   entries: [value]
    // });

    // if (result.success) {
    update(selectedIndex, value);
    // }
  };

  const setCalcByArea = (calcByArea, selectedIndex) => {
    const entries = getValues('entries');

    const adjustedEntries = entries.map((entry, index) => {
      if (selectedIndex !== null && index !== selectedIndex) {
        return entry;
      }

      return {
        ...entry,
        ...(calcByArea && entry.length && entry.width && { sqFootage: entry.length * entry.width }),
        calcByArea,
      };
    });
    reset({
      entries: adjustedEntries
    });
  };

  const resetEntryLengthAndWidth = (index) => {
    const entry = getValues(`entries[${index}]`);

    update(index, {
      ...entry,
      length: null,
      width: null,
    });
  };

  const resetCalculatorState = () => {
    reset(getDefaultCalculatorValue(calculatorType, { lineItemName }));
  };

  const setAddAdditionalCoverage = (val) => {
    setCalculate((calc) => {
      return {
        ...calc,
        addAdditionalCoverage: val,
      };
    });
  };

  useEffect(() => {
    mountedRef.current = true;

    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    const { coverage, quantity } = calculate;
    if (coverage && quantity) {
      if (mountedRef.current) {
        onSubmit();
      }
    }
  }, [unitsPerCase, addAdditionalCoverage]);

  return [
    calculate,
    {
      getValues,
      control,
      onSubmit,
      fields,
      addElement,
      calculateTotal,
      removeInputElement,
      undoRemoveElement,
      setCalcByArea,
      resetEntryLengthAndWidth,
      resolveDuplicateEntryNames,
      setAddAdditionalCoverage,
      updateElement,
      resetCalculatorState
    },
  ];
};
