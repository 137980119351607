/* eslint-disable jsx-a11y/no-autofocus */
import React, { forwardRef } from 'react';
import { shape, bool, func, string } from 'prop-types';
import classNames from 'classnames';
import { Button, IconButton } from '@one-thd/sui-atomic-components';
import { Search, Close } from '@one-thd/sui-icons';

const SearchField = forwardRef(({
  onSearchIconClick,
  showSearchIcon,
  onClearIconClick,
  showSearchClearIcon,
  formProps,
  inputProps,
  customClasses
}, ref) => {

  const controllerClasses = [
    'sui-inline-flex sui-flex-row sui-relative',
    'sui-min-w-0 sui-p-0 sui-m-0',
    'sui-align-top sui-w-full'
  ].join(' ');

  const rootClasses = [
    'sui-font-regular sui-text-base sui-leading-tight',
    'sui-relative sui-cursor-text sui-inline-flex',
    'sui-items-center sui-rounded-base',
    'sui-h-11 sui-w-full',
    'sui-bg-subtle sui-text-primary'
  ].join(' ');

  const baseInputContainerClasses = [
    'sui-flex',
    'sui-text-current sui-ring-0 sui-rounded-base',
    'sui-outline-none sui-box-border sui-m-0 sui-block',
    'sui-min-w-0 sui-w-full sui-h-11 sui-pl-3',
    'sui-whitespace-nowrap sui-overflow-hidden',
    'sui-border-1 sui-border-solid sui-border-transparent',
  ].join(' ');

  const inputContainerClasses = classNames(baseInputContainerClasses, customClasses?.inputContainer);

  const inputClasses = [
    'placeholder:sui-text-primary focus:placeholder:sui-text-transparent',
    'sui-min-w-0 sui-w-full sui-h-full sui-text-current sui-ring-0',
    'sui-outline-none sui-text-ellipsis sui-appearance-none'
  ].join(' ');

  return (
    <div className={controllerClasses}>
      <form
        className={rootClasses}
        data-testid="search-field-root"
        id="header-search"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...formProps}
      >
        <div
          data-testid="search-field-container"
          className={inputContainerClasses}
        >
          <input
            required
            ref={ref}
            className={inputClasses}
            style={{ font: 'inherit', letterSpacing: 'inherit', background: 'none' }}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...inputProps}
          />
          {showSearchClearIcon && ref?.current?.value?.length > 0 && (
            <span className="sui-flex sui-items-center sui-pr-1">
              <IconButton
                data-testid="typeahead-clear-icon-button"
                id="typeahead-clear-icon-button"
                aria-label="Clear Field Icon"
                icon={Close}
                color="primary"
                iconSize="small"
                onClick={onClearIconClick}
              />
            </span>

          )}
        </div>
        {showSearchIcon && (
          <div className="sui-w-11">
            <Button
              fullWidth
              id="typeahead-search-icon-button"
              aria-label="Search Field Icon"
              color="primary"
              onClick={onSearchIconClick}
            >
              <span className="sui-flex sui-items-center">
                <Search size="small" />
              </span>
            </Button>
          </div>
        )}
      </form>
    </div>
  );
});

SearchField.displayName = 'SearchField';

SearchField.propTypes = {
  onSearchIconClick: func,
  showSearchIcon: bool,
  onClearIconClick: func,
  showSearchClearIcon: bool,
  inverseTheme: bool,
  formProps: shape(),
  inputProps: shape(),
  customClasses: shape({
    inputContainer: string
  })
};

SearchField.defaultProps = {
  onSearchIconClick: undefined,
  showSearchIcon: true,
  onClearIconClick: undefined,
  showSearchClearIcon: true,
  inverseTheme: false,
  formProps: {},
  inputProps: {},
  customClasses: {
    inputContainer: null
  }
};

export { SearchField };
