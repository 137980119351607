import { useEffect } from 'react';

const useClickAway = (ref, onClickAway) => {
  useEffect(() => {
    const onClickAwayHandler = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickAway();
      }
    };

    document.addEventListener('click', onClickAwayHandler);
    return () => document.removeEventListener('click', onClickAwayHandler);

  }, [ref, onClickAway]);
};

export default useClickAway;
