// eslint-disable-next-line object-curly-newline
import React, { useState, useContext, useMemo, useEffect } from 'react';
import { ExperienceContext, useStore } from '@thd-nucleus/experience-context';
import { withErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { withDynamicComponent } from '@thd-nucleus/app-render';
import { withHydrator } from '@thd-olt-component-react/hydrator';
import { ProductPodUtils } from '@thd-olt-component-react/product-pod';
import {
  useDataModel,
  customType,
  extend,
  params,
  string as dsString,
  bool as dsBool,
  number as dsNumber,
  shape as dsShape,
  arrayOf as dsArrayOf,
  QueryProvider,
  QueryContext,
} from '@thd-nucleus/data-sources';
import {
  string, shape, arrayOf
} from 'prop-types';
import './RetailMediaProductListing.scss';
import { useThdCustomer } from '@thd-olt-functional/customer-information';
import { useNodeRef } from '../../hooks/useNodeRef';
import { getSchema, isExperienceDataResolved } from '../../utils';
import { RetailMediaProductListingPod } from './RetailMediaProductListingPod/RetailMediaProductListingPod';
import { useProductListingTelemetry } from '../../hooks';

const HYDRATION_STANDARD_SCROLL_BUFFER = 750;
const plaPrefix = 'sponsored-pip-pla';

const RetailMediaProductListingComponent = (props) => {
  let {
    browserId, pageContext, dataSource, breadCrumbs, categorySourceId, nValue, requestedAdCount, userId, plaLocation
  } = props;

  const experienceContext = useContext(ExperienceContext);
  const { defaultVariables } = useContext(QueryContext);
  const { storeId, isLocalized, storeZip } = useStore({ varnish: true, online: true });

  const channel = useMemo(() => {
    return experienceContext?.isConsumerApp ? 'mobileconsumer' : experienceContext?.channel;
  }, [experienceContext?.isConsumerApp, experienceContext?.channel]);
  /**
   * MCMID
   */
  const [mcmid, setMcmid] = useState(experienceContext?.cookie?.adobeCookie?.MCMID);

  // updater
  useEffect(() => {
    setMcmid((cur) => {
      // if the new incoming value is not set, then keep the current value.
      if (!experienceContext?.cookie?.adobeCookie?.MCMID) return cur;
      return experienceContext?.cookie?.adobeCookie?.MCMID;
    });
  }, [experienceContext?.cookie?.adobeCookie?.MCMID]);

  // --
  const thdCustomer = useThdCustomer();

  // --
  const validBrowserId = useMemo(() => {

    return browserId || thdCustomer?.mcvisID || mcmid || '';
  }, [browserId, mcmid, thdCustomer?.mcvisID]);

  // --
  const customerUserId = useMemo(() => {
    return userId || thdCustomer?.userID || thdCustomer?.guestUserID || null;
  }, [userId, thdCustomer?.userID, thdCustomer?.guestUserID]);

  // --
  const customerSvocId = useMemo(() => {
    return thdCustomer?.svocID || null;
  }, [thdCustomer?.svocID]);

  // --
  const zipCode = useMemo(() => {
    return experienceContext?.deliveryZip || storeZip || '30339';
  }, [experienceContext?.deliveryZip, storeZip]);

  // --
  const isResolvedContext = useMemo(() => {
    return isExperienceDataResolved({ isLocalized, experienceContext });
  }, [isLocalized, experienceContext]);

  // --
  const skip = useMemo(() => {
    return (!isResolvedContext);
  }, [isResolvedContext]);

  // node
  const [plaNodeRef, plaNode] = useNodeRef();

  // --
  const schema = useMemo(() => {
    return getSchema({
      props: { schema: props?.schema },
      schema: pageContext?.schema,
      pageContext: {
        label: pageContext?.label,
        isCategory: pageContext?.isCategory,
        isSearch: pageContext?.isSearch,
        isBrowse: pageContext?.isBrowse,
      },
    }) || 'pip_sponsored_pla';
  }, [
      props?.schema,
      pageContext?.schema,
      pageContext?.label,
      pageContext?.isCategory,
      pageContext?.isSearch,
      pageContext?.isBrowse
  ]);

  //-
  // Extracting the 'breadCrumbs' property and assigning it to the 'breadCrumbs' variable.
  const breadCrumbValue = breadCrumbs?.map(({ __typename, ...rest }) => {
    // Filtering out null values from the rest of the object properties.
    const filteredRest = Object.fromEntries(
      Object.entries(rest).filter(([key, value]) => value !== null)
    );
    // Returning a new object with non-null properties after filtering.
    return { ...filteredRest };
  }) || null;

  /**
   * GraphQL query: plaModel
   */
  // ----
  const resp = useDataModel('plaModel', {
    skip,
    skipInstallServices: true,
    variables: {
      breadCrumbs: breadCrumbValue,
      categorySourceId,
      channel,
      customer: {
        mcVisId: validBrowserId,
        customerType: experienceContext?.customer?.type,
        userId: customerUserId,
        svocId: customerSvocId,
      },
      debug: false,
      itemId: pageContext?.data?.itemId,
      itemIds: null,
      nValue,
      requestedAdCount,
      schema,
      storeId,
      zipCode,
      plaLocation,
    },
  });
  const { data, loading, error } = resp;

  /**
   * filteredProducts
   */
  // --- --- ---
  const product = useMemo(() => {
    // null value helps to determine skip condition.
    if (loading || error || !data) return null;

    const FilteredProducts = data?.plaModel?.products.filter((podInfo) => ProductPodUtils.isATCEnabled(podInfo));

    // Return the first product or undefined if not available
    return FilteredProducts[0];

  }, [data, error, loading]);
  // --- --- ---
  // ---
  const renderComponent = useMemo(() => {
    return Boolean(!loading && !error && product);
  }, [loading, error, product]);

  // -----------------
  // Telemetry
  // -----------------
  useProductListingTelemetry({
    plaNode,
    data,
    loading,
    error,
    product,
    requestedAdCount,
    renderComponent
  });

  // --
  return (
    <>
      {renderComponent && (
        <div
          ref={plaNodeRef}
          className={`${plaPrefix}-container`}
          data-component="SponsoredProductListing"
          data-testid="sponsored-product-listing"
        >
          <div className={`${plaPrefix}-content`}>
            <div className={`${plaPrefix}-pod`}>
              <QueryProvider
                cacheKey="pla-on-pip-ProductPod"
                dataSource="pla"
                defaultVariables={{
                  storeId,
                  zipCode,
                  ...(typeof defaultVariables?.current?.isBrandPricingPolicyCompliant === 'boolean' && {
                    isBrandPricingPolicyCompliant: defaultVariables.current.isBrandPricingPolicyCompliant
                  })
                }}
              >
                <RetailMediaProductListingPod
                  product={product}
                  storeId={storeId}
                  position={1}
                />

              </QueryProvider>
            </div>
            <p className={`${plaPrefix}-sponsored-tag ${channel}`}>Sponsored</p>
          </div>
        </div>
      )}
    </>
  );
};

const propTypes = {
  browserId: string,
  schema: string,
  userId: string,
  categorySourceId: string,
  nValue: string,
  requestedAdCount: Number,
  pageContext: shape({
    label: string,
    data: shape({
      itemId: Number,
    }),
  }),
  plaLocation: string.isRequired,
  dataSource: string,
  breadCrumbs: arrayOf(
    shape({
      browseUrl: string,
      label: string,
    })
  ),
};

const defaultProps = {
  browserId: '',
  pageContext: {},
  dataSource: 'pla',
  schema: 'pip_sponsored_pla',
  breadCrumbs: null,
  categorySourceId: null,
  requestedAdCount: 3,
  nValue: null,
  userId: '',
};

RetailMediaProductListingComponent.propTypes = propTypes;
RetailMediaProductListingComponent.defaultProps = defaultProps;

RetailMediaProductListingComponent.dataModel = extend({
  plaModel: params({
    breadCrumbs: dsArrayOf(
      customType('BreadCrumbInput').shape({
        browseUrl: dsString(),
        label: dsString(),
      })
    ),
    categorySourceId: dsString(),
    channel: dsString(),
    customer: customType('Customer').shape({
      mcVisId: dsNumber(),
      customerType: dsString(),
      userId: dsString(),
      svocId: dsString(),
    }),
    debug: dsBool(),
    itemId: dsString(),
    itemIds: dsArrayOf(dsString()),
    keyword: dsString(),
    nValue: dsString(),
    schema: dsString(),
    storeId: dsString(),
    requestedAdCount: dsNumber(),
    zipCode: dsString(),
    plaLocation: dsString(),
    isBrandPricingPolicyCompliant: dsBool(),
  }).shape({
    products: dsArrayOf(dsShape(RetailMediaProductListingPod.dataModel.product)),
    sponsoredReport: dsShape({
      numberOfSponsoredProducts: dsNumber(),
      numberOfMissingProductInformationProducts: dsNumber(),
      numberOfNonAvailableProducts: dsNumber(),
    }),
  }),
});

const QueryProvidedSponsoredProductListing = (props) => {
  const { defaultVariables } = useContext(QueryContext);

  return (
    <QueryProvider cacheKey="pla-on-pip" defaultVariables={{ ...(defaultVariables?.current || {}) }}>
      <RetailMediaProductListingComponent {...props} />
    </QueryProvider>
  );
};

QueryProvidedSponsoredProductListing.propTypes = propTypes;

QueryProvidedSponsoredProductListing.defaultProps = defaultProps;

QueryProvidedSponsoredProductListing.dataModel = extend({}, RetailMediaProductListingComponent);

const RetailMediaProductListing = withErrorBoundary(
  withDynamicComponent(
    withHydrator(
      {
        id: `hydrated-${plaPrefix}`,
        className: `${plaPrefix}-hydrator`,
        scrollBuffer: HYDRATION_STANDARD_SCROLL_BUFFER,
      },
      QueryProvidedSponsoredProductListing
    )
  )
);

const RetailMediaProductListingAd = RetailMediaProductListing;
RetailMediaProductListing.displayName = 'RetailMediaProductListing';
export { RetailMediaProductListing, RetailMediaProductListingAd };
