import React, { useContext } from 'react';
import classNames from 'classnames';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { Heading, Col, Row } from '@thd-olt-component-react/core-ui';
import { PackagesMiniContext } from '../PackagesMiniContext';
import { getPackageTitle, getPackageUrl } from '../../utils';
import './packages-header.scss';

const PackageHeader = () => {
  const { channel } = useContext(ExperienceContext);
  const isMobile = channel === 'mobile';
  const { packageModel = {} } = useContext(PackagesMiniContext);
  const title = getPackageTitle(packageModel);
  const packageUrl = getPackageUrl(packageModel);
  const headerClasses = classNames('packages-header', {
    'packages-header--border': !isMobile,
  });
  const headingClasses = classNames('packages-header__heading', {
    'packages-header__heading--border': !isMobile,
  });
  const bundleText = isMobile ? 'View Bundle' : 'View Bundle Page';
  return (
    <Row>
      <Col>
        <div
          className={headerClasses}
          data-testid="packages-header"
        >
          <Heading className={headingClasses} title={title} noMargin nopadding noborder />
          <a
            href={packageUrl}
            className="packages-header__url"
          >
            {bundleText}
          </a>
        </div>
      </Col>
    </Row>
  );
};

PackageHeader.displayName = 'PackageHeader';

export { PackageHeader };
