import React, { useRef } from 'react';
import {
  arrayOf, bool, shape, string, func, node, number
} from 'prop-types';
import { Card } from '@thd-olt-component-react/card';
import { Row, Col } from '@thd-olt-component-react/core-ui';
import { useProductDeprecated } from '@thd-nucleus/data-sources/react/dataModel/migration';
import { Button } from '@thd-olt-component-react/button';
import { getImageUrl, getSeoLink, shouldRenderFirstReview } from '../../helpers';
import { FirstReview } from '../FirstReview/FirstReview.component';
import { OverviewMobile } from '../Overview/OverviewMobile';
import { WriteAReviewForm } from '../WriteAReview/components/WriteAReviewForm/WriteAReviewForm';
import { CustomerImageCarousel } from '../CustomerImageCarousel/CustomerImageCarousel.component';
import { ReviewSentiments } from '../Filter/components/ReviewSentiments/ReviewSentiments.component';
import { MobileOverlay } from './MobileOverlay';
import { Review } from '../Review/Review.component';
import { dataModel } from '../ReviewDataModel';
import './RatingsAndReviewsMobile.scss';

// TODO: Removed Pros & Cons b/c the functionality doesn't currently do anything

export const RatingsAndReviewsMobileContent = ({
  apiKey,
  filterCheckboxes,
  children,
  itemId,
  mobileReviewsOnFirstPage,
  noResults,
  onDeselectFilter,
  onFilterChange,
  onAdditionalFilterChange,
  onPageChange,
  loading,
  pageContext,
  statistics,
  reviewModels,
  reviewStatistics,
  selected,
  searchTerm,
  sortBy,
  onSearch,
  onSearchClear,
  onSortChange,
  onSentimentChange,
  prosAndCons,
  selectedSentiment,
  target,
  seoPageNumber,
  category,
  storeId,
  seoStoreLink,
  totalReviewPages,
  hideOverlay
}) => {

  let title;

  if (category === 'product') {
    title = 'Customer Reviews';
  } else {
    title = 'Customer Store Reviews';
  }

  const cardClickRef = useRef();
  const { data, error, loading: productLoading } = useProductDeprecated({
    itemId,
    domains: ['identifiers', 'mediaSlim'],
    skip: category === 'store'
  });

  if (shouldRenderFirstReview(category, reviewModels, reviewStatistics)) {
    return (
      <Card noHide>
        <Card.Content maxHeight="none">
          <FirstReview
            reviewStatistics={reviewStatistics}
            itemId={itemId}
            apiKey={apiKey}
            className="outline"
          />
        </Card.Content>
      </Card>
    );
  }

  if (category === 'product' && ((error && !data) || !data || productLoading)) {
    return null;
  }

  const seoLink = getSeoLink(category,
    data?.product?.identifiers?.canonicalUrl,
    seoStoreLink,
    pageContext?.currentPage
  );

  const { product } = data || {};
  const { identifiers, media } = product || {};
  const { brandName = '', productLabel = '' } = identifiers || {};
  const imageUrl = getImageUrl(media);

  if (target === 'productDetailsRating') {
    return (
      <Card noHide className="card-rounded-corners ratings-and-reviews-mobile">
        <Card.Content>
          <Row>
            <Col xs="12" className="mobile-get-all-col">
              {children}
            </Col>
          </Row>
        </Card.Content>
      </Card>
    );
  }

  const isEventBusCreated = () => {
    return typeof window !== 'undefined' && window.LIFE_CYCLE_EVENT_BUS;
  };

  const closeCard = (scrollToBottom = false) => {
    if (typeof window !== 'undefined' && window.LIFE_CYCLE_EVENT_BUS) {
      window.LIFE_CYCLE_EVENT_BUS.trigger('ratings-reviews.card-click', { scrollToBottom });
    }
  };

  const triggerCardClick = (cardOnClick) => {
    if (!cardClickRef.current) {
      cardClickRef.current = cardOnClick;
    }
    if ((isEventBusCreated())) {
      window.LIFE_CYCLE_EVENT_BUS.on('ratings-reviews.card-click', ({ output }) => {
        const { scrollToBottom = false } = output;
        cardOnClick(this, 'all', scrollToBottom);
      });
    }
  };

  const handleCardClick = () => {
    if (cardClickRef.current) {
      cardClickRef.current(this, 'all', false);
    }
  };

  if (seoPageNumber || category === 'store') {
    return (
      <MobileOverlay
        apiKey={apiKey}
        itemId={itemId}
        reviewModels={reviewModels}
        onDeselectFilter={onDeselectFilter}
        onFilterChange={onFilterChange}
        onAdditionalFilterChange={onAdditionalFilterChange}
        onPageChange={onPageChange}
        filterCheckboxes={filterCheckboxes}
        reviewStatistics={reviewStatistics}
        selected={selected}
        selectedSentiment={selectedSentiment}
        brandName={brandName}
        imageUrl={imageUrl}
        productLabel={productLabel}
        prosAndCons={prosAndCons}
        onSearch={onSearch}
        onSearchClear={onSearchClear}
        onSentimentChange={onSentimentChange}
        searchTerm={searchTerm}
        onSortChange={onSortChange}
        sortBy={sortBy}
        noResults={noResults}
        loading={loading}
        pageContext={pageContext}
        statistics={statistics}
        storeId={storeId}
        category={category}
        seoPageNumber={seoPageNumber}
        hideOverlay={hideOverlay}
      />
    );
  }

  return (
    <Card
      noHide
      className="card-rounded-corners ratings-and-reviews-mobile"
      getOnClick={triggerCardClick}
    >
      <Card.Content
        title={title}
        maxHeight="none"
        className="ratings-and-reviews-mobile__card-content"
        onClick={() => handleCardClick()}
        underline={false}
        triggerFor="all"
      >
        <OverviewMobile
          apiKey={apiKey}
          reviewStatistics={reviewStatistics}
          itemId={itemId}
          handleCardClick={handleCardClick}
          hideSecondaryRatings
          category={category}
          storeId={storeId}
          seoStoreLink={seoStoreLink}
        />
        {category === 'product'
          && (<CustomerImageCarousel itemId={itemId} productLabel={productLabel} />
          )}
        {category === 'product' && prosAndCons
          ? (
            <ReviewSentiments
              itemId={itemId}
              selectedSentiment={selectedSentiment}
              onSentimentChange={onSentimentChange}
            />
          ) : ''}

        <Card.Content.Overlay name="all">
          <MobileOverlay
            apiKey={apiKey}
            itemId={itemId}
            reviewModels={reviewModels}
            onDeselectFilter={onDeselectFilter}
            onFilterChange={onFilterChange}
            onAdditionalFilterChange={onAdditionalFilterChange}
            onPageChange={onPageChange}
            filterCheckboxes={filterCheckboxes}
            reviewStatistics={reviewStatistics}
            selected={selected}
            selectedSentiment={selectedSentiment}
            brandName={brandName}
            imageUrl={imageUrl}
            productLabel={productLabel}
            prosAndCons={prosAndCons}
            onSearch={onSearch}
            onSearchClear={onSearchClear}
            onSentimentChange={onSentimentChange}
            searchTerm={searchTerm}
            onSortChange={onSortChange}
            sortBy={sortBy}
            noResults={noResults}
            loading={loading}
            pageContext={pageContext}
            statistics={statistics}
            category={category}
            storeId={storeId}
            seoPageNumber={seoPageNumber}
            totalReviewPages={totalReviewPages}
          />
        </Card.Content.Overlay>
        {category === 'product'
          && (
            <Card.Content.Overlay
              name="writeAReview"
              overlayTitle="Write A Review"
              className="ratings-and-reviews-mobile__overlay"
            >
              <WriteAReviewForm
                apiKey={apiKey}
                itemId={itemId}
                reviewStatistics={reviewStatistics}
                product={product}
                closeOverlay={() => closeCard()}
              />
            </Card.Content.Overlay>
          )}
      </Card.Content>
      <Card.Footer nopadding>
        <div className="ratings-and-reviews-mobile__feature-heading">
          Featured Reviews
        </div>
        {mobileReviewsOnFirstPage.map((review, reviewIndex) => {
          return (
            <Review
              review={review}
              id={reviewIndex}
              key={`review-${reviewIndex}`}
              apiKey={apiKey}
              shortenReviews
            />
          );
        })}
      </Card.Footer>
      <Card.Footer
        triggerFor="all"
        className="ratings-and-reviews-mobile__see-all-reviews"
        nopadding
      >
        <Button onClick={() => handleCardClick()}>
          <a className="seo_link" onClick={(evt) => evt.preventDefault()} href={`${seoLink}`}>
            See All Reviews
          </a>
        </Button>
      </Card.Footer>
    </Card>
  );
};

RatingsAndReviewsMobileContent.displayName = 'RatingsAndReviewsMobileContent';

RatingsAndReviewsMobileContent.propTypes = {
  apiKey: string,
  children: node,
  itemId: string,
  filterCheckboxes: arrayOf(shape({})).isRequired,
  loading: bool,
  mobileReviewsOnFirstPage: arrayOf(shape({})).isRequired,
  noResults: bool,
  onAdditionalFilterChange: func.isRequired,
  onPageChange: func.isRequired,
  onDeselectFilter: func.isRequired,
  onFilterChange: func.isRequired,
  onSearchClear: func.isRequired,
  reviewModels: arrayOf(shape({})).isRequired,
  reviewStatistics: shape({}).isRequired,
  searchTerm: string,
  selected: arrayOf(shape({})),
  onSearch: func.isRequired,
  onSortChange: func.isRequired,
  onSentimentChange: func.isRequired,
  pageContext: shape({
    currentPage: number
  }),
  prosAndCons: bool,
  target: string.isRequired,
  selectedSentiment: shape({}),
  sortBy: string.isRequired,
  statistics: shape({
    totalReviewCount: number
  }),
  storeId: string,
  category: string,
  seoPageNumber: number,
  seoStoreLink: string,
  totalReviewPages: number,
  hideOverlay: bool
};

RatingsAndReviewsMobileContent.defaultProps = {
  apiKey: '',
  children: null,
  loading: false,
  noResults: true,
  pageContext: null,
  selected: [{}],
  selectedSentiment: {},
  statistics: null,
  storeId: null,
  itemId: null,
  category: 'product',
  seoPageNumber: null,
  prosAndCons: false,
  searchTerm: '',
  seoStoreLink: null,
  totalReviewPages: null,
  hideOverlay: false
};

RatingsAndReviewsMobileContent.dataModel = dataModel;
