import React from 'react';
import { string } from 'prop-types';

import { Row } from '@thd-olt-component-react/core-ui';

import { RENDER_VISUAL } from '../constants';
import { PackagesMiniAbTest } from './PackagesMiniAbTest';

const PackageMiniGM = ({ itemId }) => (
  <div data-testid="pacakge-mini-visual" className="sui-w-full">
    <PackagesMiniAbTest
      itemId={itemId}
      renderComponent={RENDER_VISUAL}
      layoutGroup={{
        tag: Row
      }}
    />
  </div>
);

PackageMiniGM.propTypes = {
  itemId: string.isRequired
};

PackageMiniGM.displayName = 'PackageMiniGM';

export { PackageMiniGM };