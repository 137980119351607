// Temporary Component to avoid SSR error for fusion accordion
import React from 'react';
import PropTypes from 'prop-types';
import { SpecificationsAccordionTitle } from '@thd-olt-component-react/specifications';

export const BundleSpecificationsAccordionTitle = (props) => {
  const { itemId, showSubtitle } = props;

  return (
    <SpecificationsAccordionTitle itemId={itemId} showSubtitle={showSubtitle} />
  );
};

BundleSpecificationsAccordionTitle.displayName = 'BundleSpecificationsAccordionTitle';
BundleSpecificationsAccordionTitle.propTypes = {
  itemId: PropTypes.string.isRequired,
  showSubtitle: PropTypes.bool
};
BundleSpecificationsAccordionTitle.defaultProps = {
  showSubtitle: false
};
