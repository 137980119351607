import React, { useEffect } from 'react';
import { string as stringType } from 'prop-types';
import {
  params,
  shape,
  string,
  useDataModel,
  customType,
  fragment,
  arrayOf, typename,
} from '@thd-nucleus/data-sources';
import './anchor-links.scss';
import { AnchorLinksRenderer } from './AnchorLinksRenderer';

const AnchorLinks = ({ componentId, componentClass }) => {
  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('anchor-links.ready');
  }, []);

  const { data } = useDataModel('component', {
    variables: {
      id: componentId,
      componentClass,
    },
  });

  return (
    <AnchorLinksRenderer
      componentId={componentId}
      componentClass={componentClass}
      linkList={data?.component?.linkList || []}
    />
  );
};

AnchorLinks.propTypes = {
  componentId: stringType.isRequired,
  componentClass: stringType.isRequired
};

AnchorLinks.displayName = 'AnchorLinks';

AnchorLinks.dataModel = {
  component: params({
    id: string().isRequired(),
    componentClass: customType('ComponentClass').enum(['AnchorLinks']).isRequired(),
  }).shape({
    AnchorLinks: fragment().shape({
      id: string(),
      __typename: typename('AnchorLinks'),
      linkList: arrayOf(
        shape({
          id: string(),
          label: string(),
          href: string(),
        })
      ),
    }),
  }),
};

export { AnchorLinks };
