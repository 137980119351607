import React from 'react';
import { node } from 'prop-types';
import { getAuthHeadersForAPI, getCommonHeadersForAPI } from '@thd-nucleus/request-manager/shared';
import { DataProviderClient } from '@thd-nucleus/data-sources';
import { getGraphqlHost } from '../../utils/headerUtils';

const HeaderClientContext = ({ children }) => {
  const isServer = typeof window === 'undefined';
  const canReadBrowserCookie = !isServer && typeof window.cookieUtils?.readBrowserCookie === 'function';
  const isDebugMode = isServer ? false : window?.isDebugMode;
  const requestMgrAuthHeaders = isServer ? {} : { ...getAuthHeadersForAPI() };
  const requestMgrHeadersForAPI = !canReadBrowserCookie ? {} : { ...getCommonHeadersForAPI({ isServer: false }) };
  const envHost = getGraphqlHost();

  const clientContext = {
    authHeaders: {
      'X-Experience-Name': 'header-footer-static',
      'x-thd-customer-token': '',
      'x-thd-sso': '',
      ...requestMgrAuthHeaders
    },
    headers: {
      'X-Experience-Name': 'header-footer-static',
      ...requestMgrHeadersForAPI
    },
    opts: {
      federation: true,
      disableLocalization: false
    },
    isDebugMode
  };

  return (
    <DataProviderClient
      debug={clientContext.isDebugMode}
      federation={clientContext.opts.federation}
      headers={clientContext.headers}
      authHeaders={clientContext.authHeaders}
      credentials="include"
      host={envHost}
    >
      {children}
    </DataProviderClient>
  );
};

HeaderClientContext.displayName = 'HeaderClientContext';

HeaderClientContext.propTypes = {
  children: node
};
HeaderClientContext.defaultProps = {
  children: null
};

export default HeaderClientContext;
