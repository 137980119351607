import React, { useContext, } from 'react';
import {
  Popover,
  FormController,
  RadioGroup,
  Radio,
  SelectionControlLabel,
  Typography,
} from '@one-thd/sui-atomic-components';

import PropTypes from 'prop-types';
import { MOBILE } from '@thd-olt-functional/utils';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { ENGLISH_LANG_CODE, SPANISH_LANG_CODE } from '../commons/constants';

export const ControllerPopover = ({ anchor: anchorEl, close, preferredLanguage, handleChange }) => {
  const { channel } = useContext(ExperienceContext);
  const isMobile = channel === MOBILE;
  const zIndexclass = isMobile ? 'sui-z-tooltip' : 'sui-z-800';
  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={close}
      placement="bottom-end"
      title="Select Language"
      className={zIndexclass}
    >
      <div className={`${!isMobile && 'sui-w-64'} sui-h-24`}>
        <FormController>
          <RadioGroup
            orientation="horizontal"
            aria-labelledby="translation-language-options"
            name="horizontal-radio-buttons-group"
            value={preferredLanguage}
            onChange={handleChange}
          >
            <span className={`${isMobile ? 'sui-mr-20' : 'sui-mr-10'}`}>
              <SelectionControlLabel value={ENGLISH_LANG_CODE} label="English" translate="no">
                <Radio />
              </SelectionControlLabel>
            </span>
            <SelectionControlLabel value={SPANISH_LANG_CODE} label="Español" translate="no">
              <Radio />
            </SelectionControlLabel>
          </RadioGroup>
        </FormController>
        <Typography variant="body-xs">
          {preferredLanguage === ENGLISH_LANG_CODE
            ? 'Disclaimer: The accuracy of the translations may vary and not all text may be translated.'
            // eslint-disable-next-line max-len
            : 'Descargo de responsabilidad: la precisión de las traducciones puede variar y es posible que no todo el texto esté traducido.'}
        </Typography>
      </div>
    </Popover>
  );
};

ControllerPopover.propTypes = {
  anchor: PropTypes.element,
  preferredLanguage: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};

ControllerPopover.defaultProps = {
  anchor: null,
  preferredLanguage: ENGLISH_LANG_CODE,
};
