import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { extend } from '@thd-nucleus/data-sources';
import {
  Tile, TileContent, Typography, TileMedia, TileText
} from '@one-thd/sui-atomic-components';
import {
  ConfirmedFilled, Delivery, DeliveryExpress, InStorePickup, Store
} from '@one-thd/sui-icons';
import { StoreSelector } from '../StoreSelector/StoreSelector';
import '../../styles/availability-filters.scss';
import { ProductResultsContext } from '../ProductResultsContext';

const AvailabilityRefinement = ({
  refinement,
  onDisplayData,
  active,
  isMobile,
  id,
  onChange,
  dimension
}) => {

  const { selected } = refinement;
  const { onDisplay, onDisplayChange, itemCount } = onDisplayData;
  const { data } = useContext(ProductResultsContext);
  const { isStoreDisplay } = data?.searchModel?.metadata || false;

  const isTileSelected = selected || active;
  const isOnDisplayInUrl = onDisplay === 'true';

  const isOnDisplayTile = () => {
    return refinement?.label === 'Pick Up Today' && isStoreDisplay === 'true';
  };

  const isTileClicked = isOnDisplayTile() ? isOnDisplayInUrl : isTileSelected;
  const isUnavailable = isOnDisplayTile()
    ? parseInt(itemCount, 10) === 0 : parseInt(refinement?.recordCount, 10) === 0;

  const onChangeForOnDisplay = () => {
    // when onDisplay filters is selected/removed from leftNav/hortizontal-getItFast
    if (onDisplayChange) {
      window.LIFE_CYCLE_EVENT_BUS.trigger('filters-refinements-visualNav.click', {
        dimensionName: 'Get It Fast',
        label: 'On Display',
        refinementLabel: 'On Display',
        refinementKey: '',
        selected: onDisplay === 'false' ? null : true
      });

      return (!!data && onDisplay === 'false') ? onDisplayChange({ useStoreFilter: 'true' })
        : onDisplayChange({ useStoreFilter: 'false' });
    }
    return null;
  };

  const onChangeForTileClick = () => {
    if (onChange) {
      if (isOnDisplayTile()) {
        onChangeForOnDisplay();
      } else {
        onChange({
          refinement, dimension, onDisplay
        });
      }
    }
  };

  const generateRefinementLabel = (isRefinementDisabled) => {
    // eslint-disable-next-line no-nested-ternary
    const displayLabel = refinement?.refinementKey === '1z175a5'
      ? isStoreDisplay === 'false' ? 'In Stock at Store Today' : 'On Display In Store'
      : refinement?.label;

    return (
      <Typography
        variant="body-base"
        color={isRefinementDisabled ? 'inactive' : 'primary'}
        align={isMobile ? 'center' : 'left'}
      >
        {displayLabel}
      </Typography>
    );
  };

  const generateIcon = () => {
    const { refinementKey } = refinement || null;
    if (refinementKey === '1z175a5' && isStoreDisplay === 'false') {
      return <InStorePickup color="primary" />;
    }
    if (refinementKey === '1z175a5' && isStoreDisplay === 'true') {
      return <Store color="primary" />;
    }
    if (refinementKey === 'bwo6i') {
      return <Delivery color="primary" />;
    }
    if (refinementKey === '1z175cq') {
      return <DeliveryExpress color="primary" />;
    }
    return null;
  };

  const nonMobileClasses = 'sui-justify-start sui-w-auto sui-pt-2 sui-px-4 min-w-fit '
    + 'focus-visible:sui-outline focus-visible:sui-outline-input-focus '
    + 'focus-visible:sui-outline-4 focus-visible:-sui-outline-offset-4 '
    + 'hover:sui-outline-button-hover-accent hover:sui-outline-2 hover:-sui-outline-offset-2';

  const isTitleClickedClasses = 'sui-border-2 sui-border-solid sui-border-strongest '
    + 'hover:sui-outline-input-active hover:sui-bg-subtle';

  const refinementClassName = classNames('sui-p-2 sui-rounded-base sui-bg-primary sui-h-11', {
    [nonMobileClasses]: !isMobile,
    'sui-flex-row sui-p-0 sui-overflow-x-auto': isMobile,
    [isTitleClickedClasses]: isTileClicked,
    'sui-opacity-70 sui-bg-transparent': isUnavailable,
    'sui-border-1 sui-border-solid sui-border-primary': isUnavailable || !isTileClicked
  });

  return (
    <>
      <Tile
        className={refinementClassName}
        value={refinement?.label}
        aria-label={refinement?.label}
        onClick={onChangeForTileClick}
        id={`${id}-${refinement?.label}`}
        key={`${id}-${refinement?.label}`}
        orientation="vertical"
        disabled={isUnavailable}
      >
        <TileContent
          className="sui-flex sui-items-center sui-gap-2"
          orientation="horizontal"
          alignItems={isMobile ? 'center' : 'start'}
          disableGutters
        >
          <div className="sui-contents">
            {generateIcon()}
          </div>
          <div className="sui-text-sm">
            <TileText>
              {generateRefinementLabel()}
            </TileText>
          </div>
          {isTileClicked && (
            <div className="sui-absolute sui-contents">
              <ConfirmedFilled size="small" color="primary" />
            </div>
          )}
        </TileContent>
      </Tile>
    </>
  );
};

AvailabilityRefinement.propTypes = {
  dimension: PropTypes.shape({}),
  refinement: PropTypes.shape({
    label: PropTypes.string,
    recordCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    refinementKey: PropTypes.string,
    selected: PropTypes.bool,
    url: PropTypes.string,
  }),
  onDisplayData: PropTypes.shape({
    onDisplay: PropTypes.string,
    onDisplayChange: PropTypes.func,
    itemCount: PropTypes.string
  }),
  active: PropTypes.bool,
  isMobile: PropTypes.bool,
  id: PropTypes.string,
  onChange: PropTypes.func
};

AvailabilityRefinement.defaultProps = {
  dimension: null,
  refinement: null,
  onDisplayData: {
    onDisplay: 'false',
    onDisplayChange: null,
    itemCount: null
  },
  active: false,
  isMobile: false,
  id: null,
  onChange: null
};

AvailabilityRefinement.displayName = 'AvailabilityRefinement';

AvailabilityRefinement.dataModel = extend(StoreSelector);

export { AvailabilityRefinement };
