/* eslint-disable max-len */
import React, { useMemo } from 'react';
import {
  arrayOf,
  bool as boolType,
  string as stringType,
  node,
  shape,
  number as numType
} from 'prop-types';
import {
  extend,
  string,
  QueryProvider,
  params,
} from '@thd-nucleus/data-sources';
import { Carousel } from '@one-thd/sui-carousel';
import classNames from 'classnames';
import { Typography } from '@one-thd/sui-atomic-components';
import { RecentlyViewedProductPod } from './RecentlyViewedProductPod';

const Wrapper = ({ children }) => {
  return (
    <>
      {children}
    </>
  );
};

Wrapper.dataModel = extend({
  product: params({ itemId: string().isRequired() }).shape({
    dataSource: string(),
  })
}, RecentlyViewedProductPod);

Wrapper.propTypes = {
  children: node
};
Wrapper.defaultProps = {
  children: null
};

const CarouselHeader = ({ title }) => (
  <div className="sui-mt-4 sui-mb-2" data-testid="recently-viewed-carousel-header">
    <div className="sui-mb-3">
      <Typography variant="body-small" weight="bold">{title}</Typography>
    </div>
  </div>
);

CarouselHeader.datamodel = {};

CarouselHeader.propTypes = {
  title: stringType,
};

CarouselHeader.defaultProps = {
  title: undefined,
};

const RecentlyViewedCarousel = (props) => {

  const {
    brandTitleMaxLine,
    hideTitle,
    hideCarouselArrows,
    isMobile,
    moduleName,
    products,
    storeId,
    title,
    zipCode,
  } = props;

  const defaultVariables = useMemo(() => ({
    storeId,
    zipCode,
    installationServices: false,
    skipFavoriteCount: true
  }), [storeId, zipCode]);

  const colSpanClasses = useMemo(() => classNames({ 'sui-col-span-2': !isMobile }), [isMobile]);

  const breakpoints = {
    sm: {
      slidesPerView: 3,
      slidesPerGroup: 3
    },
    md: {
      slidesPerView: 3,
      slidesPerGroup: 3
    },
    lg: {
      slidesPerView: !isMobile ? 4 : 3,
      slidesPerGroup: !isMobile ? 4 : 3
    },
    xl: {
      slidesPerView: 4,
      slidesPerGroup: 4
    },
    '2xl': {
      slidesPerView: !isMobile ? 4 : 3,
      slidesPerGroup: !isMobile ? 4 : 3
    }
  };

  return (
    <>
      {products?.length > 0 && (
        <>
          {!hideTitle && (
            <CarouselHeader title={title} />
          )}
          <div data-testid="recently-viewed-carousel" className={classNames('sui-pl-2')}>
            <div className={colSpanClasses}>
              <QueryProvider
                dataSource="rv_typeahead_rr"
                cacheKey={`${moduleName}-typeahead-module`}
                defaultVariables={defaultVariables}
              >
                <Carousel
                  breakpoints={breakpoints}
                  hideCarouselArrows={hideCarouselArrows}
                  disableMargin
                  spaceBetween={0}
                  slidesPerView={3}
                  slidesPerGroup={3}
                >
                  {products.map((item, idx) => {
                    const { product } = item;
                    const { itemId } = product;
                    return (
                      <Wrapper key={idx}>
                        <RecentlyViewedProductPod
                          brandTitleMaxLine={brandTitleMaxLine}
                          disableTitle={isMobile}
                          key={`recently-viewed-typeahead-${itemId}`}
                          itemId={itemId}
                          moduleName={moduleName}
                          position={idx}
                          storeId={storeId}
                        />
                      </Wrapper>
                    );
                  }
                  )}
                </Carousel>
              </QueryProvider>
            </div>
          </div>
        </>
      )}
    </>
  );
};

RecentlyViewedCarousel.dataModel = extend(Wrapper);

RecentlyViewedCarousel.displayName = 'RecentlyViewedCarousel';

RecentlyViewedCarousel.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  brandTitleMaxLine: numType,
  hideTitle: boolType,
  hideCarouselArrows: boolType,
  isMobile: boolType,
  moduleName: stringType,
  products: arrayOf(shape({})),
  storeId: stringType.isRequired,
  title: stringType,
  zipCode: stringType
};

RecentlyViewedCarousel.defaultProps = {
  brandTitleMaxLine: 2,
  hideTitle: false,
  hideCarouselArrows: false,
  isMobile: false,
  moduleName: 'recentlyViewed',
  products: null,
  title: null,
  zipCode: null
};

export { RecentlyViewedCarousel };
