import React, { forwardRef } from 'react';
import {
  arrayOf, shape, string, func, number, oneOf
} from 'prop-types';
import { MAX_DISPLAYED_HOTSPOTS_COUNT, HOTSPOT_TYPE } from '../../constants';
import { ShowRelatedItems } from './ShowRelatedItems';
import { LifeStyleHotspot } from './LifeStyleHotspot';
import { triggerAnalytics } from '../../helpers';
import './styles/hotspot.scss';

const LifestyleImageWithHotspot = forwardRef(({
  hotspots, imageUrl, tagIndex, imgOnclick, handleClick, mediaGalleryIndex, type, onShowFirstTagProduct
}, ref) => {

  const onClick = (omsIDs, index, collapsedCount) => {
    handleClick(type === HOTSPOT_TYPE.PIP_HOTSPOTS ? true : omsIDs, index);

    if (collapsedCount && type === HOTSPOT_TYPE.PIP_HOTSPOTS) {
      triggerAnalytics('product-hotspots.collapsed-hotspot-pip-click', { skus: omsIDs });
    } else if (collapsedCount && type === HOTSPOT_TYPE.DRAWER_HOTSPOTS) {
      triggerAnalytics('product-hotspots.collapsed-hotspot-drawer-click', { skus: omsIDs });
    }
  };

  return (
    <div className="product-hotspots sui-relative" data-component="MediaHotspots" ref={ref}>
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <img
        alt="lifestyleimage"
        className="sui-w-full sui-h-full"
        src={imageUrl}
        height="1"
        width="1"
        onClick={imgOnclick}
        aria-label="lifestyleImage"
      />
      {hotspots.length > 0
        && type === HOTSPOT_TYPE.PIP_HOTSPOTS && <ShowRelatedItems showFirstTagProduct={onShowFirstTagProduct} />}
      {hotspots.length > 0 && hotspots.slice(0, MAX_DISPLAYED_HOTSPOTS_COUNT).map((hotspot, index) => {
        const { coordinate, omsIDs, collapsedCount } = hotspot;
        return (
          <div
            id={`drawer-wrapper-div-${index}`}
            key={type === HOTSPOT_TYPE.PIP_HOTSPOTS ? mediaGalleryIndex + '-' + index : index}
          >
            <LifeStyleHotspot
              xCoordinate={parseFloat(coordinate.xCoordinate)}
              yCoordinate={parseFloat(coordinate.yCoordinate)}
              omsIDs={omsIDs}
              index={index}
              currentTagIndex={tagIndex}
              type={type}
              handleClick={() => onClick(omsIDs, index, collapsedCount)}
              collapsedCount={collapsedCount}
            />
          </div>
        );
      })}
    </div>
  );
});

LifestyleImageWithHotspot.displayName = 'LifestyleImageWithHotspot';

LifestyleImageWithHotspot.propTypes = {
  hotspots: arrayOf(shape({
    coordinate: shape({
      xCoordinate: string,
      yCoordinate: string
    }),
    omsIDs: arrayOf(string),
    collapsedCount: number,
  })).isRequired,
  imageUrl: string.isRequired,
  tagIndex: number,
  imgOnclick: func,
  handleClick: func,
  type: oneOf([HOTSPOT_TYPE.PIP_HOTSPOTS, HOTSPOT_TYPE.DRAWER_HOTSPOTS]),
  mediaGalleryIndex: number,
  onShowFirstTagProduct: func
};

LifestyleImageWithHotspot.defaultProps = {
  imgOnclick: null,
  handleClick: null,
  tagIndex: 0,
  type: HOTSPOT_TYPE.PIP_HOTSPOTS,
  mediaGalleryIndex: 0,
  onShowFirstTagProduct: null
};

export { LifestyleImageWithHotspot };
