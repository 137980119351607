import React from 'react';
import { bool } from 'prop-types';
import {
  Skeleton,
  SkeletonBlock,
  SkeletonContent
} from '@one-thd/sui-atomic-components';

const EndcapPodPlaceholder = ({ placeholderPodNumber }) => {
  return [...Array(placeholderPodNumber)].map((_placeholder, _placeholderKey) => {
    return (
      <SkeletonContent disablePadding>
        <SkeletonBlock height={60} />
        <SkeletonBlock height={8} width={60} />
        <SkeletonBlock height={5} width={36} />
        <SkeletonBlock height={8} width={36} />
      </SkeletonContent>
    );
  });
};

const EndcapPlaceholder = ({ tabClick = null }) => {

  let PLACEHOLDER_TAB_NUM = 8;
  let PLACEHOLDER_POD_NUM = 5;
  const podContainerClasses = [
    'sui-flex sui-w-full sui-overflow-hidden sui-pt-5 sui-h-80 xl:sui-h-full',
    'sui-gap-4 sm:sui-gap-12',
    '[&>*:nth-child(3)]:sui-hidden [&>*:nth-child(4)]:sui-hidden [&>*:nth-child(5)]:sui-hidden',
    'md:[&>*:nth-child(3)]:sui-flex lg:[&>*:nth-child(4)]:sui-flex xl:[&>*:nth-child(5)]:sui-flex'
  ].join(' ');

  if (tabClick) {
    return (
      <div className="sui-flex sui-flex-col sui-w-full sui-gap-4 sui-p-4" data-component="EndcapTabPlaceholder">
        <div className="sui-grow">
          <div className={podContainerClasses}>
            <EndcapPodPlaceholder placeholderPodNumber={PLACEHOLDER_POD_NUM} />
          </div>
        </div>
        <SkeletonBlock height={11} />
      </div>
    );
  }

  return (
    <div
      className="sui-flex sui-flex-col sui-overflow-hidden sui-p-4 sui-w-full"
      data-component="EndcapPlaceholder"
    >
      <div className="sui-flex sui-flex-col sui-gap-4 sui-w-full">
        <div className="sui-w-72 sm:sui-w-96">
          <SkeletonBlock height={11} />
        </div>
        <div className="sui-grow">
          <div className="sui-flex sui-overflow-hidden sui-gap-4">
            {[...Array(PLACEHOLDER_TAB_NUM)].map((idx, idxKey) => {
              return (
                <div className="sui-w-full" key={idxKey}>
                  <SkeletonBlock height={11} />
                </div>
              );
            })}
          </div>
          <div className="sui-grow">
            <div className={podContainerClasses}>
              <EndcapPodPlaceholder placeholderPodNumber={PLACEHOLDER_POD_NUM} />
            </div>
          </div>
        </div>
        <SkeletonBlock height={11} />
      </div>
    </div>
  );
};

export { EndcapPlaceholder };

EndcapPlaceholder.propTypes = {
  tabClick: bool
};
