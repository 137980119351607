/* eslint-disable no-console */
/* eslint-disable max-len */
/* eslint-disable consistent-return */
/* eslint-disable no-case-declarations */
export const EVENTS = {
  QUANTITY_CHANGE: 'quantityChange',
  FULFILLMENT_CHANGE: 'fulfillmentChange'
};

let EVENT_LOGGING_ON = true;
export const initAnalytics = (forceOn = false) => {
  try {
    // TODO: allow analytics (and newrelic) to be suppressed when running
    // automation, development, unit-tests etc.
    if (typeof window !== 'undefined') {
      const href = window.location.href;
      if (href.includes('automation=true')
      || href.includes('localhost')
      || window.isUnitTest
      // || other conditions...
      ) {
        EVENT_LOGGING_ON = false;
      }
    }
    if (forceOn) {
      EVENT_LOGGING_ON = true;
    }
  } catch (err) {
    console.error(err);
  }
};

const logAnalyticsForQuantityChange = ({ errors }) => {
  try {
    if (!errors) {
      return;
    }
    const quantityErrors = errors.filter((err) => {
      return err?.errorCode === 'CART_ERR_135';
    });
    if (quantityErrors) {
      const eventObject = {
        primaryCategory: 'error',
        eventInfo: {
          errors: [],
        }
      };
      quantityErrors.forEach((err) => {
        eventObject.eventInfo.errors.push(err?.description);
      });
      if (EVENT_LOGGING_ON) {
        LIFE_CYCLE_EVENT_BUS.trigger('cartItem.DD_EVENT', eventObject);
      } else if (!sessionStorage.getItem('hushAnalytics')) {
        console.log(
          'Analytics suppressed:', 'LIFE_CYCLE_EVENT_BUS.trigger(\'cartItem.DD_EVENT\', eventObject)',
          eventObject, '(run "sessionStorage.setItem(\'hushAnalytics\', true);" in console'
          + ' to turn off this logging in local)');
      }

    }
  } catch (err) {
    console.error(err);
  }
};

const logNewRelicsForQuantityChange = (variables, updateType) => {
  try {
    const { itemId, quantity } = variables;
    const newRelicAnalytics = {
      itemId,
      quantity
    };
    if (typeof LIFE_CYCLE_EVENT_BUS !== 'undefined') {
      const eventObject = { actionName: updateType, actionObject: newRelicAnalytics };
      if (EVENT_LOGGING_ON) {
        LIFE_CYCLE_EVENT_BUS.trigger('cart.cartPageAction', eventObject);
      } else if (!sessionStorage.getItem('hushAnalytics')) {
        console.log('NewRelic suppressed:', 'LIFE_CYCLE_EVENT_BUS.trigger(\'cart.cartPageAction\', eventObject)',
          eventObject);
      }

    }
  } catch (err) {
    console.log(err);
  }
};

export const logAnalytics = ({
  mutationName,
  variables,
  updateType,
  resp
}) => {
  if (updateType === EVENTS.QUANTITY_CHANGE) {
    logAnalyticsForQuantityChange(resp);
    logNewRelicsForQuantityChange(updateType, variables);
  }
};

export const logInvalidSessionPageReload = () => {
  try {
    if (typeof LIFE_CYCLE_EVENT_BUS !== 'undefined') {
      const eventObject = { actionName: 'invalidSessionPageReload', actionObject: { cartId: '' } };
      if (EVENT_LOGGING_ON) {
        LIFE_CYCLE_EVENT_BUS.trigger('cart.cartPageAction', eventObject);
      } else if (!sessionStorage.getItem('hushAnalytics')) {
        console.log('NewRelic suppressed:', 'LIFE_CYCLE_EVENT_BUS.trigger(\'cart.cartPageAction\', eventObject)',
          eventObject);
      }

    }
  } catch (err) {
    console.log(err);
  }
};
export const getLoggingOn = () => (EVENT_LOGGING_ON);

export const SELECTED_FFM_TYPES = {
  BOPIS: 'BOPIS',
  BOSS: 'ShipToStore',
  STH: 'ShipToHome',
  BODFS: 'DeliverFromStore',
  APPL_DELIV: 'DirectDelivery'
};

export const ADDON_TYPES = Object.freeze({
  HDPP: 'protectionPlan',
  ASSEMBLY: 'Assembly',
  FREE_ASSEMBLY: 'freeAssemblyAtStore',
  INSTALL: 'Install',
  PRO_INSTALL: 'PRInstall',
  PAINTER: 'Painter',
  SUBSCRIPTION: 'subscription'
});

export const GIFT_CARD = 'GIFT_CARD';

export function getQueryParam(param) {
  try {
    const location = window.location;
    const re = new RegExp(param + '=([^&?]*)');
    const result = re.exec(location.search || location.hash);
    if (!result) return null;
    return result[1];
  } catch (err) {
    console.error(err);
  }
  return '';
}

const getPromoCodeData = ({ data }) => {
  try {
    if (data?.cartInfo?.promos?.length) {
      return data?.cartInfo?.promos?.find((promo) => promo?.promoCode
        && promo?.promoItems?.length
        && promo?.type?.toLowerCase() === 'order level');
    }
  } catch (error) {
    console.error(error);
  }
  return {};
};

const getItemMapPolicy = ({ item, isCustomerIdentified }) => {
  if (item?.product?.pricing?.mapDetail?.mapPolicy !== 'showSpecialPriceIfAuth') {
    return 'n/a';
  }
  return isCustomerIdentified ? 'lg map policy auth' : 'lg map policy unauth';
};

const getFfmMethodForAnalytics = (ffmMethod) => {
  switch (ffmMethod) {
  case SELECTED_FFM_TYPES.BOPIS:
    return 'bopis';
  case SELECTED_FFM_TYPES.BOSS:
    return 'boss';
  case SELECTED_FFM_TYPES.STH:
    return 'sth';
  case SELECTED_FFM_TYPES.BODFS:
    return 'bodfs';
  case SELECTED_FFM_TYPES.APPL_DELIV:
    return 'appl';
  default:
    return '';
  }
};

export const bopis = (fulfillment) => {
  const pickupService = (fulfillment?.fulfillmentOptions || []).find((option) => option.type === 'pickup');
  const bopisFulfillment = (pickupService?.services || []).find((service) => service.type === 'bopis');
  return bopisFulfillment || null;
};

export const sth = (fulfillment) => {
  const deliveryService = (fulfillment?.fulfillmentOptions || []).find((option) => option.type === 'delivery');
  const sthFulfillment = (deliveryService?.services || []).find((service) => service.type === 'sth');
  return sthFulfillment || null;
};

export const boss = (fulfillment) => {
  const pickupService = (fulfillment?.fulfillmentOptions || []).find((option) => option.type === 'pickup');
  const bossFulfillment = (pickupService?.services || []).find((service) => service.type === 'boss');
  return bossFulfillment || null;
};

export const bodfs = (fulfillment) => {
  const deliveryService = (fulfillment?.fulfillmentOptions || []).find((option) => option.type === 'delivery');
  const bodfsFulfillment = (deliveryService?.services || []).find((service) => service.type === 'express delivery');
  return bodfsFulfillment || null;
};

export const applianceDelivery = (fulfillment) => {
  const deliveryService = (fulfillment?.fulfillmentOptions || []).find((option) => option.type === 'delivery');
  const bodfsFulfillment = (deliveryService?.services || []).find((service) => service.type === 'direct delivery');
  return bodfsFulfillment || null;
};

const getStoreOrZip = ({ item }) => {
  try {
    const selectedFFM = item?.selectedFulfillment || '';
    const fulfillment = item?.product?.fulfillment || {};
    let locations = [];

    switch (selectedFFM) {
    case SELECTED_FFM_TYPES.BOPIS:
      const bopisService = bopis(fulfillment);
      locations = bopisService?.locations || [];
      const isBopisPrimaryStoreOOS = !locations?.[0]?.inventory?.isInStock;
      if (locations?.length > 1 && isBopisPrimaryStoreOOS) {
        return locations?.[1]?.locationId;
      }
      return locations?.[0]?.locationId;

    case SELECTED_FFM_TYPES.BOSS:
      const bossService = boss(fulfillment);
      locations = bossService?.locations || [];
      const isBossPrimaryStoreOOS = !locations?.[0]?.inventory?.isInStock;
      // if nearbyStore
      if (locations?.length > 1 && isBossPrimaryStoreOOS) {
        return locations?.[1]?.locationId;
      }
      return locations?.[0]?.locationId;

    case SELECTED_FFM_TYPES.BODFS:
      const bodfsService = bodfs(fulfillment);
      locations = bodfsService?.locations || [];
      return locations?.[0]?.zipCode;

    case SELECTED_FFM_TYPES.APPL_DELIV:
      const applDeliveryService = applianceDelivery(fulfillment);
      locations = applDeliveryService?.locations || [];
      return locations?.[0]?.zipCode;

    default:
      break;
    }
  } catch (error) {
    console.error(error);
  }
  return '';
};

const buildItemFulfillment = ({ item, isCartObjectStructure }) => {
  const selectedFulfillment = item?.selectedFulfillment;
  const isPickupFfm = selectedFulfillment === SELECTED_FFM_TYPES.BOPIS
    || selectedFulfillment === SELECTED_FFM_TYPES.BOSS;

  const itemFulfillment = {
    method: getFfmMethodForAnalytics(selectedFulfillment),
  };

  if (isCartObjectStructure) {
    itemFulfillment.backOrder = item?.product?.fulfillment?.backordered ? 'yes' : 'no';
  }

  if (isPickupFfm) {
    itemFulfillment.store = getStoreOrZip({ item });
  } else if (selectedFulfillment !== SELECTED_FFM_TYPES.STH) {
    itemFulfillment.zip = getStoreOrZip({ item });
  }

  return itemFulfillment;
};

export const getAddonFromItem = ({ item, addonType }) => {
  try {
    if (!item?.product?.addons || !addonType) { return false; }
    return (item?.product?.addons?.find((addon) => addon?.type === addonType));
  } catch (error) {
    console.error('Error occurred in getAddonFromItem()', error);
    return {};
  }
};

const getSubscriptionFrequency = (frequency = '') => {
  try {
    const frequencyMap = {
      '1_3': '1 months', // every 1 month
      '2_3': '2 months', // every 2 months, etc
      '3_3': '3 months',
      '4_3': '4 months',
      '5_3': '5 months',
      '6_3': '6 months',
      '9_3': '9 months',
      '12_3': '12 months'
    };
    return frequency ? frequencyMap[frequency] : '';
  } catch (error) {
    console.error(error);
    return '';
  }
};

const getItemDiscountAmount = ({ lineItemId = '', promoItemsArray = [] }) => {
  try {
    if (!lineItemId || !promoItemsArray?.length) {
      return '';
    }
    const promoItemData = promoItemsArray?.find((promoItem) => promoItem?.appliedOn === lineItemId);
    return promoItemData?.appliedDiscount?.substring(1) || '';
  } catch (error) {
    console.log(error);
  }
  return '';
};

const buildItemETA = (item) => {
  let eta = {};

  const fulfillment = item?.product?.fulfillment || {};
  const selectedFFM = item?.selectedFulfillment || '';

  switch (selectedFFM) {
  case SELECTED_FFM_TYPES.BOSS:
    const bossService = boss(fulfillment);
    eta = {
      bossStartDate: bossService?.deliveryDates?.startDate || 'NA',
      bossEndDate: bossService?.deliveryDates?.endDate || 'NA'
    };
    break;
  case SELECTED_FFM_TYPES.STH:
    const sthService = sth(fulfillment);
    eta = {
      sthStartDate: sthService?.deliveryDates?.startDate || 'NA',
      sthEndDate: sthService?.deliveryDates?.endDate || 'NA'
    };
    break;
  case SELECTED_FFM_TYPES.BODFS:
    const bodfsService = bodfs(fulfillment);
    eta = {
      bodfsStartDate: bodfsService?.deliveryDates?.startDate || 'NA',
      bodfsEndDate: bodfsService?.deliveryDates?.endDate || 'NA'
    };
    break;
  case SELECTED_FFM_TYPES.APPL_DELIV:
    const applDeliveryService = applianceDelivery(fulfillment);
    eta = {
      applStartDate: applDeliveryService?.expectedArrival || 'NA',
      applEndDate: applDeliveryService?.expectedArrival || 'NA'
    };
    break;
  default:
    break;
  }

  return eta;
};

export const getLocalStore = (fulfillment) => {
  const locations = fulfillment?.locations || [];
  return locations.find((location) => location.isAnchor);
};

export const isQuantityAvailable = (fulfillment) => {
  const localStore = getLocalStore(fulfillment);
  return (localStore?.inventory?.quantity > 0 || localStore?.inventory?.isLimitedQuantity);
};

export const isItemOnClearance = (fulfillment, pricing) => {
  if (!fulfillment) {
    return false;
  }
  const pickupService = fulfillment?.fulfillmentOptions?.find((option) => option.type === 'pickup');
  const bopisFulfillment = pickupService?.services?.find((service) => service.type === 'bopis');

  return !!((fulfillment?.anchorStoreStatusType?.toUpperCase() === 'CLEARANCE'
    && bopisFulfillment && isQuantityAvailable(bopisFulfillment) && pricing?.clearance));
};

const transformItemToAnalyticsStructure = ({ item, promoCodeData, isCartObjectStructure = false, isCustomerIdentified }) => {
  const totalPrice = item?.product?.pricing?.total?.toFixed(2) || '';
  const type = getItemMapPolicy({ item, isCustomerIdentified });
  const itemAnalytics = {
    quantity: item?.quantity || '',
    price: {
      basePrice: totalPrice,
      mapPrice: type
    },
    productInfo: {
      sku: item?.product?.identifiers?.itemId || '',
    },
    fulfillment: buildItemFulfillment({ item, isCartObjectStructure })
  };
  const subscriptionAddonData = getAddonFromItem({ item, addonType: ADDON_TYPES.SUBSCRIPTION });
  const isSubscriptionAddonSelected = subscriptionAddonData?.selected || false;
  if (subscriptionAddonData && isSubscriptionAddonSelected) {
    itemAnalytics.subscription = {
      frequency: getSubscriptionFrequency(subscriptionAddonData?.configAttr)
    };
  }
  if (isCartObjectStructure) {
    itemAnalytics.price.discountCode = promoCodeData?.promoCode || '';
    itemAnalytics.price.discountAmount = getItemDiscountAmount({
      lineItemId: item?.id, promoItemsArray: promoCodeData?.promoItems
    }) || '';
    itemAnalytics.productInfo.superSKU = item?.product?.identifiers?.itemId || '';
    itemAnalytics.productInfo.productName = item?.product?.identifiers?.productLabel || '';
    itemAnalytics.productInfo.manufacturer = item?.product?.identifiers?.brandName || '';
    itemAnalytics.productInfo.subCategory2 = item?.product?.identifiers?.productLabel || '';
    const isGiftCard = item?.product?.identifiers?.productType?.toUpperCase() === GIFT_CARD;
    if (isGiftCard) {
      itemAnalytics.price.giftCardPrice = totalPrice;
      itemAnalytics.price.basePrice = '0.00';
    }
    // item ETA applies to all ffm EXCEPT bopis
    if (item?.selectedFulfillment !== SELECTED_FFM_TYPES.BOPIS) {
      itemAnalytics.productInfo.eta = buildItemETA(item);
    }
    if (isItemOnClearance(item?.product?.fulfillment, item?.product?.pricing)) {
      itemAnalytics.productInfo.badge = [{
        recommendationStrategy: 'n/a',
        recommendationVersion: 'n/a',
        type: 'in-store clearance messaging'
      }];
    }
  }
  return itemAnalytics;
};

const isProReferralAddon = (addonType = '') => {
  return (addonType === ADDON_TYPES.PRO_INSTALL || addonType === ADDON_TYPES.PAINTER);
};

const getItemSkuForAnalytics = ({ item, addon }) => {
  try {
    const ASSEMBLY_SKU = '764092'; // <-- must return this as itemSKU for bopis or bodfs free store assembly

    switch (addon?.type) {
    case ADDON_TYPES.FREE_ASSEMBLY:
      return ASSEMBLY_SKU;
    case ADDON_TYPES.HDPP:
    case ADDON_TYPES.ASSEMBLY:
    case ADDON_TYPES.INSTALLL:
      return addon?.configAttr || '';
    case ADDON_TYPES.PRO_INSTALL:
    case ADDON_TYPES.PAINTER:
      return item?.product?.itemId || '';
    default:
      return item?.product?.itemId || '';
    }
  } catch (err) {
    console.log(err);
  }
  return '';
};

const getFulfillmentMethodForAddons = ({ item, addon }) => {
  const isFreeAssemblyAtStore = addon?.type === ADDON_TYPES.FREE_ASSEMBLY;
  const ffmMethod = isFreeAssemblyAtStore
    ? getFfmMethodForAnalytics(item?.selectedFulfillmentType) // could be 'bopis' or 'bodfs'
    : 'sth';
  return ffmMethod;
};

const buildEtaForAddon = ({ item, addon }) => {
  let eta = {};

  switch (addon?.type) {
  case ADDON_TYPES.HDPP:
    eta = {
      sthStartDate: 'NA', // <-- we do NOT have HDPP dates available to use.
      sthEndDate: 'NA' // we had it legacy mcc-cart
    };
    break;
  default:
    break;
  }

  return eta;
};

const transformAddonToAnalyticsStructure = ({ item, data }) => {
  try {
    const addons = item?.product?.addons || [];
    const addonsForAnalytics = [];

    if (addons?.length) {
      addons.forEach((addon) => {
        const isValidAddonForAnalytics = [
          ADDON_TYPES.HDPP,
          ADDON_TYPES.PRO_INSTALL,
          ADDON_TYPES.PAINTER,
          ADDON_TYPES.FREE_ASSEMBLY
        ].includes(addon?.type);
        const isHdppAddon = addon?.type === ADDON_TYPES.HDPP;
        const isHddpForAppl = isHdppAddon && item?.selectedFulfillment === SELECTED_FFM_TYPES.APPL_DELIV;
        const isAddonSelected = addon?.selected || false;
        const isProReferral = isProReferralAddon(addon?.type);

        // only publish *SELECTED* addon analytics, UNLESS that addon is Pro Referral.
        // We always allow pro referral to publish
        if (isValidAddonForAnalytics && !isHddpForAppl && (isAddonSelected || isProReferral)) {
          const price = addon?.totalPrice ? addon?.totalPrice?.toFixed(2) : '0.00';

          const analyticsObject = {
            quantity: addon?.quantity ? addon?.quantity?.toString() : '1',
            price: {
              basePrice: price
            },
            productInfo: {
              sku: getItemSkuForAnalytics({ item, addon })
            },
            fulfillment: {
              method: getFulfillmentMethodForAddons(item?.selectedFulfillment)
            }
          };
          const isFreeAssemblyAtStore = addon?.type === ADDON_TYPES.FREE_ASSEMBLY;

          if (isHdppAddon) {
            analyticsObject.productInfo.eta = buildEtaForAddon({ item, addon });
            analyticsObject.fulfillment.backOrder = item?.product?.fulfillment?.backordered ? 'yes' : 'no';
          } else if (isFreeAssemblyAtStore) {
            analyticsObject.productInfo.assembly = 'free in-store';
          } else if (isHddpForAppl) {
            analyticsObject.price.applDeliveryFee = data?.cartInfo?.totals?.applianceDeliveryCharge?.toFixed(2) || '';
          } else if (isProReferral) {
            const isProReferralSelected = addon?.selected;
            if (isProReferralSelected) {
              analyticsObject.productInfo.proReferralSelected = true;
            } else {
              analyticsObject.productInfo.proReferralDisplayed = true;
            }
          }

          addonsForAnalytics.push(analyticsObject);
        }
      });
    }
    return addonsForAnalytics;
  } catch (error) {
    console.error(error);
  }
};

export const buildCartObject = ({ data, isCustomerIdentified }) => {
  try {
    const items = data?.cartInfo?.items || [];
    const promoCodeData = getPromoCodeData({ data });
    let processedItems = [];
    // eslint-disable-next-line no-unused-expressions
    items?.forEach((item) => {
      processedItems.push(transformItemToAnalyticsStructure({ item, promoCodeData, isCartObjectStructure: true, isCustomerIdentified }));

      // Add certain Product-Add-Ons to item array for analytics
      const addonsArrayForAnalytics = transformAddonToAnalyticsStructure({ item });
      processedItems = processedItems.concat(addonsArrayForAnalytics);
    });

    let discountAmount = '';
    if (promoCodeData?.appliedDisc) {
      discountAmount = (promoCodeData?.appliedDisc * -1)?.toFixed(2); // transform -1.23 into '1.23' for analytics
    }

    let cartObj = {
      attributes: {
        OMS: 'aurora'
      },
      cartID: data?.cartInfo?.cartId,
      item: processedItems,
      price: {
        cartTotal: data?.cartInfo?.totals?.total?.toFixed(2) || '',
        discountCode: promoCodeData?.promoCode || '',
        discountAmount,
        paymentMethod: '',
        applDeliveryFee: data?.cartInfo?.totals?.applianceDeliveryCharge?.toFixed(2) || ''
      },
      uniqueSkuCount: data?.cartInfo?.descriptiveAttr?.uniqueItemCount || '',
      quantityTotal: data?.cartInfo?.itemCount || ''
    };
    const sharedCartID = getQueryParam('sharedCartId');
    // check that sharedCartID exists in url and is only passed on initial retrieval
    // (not page refresh or reloads) acc to spec
    if (sharedCartID && !data?.cartInfo?.sharedCartID) {
      cartObj.sharedCartID = sharedCartID;
    }
    return cartObj;
  } catch (error) {
    console.error(error);
  }
};
