export const isSafari = () => {
  if (!global?.navigator) return false;

  return global?.navigator?.vendor && global?.navigator?.vendor.indexOf('Apple') > -1
      && global?.navigator?.userAgent
      && global?.navigator?.userAgent.indexOf('CriOS') === -1
      && global?.navigator?.userAgent.indexOf('FxiOS') === -1;
};

export const getSchema = ({ schema = '', pageContext = {}, props }) => {

  const { label, isCategory, isSearch, isBrowse } = pageContext;
  // schema props is from educational layout
  if (props?.schema) return props.schema;
  if (schema) {
    return schema;
  }

  if (label === 'browse-search') {
    if (isCategory) {
      return 'cat_sponsored';
    }
    if (isSearch) {
      return 'search_sponsored';
    }
    if (isBrowse) {
      return 'browse_sponsored';
    }
  }

  return null;
};

export const isExperienceDataResolved = ({ isLocalized, experienceContext }) => {
  const { mounted } = experienceContext;
  if (mounted && isLocalized) {
    return true;
  }
  return false;
};

export const nValueIsValid = (nValue) => {
  if (nValue?.startsWith('N-') && nValue?.includes('Z')) {
    return true;
  }
  return false;
};

export const showCarouselPlaceholder = (renderNewCarousel, loadingState, errorState) => {
  if (renderNewCarousel && loadingState && !errorState) {
    return true;
  }
  return false;
};

export const triggerNewRelic = (adType, eventType) => {
  if (!window.sponsoredContent) {
    window.sponsoredContent = {};
  }

  if (window.newrelic && typeof LIFE_CYCLE_EVENT_BUS !== 'undefined') {
    window.LIFE_CYCLE_EVENT_BUS.trigger('sponsored-content.event', { adType, event: eventType });
    window.sponsoredContent[adType] = true;
  }
};

export const mkTelemetryField = ({ key, value }) => {
  return `[${key}:${value}]`;
};

export const cleanString = (str) => {
  return str.replace(/ /g, '').toLowerCase();
};
