import React, { useEffect, useReducer, useRef } from 'react';
import { Button } from '@thd-olt-component-react/button';
import { Col, Row, Heading } from '@thd-olt-component-react/core-ui';
import { Review } from './Review/Review.component';
import { ReviewPager } from './Paging/ReviewPager.component';
import { OverviewDesktop } from './Overview/OverviewDesktop';
import { Search, Sort, AdditionalFilter } from './Filter/components';
import { PagerDisplayText } from './PagerDisplayText/PagerDisplayText';
import { useStoreReviews } from './useStoreReviews';
import { dataModel } from './ReviewDataModel';
import { storeInitialState } from '../constants';
import { stateReducer } from '../reducer';
import { RatingsAndReviewsDefaultProps, StoreRatingsAndReviewsPropTypes } from './RatingsAndReviewsPropTypes';
import { RatingsAndReviewsPlaceholder } from './RatingsAndReviewsPlaceholder';
import {
  getProductReviews, getSelectedSentiment, getUpdatedTotalPage, onFilterChange, shouldRenderPlaceholder
} from '../helpers';
import './ratings-and-reviews.style.scss';

export const StoreRatingsAndReviewsDesktop = (props) => {
  const {
    apiKey, storeId, category, seoPageNumber
  } = props;
  const [state, dispatch] = useReducer(stateReducer, storeInitialState);
  const persistedStatistics = useRef({});
  const initialReviews = useRef({});
  const persistedPageContext = useRef();
  const searchRef = useRef();

  useEffect(() => {
    if (seoPageNumber) {
      dispatch({ type: 'sort', value: 'photoreview' });
      dispatch({ type: 'pageLoad', value: seoPageNumber });
    }
  }, [seoPageNumber]);

  const {
    FilterSelected,
    loading,
    reviewModels = [],
    statistics
  } = useStoreReviews({ storeId, seoPageNumber, ...state });

  useEffect(() => {
    if (!persistedPageContext.current) {
      persistedPageContext.current = state.pageContext;
      return;
    }
    if (persistedPageContext.current.currentPage !== state.pageContext.currentPage) {
      persistedPageContext.current = state.pageContext;
      if (searchRef.current) {
        searchRef.current.scrollIntoView({
          behavior: 'auto'
        });
      }
    }
  }, [state.pageContext]);

  const {
    filters, pageContext, searchText, selectedSentiment, sortBy
  } = state;
  if (shouldRenderPlaceholder(loading, persistedStatistics, storeId)) {
    return (
      <RatingsAndReviewsPlaceholder
        color="#E0E0E0"
        showLoadingAnimation
      />
    );
  }
  const selected = getSelectedSentiment(selectedSentiment, filters);
  let storeReviews = getProductReviews({
    reviewModels, initialReviews, storeId, searchText, selected
  });
  const noFallbacks = !(storeReviews || persistedStatistics.current[storeId]);
  if (noFallbacks) {
    return null;
  }
  const updatedTotalpage = getUpdatedTotalPage(selectedSentiment, statistics?.totalPages);
  if (state.pageContext.totalPages !== updatedTotalpage) {
    dispatch({ type: 'totalPages', value: updatedTotalpage });
  }
  const { SearchText } = FilterSelected || {};
  if (persistedStatistics.current && !persistedStatistics.current[storeId]) {
    persistedStatistics.current[storeId] = statistics;
    initialReviews.current[storeId] = reviewModels;
  }
  const reviewStatistics = persistedStatistics.current && persistedStatistics.current[storeId]
    ? persistedStatistics.current[storeId]
    : statistics;
  const noResults = state.searchText
    && reviewModels.length === 0
    && !loading;

  return (
    <div className="ratings-reviews" id="ratings-and-reviews" data-component="StoreRatingsAndReviewsDesktop">
      <Heading title="Customer Store Reviews" underline className="overview-heading" />
      <OverviewDesktop
        clickableFilters
        reviewStatistics={reviewStatistics}
        filterCheckboxes={filters}
        onFilterChange={(updatedFilters) => onFilterChange(updatedFilters, { dispatch, category })}
        selectedSentiment={selectedSentiment}
        apiKey={apiKey}
        category={category}
        storeId={storeId}
      />

      <>
        <Row className="ratings-reviews__search-and-sort" id="filter-by-profile">
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            fallback={12}
            className="ratings-and-reviews__search-and-filter"
          >
            <AdditionalFilter
              ratingFilters={filters}
              onFilterChange={(updatedFilters) => onFilterChange(updatedFilters, { dispatch, category })}
              reviewStatistics={reviewStatistics}
            />
          </Col>
        </Row>
        <div ref={searchRef} />
        <Row className="ratings-reviews__search-and-sort" id="search-and-sort">
          <Col
            xs={3}
            sm={3}
            md={3}
            lg={3}
            fallback={3}
            className="ratings-and-reviews__search-and-filter"
          >
            <Search
              onSearch={(searchTerm) => {
                if (searchTerm) {
                  dispatch({ type: 'search', value: searchTerm });
                }
              }}
              searchTerm={state.searchText}
              noResults={noResults}
            />
          </Col>
          <Col xs={3} sm={2} md={2} lg={2} fallback={2}>
            <Sort onChange={(newSort) => dispatch({ type: 'sort', value: newSort })} sortBy={sortBy} />
          </Col>
        </Row>
        <Row className="ratings-reviews__sort-and-filter">
          <Col sm={3} md={3} lg={3} fallback={3}>
            <PagerDisplayText
              topPager
              pageContext={pageContext}
              loading={loading}
              statistics={statistics}
              showReviewsText
              seoPageNumber={seoPageNumber}
            />
          </Col>
          <Col xs={6} sm={7} md={7} lg={7} fallback={7} className="ratings-reviews__pills-wrapper">
            {selected.length > 0
              && (
                <>
                  {selected
                    .map((checkbox, checkboxIndex) => {

                      let pillText;

                      if (checkbox.value === 'verified') {
                        pillText = 'Verified Purchases';
                      } else if (checkbox.id.includes('rating')) {
                        pillText = `${checkbox.value} Star`;
                      } else if (checkbox.id.includes('age')) {
                        pillText = checkbox.label;
                      } else {
                        pillText = checkbox.value;
                      }

                      return (
                        <Button
                          key={checkboxIndex}
                          id={checkbox.id}
                          onClick={() => dispatch({ type: 'removeFilter', value: checkbox })}
                          filter
                        >
                          {selectedSentiment ? selectedSentiment.Feature : pillText}
                        </Button>
                      );
                    }
                    )}
                </>
              )}
            {
              SearchText
              && (
                <Button
                  key="search"
                  id="search"
                  onClick={() => dispatch({ type: 'search', value: null })}
                  filter
                >
                  {noResults && SearchText ? 'Clear Search Term' : SearchText}
                </Button>
              )
            }
          </Col>
        </Row>
      </>

      {(storeReviews).map((review, reviewIndex) => {
        return (
          <Review
            review={review}
            id={reviewIndex}
            key={`review-${reviewIndex}`}
            searchTerm={SearchText}
            apiKey={apiKey}
            category={category}
          />
        );
      })}
      <Row>
        <Col xs="12" sm="12" md="12" className="pager-section">
          <div className="ratings-reviews__review-pager">
            <ReviewPager
              category={category}
              storeId={storeId}
              reviewsPageContext={pageContext}
              onPageChanged={(newPageContext) => dispatch({ type: 'pageChange', value: newPageContext })}
            />
          </div>
          <Row className="ratings-reviews__review-pager__summary">
            <PagerDisplayText
              pageContext={pageContext}
              statistics={statistics}
              loading={loading}
              showReviewsText
              seoPageNumber={seoPageNumber}
            />
          </Row>
        </Col>
      </Row>
    </div>
  );
};

StoreRatingsAndReviewsDesktop.displayName = 'StoreRatingsAndReviewsDesktop';

StoreRatingsAndReviewsDesktop.propTypes = StoreRatingsAndReviewsPropTypes;

StoreRatingsAndReviewsDesktop.defaultProps = RatingsAndReviewsDefaultProps;

StoreRatingsAndReviewsDesktop.dataModel = dataModel;
