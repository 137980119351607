import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  useDataModel,
  params,
  shape as shapeType,
  string as stringType
} from '@thd-nucleus/data-sources';
import { useLifeCycleEventBus } from '@thd-olt-functional/utils';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import styles from './global-custom-configurator.module.scss';
import { getCustomUrl } from '../helpers/getCustomUrl';

const GlobalCustomConfigurator = (props) => {
  const { itemId, placement } = props;
  useEffect(() => { LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('global-custom-configurator.ready'); }, []);

  const { data, loading, error } = useDataModel('product', { variables: { itemId } });
  const experienceContext = useContext(ExperienceContext);
  const gccBaseUrl = experienceContext?.hosts.globalCustomConfiguratorsBaseUrl;
  const { deckingCalculatorUrl } = useLifeCycleEventBus('configurator.configurator_decking_event');
  const { globalCustomConfigurator } = data?.product?.info || {};
  const customUrl = globalCustomConfigurator?.customExperienceUrl;
  const [customExperienceUrl, setExperienceUrl] = useState(customUrl);

  useEffect(() => {
    setExperienceUrl(customUrl);
  }, [customUrl]);

  useEffect(() => {
    if (globalCustomConfigurator) {
      if (deckingCalculatorUrl && globalCustomConfigurator.customExperience === 'custom-decking') {
        setExperienceUrl(deckingCalculatorUrl);
      } else if (gccBaseUrl) {
        getCustomUrl(gccBaseUrl, itemId).then((response) => {
          return response.json();
        }).then((omsId) => {
          if (omsId) {
            setExperienceUrl(`${window.location.origin}/p/${omsId}`);
          }
        });
      }
    }
  }, [deckingCalculatorUrl, globalCustomConfigurator]);

  if (!data || loading || (error && !data)) {
    return null;
  }

  const { customButtonText, customTitle, customDescription, customPosition } = globalCustomConfigurator || {};
  if (!customTitle) return null;
  if (placement === 'above' && (!customPosition || customPosition !== 'above')) return null;
  if (placement === 'below' && (customPosition === 'above')) return null;

  return (
    <>
      <div
        className={styles['global-custom-configurator__container']}
        data-component="GlobalCustomConfigurator"
        id="wall-area-calculator"
      >
        <div className={styles['global-custom-configurator__info-box']}>
          <img
            className={styles['global-custom-configurator__icon']}
            height="26"
            width="32"
            src="https://assets.homedepot-static.com/images/v1/order-sample.svg"
            alt="sample icon"
          />
          <div className={styles['global-custom-configurator__info-text']}>
            <div className={styles['global-custom-configurator__custom-size']}>
              {customTitle}
            </div>
            <div className={styles['global-custom-configurator__cta-messaging']}>
              {customDescription}
            </div>
          </div>
        </div>
        <div className={styles['global-custom-configurator__button-wrapper']}>
          <a
            className={styles['global-custom-configurator__container-link']}
            href={customExperienceUrl}
            data-target="configurator button submission"
          >
            {/* eslint-disable-next-line max-len */}
            <div className="bttn-outline bttn-outline--primary bttn--inline">
              <div className="bttn__content">
                {customButtonText}
              </div>
            </div>
          </a>
        </div>
      </div>
    </>
  );
};

GlobalCustomConfigurator.displayName = 'GlobalCustomConfigurator';

GlobalCustomConfigurator.dataModel = {
  product: params({ itemId: stringType().isRequired() }).shape({
    itemId: stringType(),
    dataSources: stringType(),
    info: shapeType({
      globalCustomConfigurator: shapeType({
        customButtonText: stringType(),
        customDescription: stringType(),
        customExperience: stringType(),
        customExperienceUrl: stringType(),
        customTitle: stringType(),
        customPosition: stringType()
      })
    })
  })
};

GlobalCustomConfigurator.propTypes = {
  itemId: PropTypes.string,
  placement: PropTypes.string
};

GlobalCustomConfigurator.defaultProps = {
  itemId: null,
  placement: ''
};

export { GlobalCustomConfigurator };
