export const ITEM_PROMO = 'ITEMPROMO';
export const ORDER_PROMO = 'ORDERPROMO';
export const RENDER_FBT = 'RENDER_FBT';
export const RENDER_PMV = 'RENDER_PMV';
export const RENDER_THD_RECS = 'RENDER_THD_RECS';
export const RENDER_TRANSACTIONAL = 'transactional';
export const RENDER_VISUAL = 'visual';
export const RENDER_CO_WORK = 'work-co';
export const SHOW_SPECIAL_PRICE_IF_AUTH = 'showSpecialPriceIfAuth';
export const SHOW_PRICE_IN_CART = 'showPriceInCart';
export const EXPERIENCE_TYPE_KITCHEN = 'kitchen';
export const EXPERIENCE_TYPE_COMBO = 'combo';
export const EXPERIENCE_TYPE_LAUNDRY = 'laundry';
export const BUNDLE_MINI_TO_PIP_ANALYTICS = 'bundle-mini.viewBundleClick';