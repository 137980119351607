import { takeLatest, call } from 'redux-saga/effects';
import safeSaga from '../safeSaga';
import { PREVIEW_ERROR_ACTIONS } from '../../reducers/errorGroups';
import isPreviewProduct from '../../utils/isPreviewProduct';
import {
  HIDE_ELEMENTS_PREVIEW_LIST, PREVIEW_SELECTORS,
  PREVIEW_PRODUCT_TITLE, PREVIEW_PRODUCT_BRAND,
  PREVIEW_LIFECYCLE_EVENTS
} from './constants';

const removeChildrenByDataComponent = (substrings) => {
  substrings.forEach((substring) => {
    // Select all divs with data-component substring
    const elements = document.querySelectorAll(`div[data-component*="${substring}"]`);
    elements.forEach((element) => {
      while (element.firstChild) {
        element.removeChild(element.firstChild);
      }
    });
  });
};

const handlePreview = () => {
  LIFE_CYCLE_EVENT_BUS.trigger(PREVIEW_LIFECYCLE_EVENTS.CONFIGURATOR.CHOICE_SELECTED_EVENT, {
    configuredProductLabel: PREVIEW_PRODUCT_TITLE,
  });

  removeChildrenByDataComponent(HIDE_ELEMENTS_PREVIEW_LIST);

  const productDetails = document.querySelector(PREVIEW_SELECTORS.PRODUCT_DETAILS);
  if (productDetails) {
    const newSpan = document.createElement('span');
    newSpan.textContent = PREVIEW_PRODUCT_BRAND;
    productDetails.appendChild(newSpan);
  }

  // Hide product-info-bar number
  const productInfo = document.querySelector(PREVIEW_SELECTORS.PRODUCT_INFO_BAR);
  if (productInfo) {
    productInfo.style.display = 'none';
  }

  // Hide all the sections outside of the product-configurator
  document.querySelectorAll('section').forEach((section) => {
    if (!section.closest(PREVIEW_SELECTORS.PRODUCT_CONFIGURATOR)) {
      const sectionToModify = section;
      sectionToModify.style.display = 'none';
    }
  });
};

function* hideComponent() {
  if (isPreviewProduct) {
    yield call(handlePreview);
  }
}

export default function* previewSaga() {
  yield takeLatest(
    PREVIEW_ERROR_ACTIONS,
    safeSaga(hideComponent)
  );
}
