// Analytics
export const ADD_TO_CART = 'Add to cart';
export const PRODUCT_POD_IRG_ATC = 'product-pod-irg.add-to-cart';
export const PRODUCT_POD_ATC = 'product-pod-v7.add-to-cart';
export const PRODUCT_POD_V7_CLICK = 'product-pod-v7.click';

// Fulfillment
export const FULFILLMENT_LOCATION_STH = 'DirectShip';
export const FULFILLMENT_METHOD_STH = 'ShipToHome';
export const FULFILLMENT_METHOD_BOSS = 'ShipToStore';
export const FULFILLMENT_METHOD_BOPIS = 'BOPIS';
export const FULFILLMENT_METHOD_BODFS = 'DeliverFromStore';
export const FULFILLMENT_METHOD_APPLIANCE = 'DirectDelivery';
export const NON_RETURNABLE_MESSAGE = 'Made to order, not returnable';
export const CONFIGURABLE_BLINDS = 'CONFIGURABLE_BLINDS';
export const MERCHANDISE = 'MERCHANDISE';
export const MAJOR_APPLIANCE = 'MAJOR_APPLIANCE';

// CTA Buttons
export const SCHEDULE_A_MEASURE = 'Schedule a Measure';
export const GET_ESTIMATE = 'Get Estimate';
export const REQUEST_A_QUOTE = 'Request a Quote';
export const SCHEDULE_A_CONSULTATION = 'Schedule a Consultation';
export const BUILD_AND_BUY = 'Build and Buy';
export const VIEW_DETAILS = 'View Details';
export const CHOOSE_OPTIONS = 'Choose Options';
export const VIEW_PACKAGE = 'View Package';

// CONSTANT URLS
export const SCHEDULE_A_MEASURE_URL = 'https://www.homedepot.com/services/i/schedule-a-measure/b76e9e302/form';
export const CLOPAY_URL = 'https://hddchtml.clopay.com/#resDoorTypeV1';

// PRODUCT SERVICE ADD ONS
export const DUAL_PATH_IDENTIFIER = 'SFI_Available';

// PRODUCT DETAILS
export const HOME_DEPOT_QUOTE_CENTER = 'HDQC';

// INSTANT RECS
export const HOME_DEPOT_DESIGN_CENTER = 'HDDC';

export const POD_IMAGE_SIZE = 300;

// SEE MORE IN DEPARTMENT
export const PRODUCT_RECORD_TYPE = 10000003;
export const MAX_KEYWORD_LENGTH = 27;
