import React from 'react';
import { node, string } from 'prop-types';

const ZoneCard = ({ children, className }) => {
  return <div className={className}>{children}</div>;
};

ZoneCard.propTypes = {
  children: node.isRequired,
  className: string
};

ZoneCard.defaultProps = {
  className: 'zone-card'
};

ZoneCard.displayName = 'ZoneCard';

export default ZoneCard;
