import React, { useEffect } from 'react';
import { string, func } from 'prop-types';
import { Alert } from '@one-thd/sui-atomic-components';

export const BuyboxMessage = React.forwardRef(({ message, status, onRender }, ref) => {
  useEffect(() => {
    onRender();
  }, []);

  return (
    <div className="sui-flex sui-flex-col sui-w-full md:sui-max-w-md" ref={ref}>
      <Alert status={status}>
        {message}
      </Alert>
    </div>
  );
});

BuyboxMessage.propTypes = {
  message: string.isRequired,
  status: string,
  onRender: func
};

BuyboxMessage.defaultProps = {
  status: 'warning',
  onRender: () => {}
};