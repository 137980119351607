import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography, Link } from '@one-thd/sui-atomic-components';
import { Info } from '@one-thd/sui-icons';
import { ExperienceContext, useConfigService } from '@thd-nucleus/experience-context';
import { CARD_IMAGE } from '../utils/constants';
import { formatPrice, getDynamicOffer } from '../utils/general';
import { clickEvent } from '../utils/publisher';
import { getDefaultApplyNowURL, loadEstimatorScript } from '../utils/payment-estimator-utils';
import TermsAndConditionsDrawer from '../common/terms-and-conditions-drawer.component';

function DynamicDiscount({ isB2B, total, hideDynamicMath, saleAmount }) {

  const experienceContext = useContext(ExperienceContext);
  const paymentEstimatorVersion = useConfigService('PaymentEstimatorVersion') || 'v1.2.0';
  const [applyNowLink, setApplyNowUrl] = useState('');
  const [showDrawer, setShowDrawer] = useState(false);

  const { channel } = experienceContext;
  const isMobile = channel === 'mobile';

  const printValues = (title, value, bold = false, success = false, negative = false) => {
    return (
      <div
        className={`sui-flex sui-flex-row sui-justify-between sui-mt-2 ${success && 'sui-text-success'
          }`}
      >
        <div className={bold ? 'sui-font-w-bold' : ''}>{title}</div>
        <div className={bold ? 'sui-font-w-bold' : ''}>
          {negative && '-'}
          {formatPrice(value)}
        </div>
      </div>
    );
  };

  const doTheMathApplyNowClicked = () => {
    clickEvent('plcc', 'right rail');
    return isMobile ? window.open(applyNowLink, 'self') : window.open(applyNowLink, '_blank');
  };

  const cardImage = isB2B ? CARD_IMAGE.PLCR_HOMEDEPOT : CARD_IMAGE.PLCN_HOMEDEPOT;

  const updateApplyNowUrl = () => {
    let saleAmountParam = saleAmount ? '&saleAmt=' + saleAmount : '';
    setApplyNowUrl(getDefaultApplyNowURL(isMobile, 'dothemath', isB2B) + saleAmountParam);
  };

  useEffect(() => {
    loadEstimatorScript(
      paymentEstimatorVersion,
      updateApplyNowUrl,
      channel === 'mobile'
    );
  }, []);

  return (
    <div className="sui-flex sui-flex-col sui-mt-4">
      <TermsAndConditionsDrawer
        closeDrawer={() => setShowDrawer(false)}
        open={showDrawer}
        isB2B={isB2B}
        price={total}

      />
      <div className="sui-flex">
        <img
          src={cardImage}
          alt="Credit Card Icon"
          className="sui-h-8 sui-mr-2"
          width="50"
          height="30"
        />
        <Typography variant="h4" weight="display">
          Get Up to $100 Off
        </Typography>
      </div>

      <div className="sui-mt-4 sui-mb-2">
        Get a Home Depot Credit Card and receive <b>$25 off</b> your qualifying purchase of $25+,{' '}
        <b>$50 off</b> purchases $300+, or <b>$100 off</b> orders over $1000.
        <span className="sui-pl-1 sui-cursor-pointer sui-relative sui-top-1">
          <Info
            aria-label="Credit Offer Details"
            color="info"
            onClick={() => setShowDrawer(!showDrawer)}
            size="small"
          />
        </span>
      </div>

      <div id="applyNowLink" className="sui-mb-4">
        <Link
          onClick={doTheMathApplyNowClicked}
          color="brand"
        >
          Apply Now
        </Link>
      </div>

      {total >= 25 && !hideDynamicMath && (
        <div id="values" className=" sui-mb-3">
          {printValues('Item Total', total, true)}
          {printValues('Savings', getDynamicOffer(total), false, false, true)}
          {printValues('New Total', total - getDynamicOffer(total), true, true)}
        </div>
      )}
    </div>
  );
}

DynamicDiscount.propTypes = {
  isB2B: PropTypes.bool,
  total: PropTypes.number.isRequired,
  hideDynamicMath: PropTypes.bool,
  saleAmount: PropTypes.number,
};

DynamicDiscount.defaultProps = {
  isB2B: false,
  hideDynamicMath: false,
  saleAmount: 0,
};

export { DynamicDiscount };
