import { podFulFillmentUtils } from '@thd-olt-component-react/fulfillment';
import {
  EXPERIENCE_TYPE_KITCHEN,
  EXPERIENCE_TYPE_COMBO,
  EXPERIENCE_TYPE_LAUNDRY
} from '../constants';
import {
  COMBO_EXPERIENCE_FEATURES,
  LAUNDRY_EXPERIENCE_FEATURES,
  KITCHEN_EXPERIENCE_FEATURES
} from '../bundle-features-constants';

const getUrlSearchParams = (param, queryString) => {
  const searchParam = new RegExp('[?&]' + param + '=([^&#]*)').exec(queryString);
  return searchParam && decodeURI(searchParam[1]);
};

export const shouldDisplayReferrerRecommendations = (referer) => {
  return /google.com|bing.com|yahoo.com|duckduckgo.com|aol.com/i.test(referer)
    || /^Shopping/.test(getUrlSearchParams('cm_mmc', referer))
    || /PIN_Shopping/.test(getUrlSearchParams('cm_mmc', referer));
};

export const shouldDisplayPipInStock = (data = {}, referer) => {
  const { product = {} } = data || {};
  if (shouldDisplayReferrerRecommendations(referer)) return false;
  if (podFulFillmentUtils.isDiscontinued(product)) return true;
  if (product?.availabilityType?.type === 'Browse Only') return podFulFillmentUtils.isOutOfStockYourStore(product);
  if (product?.availabilityType?.type === 'Online') return podFulFillmentUtils.isOutOfStockOnline(product);
  if (product?.availabilityType?.type === 'Shared') {
    return !!(podFulFillmentUtils.isOutOfStockOnline(product)
      || podFulFillmentUtils.isOutOfStockShared(product)
      || podFulFillmentUtils.isLimitedStock(product)
      || podFulFillmentUtils.isBackordered(product)
      || !podFulFillmentUtils.isATCEnabled(product));
  }
  if (product?.availabilityType?.type === 'Store Only') {
    return podFulFillmentUtils.isBuyInStore(product);
  }
  return false;
};

export const shouldDisplayRecsFBT = (data = {}, referer = false) => {
  return data?.product?.info?.recommendationFlags?.frequentlyBoughtTogether && !shouldDisplayPipInStock(data, referer);
};

export const shouldDisplayFbtbundle = (data = {}) => {
  return data?.product?.info?.recommendationFlags?.bundles;
};

export const shouldDisplayFBTPMV = (setIsPMVEnabled, setIsFBTEnabled, setIsPMVAndFBTEnabled) => {
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('enablePackagesMiniVisual', (data) => {
    setIsPMVEnabled(data?.output?.enabled || false);
  });
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('enableFrequentlyBoughtTogether', (data) => {
    setIsFBTEnabled(data?.output?.enabled || false);
  });
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('enablePMVAndFBT', (data) => {
    setIsPMVAndFBTEnabled(data?.output?.enabled || false);
  });
};

const isDiscontinued = (product) => {
  return !!(product?.availabilityType?.discontinued);
};

const getNearByStore = (fulfillment) => {
  const locations = fulfillment?.locations || [];
  if (locations.length > 1) {
    const nearbyStore = locations.find((location) => !location.isAnchor);
    return nearbyStore;
  }
  return null;
};
const getLocalStore = (fulfillment) => {
  const locations = fulfillment?.locations || [];
  const localStore = locations.find((location) => location.isAnchor);
  return localStore;
};

const isBOPISHaveQuantity = (product) => {
  const pickupService = (product?.fulfillment?.fulfillmentOptions || []).find((option) => option.type === 'pickup');
  const bopisFulfillment = (pickupService?.services || []).find((service) => service.type === 'bopis');
  if (!bopisFulfillment) {
    return false;
  }
  const nearByStoreLocation = getNearByStore(bopisFulfillment);
  const localStoreLocation = getLocalStore(bopisFulfillment);
  return pickupService?.fulfillable
    && (localStoreLocation?.inventory?.quantity > 0 || nearByStoreLocation?.inventory?.quantity > 0);
};

export const isDisplayableBasedOnFulfillment = (product) => {
  const deliveryService = (product?.fulfillment?.fulfillmentOptions || []).find((option) => option.type === 'delivery');
  const pickupService = (product?.fulfillment?.fulfillmentOptions || []).find((option) => option.type === 'pickup');
  const shippingFulfillment = (deliveryService?.services || []).find((service) => service.type === 'sth');
  const bossFulfillment = (pickupService?.services || []).find((service) => service.type === 'boss');
  return !!(!isDiscontinued(product)
    && (deliveryService
      || shippingFulfillment
      || bossFulfillment
      || isBOPISHaveQuantity(product)));
};

export const isPackageRecommendationFlag = (data = {}) => {
  return !!data?.info?.recommendationFlags?.packages;
};

export const isProductAvailable = (product, abcTestEnable) => {
  if (
    !product
    || (abcTestEnable && isPackageRecommendationFlag(product) && !isDisplayableBasedOnFulfillment(product))
  ) {
    return null;
  }
  return product;
};

export const shouldDisplayConfigurator = (data = {}, isServer) => {
  return (
    !isServer
    && data?.product?.identifiers?.productType === 'CONFIGURABLE_BLINDS'
  );
};

export const getBundleMedia = (parent) => {
  if (!parent?.media) {
    return null;
  }

  return {
    media: {
      image: parent?.media?.image
    }
  };
};

export const getBundleId = (parents) => {
  return parents?.[0]?.parentId;
};

export const shouldDisplayBundleMini = (isBundlePackageEnabled, product) => {
  const hasBundleParents = product?.bundleSpecificationDetails?.parents?.length;
  return !!(isBundlePackageEnabled && hasBundleParents);
};

export const getButtonText = (experienceType) => {
  switch (experienceType) {
  case EXPERIENCE_TYPE_KITCHEN:
    return 'View Package';
  case EXPERIENCE_TYPE_COMBO:
    return 'View Bundle';
  case EXPERIENCE_TYPE_LAUNDRY:
    return 'View Set';
  default:
    return 'View Bundle';
  }
};

export const getBrandName = (product, products) => {
  const { brand } = product?.bundleSpecificationDetails ?? {};
  if (brand) {
    return brand;
  }

  const { brandName: productBrandName } = product?.identifiers ?? {};
  if (productBrandName) {
    return productBrandName;
  }

  const { brandName: bundleBrandName } = products?.[0]?.identifiers ?? {};
  const showBrandName = products?.every((bundleProduct) => bundleProduct?.identifiers?.brandName === bundleBrandName);
  return showBrandName ? bundleBrandName : null;
};

export const tiggerAnalyticsCustomEvent = (eventName, data) => {
  if (typeof window !== 'undefined' && window.LIFE_CYCLE_EVENT_BUS) {
    window.LIFE_CYCLE_EVENT_BUS.trigger(eventName, data);
  }
};

const getExperienceType = (product) => {
  return product?.bundleSpecificationDetails?.experienceType;
};

const getProductFeatures = (experienceType) => {
  switch (experienceType) {
  case EXPERIENCE_TYPE_COMBO:
    return COMBO_EXPERIENCE_FEATURES;
  case EXPERIENCE_TYPE_KITCHEN:
    return KITCHEN_EXPERIENCE_FEATURES;
  case EXPERIENCE_TYPE_LAUNDRY:
    return LAUNDRY_EXPERIENCE_FEATURES;
  default:
    return COMBO_EXPERIENCE_FEATURES;
  }
};

export const getUpdatedProduct = (bundleProduct) => {
  const experienceType = getExperienceType(bundleProduct);
  return {
    ...bundleProduct,
    features: getProductFeatures(experienceType)
  };
};

export const getTotalPriceText = (products, features) => {
  const text = products?.length > 1 ? (features?.totalPriceText)
    : (features?.singleTotalPriceText);
  return text;
};