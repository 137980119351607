const DOM_ELEMENTS = {
  SHARE_BELT: 'ShareBelt',
  BUYBOX: 'buybox',
  SALIENT_POINTS: 'salient-points',
  BREADCRUMBS: 'breadcrumbs',
  RETURNS_MESSAGING: 'returns-messaging',
  PRODUCT_DETAILS: {
    BRAND_COLLECTION: 'product-details:ProductDetailsBrandCollection',
    RATINGS: 'product-details:ProductDetailsRatings'
  },
  WHAT_WE_OFFER: 'what-we-offer',
  DELIVERY_AND_INSTALLATION: 'delivery-and-installation',
  PRODUCT_CONFIGURATOR: 'ProductConfigurator',
  STICKY_NAV: 'sticky-nav',
};

export const HIDE_ELEMENTS_PREVIEW_LIST = [
  DOM_ELEMENTS.SHARE_BELT,
  DOM_ELEMENTS.BUYBOX,
  DOM_ELEMENTS.SALIENT_POINTS,
  DOM_ELEMENTS.BREADCRUMBS,
  DOM_ELEMENTS.RETURNS_MESSAGING,
  DOM_ELEMENTS.PRODUCT_DETAILS.BRAND_COLLECTION,
  DOM_ELEMENTS.PRODUCT_DETAILS.RATINGS,
  DOM_ELEMENTS.WHAT_WE_OFFER,
  DOM_ELEMENTS.DELIVERY_AND_INSTALLATION,
  DOM_ELEMENTS.STICKY_NAV,
];

// AVOID DOM MANIPULATION IF POSSIBLE
// IF YOU CANNOT MAKE SURE YOUR SELECTORS WILL WORK IN ANY ENV
// *** //
// HARMONY DOM ELEMENTS TYPICALLY HAVE
// PROD: <element data-attribute={packageName}:{componentName}:{version}>
// DEV: <element data-attribute={componentName}>

export const PREVIEW_SELECTORS = {
  PRODUCT_CONFIGURATOR: `div[data-component*="${DOM_ELEMENTS.PRODUCT_CONFIGURATOR}"]`,
  PRODUCT_DETAILS: `div[data-component*="${DOM_ELEMENTS.PRODUCT_DETAILS.BRAND_COLLECTION}"]`,
  PRODUCT_INFO_BAR: '.product-info-bar'
};

export const PREVIEW_PRODUCT_TITLE = '[Product Title]';
export const PREVIEW_PRODUCT_BRAND = '[Brand]';

export const PREVIEW_LIFECYCLE_EVENTS = {
  CONFIGURATOR: {
    CHOICE_SELECTED_EVENT: 'configurator.configurator_choice_selected_event'
  }
};
