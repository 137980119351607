import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography, Button } from '@one-thd/sui-atomic-components';
import { Info } from '@one-thd/sui-icons';
import { ExperienceContext, useConfigService } from '@thd-nucleus/experience-context';
import { CARD_IMAGE } from '../utils/constants';
import { formatPrice, getDynamicOffer } from '../utils/general';
import { clickEvent } from '../utils/publisher';
import { getDefaultApplyNowURL, loadEstimatorScript } from '../utils/payment-estimator-utils';
import TermsAndConditionsDrawer from '../common/terms-and-conditions-drawer.component';

function NoImpact({ isB2B, total, hideDynamicMath, saleAmount }) {

  const experienceContext = useContext(ExperienceContext);
  const paymentEstimatorVersion = useConfigService('PaymentEstimatorVersion') || 'v1.2.0';
  const [applyNowLink, setApplyNowUrl] = useState('');
  const [showDrawer, setShowDrawer] = useState(false);

  const { channel } = experienceContext;
  const isMobile = channel === 'mobile';

  const printValues = (title, value, bold = false, success = false, negative = false) => {
    return (
      <div
        className={`sui-flex sui-flex-row sui-justify-between sui-mt-2 ${success && 'sui-text-success'
          }`}
      >
        <div className={bold ? 'sui-font-w-bold' : ''}>{title}</div>
        <div className={bold ? 'sui-font-w-bold' : ''}>
          {negative && '-'}
          {formatPrice(value)}
        </div>
      </div>
    );
  };

  const cardImage = isB2B ? CARD_IMAGE.PLCR_HOMEDEPOT : CARD_IMAGE.PLCN_HOMEDEPOT;

  const updateApplyNowUrl = () => {
    let saleAmountParam = saleAmount ? '&saleAmt=' + saleAmount : '';
    let sc = '&sc=30065';
    setApplyNowUrl(getDefaultApplyNowURL(isMobile, 'noimpact', isB2B) + saleAmountParam + sc);
  };

  const gotoCheckNow = () => {
    if (isMobile) {
      window.location.href = applyNowLink;
    } else {
      window.open(applyNowLink, '_blank');
    }
    clickEvent('plcc', 'right rail');
  };

  useEffect(() => {
    loadEstimatorScript(
      paymentEstimatorVersion,
      updateApplyNowUrl,
      channel === 'mobile'
    );
  }, []);

  if (isB2B) return null;

  return (
    <div className="sui-flex sui-flex-col sui-mt-4">
      <TermsAndConditionsDrawer
        closeDrawer={() => setShowDrawer(false)}
        open={showDrawer}
        isB2B={isB2B}
        price={total}
        isNoImpact
        applyNowClick={gotoCheckNow}
      />
      <div className="sui-flex">

        <Typography variant="h4" weight="display">
          You Could Save Up To $100 Today*
        </Typography>
      </div>

      <div className="sui-mt-4 sui-mb-2">

        See if you pre-qualify with no impact to your credit score.
        <span className="sui-pl-1 sui-cursor-pointer sui-relative sui-top-1">
          <Info
            aria-label="Credit Offer Details"
            color="info"
            onClick={() => setShowDrawer(!showDrawer)}
            size="small"
          />
        </span>
      </div>

      <div id="applyNowLink" className="sui-mb-4 sui-mt-4 sui-flex sui-gap-3">
        <img
          src={cardImage}
          width="auto"
          height="auto"
          alt="Credit Card Icon"
          className="sui-h-11 sui-mr-2"
        />
        <Button onClick={gotoCheckNow} fullWidth variant="secondary">
          Check Now
        </Button>
      </div>

      {
        total >= 25 && !hideDynamicMath && (
          <div id="values" className=" sui-mb-3">
            {printValues('Item Total', total, true)}
            {printValues('Savings', getDynamicOffer(total), false, false, true)}
            {printValues('New Total', total - getDynamicOffer(total), true, true)}
          </div>
        )
      }
    </div>
  );
}

NoImpact.propTypes = {
  isB2B: PropTypes.bool,
  total: PropTypes.number.isRequired,
  hideDynamicMath: PropTypes.bool,
  saleAmount: PropTypes.number,
};

NoImpact.defaultProps = {
  isB2B: false,
  hideDynamicMath: false,
  saleAmount: 0,
};

export { NoImpact };
