import React, { useContext } from 'react';
import classNames from 'classnames';
import { useController, useFormState } from 'react-hook-form';
import {
  bool, node, number, oneOfType, string
} from 'prop-types';
import { TextField, InputAdornment } from '@one-thd/sui-atomic-components';
import { CalculatorContext } from './CalculatorContext';
import { FLOORING_DRAWER } from '../../constants';

// Regex is loose so that users are free to type out what they need without too many restrictions
// For more info on this Regex, try it out at https://ihateregex.io/playground
const looseNumberRegex = /^[0-9\b]{0,5}?([.]([0-9\b]{1,2})?)?$/;
const noDigitsRegex = /^[\D]*$/;

export const CalculatorInputElement = ({
  endAdornment, hideLabel, index, labelName, propName, reducedMargin
}) => {
  const {
    control,
    calculatorType,
    resolveDuplicateEntryNames,
    onSubmit,
    resetEntryLengthAndWidth
  } = useContext(CalculatorContext);
  const {
    errors: { entries: errors = [] },
  } = useFormState({ control });
  const regexPattern = propName === 'name' ? noDigitsRegex : looseNumberRegex;

  const id = `calculator-entries.${index}.${propName}`;

  const { field } = useController({
    name: `entries.${index}.${propName}`,
    control,
  });
  const {
    name, ref, onChange, onBlur, value
  } = field;

  const wrappedOnChange = (event) => {
    const val = event.target?.value;

    if (regexPattern.test(val) || val.length < value.length || val.length === 0) {
      let parsedEvent = null;
      if (propName !== 'name') {
        parsedEvent = {
          ...event,
          target: {
            ...event.target,
            value: val === '' ? null : val,
          },
        };
      }

      onChange(parsedEvent !== null ? parsedEvent : event);
    }
  };

  const wrappedOnBlur = (event) => {
    if (propName === 'name') {
      resolveDuplicateEntryNames();
    }

    if (propName === 'sqFootage') {
      resetEntryLengthAndWidth(index);
    }

    if (calculatorType !== FLOORING_DRAWER) {
      onSubmit();
    }
    onBlur(event);
  };

  const marginOverrideLabelWrapper = (props) => {
    // eslint-disable-next-line react/prop-types
    const { children, ...otherProps } = props;
    const overridenProps = {
      ...otherProps,
      className: otherProps.className
        .replace(/sui-mb-(\d)+/, '')
        .replace('sui-leading-tight', 'sui-leading-normal')
    };

    return (
      // eslint-disable-next-line jsx-a11y/label-has-associated-control
      <label
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...overridenProps}
      >
        {children}
      </label>
    );
  };

  return (
    <div className="sui-w-full">
      <TextField
        fullWidth
        label={!hideLabel ? `${labelName || propName}:` : null}
        FormLabelProps={{
          component: reducedMargin ? marginOverrideLabelWrapper : 'label'
        }}
        onChange={wrappedOnChange}
        type="string"
        name={name}
        ref={ref}
        value={value === null ? '' : value?.toString() ?? ''}
        helpMessage={propName === 'height' ? '3 in. recommended' : null}
        status={(errors[index] || {})[propName] ? 'error' : null}
        statusMessage={((errors[index] || {})[propName] || {})?.message}
        placeholder={propName === 'name' ? 'Name' : null}
        InputProps={{
          onBlur: wrappedOnBlur,
          endAdornment: endAdornment ? (
            <InputAdornment position="end">{endAdornment}</InputAdornment>
          ) : undefined,
        }}
      />
    </div>
  );
};

CalculatorInputElement.propTypes = {
  endAdornment: oneOfType([string, node]),
  hideLabel: bool,
  index: number.isRequired,
  labelName: string,
  propName: string.isRequired,
  reducedMargin: bool
};

CalculatorInputElement.defaultProps = {
  endAdornment: '',
  hideLabel: false,
  labelName: '',
  reducedMargin: false
};
