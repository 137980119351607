import React, { useState, useEffect } from 'react';
import { oneOf } from 'prop-types';
import { CreditCard } from '@one-thd/sui-icons';
import {
  NAV_LINKS_DEFAULT, NAV_LINKS_B2B, SOCIAL_BUTTONS, OTHER_SITE_LINKS
} from './footer-data';
import { HOST_CONSTANTS } from '../../constants';
import { FooterEmail } from './FooterEmail';
import { useFooterContext } from './FooterContext';

const NavigationLinks = () => {
  const { customerType } = useFooterContext();

  const isProCustomer = customerType === 'b2b';
  const navLinks = isProCustomer ? NAV_LINKS_B2B : NAV_LINKS_DEFAULT;

  const { DEFAULT_HOST } = HOST_CONSTANTS;

  const FINANCIAL_FINANCING = 'Special Financing Available everyday*';
  const FINANCIAL_CARD = 'Pay & Manage Your Card';
  const FINANCIAL_OFFERS = 'Credit Offers';

  const footerColumnsClasses = 'sui-w-full sui-flex sui-grid sui-grid-cols-4 sui-gap-3';

  const footerLinksClasses = 'sui-flex sui-flex-col sui-flex-wrap sui-list-none sui-justify-center';

  // eslint-disable-next-line max-len
  const financingWrapperClasses = 'sui-bg-subtle sui-grid sui-gap-1 sui-h-auto sui-p-5 sui-flex sui-flex-col sui-justify-between';

  const socialButtonsWrapperClasses = 'sui-h-auto sui-pt-2 sui-flex sui-flex-row sui-justify-between';

  // eslint-disable-next-line max-len
  const otherSitesWrapper = `sui-flex sui-justify-center sui-text-center sui-pt-4 ${ isProCustomer ? 'sui-block' : 'sui-hidden' }`;

  const otherSitesLinks = 'sui-flex sui-flex-wrap sui-gap-2 sui-list-none sui-pt-2';

  return (
    <div className="sui-hidden lg:sui-block sui-flex sui-p-5">
      <div className={footerColumnsClasses}>
        { navLinks.map((category, categoryIndex) => {
          return (
            <div id="footer" key={`${category.name}-${categoryIndex}`}>
              <h3 className="sui-text-lg sui-pb-5">
                {category.name}
              </h3>
              <ul className={footerLinksClasses}>
                {category.links.map((link, linkIndex) => {
                  return (
                    <li className="sui-text-sm sui-mb-2 sui-underline" key={`${link.label}-${linkIndex}`}>
                      <a
                        alt={link.label}
                        href={link.href}
                        title={link.label}
                        data-testid={link?.testid}
                        onClick={link?.onClick}
                      >
                        {link.label}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        })}
        <div>
          <div className="sui-flex sui-flex-col sui-gap-3">
            <div id="footer" className={financingWrapperClasses}>
              <div className="sui-flex sui-flex-row">
                <div className="sui-pr-2 sui-pb-2"><CreditCard /></div>
                <div>
                  {FINANCIAL_FINANCING}
                </div>
              </div>
              <a
                className="sui-text-sm sui-mb-2 sui-underline"
                href={`${DEFAULT_HOST}/myaccount/thdcreditcard`}
                title="Pay & Manage Your Card"
                alt="Pay & Manage Your Card"
              >
                {FINANCIAL_CARD}
              </a>
              <a
                className="sui-text-sm sui-mb-2 sui-underline"
                href={`${DEFAULT_HOST}/c/Credit_Center`}
                title="Credit Offers"
                alt="Credit Offers"
              >
                {FINANCIAL_OFFERS}
              </a>
            </div>
            {!isProCustomer && (<FooterEmail />)}
            <div className={socialButtonsWrapperClasses}>
              {SOCIAL_BUTTONS.map((socialButton) => {
                return (
                  <a
                    className="sui-flex sui-flex-wrap sui-justify-center sui-items-center sui-w-8 sui-h-8"
                    href={socialButton.href}
                    aria-label={socialButton.label}
                    target={socialButton.target}
                    rel="nofollow noopener noreferrer"
                    key={socialButton.label}
                  >
                    <img
                      className="sui-h-full sui-w-auto"
                      loading="lazy"
                      src={socialButton.src}
                      alt={socialButton.label}
                      aria-label={socialButton.label}
                      key={socialButton.label}
                      height="inherit"
                      width="inherit"
                    />
                  </a>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className={otherSitesWrapper}>
        <div>
          <h3 className="sui-text-lg">Our Other Sites</h3>
          <div id="footer" className={otherSitesLinks}>
            {OTHER_SITE_LINKS.map((siteLink, index) => {
              return (
                <ul key={index}>
                  <a
                    className="sui-text-sm sui-underline"
                    href={siteLink.href}
                    title={siteLink.label}
                    alt={siteLink.label}
                  >
                    {siteLink.label}
                  </a>
                </ul>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

NavigationLinks.propTypes = {};
NavigationLinks.defaultProps = {};
NavigationLinks.displayName = 'Footer Navigation Links';

export { NavigationLinks };
