/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react/require-default-props */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link, Typography } from '@one-thd/sui-atomic-components';
import './visual-navigation-tile.scss';

export const VisualNavigationTile = ({
  href, label, src, isMobileSlider, classes, onClick
}) => {

  return (
    <div className={`visual-navigation ${classes}`} data-component="VisualNavigationTile">
      <Link
        href={href}
        title={label}
        onClick={onClick}
        underline="hover"
      >
        <div className={classNames(
          'vis-nav__image--frame',
          'sui-mx-auto', {
            'sui-w-20': isMobileSlider,
            'sui-h-20': isMobileSlider,
            'sui-w-24': !isMobileSlider,
            'sui-h-24': !isMobileSlider
          })}
        >
          <img
            src={src}
            alt={`${label}`}
            title={`${label}`}
            className={classNames('vis-nav__image', {
              'sui-w-20': isMobileSlider,
              'sui-h-20': isMobileSlider,
              'sui-max-w-24': !isMobileSlider,
              'sui-max-h-24': !isMobileSlider,
              stretchy: !isMobileSlider
            })}
            height={1}
            width={1}
            loading="lazy"
          />
        </div>
        <div className="sui-mt-1">
          <Typography
            variant={isMobileSlider ? 'body-xs' : 'body-base'}
            align="center"
            lineClamp={{ default: '3' }}
            color="primary"
          >
            {label}
          </Typography>
        </div>
      </Link>
    </div>
  );
};

const displayName = 'VisualNavigationTile';

const propTypes = {
  classes: PropTypes.string,
  href: PropTypes.string.isRequired,
  isMobileSlider: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func,
  src: PropTypes.string.isRequired
};

const defaultProps = {
  classes: null,
  isMobileSlider: false,
  label: '',
  onClick: () => {}
};

VisualNavigationTile.displayName = displayName;
VisualNavigationTile.propTypes = propTypes;
VisualNavigationTile.defaultProps = defaultProps;
