import React, { useContext } from 'react';
import { func, string, bool } from 'prop-types';
import { Button } from '@one-thd/sui-atomic-components';
import { QueryProvider, QueryContext } from '@thd-nucleus/data-sources';
import { SwapItem } from '@thd-olt-component-react/swap-item';
import { getBundleDrawerRootProps } from '../../utils/product-bundle-utils';

/**
 * Props
 *
 * @typedef {Object} Props
 * @property {string} itemId - The unique identifier of the item to be swapped.
 * @property {Function} swapProducts - The function to call for swapping the current item with another item.
 * @property {Function} [closeDrawer] - An optional function that called to close the drawer component after swapping.
 * @property {string} [buttonText='Swap'] - The text to display on the swap button. Defaults to "Swap".
 * @property {boolean} isMobile - Boolean to check if the platform is mobile.
 *
 * @param {Props} props The properties passed to the Swap component.
 * @returns {React.ReactElement} A component that includes a button to trigger the swapping of items.
 */

export const SwapButton = ({
  itemId,
  swapProducts,
  closeDrawer,
  buttonText = 'Swap',
  setSelectedItemId,
  itemIndex,
  isMobile
}) => {
  const { defaultVariables } = useContext(QueryContext) || {};
  return (
    <QueryProvider
      cacheKey="bundle-products-swap-item"
      defaultVariables={defaultVariables?.current}
    >
      <SwapItem
        itemId={itemId}
        onSwap={(swappedItem) => {
          if (swappedItem) {
            setSelectedItemId(itemId);
            swapProducts(itemId, swappedItem?.product?.itemId);
            if (closeDrawer) closeDrawer();
          }
        }}
        itemIndex={itemIndex}
        closeOnSwap
        openInQuickViewDescription
        openDetailsInNewTab
        hideQuickViewMask
        drawerRootProps={getBundleDrawerRootProps(isMobile)}
        customElement={(
          <Button
            fullWidth
            variant="secondary"
            data-testid="swap-cta-button"
          >
            {buttonText}
          </Button>
        )}
      />
    </QueryProvider>
  );
};

SwapButton.propTypes = {
  itemId: string,
  swapProducts: func.isRequired,
  closeDrawer: func,
  setSelectedItemId: func.isRequired,
  buttonText: string,
  itemIndex: string,
  isMobile: bool
};

SwapButton.defaultProps = {
  itemId: undefined,
  closeDrawer: undefined,
  buttonText: 'Swap',
  itemIndex: 'n/a',
  isMobile: false
};