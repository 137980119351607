import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Tooltip } from '@thd-olt-component-react/tooltip';
import { Link, Typography } from '@one-thd/sui-atomic-components';
import { Info } from '@one-thd/sui-icons';
import { signIn } from '../../../../utils/utils';

export const SignInLink = ({ layoutType, customClasses, itemId, align, displayDarkTheme, isSignInToolTip }) => {
  const SignText = "Sign in for The Home Depot price"
  const SignInTooltiptext = "The Home Depot price is different from what the manufacturer allows us to advertise. Sign in to your account to view The Home Depot price."
  const iconSize = layoutType === "detailed" ? "5" : "4";
  const adaptiveTextStyles = {
    'sui-text-subtle': !isSignInToolTip && !displayDarkTheme,
  };
  const minimalLayout = classNames(
    'sui-font-regular sui-text-xs sui-leading-tight sui-underline',
    {
      'sui-align-right sui-text-right sui-float-right': align !== 'start',
      'sui-align-left sui-text-left sui-float-left': align === 'start',
      ...adaptiveTextStyles,
      'sui-text-primary': displayDarkTheme
    }
  );
  const detailedLayout = classNames(
    'sui-font-regular sui-text-base sui-leading-tight sui-align-left',
    'sui-w-full sui-text-left sui-underline',
    {
      ...adaptiveTextStyles,
      'sui-text-primary': displayDarkTheme
    }
  );
  const simpleLayout = classNames(
    'sui-font-regular sui-text-xs sui-leading-tight sui-align-left',
    'sui-w-full sui-text-left sui-underline',
    {
      ...adaptiveTextStyles,
      'sui-text-primary': displayDarkTheme
    }
  );
  let signInClasses;
  if (layoutType === 'minimal') {
    signInClasses = minimalLayout;
  } else if (layoutType === 'simple') {
    signInClasses = simpleLayout;
  } else if (layoutType === 'detailed') {
    signInClasses = detailedLayout;
  } else {
    signInClasses = customClasses;
  }

  return (
    <div className={`sui-mb-1 sui-mt-1 ${isSignInToolTip ? 'sui-flex sui-text-center' : ''}`}>
      {isSignInToolTip ?
        <Tooltip
          content={
            <div className="sui-relative sui-flex sui-flex-col sui-bg-white sui-rounded-md sui-mr-2">
              <div className="sui-flex sui-justify-between sui-items-center">
                <Typography variant="body-xs" className="sui-font-regular sui-text-xs">
                  {SignInTooltiptext}
                </Typography>
              </div>
            </div>
          }
          placement="bottom"
          maxWidth={300}
          offset="normal"
        >
          <div className='sui-cursor-pointer sui-flex'>
            <Info color="primary" className={`sui-align-middle sui-mr-1 sui-w-${iconSize}`} />
            <span className={signInClasses}>{SignText}</span>
          </div>
        </Tooltip>
        :
        <Link
          component="button"
          onClick={() => signIn(itemId, layoutType)}
          className={signInClasses}>
          {SignText}
        </Link>
      }
    </div>
  );
};

SignInLink.propTypes = {
  layoutType: PropTypes.string,
  customClasses: PropTypes.string,
  itemId: PropTypes.string,
  align: PropTypes.string,
  displayDarkTheme: PropTypes.bool
};

SignInLink.defaultProps = {
  layoutType: '',
  customClasses: '',
  itemId: '',
  align: '',
  displayDarkTheme: false
};
