import React, { useContext } from 'react';
import { string, func } from 'prop-types';
import { QueryContext, QueryProvider } from '@thd-nucleus/data-sources';
import { withDynamicComponent } from '@thd-nucleus/app-render';
import { useStore } from '@thd-nucleus/experience-context';
import { Col, Row } from '@thd-olt-component-react/core-ui';
import { withHydrator } from '@thd-olt-component-react/hydrator';
import { withErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { TransactionalPlaceholder } from './loader/TransactionalPlaceholder';
import { TransactionalPackageSummary } from './subcomponents/TransactionalPackageSummary';
import { TransactionalPackageInfo } from './subcomponents/TransactionalPackageInfo';
import { TransactionalPods } from './subcomponents/TransactionalPods';
import PackagesMiniProvider from './PackagesMiniProvider';
import { transactionalDataModel } from './transactionalDataModel';
import './packages-mini-transactional.scss';

const PackagesMiniTransactionalComponent = ({ itemId, callOnLoaded }) => {
  const { storeId, storeZip } = useStore();
  const { defaultVariables } = useContext(QueryContext) || {};
  const newDefaultVariables = {
    storeId,
    zipCode: storeZip,
    ...(typeof defaultVariables?.current?.isBrandPricingPolicyCompliant === 'boolean' && {
      isBrandPricingPolicyCompliant: defaultVariables.current.isBrandPricingPolicyCompliant
    })
  };

  return (
    <div className="packages-mini" data-testid="packages-mini" data-component="PackagesMiniTransactional">
      <PackagesMiniProvider
        itemId={itemId}
        callOnLoaded={callOnLoaded}
        placeholder={<TransactionalPlaceholder />}
      >
        <Row className="packages-mini__main-grid">
          <TransactionalPackageInfo />
          <Col xs="7" sm="8" flatten>
            <Row>
              <Col className="packages-mini__transactional-pods-container">
                <QueryProvider
                  defaultVariables={newDefaultVariables}
                  dataSource="packages"
                  cacheKey="package-transactional-products"
                >
                  <TransactionalPods />
                </QueryProvider>
              </Col>
              <Col flatten>
                <TransactionalPackageSummary />
              </Col>
            </Row>
          </Col>
        </Row>
      </PackagesMiniProvider>
    </div>
  );
};

const propTypes = {
  itemId: string,
  callOnLoaded: func
};

const defaultProps = {
  itemId: '',
  callOnLoaded: () => {},
};

PackagesMiniTransactionalComponent.displayName = 'PackagesMiniTransactional';
PackagesMiniTransactionalComponent.propTypes = propTypes;
PackagesMiniTransactionalComponent.defaultProps = defaultProps;
PackagesMiniTransactionalComponent.dataModel = transactionalDataModel;

const HydratedComponent = withHydrator({
  id: 'product-section-packages-mini-transactional',
  scrollBuffer: 100,
  delay: 1500,
  preserveCtxVal: 'clientStore',
  placeholder: <TransactionalPlaceholder />
}, PackagesMiniTransactionalComponent);

const DynamicComponent = withDynamicComponent(HydratedComponent, { placeholder: <TransactionalPlaceholder /> });
const PackagesMiniTransactional = withErrorBoundary(DynamicComponent);
PackagesMiniTransactional.displayName = PackagesMiniTransactionalComponent.displayName;

export { PackagesMiniTransactional };
