import React, {
  lazy, Suspense, useContext, useEffect, useState
} from 'react';
import {
  string,
  func,
  bool,
  shape,
  oneOfType,
  oneOf
} from 'prop-types';
import { ExperienceContext, useStore } from '@thd-nucleus/experience-context';
import { useDataModel, extend } from '@thd-nucleus/data-sources';
import { CustomerContext, useThdCustomer } from '@thd-olt-functional/customer-information';
import { PromotionProductsLoader } from './PromotionProductsLoader/PromotionProductsLoader';
import { MA } from '../utils/constants';
import { hasEligibleAnchor, hasEnabledPromotion, isCategorizedPromotion } from '../utils/promo-utils';
import { useFeatureSwitches } from '../hooks/useFeatureSwitches';
import { useMACheckAvailability } from '../hooks/useMACheckAvailability';
import { PromotionProductsAnchorDataModel } from '../models/PromotionProductsAnchorDataModel';

const PromotionProducts = lazy(() => import(
  /* webpackChunkName: "promotion-products-lazy" */
  './PromotionProducts'
).then((mod) => {
  return {
    default: (props) => {
      const { PromotionProducts: PromotionProductsComp } = mod;

      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <PromotionProductsComp {...props} />
      );
    },
  };
}),
);

/**
 * @param {Object} props
 * Component props
 * @param {string} props.itemId
 * The itemId of the "Anchor Item". It is the promotion associated with that item that is displayed
 * @param {boolean | null} [props.bogoEnabled]
 * Enables BOGO promotions. The config service can enable or disable as well. Default: `true`
 * @param {boolean | null} [props.msbEnabled]
 * Enables MSB promotions. The config service can enable or disable as well. Default: `true`
 * @param {boolean | null} [props.bmsmEnabled]
 * Enables BMSM promotions. The config service can enable or disable as well. Default: `true`
 * @param {boolean | null} [props.backwardsBogoEnabled]
 * Enables Backwards BOGO promotions. The config service can enable or disable as well. Default: `false`
 * @param {boolean | null} [props.bogoDollarThresholdEnabled]
 * Enables BOGO Dollar Threshold promotions. The config service can enable or disable as well. Default: `false`
 * @param {boolean | null} [props.bxg1Enabled]
 * Enables Buy Two Get One promotions. The config service can enable or disable as well. Default: `false`
 * @param {boolean | null} [props.categorizedPromotionEnabled]
 * Enables Categorized promotions. The config service can enable or disable as well. Default: `false`
 * @param {(anchorItem: Object) => boolean} [props.isMultiSelect]
 * For BMSM promotions for major appliance products
 * @param {string} [props.type]
 * For onFail fallback
 * @param {boolean | null} [props.setMultiSelectIfNValuesExist]
 * Enables the isMultiSelect prop on Contentful
 * @returns {React.ReactElement | null}
 */
export const PromotionProductsAnchor = ({
  itemId,
  bogoEnabled: bogoEnabledProp,
  msbEnabled: msbEnabledProp,
  bmsmEnabled: bmsmEnabledProp,
  backwardsBogoEnabled: backwardsBogoEnabledProp,
  bogoDollarThresholdEnabled: bogoDollarThresholdEnabledProp,
  bxg1Enabled: bxg1EnabledProp,
  categorizedPromotionEnabled: categorizedPromotionEnabledProp,
  isMultiSelect,
  onFail,
  type,
  setMultiSelectIfNValuesExist
}) => {
  const { storeId, storeZip } = useStore();
  const { deliveryZip } = useContext(ExperienceContext);
  const zipCode = deliveryZip || storeZip || null;
  const [mounted, setMounted] = useState(false);
  useEffect(() => { setMounted(true); }, []);

  const {
    bogoEnabled,
    msbEnabled,
    bmsmEnabled,
    backwardsBogoEnabled,
    bogoDollarThresholdEnabled,
    bxg1Enabled,
    categorizedPromotionEnabled,
  } = useFeatureSwitches({
    bogoEnabledProp,
    msbEnabledProp,
    bmsmEnabledProp,
    backwardsBogoEnabledProp,
    bogoDollarThresholdEnabledProp,
    bxg1EnabledProp,
    categorizedPromotionEnabledProp,
  });

  const isEnabled = bogoEnabled || msbEnabled || bmsmEnabled;

  const productVariables = zipCode
    ? {
      itemId,
      storeId,
      zipCode
    }
    : {
      itemId,
      storeId
    };

  const { exchangeCustomerCookies } = useContext(CustomerContext);
  const { isExchangeCustomer } = useThdCustomer() || {};

  // this is the request for the anchor item
  const { data } = useDataModel('product', {
    variables: productVariables,
    context: {
      headers: isExchangeCustomer ? exchangeCustomerCookies : null
    },
    skip: !itemId || !isEnabled,
  });

  const anchorItem = data?.product || { itemId };
  const anchorProductType = anchorItem.identifiers?.productType;
  const isAnchorAppliance = anchorProductType === MA;
  const conditionalPromotion = anchorItem.pricing?.conditionalPromotions?.[0] || {};
  const experienceTag = conditionalPromotion.experienceTag;
  const subExperienceTag = conditionalPromotion.subExperienceTag;
  const tiers = conditionalPromotion.reward?.tiers;
  const shouldUseMultiSelect = isCategorizedPromotion({ subExperienceTag }) || (
    isMultiSelect && isMultiSelect(anchorItem)) || !!(setMultiSelectIfNValuesExist && conditionalPromotion.nvalues?.length);
  // for MA BMSM (multiselect), the maAvailability for the selections are determined when they are all added to cart,
  // so for initial loading flow, we are only concerned about maAvailability for single select promos (BOGOs).
  const shouldUseMACheckAvailability = isAnchorAppliance && !shouldUseMultiSelect;
  const maAvailability = useMACheckAvailability({ skip: !shouldUseMACheckAvailability });

  const isDisplayablePromotion = (
    hasEligibleAnchor({ anchorItem, experienceTag, tiers })
    && hasEnabledPromotion({
      experienceTag,
      subExperienceTag,
      backwardsBogoEnabled,
      bogoDollarThresholdEnabled,
      bogoEnabled,
      bmsmEnabled,
      bxg1Enabled,
      msbEnabled,
      type,
      categorizedPromotionEnabled
    })
  );

  // anchor ineligible or experience disabled
  if (!isDisplayablePromotion) {
    return null;
  }

  // server and client first render.
  // We cannot return Suspense on server-side, so we must wait until we are mounted.
  if (!mounted) {
    return (
      <PromotionProductsLoader
        experienceTag={experienceTag}
        subExperienceTag={subExperienceTag}
        type={type}
      />
    );
  }

  // we are now on the client with a hydrated DOM and displayable promotion
  // we request the lazy bundle.
  return (
    <Suspense fallback={(
      <PromotionProductsLoader
        experienceTag={experienceTag}
        subExperienceTag={subExperienceTag}
        type={type}
      />
    )}
    >
      <PromotionProducts
        anchorItem={anchorItem}
        experienceTag={experienceTag}
        subExperienceTag={subExperienceTag}
        isMultiSelect={shouldUseMultiSelect}
        maAvailability={maAvailability}
        onFail={onFail}
        type={type}
      />
    </Suspense>
  );
};

PromotionProductsAnchor.displayName = 'PromotionProductsAnchor';

PromotionProductsAnchor.propTypes = {
  /** The itemId of the "Anchor Item". It is the promotion associated with that item that is displayed */
  itemId: string.isRequired,
  /** Enables BOGO promotions. The config service can enable or disable as well. Default: `true` */
  bogoEnabled: oneOfType([bool, shape({})]),
  /** Enables MSB promotions. The config service can enable or disable as well. Default: `true` */
  msbEnabled: oneOfType([bool, shape({})]),
  /** Enables BMSM promotions. The config service can enable or disable as well. Default: `true` */
  bmsmEnabled: oneOfType([bool, shape({})]),
  /** Enables Backwards BOGO promotions. The config service can enable or disable as well. Default: `false` */
  backwardsBogoEnabled: oneOfType([bool, shape({})]),
  /** Enables Backwards BOGO promotions. The config service can enable or disable as well. Default: `false` */
  bogoDollarThresholdEnabled: oneOfType([bool, shape({})]),
  /** Enables Buy One Get Two promotions. The config service can enable or disable as well. Default: `false` */
  categorizedPromotionEnabled: oneOfType([bool, shape({})]),
  /** Enables BMSM Categorized promotions. The config service can enable or disable as well. Default: `false` */
  bxg1Enabled: oneOfType([bool, shape({})]),
  /**
   * A function that returns a bool that enables multi-select/multi-item add-to-cart
   * primary use case: major-appliance BMSM add-to-cart
   */
  isMultiSelect: func,
  /** callback invoked if data is not displayable */
  onFail: func,
  /** Decides the type of UI to render */
  type: oneOf(['card', 'pod']),
  /** Enables the isMultiSelect prop on Contentful  **/
  setMultiSelectIfNValuesExist: bool
};

PromotionProductsAnchor.defaultProps = {
  bogoEnabled: null,
  msbEnabled: null,
  bmsmEnabled: null,
  backwardsBogoEnabled: null,
  bogoDollarThresholdEnabled: null,
  categorizedPromotionEnabled: null,
  bxg1Enabled: null,
  isMultiSelect: () => false,
  onFail: null,
  type: 'pod',
  setMultiSelectIfNValuesExist: false
};

PromotionProductsAnchor.dataModel = extend({}, PromotionProductsAnchorDataModel);
