import React, {
  useCallback, useContext, useEffect, useRef, useState
} from 'react';
import {
  extend,
  hoist,
  params as gqlParams,
  QueryContext,
  QueryProvider,
  number,
  string
} from '@thd-nucleus/data-sources';
import { ExperienceContext, useStore, useConfigService } from '@thd-nucleus/experience-context';
import { CustomerContext, useThdCustomer } from '@thd-olt-functional/customer-information';
import { node } from 'prop-types';
import { PIPRedirector } from '@thd-nucleus/app-render';
import { AisleBay } from '@thd-olt-component-react/aisle-bay';
// @TODO: James to look at multiple `clientOnlyProduct` firing (one w/ zip, one w/o) from buybox(?)
import { Buybox } from '@thd-olt-component-react/buybox';
import { CategoryList } from '@thd-olt-component-react/category-list';
import { CompareTray } from '@thd-olt-component-react/compare';
import { Col, Row } from '@thd-olt-component-react/core-ui';
import { Button } from '@one-thd/sui-atomic-components';
import { HdhomeSignup } from '@thd-olt-component-react/email-signup';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { MediaGalleryWithHotspotsCarousel } from '@thd-olt-component-react/media-gallery';
import { Metadata, ProductEntryMetadata } from '@thd-olt-component-react/metadata';
import { OrderSample, usePLPSamples } from '@thd-olt-component-react/order-sample';
import { Promotion } from '@thd-olt-component-react/promotion';
import { Price, PriceClearanceDataModel } from '@thd-olt-component-react/price';
import { ProductDetails } from '@thd-olt-component-react/product-details';
import { useHistory, useNavigate, useLocation, useParams } from '@thd-olt-component-react/router';
import { Sharebelt, ShareFavorite } from '@thd-olt-component-react/thd-sharebelt';
import {
  parseUrl,
  productDefaultResultsDataModel,
  ProductResults,
  ProductResultsContext,
  ResultsApplied,
  ResultsDimensions,
  ResultsHeader,
  ResultsPagination,
  ResultsSortBy,
  ResultsWrapped,
  updateQueryParams,
  useProductResults
} from '@thd-olt-component-react/product-results';
import {
  DynamicRecsWrapper,
  RecentlyViewedWrapper,
  InstantRecommendations
} from '@thd-olt-component-react/thd-recs-containers';
import { SalientPoints } from '@thd-olt-component-react/salient-points';
import { SearchFeedback } from '@thd-olt-component-react/search-feedback';
import { SponsoredCarousel } from '@thd-olt-component-react/sponsored-content';
import { SuperSku } from '@thd-olt-component-react/super-sku';
import { declareContexts, Hydrator } from '@thd-olt-component-react/hydrator';
import { ConnectedTranslatorWidget } from '@thd-olt-component-react/translator_widget';
import { CustomProductPodResponsive } from '../browse-search/CustomProductPodResponsive';
import { Breadcrumbs, BreadcrumbsLoad, BreadcrumbsModel } from './dynamic-imports';
import { pepCampaignId, subscriptionType, requestKey } from '../../constants';
import '../../styles/pep-page.scss';
import { shouldDisplayResultsAppliedDesktop, hasInStoreFilter } from './pep-utils';

const HoistedResultsWrapped = hoist(ResultsWrapped, CustomProductPodResponsive);

const ZoneCard = ({ children }) => {
  return <div className="zone-card">{children}</div>;
};

ZoneCard.propTypes = {
  children: node.isRequired
};

export const Pep = () => {
  declareContexts([ProductResultsContext, QueryContext, ExperienceContext, CustomerContext]);
  const { isCustomerIdentified = false } = useThdCustomer() || {};
  const pageSize = 24;
  const ctx = useContext(ExperienceContext);
  const { clientStore, cookie, channel, isConsumerApp } = ctx;
  const { itemId } = useParams();
  const { defaultVariables, isClientResolved } = useContext(QueryContext);
  const navigate = useNavigate();
  const { isLocalized, storeId, storeZip } = useStore({ varnish: false });
  const { search: querystring, pathname } = useLocation();
  const history = useHistory();
  const path = `${pathname}${querystring}`;
  const isMobileOrConsumerApp = isConsumerApp || channel === 'mobile';

  const HYDRATION_STANDARD_SCROLL_BUFFER = 750;
  const CLIENT_SIDE_PRODUCT_POD_COUNT = 24;

  const [containerHeight, setContainerHeight] = useState(null);

  let fsInteractiveRecs = useConfigService('fs:isInteractiveRecs');

  const scrollToRef = React.createRef();

  const onStoreChange = ({ output }) => {
    const { storeId: localStore } = output || {};
    if (!storeId) return;

    if (window && window.THD_LOCALIZER_AUTO_INIT && window.THD_LOCALIZER_AUTO_INIT.Localizer) {
      window.THD_LOCALIZER_AUTO_INIT.Localizer.localize({ storeId: localStore }).then(() => {
        window.location.reload();
      });
    }
  };

  const resizeObserverZoneA = () => {
    const ColZoneA = document.getElementById('zone-a__col');

    if (!ColZoneA) return;
    const resizeObserver = new ResizeObserver(() => {
      // Set the height of the categories list component based on zone-a-parent-container height
      const height = ColZoneA.clientHeight;
      setContainerHeight(height);
    });
    resizeObserver.observe(ColZoneA);

    // eslint-disable-next-line
    return () => resizeObserver.disconnect;
  };

  useEffect(() => {
    window.LIFE_CYCLE_EVENT_BUS.on('product-results.change-store', onStoreChange);
    resizeObserverZoneA();
  }, []);

  const targetPosition = (element) => {
    if (!element) return null;
    const currentPosition = window.scrollY || window.pageYOffset;
    return element.getBoundingClientRect().top + currentPosition;
  };

  const onDisplayChange = ({ useStoreFilter }) => {
    const updatedPath = updateQueryParams(path, [
      { paramName: 'onDisplay', paramValue: useStoreFilter }
    ]);
    history.push(updatedPath);
  };

  const onDimensionsChange = useCallback(({ refinement, dimension = '' }) => {
    const { redirectUrl, url, clear = false, refinementKey } = refinement;
    const params = {
      ...parseUrl({ path }),
      ...parseUrl({ path: url }),
      Nao: 0
    };

    if (clear || (dimension && dimension?.label === 'Price' && !refinementKey)) {
      params.lowerbound = '';
      params.upperbound = '';
    }

    const paramsObject = Object.keys(params)
      .map((param) => {
        return {
          paramName: param,
          paramValue: params[param]
        };
      })
      .filter((par) => {
        return !/category|context|slugs/.test(par.paramName);
      });
    const updatedUrl = redirectUrl || updateQueryParams(path, paramsObject);
    window.LIFE_CYCLE_EVENT_BUS.trigger('filters-refinements.click', refinement);
    const targetNode = scrollToRef && scrollToRef.current ? scrollToRef.current : null;
    let targetLocation = targetPosition(targetNode);
    window.scrollTo({
      top: targetLocation,
      behavior: 'smooth'
    });
    navigate(updatedUrl);
  });

  const onPageChange = useCallback(({ page }) => {
    const newPath = updateQueryParams(path, [
      {
        paramName: 'Nao',
        paramValue: (page - 1) * pageSize
      }
    ]);

    navigate(newPath);

    const targetNode = scrollToRef && scrollToRef.current ? scrollToRef.current : null;
    let targetLocation = targetPosition(targetNode);
    window.scrollTo({
      top: targetLocation,
      behavior: 'smooth'
    });
  });

  const onSortChange = useCallback(({
    sortby, sortorder, name, currentPage, page
  }) => {
    const newPath = updateQueryParams(path, [
      {
        paramName: 'Nao',
        paramValue: null
      },
      {
        paramName: 'sortorder',
        paramValue: sortorder
      },
      {
        paramName: 'sortby',
        paramValue: sortby
      }
    ]);

    window.LIFE_CYCLE_EVENT_BUS.trigger('sort-refinements.click', {
      name,
      currentPage,
      page
    });
    navigate(newPath);
  });

  const onChange = (dataSku = {}) => {
    const { slug, itemId: sskuItemId } = dataSku;
    if (slug && sskuItemId) {
      navigate(`/pep/${slug}/${sskuItemId}`);
    }
  };

  const cleanPath = path.replace(/^\/render[^/]*/, '');

  const {
    keyword,
    lowerbound,
    Nao = 0,
    nValue,
    sortby,
    sortorder,
    upperbound,
    onDisplay
  } = parseUrl({ path: cleanPath });

  const prcStoreId = useRef(storeId);
  const localizedAndClientLoaded = useRef(false);

  // show the sponsored products
  const additionalSearchParams = {
    sponsored: true, // ['bestmatch', 'topsellers'].indexOf(sortby) > -1
    deliveryZip: storeZip,
    mcvisId: cookie?.adobeCookie?.MCMID
  };

  let THDCustomer = {};

  if (clientStore) {
    THDCustomer = typeof window !== 'undefined' ? window.THDCustomer?.default : {};
  }

  if (Object.keys(THDCustomer).length !== 0 && THDCustomer.svocID) {
    additionalSearchParams.svocID = THDCustomer.svocID;
  }

  const {
    data, error, loading, responseLoading, variables
  } = useProductResults({
    additionalSearchParams,
    itemId,
    keyword,
    lowerbound,
    nValue,
    pageSize,
    sortby,
    sortorder,
    start: Nao,
    storeId,
    upperbound,
    isCustomerIdentified: isLocalized ? isCustomerIdentified : false
  });

  if (data && !loading) {
    prcStoreId.current = variables.storeId;
    localizedAndClientLoaded.current = true;
  }

  const { products = [] } = data?.searchModel || {};
  const pipPath = pathname && pathname.replace(/\/pep\//gi, '/p/');
  const cleanedPath = (pathname || '').replace(/render-\w+\//, '');
  const isInStoreFilterON = hasInStoreFilter(data);

  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('pep.pip-ready', { itemId });
  }, [itemId]);

  const stringifyItemIds = (productsList) => {
    return productsList?.map(({ itemId: sku }) => sku).join('-');
  };

  useEffect(() => {
    if (data && !loading) {
      LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('pep.new-products', {
        ...data?.searchModel,
        defaultPageSize: pageSize
      });
    }
  }, [stringifyItemIds(products)]);

  const [selectedForCompare, setSelectedForCompare] = useState([]);

  const onSelectedForCompare = (id, selected = false) => {
    if (selected) {
      setSelectedForCompare([...selectedForCompare, id]);
    } else {
      setSelectedForCompare(selectedForCompare.filter((item) => item !== id));
    }
  };

  const onCompare = () => {
    const queryparams = selectedForCompare.map((item, idx) => `item${idx + 1}=${item}`).join('&');
    if (cleanedPath) {
      window.sessionStorage.setItem('compareBaseURL', cleanedPath);
    }
    navigate(
      {
        pathname: '/compare',
        search: `?${queryparams}`
      },
      {
        bypassExperienceCheck: true
      }
    );
  };

  useEffect(() => {
    const itemIds = window.sessionStorage.getItem('compare');
    const compareBaseURL = window.sessionStorage.getItem('compareBaseURL');
    window.sessionStorage.removeItem('compareBaseURL');
    window.sessionStorage.removeItem('compare');
    if (itemIds && compareBaseURL === cleanedPath) {
      setSelectedForCompare(JSON.parse(itemIds));
    }
  }, []);

  const newDefaultVariables = {
    storeId,
    zipCode: storeZip,
    skipKPF: true,
    skipSubscribeAndSave: true,
    skipInstallServices: false,
    isBrandPricingPolicyCompliant: localizedAndClientLoaded.current ? isCustomerIdentified : false
  };
  const skipFn = ({ skip, queryName, attributes }) => {
    if (queryName !== 'product') {
      return skip;
    }
    const isResolved = isClientResolved({ queryName: 'searchModel' });
    if (attributes.product.fulfillment) {
      if (!isResolved) {
        return true;
      }
    }
    return skip;
  };
  const mountedFn = ({ mounted }) => {
    if (!isLocalized) return false;
    if (responseLoading) return false;
    return mounted;
  };
  const handleProductClick = useCallback((evt, { canonicalUrl, target }) => {
    if (canonicalUrl) {
      navigate(canonicalUrl);
    }
  }, []);
  /*
    Get Sample Products
  */
  const { loading: samplesLoading } = usePLPSamples({
    products,
    productsFinishedLoading: pageSize === CLIENT_SIDE_PRODUCT_POD_COUNT
      && !responseLoading,
    storeId,
    storeZip
  });

  return (
    <div className="pep-page">
      <Row className="isBound">
        <ErrorBoundary name="redirector">
          <PIPRedirector disableCanonicalRedirect disableCMMMCRedirect />
        </ErrorBoundary>
        <ErrorBoundary name="pep-metadata">
          <Metadata>
            <ProductEntryMetadata itemId={itemId} />
          </Metadata>
        </ErrorBoundary>
        <ErrorBoundary name="based-on-your-search">
          <Row>
            <Col className="based-on-your-search sui-py-2 sui-leading-none">Based on your search:</Col>
            <Col className="sui-items-center sui-flex sui-justify-end sui-ml-auto sui-mr-4 sui-p-0">
              <ConnectedTranslatorWidget />
            </Col>
          </Row>
        </ErrorBoundary>
        <Row className="sui-flex sui-bg-subtle max-lg:!sui-max-h-none">
          <ErrorBoundary name="zone-a">
            <div>
              <Row className="sui-flex sui-mb-4">
                <Col id="zone-a__col" className="sui-w-full sui-h-full sui-bg-primary sui-rounded-md">
                  <ErrorBoundary id="zone-a-product" name="zone-a-product">
                    {/* eslint-disable-next-line max-len */}
                    <Col className="lg:sui-w-1/3 max-lg:sui-w-1/2 max-sm:sui-p-1 max-sm:sui-pb-0 sui-pr-2 sui-pl-1 sui-py-0">
                      <ErrorBoundary name="media">
                        <div className="sui-z-10 sui-absolute sui-p-1 sui-right-2">
                          <Sharebelt itemId={itemId}>
                            <ShareFavorite showCircle showFavoritesCount />
                          </Sharebelt>
                        </div>
                        <MediaGalleryWithHotspotsCarousel
                          hover
                          itemId={itemId}
                          firstItemFetchPriority="high"
                          showDynamicPagination
                          showLoader
                        />
                      </ErrorBoundary>
                    </Col>
                    {/* eslint-disable-next-line max-len */}
                    <Col className="lg:sui-w-1/3 max-lg:sui-w-1/2 max-sm:sui-flex max-sm:sui-flex-col max-sm:sui-mt-1 max-sm:sui-pt-1 max-sm:sui-pr-1">
                      <ErrorBoundary name="info">
                        <ErrorBoundary name="product-details">
                          <ProductDetails itemId={itemId}>
                            <div className="product-details__badge-title--wrapper">
                              <ProductDetails.Badge itemId={itemId} noPlaceholderClass />
                              <ProductDetails.Title link />
                            </div>
                            <ProductDetails.BrandCollection showCollection />
                            <ProductDetails.Ratings
                              pageType="PEP"
                              showBadge={false}
                              disableReviewsIfZero
                            />
                          </ProductDetails>
                        </ErrorBoundary>
                        <ErrorBoundary name="aisle-bay">
                          <AisleBay itemId={itemId} />
                        </ErrorBoundary>
                        <ErrorBoundary name="salient-points">
                          <SalientPoints hideIfMultiVariant itemId={itemId} max={3} />
                        </ErrorBoundary>
                        <ErrorBoundary name="super-sku">
                          <SuperSku itemId={itemId} onChange={onChange} />
                        </ErrorBoundary>
                        <ErrorBoundary name="order-sample">
                          <OrderSample itemId={itemId} link oosMessage>
                            Need a closer look?
                          </OrderSample>
                        </ErrorBoundary>
                      </ErrorBoundary>
                    </Col>
                    <Col className="lg:sui-w-1/3 max-lg:sui-w-full max-sm:sui-py-0">
                      <ErrorBoundary name="checkout">
                        <ErrorBoundary name="price">
                          <Price
                            channel="desktop"
                            disableRangePricing
                            displayEachUom={false}
                            itemId={itemId}
                            large
                            clsRemediation={{
                              placeholders: true,
                              preservePlaceholders: true
                            }}
                            nopadding
                          />
                        </ErrorBoundary>
                        <ErrorBoundary name="promotion">
                          <div className="sui-ml-1">
                            <Promotion channel="desktop" storeId={storeId} itemId={itemId} />
                          </div>
                        </ErrorBoundary>
                        <Col className="sui-p-0">
                          <ErrorBoundary name="buybox">
                            <div>
                              <Buybox hideInstantCheckout hidePaypalCheckout itemId={itemId} lite noTopPadding />
                            </div>
                            <ErrorBoundary name="details">
                              <div className="see-details">
                                <div className="see-details__box">
                                  <Button
                                    fullWidth
                                    variant="secondary"
                                    href={pipPath}
                                    target="_blank"
                                  >
                                    View Full Product Details
                                  </Button>
                                </div>
                              </div>
                            </ErrorBoundary>
                          </ErrorBoundary>
                        </Col>
                      </ErrorBoundary>
                    </Col>
                  </ErrorBoundary>
                </Col>
              </Row>
            </div>
            {fsInteractiveRecs && (
              <InstantRecommendations
                errorBoundary
                hydrator={{
                  className: 'grid',
                  scrollBuffer: HYDRATION_STANDARD_SCROLL_BUFFER,
                  delay: 0
                }}
                anchorId={itemId}
                schemaName="instantrecomm"
                requestKey={requestKey}
              />
            )}
            <DynamicRecsWrapper
              schemaName="pipsem"
              anchorId={itemId}
              hideATC
              requestKey={requestKey}
              errorBoundary
              hydrator={{
                className: 'grid',
                tag: ZoneCard,
                id: `pipsem-${itemId}`,
                scrollBuffer: 0,
                delay: 200
              }}
            />
            <Col>
              <ErrorBoundary name="breadcrumbs">
                <Hydrator
                  className="grid"
                  delay={3000}
                  id="pep-breadcrumbs"
                  scrollBuffer={1500}
                  waitFor={[BreadcrumbsLoad]}
                >
                  <Breadcrumbs data={data} error={error} loading={loading} />
                </Hydrator>
              </ErrorBoundary>
            </Col>
            <Col>
              <ErrorBoundary name="product-results">
                <Hydrator
                  className="grid"
                  delay={3000}
                  id="pep-product-results"
                  preserveCtxVal="clientStore"
                  scrollBuffer={1500}
                >
                  <span ref={scrollToRef} />
                  <ProductResults data={data} error={error} loading={loading}>
                    <Row>
                      <Col nopadding>
                        <Col>
                          <ErrorBoundary name="results-header">
                            <ResultsHeader preamble="Similar" />
                          </ErrorBoundary>
                        </Col>
                      </Col>
                    </Row>
                    <Row>
                      {shouldDisplayResultsAppliedDesktop(ctx) && (
                        <Col nopadding sm="9">
                          <Col>
                            <ErrorBoundary name="results-applied">
                              <ResultsApplied onAppliedChange={onDimensionsChange} />
                            </ErrorBoundary>
                          </Col>
                        </Col>
                      )}
                      <Col nopadding xs="3">
                        <Col>
                          <ErrorBoundary name="results-sortby">
                            <ResultsSortBy onSortChange={onSortChange} />
                          </ErrorBoundary>
                        </Col>
                      </Col>
                    </Row>
                    <Row className="u--paddingBottom">
                      <Col lg="2" md="2" nopadding sm="3" xs="4">
                        <Col>
                          <ErrorBoundary name="results-dimensions">
                            <ResultsDimensions
                              enableVisualRefinement
                              onDimensionsChange={onDimensionsChange}
                              enableMultiStore
                              onDisplay={onDisplay}
                              onDisplayChange={onDisplayChange}
                            />
                          </ErrorBoundary>
                        </Col>
                      </Col>
                      <Col lg="10" md="10" nopadding sm="9" xs="8">
                        <Col>
                          <ErrorBoundary name="wrapped-results">
                            <Row>
                              <Col nopadding>
                                <QueryProvider
                                  cacheKey="pep-desktop-product-pods"
                                  dataSource="searchNav"
                                  defaultVariables={newDefaultVariables}
                                  mounted={mountedFn}
                                  skip={skipFn}
                                >
                                  <HoistedResultsWrapped>
                                    {products.map((product, itemIndex) => {
                                      const { itemId: skuId, canonicalUrl } = product?.identifiers || {};
                                      if (!skuId) return null;
                                      return (
                                        <ErrorBoundary
                                          id={`plp-product-pod__${itemIndex}`}
                                          index={itemIndex}
                                          key={`${skuId}-${itemIndex}`}
                                          name={`Product Pod: ${itemIndex}`}
                                        >
                                          <Col
                                            className="pep__pod"
                                            fallback="6"
                                            lg="3"
                                            md="3"
                                            sm="3"
                                            xs="4"
                                          >
                                            <CustomProductPodResponsive
                                              parent="pep"
                                              itemId={skuId}
                                              url={canonicalUrl}
                                              storeId={prcStoreId.current}
                                              onHover={() => {}}
                                              hoverDelay={0}
                                              position={itemIndex}
                                              itemRecsIndex={itemIndex}
                                              hasInStoreFilter={isInStoreFilterON}
                                              onClick={handleProductClick}
                                              samplesLoading={samplesLoading}
                                              isMobileOrConsumerApp={isMobileOrConsumerApp}
                                            />
                                          </Col>
                                        </ErrorBoundary>
                                      );
                                    })}
                                  </HoistedResultsWrapped>
                                </QueryProvider>
                              </Col>
                            </Row>
                            <Row className="u--paddingTop u--paddingBottom">
                              <Col nopadding>
                                <Col>
                                  <ErrorBoundary name="results-pagination">
                                    <ResultsPagination
                                      onPageChange={onPageChange}
                                      pageSize={pageSize}
                                    />
                                  </ErrorBoundary>
                                </Col>
                                <Col>
                                  <ErrorBoundary name="search feedback">
                                    <SearchFeedback />
                                  </ErrorBoundary>
                                </Col>
                              </Col>
                            </Row>
                          </ErrorBoundary>
                        </Col>
                      </Col>
                    </Row>
                  </ProductResults>
                </Hydrator>
              </ErrorBoundary>
            </Col>
            <Col>
              <ErrorBoundary name="promotional">
                <HdhomeSignup
                  formSubmission
                  subscriptionType={subscriptionType}
                  campaignId={pepCampaignId}
                />
              </ErrorBoundary>
            </Col>
            <Row className="u--paddingBottom">
              <SponsoredCarousel
                breadCrumbs={data?.searchModel?.taxonomy?.breadCrumbs}
                pageContext={{
                  schema: 'pip_sponsored',
                  data: { itemId },
                  isPip: true
                }}
                showSponsoredCarousel
                dynamic={{
                  pageType: 'pip'
                }}
                hydrator={{
                  className: 'grid',
                  tag: ZoneCard,
                  scrollBuffer: HYDRATION_STANDARD_SCROLL_BUFFER
                }}
              />
            </Row>
            <QueryProvider
              cacheKey="rv-pep-recs"
              persist
              defaultVariables={defaultVariables?.current}
            >
              <Row className="u--paddingTop ">
                <RecentlyViewedWrapper
                  schemaName="rv_gm_pip_rr"
                  errorBoundary
                  hydrator={{
                    className: 'grid',
                    tag: ZoneCard,
                    id: 'rv_gm_pip_rr',
                    scrollBuffer: 1500,
                    delay: 3000
                  }}
                />
              </Row>
            </QueryProvider>
            <Row className="u--paddingTop u--paddingBottom">
              <ErrorBoundary name="compare">
                <Col>
                  <CompareTray
                    itemIds={selectedForCompare}
                    onRemove={onSelectedForCompare}
                    onCompare={onCompare}
                  />
                </Col>
              </ErrorBoundary>
            </Row>
          </ErrorBoundary>
        </Row>
      </Row>
    </div>
  );
};

Pep.displayName = 'PepPage';

Pep.propTypes = {};

Pep.defaultProps = {};

const ProductSearchDataModel = {
  searchModel: gqlParams({ keyword: string(), navParam: string() }).shape({
    products: gqlParams({ startIndex: number() }).arrayOf(CustomProductPodResponsive.dataModel.product)
  })
};

Pep.dataModel = extend(
  PIPRedirector,
  AisleBay,
  BreadcrumbsModel,
  Buybox,
  CategoryList,
  ResultsDimensions,
  CompareTray,
  HdhomeSignup,
  MediaGalleryWithHotspotsCarousel,
  OrderSample,
  Price,
  PriceClearanceDataModel,
  Promotion,
  ProductDetails,
  Sharebelt,
  SalientPoints,
  SuperSku,
  productDefaultResultsDataModel,
  DynamicRecsWrapper,
  RecentlyViewedWrapper,
  InstantRecommendations,
  ProductSearchDataModel
);
