import apiGetGccProductDetails from '../api/getGccProductDetails';
import {
  UPDATE_GCC_PRODUCT_DETAILS,
  UPDATE_GCC_PRODUCT_DETAILS_SUCCESS,
  UPDATE_THD_PRODUCT_DETAILS,
  GET_GCC_PRODUCT_DETAILS_ERROR,
  GET_GCC_PRODUCT_DETAILS_SUCCESS,
} from './actionTypes';
import { createAction } from '../utils/createAction';

// actions
const updateGccProductDetails = createAction(UPDATE_GCC_PRODUCT_DETAILS, 'productDetails');
export const updateTHDProductDetails = createAction(UPDATE_THD_PRODUCT_DETAILS, 'thdProductDetails');
export const updateGccProductDetailsSuccess = createAction(UPDATE_GCC_PRODUCT_DETAILS_SUCCESS);
const getGccProductDetailsError = createAction(GET_GCC_PRODUCT_DETAILS_ERROR, 'err');
const getGccProductDetailsSuccess = createAction(GET_GCC_PRODUCT_DETAILS_SUCCESS);

// thunk
export default () => (dispatch, getState) => {
  const { partnerProductId, siteId } = getState().config;

  return apiGetGccProductDetails(partnerProductId, siteId)
    .then((productDetails) => {
      dispatch(getGccProductDetailsSuccess());
      dispatch(updateGccProductDetails(productDetails));
      dispatch(updateGccProductDetailsSuccess());
    })
    .catch((error) => {
      dispatch(getGccProductDetailsError(error));
    });
};
