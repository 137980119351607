export const track = (opts) => {
  const { ICTemplate = '' } = opts;
  if (typeof window !== 'undefined' && window.LIFE_CYCLE_EVENT_BUS && !!ICTemplate) {
    window.LIFE_CYCLE_EVENT_BUS.trigger('instant-checkout.load', { ICTemplate });
  }
};

export const checkoutOrderConfirmationAnalytics = ({ icEnrolled = false }) => {
  try {
    if (typeof window !== 'undefined' && window?.digitalData) {
      window.digitalData.content = {
        ...window?.digitalData?.content,
        feature: {
          ...window?.digitalData?.content?.feature,
          instantCheckoutEnroll: icEnrolled ? 'y' : 'n',
        },
      };
    }
  } catch (error) {
    console.error('Error in checkoutOrderConfirmationAnalytics', error);
  }
};
