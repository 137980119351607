import React, { useContext } from 'react';
import { arrayOf, string, func } from 'prop-types';
import {
  extend,
  QueryContext,
  QueryProvider
} from '@thd-nucleus/data-sources';
import { useProductDeprecated } from '@thd-nucleus/data-sources/react/dataModel/migration';
// eslint-disable-next-line import/no-cycle
import { CompareSpecifications, CompareStickyHeader } from '../compare';

const CompareCatalogLoader = ({ itemIds, storeId, children }) => {

  const { defaultVariables } = useContext(QueryContext);

  const newDefaultVariables = {
    storeId,
    skipInstallServices: false,
    skipSpecificationGroup: false,
    ...(typeof defaultVariables?.current?.isBrandPricingPolicyCompliant === 'boolean' && {
      isBrandPricingPolicyCompliant: defaultVariables.current.isBrandPricingPolicyCompliant
    })
  };

  const domains = ['specificationGroup'];
  const { data } = useProductDeprecated({
    domains,
    itemIds,
    ssr: false
  }, 'products');

  if (!data) {
    return null;
  }

  return (
    <QueryProvider
      cacheKey="compare-catalog-loader"
      defaultVariables={newDefaultVariables}
    >
      {children({ data })}
    </QueryProvider>
  );
};

CompareCatalogLoader.displayName = 'CompareCatalogLoader';

CompareCatalogLoader.propTypes = {
  itemIds: arrayOf(string),
  storeId: string,
  children: func.isRequired
};

CompareCatalogLoader.defaultProps = {
  itemIds: [],
  storeId: ''
};

CompareCatalogLoader.dataModel = extend({}, CompareSpecifications, CompareStickyHeader);

export { CompareCatalogLoader };
