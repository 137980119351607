import React from 'react';
import PropTypes from 'prop-types';
import { json, params, string, useDataModel } from '@thd-nucleus/data-sources';

const ANCHOR_CLASSES = [
  'sui-lab-btn-base',
  'sui-block',
  'sui-text-inherit',
  'sui-w-full',
  'sui-h-full',
  'focus-visible:sui-outline-button-focus',
  'focus-visible:sui-outline-4',
  'focus-visible:sui-outline-offset-0',
  'hover:sui-shadow-lg',
  'hover:sui-no-underline'
].join(' ');

const IMAGE_CONTAINER_CLASSES = [
  'sui-flex',
  'sui-overflow-hidden',
  'sui-gap-4',
  'sui-flex-col',
  'sui-basis-3/4',
  'md:sui-basis-1/3',
  'lg:sui-basis-1/4'
].join(' ');

const PROMO_CONTAINER_CLASSES = [
  'sui-flex',
  'sui-overflow-hidden',
  'sui-gap-4',
  'sui-flex-col',
  'sui-basis-1/4',
  'md:sui-basis-2/3',
  'lg:sui-basis-3/4'
].join(' ');

const DESCRIPTION_CLASSES = [
  'sui-font-regular',
  'sui-text-lg',
  'sui-tracking-normal',
  'sui-normal-case',
  'sui-line-clamp-unset',
  'sui-text-primary'
].join(' ');

const HomeServicesPromoPod = ({ contentId, pageName }) => {
  const { loading, data, error } = useDataModel(
    'emtContent', { variables: { contentId, pageName } }
  );

  const currentPromo = data?.emtContent?.content?.[pageName]?.promotion;

  if (!data || loading || error || !currentPromo) {
    return null;
  }

  const {
    description, dates, eventDetailsUrl, image, title
  } = currentPromo;

  if (!description || !dates || !eventDetailsUrl || !title || !image || !image.source) {
    return null;
  }

  return (
    <div data-component="HomeServicesPromoPod" className="sui-paper sui-paper-outlined sui-rounded-base sui-flex">
      <a className={ANCHOR_CLASSES} href={eventDetailsUrl}>
        <div className="sui-flex sui-h-full sui-overflow-hidden sui-p-4 sui-gap-4 sui-flex-col md:sui-flex-row">
          <div className={IMAGE_CONTAINER_CLASSES}>
            <img
              className="sui-object-cover sui-h-full sui-w-full sui-aspect-video"
              width="480"
              height="270"
              src={image.source}
              alt={image.altDescription}
              loading="lazy"
            />
          </div>
          <div className={PROMO_CONTAINER_CLASSES}>
            <h2 className="sui-h2-bold sui-line-clamp-3 sui-text-primary">
              {title}
            </h2>
            <div className="sui-grow sui-flex sui-flex-col sui-justify-center">
              <h3 className={DESCRIPTION_CLASSES}>
                {description}
              </h3>
            </div>
            <div className="sui-flex sui-align-center sui-grow-0 sui-shrink-0 sui-basis-auto sui-justify-start">
              <h3 className="sui-h5-bold sui-line-clamp-unset sui-text-primary">
                {dates}
              </h3>
            </div>
          </div>
        </div>
      </a>
    </div>
  );
};

HomeServicesPromoPod.dataModel = {
  emtContent: params({
    contentId: string().isRequired(),
    pageName: string().isRequired()
  }).shape({
    content: json()
  })
};

HomeServicesPromoPod.propTypes = {
  contentId: PropTypes.string.isRequired,
  pageName: PropTypes.oneOf(['homeServices']).isRequired
};

HomeServicesPromoPod.displayName = 'HomeServicesPromoPod';

export default HomeServicesPromoPod;