import React from 'react';
import { bool, string } from 'prop-types';

import { PackagesMiniAbTest } from './PackagesMiniAbTest';
import { RENDER_FBT, RENDER_THD_RECS } from '../constants';

const FbtGm = ({ shouldDisplayRecs, itemId, shouldDisplayPipInStocks }) => {
  return (
    <>
      {
        (shouldDisplayRecs ? (
          <div data-testid="fbt-recs" className="sui-w-full">
            <PackagesMiniAbTest itemId={itemId} renderComponent={RENDER_THD_RECS} />
          </div>
        ) : (
          <div data-testid="fbt" className="sui-w-full">
            {shouldDisplayPipInStocks && <> <PackagesMiniAbTest itemId={itemId} renderComponent={RENDER_FBT} /> </>}
          </div>
        ))
      }
    </>
  );
};
FbtGm.propTypes = {
  shouldDisplayRecs: bool.isRequired,
  shouldDisplayPipInStocks: bool.isRequired,
  itemId: string.isRequired
};

FbtGm.displayName = 'FbtGm';

export { FbtGm };