import { createAction } from '../../utils/createAction';
import {
  THD_PRODUCT_CHOICE_SKU_AVAILABILITY_SUCCESS,
  THD_PRODUCT_CHOICE_SKU_AVAILABILITY_ERROR,
  THD_PRODUCT_AVAILABILITY_INIT,
  THD_PRODUCT_AVAILABILITY_DEFAULT,
} from '../actionTypes';

export const getChoiceSkuAvailabilitySuccess = createAction(
  THD_PRODUCT_CHOICE_SKU_AVAILABILITY_SUCCESS,
  'skuAvailability',
  'choiceAvailability',
  'skusByChoiceId',
  'choicesBySku',
  'skusByOptionIdAndChoiceId',
);

export const getChoiceSkuAvailabilityError = createAction(
  THD_PRODUCT_CHOICE_SKU_AVAILABILITY_ERROR,
  'err',
);

export const getchoiceDefault = createAction(THD_PRODUCT_AVAILABILITY_DEFAULT);

export const getchoiceInit = createAction(
  THD_PRODUCT_AVAILABILITY_INIT,
  'choiceInit',
);
