import React from 'react';
import { oneOf, node, shape, bool } from 'prop-types';
import THDCustomer from '@thd-olt-global/thd-customer';
import useStorefront from '../../hooks/useStorefront';
import useContactCenter from '../../hooks/useContactCenter';
import { UserContext, defaultUserContextValues } from './UserContext';

const UserProvider = ({
  children,
  options
}) => {
  const {
    skipContactCenter = true,
    skipStorefront = true
  } = options;

  const [contactCenter] = useContactCenter({ skip: skipContactCenter });
  const [storefront] = useStorefront({ skip: skipStorefront });

  const userContext = {
    ...defaultUserContextValues(),
    contactCenter,
    thdCustomer: THDCustomer,
    storefront
  };

  return (
    <UserContext.Provider value={userContext}>
      {children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: node,
  options: shape({
    skipContactCenter: bool,
    skipStorefront: bool
  })
};

UserProvider.defaultProps = {
  children: null,
  options: shape({
    skipContactCenter: true,
    skipStorefront: true
  })
};

UserProvider.displayName = 'UserProvider';

export { UserProvider };
