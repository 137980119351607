import React, { forwardRef } from 'react';
import {
  string, func, bool, oneOf, node, elementType, shape
} from 'prop-types';
import classNames from 'classnames';

const HeaderIconTextButton = forwardRef(({
  name,
  preamble,
  label,
  textSize,
  textColor,
  icon: Icon,
  iconSize,
  iconColor,
  href,
  children,
  onClick,
  activeName,
  mobileHideText,
  flexDirection,
  customClasses,
  ...otherAttributes
}, ref) => {

  const iconBuilder = ({ IconComponent, size }) => {
    const isResponsive = size === 'responsive';
    const responsiveIconProps = {
      className: 'sui-h-4 sui-w-4 lg:sui-h-6 lg:sui-w-6',
      size: 'inherit',
      color: 'inherit'
    };
    const staticIconProps = {
      size,
      color: 'inherit'
    };
    const iconProps = isResponsive ? responsiveIconProps : staticIconProps;
    return (
      <div className="sui-flex">
        {React.createElement(IconComponent, { ...iconProps })}
      </div>
    );
  };

  const isActiveIcon = name && name === activeName;

  const rootClasses = classNames(
    [
      'sui-flex sui-items-center sui-text-center',
      'hover:sui-fill-brand hover:sui-no-underline hover:sui-text-brand'
    ].join(' '),
    {
      'sui-flex-col sui-w-16': flexDirection === 'column',
      'sui-gap-2': flexDirection === 'row',
      'sui-fill-inverse': iconColor === 'inverse' && !isActiveIcon,
      'sui-fill-primary': iconColor === 'primary' && !isActiveIcon,
      'sui-fill-brand sui-text-brand': isActiveIcon
    }, customClasses?.root
  );

  const buttonIconClasses = classNames([
    'focus-visible:sui-outline focus-visible:sui-outline-2',
    'focus-visible:sui-outline-button-focus'].join(' '), rootClasses);

  const textClasses = classNames(
    [
      'sui-font-regular sui-min-fit sui-leading-tight sui-truncate',
      'sui-tracking-normal sui-normal-case sui-line-clamp-unset'
    ].join(' '),
    {
      'sui-mt-0': flexDirection === 'row',
      'sui-mt-1': flexDirection !== 'row',
      'sui-hidden lg:sui-block': mobileHideText,
      'sui-text-base': textSize === 'regular',
      'sui-text-xs': textSize === 'body-xs',
      'sui-text-sm': textSize === 'body-s'
    }, customClasses?.text);

  const preambleClasses = classNames(
    [
      'sui-flex sui-items-center sui-text-center sui-pr-0'
    ].join(' '),
    {
      'sui-flex-col sui-w-16': flexDirection === 'column',
      'sui-gap-2': flexDirection === 'row',
      'sui-fill-inverse': iconColor === 'inverse' && !isActiveIcon,
      'sui-fill-primary': iconColor === 'primary' && !isActiveIcon,
      'sui-fill-brand sui-text-brand': isActiveIcon
    }
  );

  const icon = Icon && iconBuilder({
    IconComponent: Icon,
    size: iconSize
  });

  const text = ((label || name) && (
    <div className="sui-min-fit sui-mt-0 sui-w-full">
      <p className={textClasses}>
        {label || name}
      </p>
    </div>
  ));

  const preambleText = ((preamble) && (
    <div className="sui-min-fit sui-mt-0">
      <p className={textClasses}>
        {preamble}
      </p>
    </div>
  ));

  return (
    <>
      {href && (
        <a
          ref={ref}
          href={href}
          onClick={onClick}
          className={rootClasses}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...otherAttributes}
        >
          {icon}
          {text}
          {children}
        </a>
      )}
      {!href && (
        <>
          { preamble && (
            <div
              className={preambleClasses}
            >
              {icon}{preambleText}
            </div>
          )}
          <button
            ref={ref}
            type="button"
            onClick={onClick}
            className={buttonIconClasses}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...otherAttributes}
          >
            {!preamble && icon}
            {text}
            {children}
          </button>
        </>
      )}
    </>
  );
});

export { HeaderIconTextButton };

HeaderIconTextButton.displayName = 'HeaderIconTextButton';

HeaderIconTextButton.propTypes = {
  name: string,
  preamble: string,
  label: node,
  textSize: oneOf(['regular', 'body-s', 'body-xs']),
  textColor: oneOf(['primary', 'inverse']),
  icon: elementType,
  /* Stencil sizes sm, reg, lg; Responsive will flex between small and regular based on screen-size. */
  iconSize: oneOf(['small', 'regular', 'large', 'responsive']),
  iconColor: oneOf(['primary', 'inverse']),
  href: string,
  children: node,
  onClick: func,
  activeName: string,
  mobileHideText: bool,
  flexDirection: oneOf(['row', 'column']),
  customClasses: shape({
    root: string,
    text: string
  })

};

HeaderIconTextButton.defaultProps = {
  name: undefined,
  preamble: undefined,
  label: undefined,
  textSize: 'regular',
  textColor: 'primary',
  icon: undefined,
  iconSize: 'regular',
  iconColor: 'primary',
  href: undefined,
  children: undefined,
  onClick: undefined,
  activeName: undefined,
  mobileHideText: false,
  flexDirection: 'column',
  customClasses: {
    root: null,
    text: null
  }
};
