import { useContext, useState, useEffect } from 'react';
import { ExperienceContext, useStoreId } from '@thd-nucleus/experience-context';
import { QuantityAdjustmentsContext } from '@thd-olt-component-react/bundling-product-tabs';
import { PackagesMiniContext } from '../components/PackagesMiniContext';
import { GM_PACKAGE, KITCHEN_PACKAGE, PRODUCT_POD } from '../constants';

const MCC_FULFILLMENT_METHOD_DIRECT_DELIVERY = 'DirectDelivery';
const MCC_FULFILLMENT_METHOD_STH = 'ShipToHome';

const buildCartRequestParams = (selectedProducts, zipCode, isMajorAppliancePackage, itemMap, storeId) => {
  const getFulfillmentMethod = (itemId) => {
    if (isMajorAppliancePackage) {
      return MCC_FULFILLMENT_METHOD_DIRECT_DELIVERY;
    }
    if (itemMap[itemId]?.fulfillmentMethod) {
      return itemMap[itemId].fulfillmentMethod;
    }
    return MCC_FULFILLMENT_METHOD_STH;
  };

  return (selectedProducts || [])
    .filter((itemId) => itemMap[itemId]?.availableQty > 0 || isMajorAppliancePackage)
    .map((itemId) => {
      const quantity = itemMap[itemId]?.selectedQty || 1;
      return {
        itemId,
        quantity,
        fulfillmentMethod: getFulfillmentMethod(itemId),
        fulfillmentLocation: getFulfillmentMethod(itemId) === 'BOPIS' ? storeId : zipCode
      };
    });
};

const enhanceMetadataOptions = (metadata) => {
  return {
    brandImage: metadata.brandImage?.url || '',
    heroImage: metadata.heroImage?.url || '',
    packageBrand: metadata.brandName,
    packageImage: metadata.image?.url?.replace(/<SIZE>/g, '400') || '',
    packageName: metadata.name
  };
};

const getHostName = () => {
  let host = 'https://www.homedepot.com';
  if (typeof window !== 'undefined') {
    const origin = window.location.origin;
    host = origin.match(/local/g) ? 'https://www.homedepot.com' : origin;
  }
  return host;
};

const getCartOptions = ({
  isMajorAppliancePackage, selectedProductIds, channel, metadata, anchorItemId
}) => {
  const defaultParams = {
    host: getHostName(),
    channel,
    paypal: false,
    misship: false,
    component: PRODUCT_POD,
    anchorProductSku: anchorItemId,
    recommendationType: isMajorAppliancePackage ? KITCHEN_PACKAGE : GM_PACKAGE,
    sharedSection: isMajorAppliancePackage ? KITCHEN_PACKAGE : GM_PACKAGE
  };
  if (isMajorAppliancePackage && selectedProductIds.length === 1) {
    return defaultParams;
  }
  return { ...defaultParams, packageInfo: { ...metadata, ...enhanceMetadataOptions(metadata) } };
};

export const useAddToCartProps = () => {
  const { channel, deliveryZip } = useContext(ExperienceContext);
  const {
    packageModel: {
      metadata,
      isMajorAppliancePackage,
      selectedProductIds
    } = {},
    anchorItemId,
  } = useContext(PackagesMiniContext);

  const { itemMap } = useContext(QuantityAdjustmentsContext);
  const storeId = useStoreId();

  const [liveZipCode, setLiveZipCode] = useState(deliveryZip);
  if (typeof window !== 'undefined') {
    LIFE_CYCLE_EVENT_BUS.on('check-availability.depot-direct-success', (eventData) => {
      const { zipCode } = eventData?.output || '';
      setLiveZipCode(zipCode);
    });
  }

  const cartReqParams = buildCartRequestParams(
    selectedProductIds,
    liveZipCode,
    isMajorAppliancePackage,
    itemMap,
    storeId
  );
  const cartOptions = getCartOptions({
    isMajorAppliancePackage,
    selectedProductIds,
    channel,
    metadata,
    anchorItemId,
  });

  const [isAddToCartDisabled, setIsAddToCartDisabled] = useState(false);

  useEffect(() => {
    if (Object.values(itemMap).every(({ availableQty, selectedQty, quantityLimit }) => {
      const availableQuantitiesUnsupported = typeof availableQty === 'undefined';
      const isOutOfStock = availableQty === 0;
      const qtyIsAvailable = selectedQty <= availableQty;
      const qtyWithinLimit = quantityLimit === 0 || selectedQty <= quantityLimit;
      return availableQuantitiesUnsupported || isOutOfStock || (qtyIsAvailable && qtyWithinLimit);
    })) {
      setIsAddToCartDisabled(false);
    } else {
      setIsAddToCartDisabled(true);
    }
  }, [itemMap]);

  return { cartReqParams, cartOptions, isAddToCartDisabled };
};