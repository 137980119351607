export const newRelicConstants = {
  // ad types
  CAROUSEL_PLA: 'sponsoredBottomCarousel',
  PRODUCT_LISTING_AD: 'sponsoredProductListingAd',

  // event types
  LOAD: 'load',
  IMPRESSION: 'impression',
  CLICK: 'click',
  PLA_CAROUSEL_MOUNT: 'pla_carousel_mount',
  PLA_PRODUCT_POD_MOUNT: 'pla_product_pod_mount',
  PLA_CAROUSEL_SLIDE_CHANGE: 'pla_carousel_slide_change',
  PLA_CAROUSEL_NOT_MOUNTED: 'pla_carousel_not_mounted',
  PLA_CAROUSEL_FILTERED_PRODUCTS: 'pla_carousel_filtered_products',
  // --
  PRODUCT_LISTING_AD_MOUNT: 'sponsored_product_listing_ad_mount',
  PRODUCT_LISTING_AD_NOT_MOUNTED: 'sponsored_product_listing_ad_not_mounted',

  // click sources
  CLICK_SRC_PRODUCT_SHOP_NOW_BUTTON: 'productShopNowButton',
  CLICK_SRC_PRODUCT_ATC_BUTTON: 'productAtcButton',
  CLICK_SRC_PRODUCT_IMAGE: 'productImage',
  CLICK_SRC_PRODUCT_HEADER: 'productHeader',
  CLICK_SRC_PRODUCT_RATING: 'productRating',
  CLICK_SRC_PRODUCT_POD: 'productPod',
};