import React, { useContext, useMemo, useState } from 'react';
import { number } from 'prop-types';
import { Pagination, PaginationItem, PaginationCompact } from '@one-thd/sui-atomic-components';
import { useDataModel, params, string as stringType, shape as shapeType } from '@thd-nucleus/data-sources';
import { triggerAnalytics } from '../../Analytics';
import QAContext from '../../context/QAContext';
import {
  QUESTION_DISPLAY_PER_PAGE,
  DEFAULT_NUMBER_OF_PAGES,
  TOTAL_RESULTS_LIMIT,
} from '../../constants';
import './Pager.scss';

export const Pager = ({ seoTotalResults }) => {
  const {
    currentPage, dispatch, TotalResults, itemId, channel
  } = useContext(QAContext);

  const isMobile = channel === 'mobile';

  const results = TotalResults || seoTotalResults;
  const { data } = useDataModel('product', { variables: { itemId } });
  const seoLink = data?.product?.identifiers?.canonicalUrl?.replace('/p', '/p/questions');
  const [pagerNumber, setPagerNumber] = useState(currentPage);

  const totalPages = useMemo(() => {
    if (!results) return DEFAULT_NUMBER_OF_PAGES;
    if (results >= TOTAL_RESULTS_LIMIT) return Math.floor(TOTAL_RESULTS_LIMIT / QUESTION_DISPLAY_PER_PAGE);
    return Math.ceil(results / QUESTION_DISPLAY_PER_PAGE);
  }, [results]);

  const scrollToQASection = () => {
    const el = document.getElementById('questions_answers_accordion_title');
    if (el) {
      document.getElementById('questions_answers_accordion_title').scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  const handleChange = (event, value) => {
    event.preventDefault();
    setPagerNumber(value);

    dispatch({ action: 'SET_CURRENT_PAGE', value });

    if (value === DEFAULT_NUMBER_OF_PAGES) {
      dispatch({ action: 'SET_START_INDEX', value: DEFAULT_NUMBER_OF_PAGES });
    } else {
      const newStartIndex = QUESTION_DISPLAY_PER_PAGE * (value - DEFAULT_NUMBER_OF_PAGES) + DEFAULT_NUMBER_OF_PAGES;
      dispatch({ action: 'SET_START_INDEX', value: newStartIndex });
    }

    const payload = { component: 'pagination' };
    triggerAnalytics(payload);
    scrollToQASection();
  };
  return (
    isMobile ? (
      <PaginationCompact
        // eslint-disable-next-line react/jsx-props-no-spreading
        renderItem={(item) => <PaginationItem {...item} href={`${seoLink}/${item.page}`} />}
        count={totalPages}
        page={pagerNumber}
        onChange={handleChange}
        fullWidth
        data-testid="pagination-compact"
        disable={totalPages === 1}
      />
    ) : (
      <Pagination
        // eslint-disable-next-line react/jsx-props-no-spreading
        renderItem={(item) => <PaginationItem {...item} href={`${seoLink}/${item.page}`} />}
        count={totalPages}
        page={pagerNumber}
        onChange={handleChange}
        siblingCount={2}
        fullWidth
        data-testid="pagination"
        disable={totalPages === 1}
      />
    )
  );
};

Pager.displayName = 'Pager';

Pager.dataModel = {
  product: params({ itemId: stringType().isRequired() }).shape({
    identifiers: shapeType({
      canonicalUrl: stringType(),
    }),
  }),
};

Pager.propTypes = {
  seoTotalResults: number
};

Pager.defaultProps = {
  seoTotalResults: null
};