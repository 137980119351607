/* eslint-disable max-len */
import React from 'react';
import { shape, string, oneOf } from 'prop-types';
import { Mobile } from '@one-thd/sui-icons';
import { Typography } from '@one-thd/sui-atomic-components';
import { useFooterContext } from './FooterContext';
import { HOST_CONSTANTS } from '../../constants';

const FooterTagLine = () => {
  const { customerType, contactCenter } = useFooterContext();

  const isProCustomer = customerType === 'b2b';

  const isB2C = customerType === 'default';
  const TAG_LINE = 'How doers get more done™';
  const { DEFAULT_HOST } = HOST_CONSTANTS;
  const NEED_HELP_LINE = 'Need Help? Please call us at: ';
  const NEED_HELP_LINE_B2C = 'Need Help? Visit our ';
  const CUSTOMER_SERVICE_CENTER = 'Customer Service Center';
  const PHONE_NUMBER_BOLD = contactCenter?.number ? contactCenter.number : '1-800-HOME-DEPOT';
  const PHONE_NUMBER = contactCenter?.number ? `(${contactCenter.number})` : '(1-800-466-3337)';
  const PHONE_NUMBER_LINK = contactCenter?.number ? contactCenter.number : '1-800-466-3337';
  const PRO_TEXT_TAG_LINE = ' or Text us at: ';
  const PRO_TEXT_NUMBER = contactCenter?.smsNumber ? contactCenter.smsNumber : '33955';

  const mobileAppBannerClasses = 'lg:sui-hidden sui-flex sui-flex-row sui-justify-center sui-p-5 sui-bg-inverse';

  const tagLineAndPhoneContainer = `sui-hidden lg:sui-block sui-w-full ${ isProCustomer ? 'sui-bg-inverse sui-text-inverse' : 'sui-bg-subtle sui-text-primary' }`;

  const taglineAndPhoneClasses = 'lg:sui-max-w-screen-2xl lg:sui-m-auto sui-flex sui-flex-row sui-justify-between sui-items-center sui-text-sm sui-px-4 sui-py-2';
  const taglineBoldTextClasses = 'sui-font-bold sui-text-lg';

  return (
    <div>
      <div className={mobileAppBannerClasses}>
        <a className="sui-flex sui-gap-2" href="/c/SF_Mobile_Shopping">
          <div className=" sui-flex sui-items-center sui-bg-brand sui-rounded-md">
            <Mobile color="subtle" size="small" />
          </div>
          <Typography color="inactive" weight="bold">Download Our App</Typography>
        </a>
      </div>
      <div className={tagLineAndPhoneContainer}>
        <div className={taglineAndPhoneClasses}>
          <div className={taglineBoldTextClasses}>
            {TAG_LINE}
          </div>
          <div className="sui-flex sui-whitespace-pre">
            {isB2C ? NEED_HELP_LINE_B2C : NEED_HELP_LINE}
            {isProCustomer
              ? (
                <>
                  <a href={`tel:+${PHONE_NUMBER_LINK}`} title="Call The Home Depot" id="contact-center-number">
                    <b>{PHONE_NUMBER_BOLD}</b>
                  </a>
                  {PRO_TEXT_TAG_LINE}
                  <a href={`sms:${PRO_TEXT_NUMBER}`} title="Text The Home Depot" id="contact-center-sms-number">
                    <b>{PRO_TEXT_NUMBER}</b>
                  </a>
                </>
              ) : (
                <a href={`${DEFAULT_HOST}/c/customer_service`} title="Customer Service Center">
                  <b>{CUSTOMER_SERVICE_CENTER}</b>
                </a>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

FooterTagLine.propTypes = {};
FooterTagLine.defaultProps = {};
FooterTagLine.dataModel = {};
FooterTagLine.displayName = 'Footer Tag Line';

export { FooterTagLine };