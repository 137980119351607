/* eslint-disable tailwindcss/no-arbitrary-value */
import React from 'react';
import PropTypes, {
  bool,
  number,
  func,
  string,
  shape
} from 'prop-types';
import { Quantity } from '@thd-olt-component-react/quantity';
import { AddToCart } from '@thd-olt-component-react/add-to-cart';
import { Fulfillment } from '@thd-olt-component-react/fulfillment';
import { Alert, Typography } from '@one-thd/sui-atomic-components';
import classNames from 'classnames';

const BuyboxUnavailable = ({
  itemId,
  quantity,
  onFulfillmentChange,
  onDeliveryOptionsZipCodeChange,
  hideQuantity,
  onQuantityChange,
  quantityLimitExceed,
  directData,
  configurableProductDetails,
  isTwoTileEnabledFS,
  originalConfigId
}) => {
  const showCards = true;
  const cardClasses = classNames({ 'sui-p-2 sui-bg-primary': showCards });

  return (
    <div className="sui-grid sui-ml-[-10px] sui-mr-[-10px] sui-w-auto">
      <div className="sui-grid-cols-12" data-component="BuyboxUnavailable">
        <div className={cardClasses}>
          <div>
            <div>
              <Fulfillment
                itemId={itemId}
                quantity={quantity}
                originalConfigId={originalConfigId}
                onChange={onFulfillmentChange}
                onDeliveryZipCodeChange={onDeliveryOptionsZipCodeChange}
                directData={directData}
                configurableProductDetails={configurableProductDetails}
                isTwoTileEnabledFS={isTwoTileEnabledFS}
              />
            </div>
          </div>
          <div
            className="sui-bg-subtle sui-p-2 sui-ml-[-10px] sui-mr-[-10px] sui-w-auto sui-mt-2"
            data-component="buybox-divider"
          />
          <div className="sui-pt-4 sui-w-full md:sui-max-w-md" data-component="BuyboxUnavailableAlert">
            <Alert status="error">
              <Typography variant="body-base">
                This combination of customizable options is unavailable. Please change your selections.
              </Typography>
            </Alert>
          </div>
          <div className="sui-flex sui-gap-4 sui-w-full md:sui-max-w-md">
            {!hideQuantity && (
              <div className="lg:sui-max-w-40 sui-mt-[-8px]">
                <Quantity
                  value={quantity}
                  onChange={onQuantityChange}
                />
              </div>
            )}
            <div className="sui-w-full">
              <AddToCart disabled> Add to Cart </AddToCart>
            </div>

          </div>
        </div>
      </div>
      <div className="sui-bg-subtle sui-p-2 sui--ml-1" data-component="buybox-divider" />
    </div>
  );
};

BuyboxUnavailable.propTypes = {
  itemId: string.isRequired,
  quantity: number,
  onFulfillmentChange: func,
  onDeliveryOptionsZipCodeChange: func,
  hideQuantity: bool,
  onQuantityChange: func,
  quantityLimitExceed: bool,
  originalConfigId: string,
  /* eslint-disable-next-line react/forbid-prop-types */
  directData: PropTypes.objectOf(PropTypes.any),
  isTwoTileEnabledFS: bool,
  configurableProductDetails: PropTypes.shape({
    isSpecialOrder: bool,
    configuratorHideQuantity: bool,
    currentSelectedFulfillment: string,
    /* eslint-disable-next-line react/forbid-prop-types */
    configuratorData: PropTypes.objectOf(PropTypes.any)
  })
};

BuyboxUnavailable.defaultProps = {
  quantity: 1,
  onFulfillmentChange: () => {},
  onDeliveryOptionsZipCodeChange: () => {},
  hideQuantity: false,
  onQuantityChange: () => {},
  quantityLimitExceed: false,
  originalConfigId: null,
  directData: null,
  isTwoTileEnabledFS: false,
  configurableProductDetails: {
    isSpecialOrder: false,
    configuratorHideQuantity: false,
    currentSelectedFulfillment: '',
    configuratorData: {}
  }
};

export { BuyboxUnavailable };
