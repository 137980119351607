/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Price } from '@thd-olt-component-react/price';
import { Typography } from '@one-thd/sui-atomic-components';
import { extend } from '@thd-nucleus/data-sources';
import {
  getPrice, mapPriceExistsInPackage,
  getPriceProductData, getDollarPercentOff
} from '../utils/bundle-price-utils';
import { getUpdatedProduct, getTotalPriceText } from '../utils/product-utils';

const BundleMiniPrice = ({ product, products, bundleId }) => {
  const { features } = getUpdatedProduct(product);
  const { mapAboveOriginalPrice, mapPolicy, message } = mapPriceExistsInPackage(products);
  const prices = getPrice(products);
  const { dollarOff, percentOff } = getDollarPercentOff(prices);
  const propProductData = getPriceProductData(
    features,
    prices?.original,
    dollarOff,
    percentOff,
    mapAboveOriginalPrice,
    mapPolicy,
    prices?.value,
    bundleId,
    message
  );

  return (
    <div data-testid="bundle-mini-price">
      <div className="sui-mb-2">
        <Typography variant="body-base" height="tight">
          Total price for {products?.length} {getTotalPriceText(products, features)}
        </Typography>
      </div>
      <div className="sui-flex sui-gap-2">
        <Price
          product={{
            ...propProductData
          }}
          itemId={bundleId}
        />
      </div>
    </div>
  );
};

BundleMiniPrice.propTypes = {
  product: PropTypes.shape({
    itemId: PropTypes.string,
    bundleSpecificationDetails: PropTypes.shape({
      experienceType: PropTypes.string,
      type: PropTypes.string
    })
  }),
  products: PropTypes.arrayOf(PropTypes.shape({
    pricing: PropTypes.shape()
  })),
  bundleId: PropTypes.string.isRequired
};

BundleMiniPrice.defaultProps = {
  product: {},
  products: []
};

BundleMiniPrice.displayName = 'BundleMiniPrice';

BundleMiniPrice.dataModel = extend(Price);

export { BundleMiniPrice };