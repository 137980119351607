/* eslint-disable max-len */
import { useEffect } from 'react';
import { bool, string } from 'prop-types';
import {
  arrayOf as arrayType,
  bool as boolType,
  number as numberType,
  params,
  shape as shapeType,
  string as stringType,
  json,
  useDataModel
} from '@thd-nucleus/data-sources';
import { useStoreId } from '@thd-nucleus/experience-context';
import {
  useHelmet, TitleTag, MetaTag, ScriptTag, LinkTag
} from '@thd-nucleus/thd-helmet';
import { copy } from '@thd-olt-functional/utils';
import {
  formatDescription,
  formatProductLabel,
  getDynamicOGTags,
  getHoistedImgPath,
  getPrimaryImage,
  getSecondaryImgPath
} from '../helpers/product-helpers';
import { getVideoStructuredData } from './structured-data/getVideoStructuredData';
import { getBreadcrumbStructuredData } from './structured-data/getBreadcrumbStructuredData';
import { getProductStructuredData } from './structured-data/getProductStructuredData';
import { getProductGroupSchema } from './structured-data';

const productMetaDataHandler = ({ data, props = {} }) => {
  if (!data?.product) {
    return [];
  }

  const { productGroup } = data?.emtContent?.content || {};
  const {
    itemId,
    details,
    identifiers,
    seo,
    availabilityType,
    media,
    taxonomy,
    pricing,
    specificationGroup,
    info
  } = data?.product || {};

  const productLabel = formatProductLabel({ identifiers });
  const returnable = info?.returnable;
  const brandName = (!identifiers?.brandName || identifiers?.brandName?.toLowerCase() === 'unbranded') ? '' : identifiers.brandName;
  const defaultTitle = `${brandName} ${identifiers?.productLabel || ''} ${identifiers?.modelNumber || ''} ${props?.pageNumber ? '| Pg' + props.pageNumber : ''}`.trim();

  const title = props?.pageTitle?.trim() || `${defaultTitle} - The Home Depot`;

  const { seoDescription } = seo || {};
  const defaultDescription = formatDescription(seoDescription || details?.description);
  const description = props?.pageDescription || defaultDescription;
  const { canonicalUrl: canonicalPath = '' } = identifiers || {};

  const canonical = `https://www.homedepot.com${canonicalPath}`;
  const { video } = media || {};
  const primaryImage = getPrimaryImage({ media });
  const hoistedImgPath = getHoistedImgPath({ primaryImage });
  const secondaryImgPath = getSecondaryImgPath({ media });
  const alternate = `android-app://com.thehomedepot/homedepot/product/${itemId}`;
  const dynamicOGTags = getDynamicOGTags({
    props, brandName, identifiers, title, description
  });

  const tags = [
    new TitleTag(title),
    new MetaTag({ name: 'description', content: description, id: 'description' }),
    ...dynamicOGTags,
    new MetaTag({ property: 'og:url', content: canonical, id: 'ogUrl' }),
    new MetaTag({ property: 'og:image', content: primaryImage, id: 'ogImage' }),
    new MetaTag({ property: 'og:site_name', content: 'The Home Depot', id: 'ogSiteName' }),
    new MetaTag({ property: 'fb:admins', content: '100005416960152', id: 'fbAdmins' }),
    new LinkTag({ rel: 'canonical', href: canonical, id: 'canonical' }),
    new LinkTag({ rel: 'alternate', href: alternate, id: 'alternate' }),
    new LinkTag({
      rel: 'preload', href: hoistedImgPath, id: 'preloadImg', as: 'image', media: '(max-width: 600px)', fetchpriority: 'high'
    }),
  ];
  if (secondaryImgPath) {
    tags.push(new LinkTag({
      rel: 'preload', href: secondaryImgPath, id: 'preloadSecondaryImg', as: 'image', media: '(max-width: 600px)', fetchpriority: 'high'
    }));
  }

  const aggregateReviews = {};

  if (data?.reviews?.Includes?.Products?.store?.FilteredReviewStatistics?.AverageOverallRating
    && data?.reviews?.TotalResults) {
    aggregateReviews.averageRating = data.reviews.Includes.Products.store.FilteredReviewStatistics.AverageOverallRating;
    aggregateReviews.totalReviews = data.reviews.TotalResults;
  }

  const productStructure = getProductStructuredData({
    aggregateReviews: !(props?.hideIfHDPPSku && identifiers?.skuClassification === 'hdpp') && aggregateReviews,
    reviews: !(props?.hideIfHDPPSku && identifiers?.skuClassification === 'hdpp') && data?.reviews?.Results,
    identifiers: {
      ...identifiers,
      productLabel
    },
    media,
    details,
    pricing,
    specifications: specificationGroup,
    availabilityType,
    schemaExclude: props.schema,
    ppq: props.ppq,
    returnable,
  });

  tags.push(new ScriptTag({ content: productStructure, id: 'productStructureData' }));

  const breadcrumbStructuredData = getBreadcrumbStructuredData({
    canonical,
    breadcrumbs: copy(taxonomy?.breadCrumbs || []).data,
    title
  });

  tags.push(new ScriptTag({ content: breadcrumbStructuredData, id: 'breadcrumbStructureData' }));

  if (availabilityType?.discontinued) {
    tags.push(new MetaTag({ name: 'robots', content: 'noindex', id: 'robots' }));
  } else {
    tags.push(new MetaTag({ name: 'robots', content: 'max-image-preview:large', id: 'robots' }));
  }

  if (video?.length) {
    const videoStructuredData = getVideoStructuredData({
      videos: video,
      productLabel,
    });
    tags.push(new ScriptTag({ content: videoStructuredData, id: 'videoStructureData' }));
  }

  if (productGroup?.productGroupName) {
    const productGroupSchema = getProductGroupSchema({
      product: data.product,
      reviews: data.reviews,
      groupName: productGroup.productGroupName
    });

    tags.push(new ScriptTag({ content: productGroupSchema, id: 'productGroup' }));
  }

  return tags;
};

const ProductMetadata = ({
  itemId,
  pageDescription = null,
  pageNumber = null,
  pageTitle = null,
  ppq = false,
  questionsCanonical = false,
  reviewsCanonical,
  hideIfHDPPSku
}) => {
  const pageSize = 30;
  const storeId = useStoreId();
  useEffect(() => { LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('product-metadata.ready'); }, []);
  const productOptions = {
    variables: {
      itemId,
      storeId,
    },
  };

  let startIndex = (pageNumber - 1) * pageSize;
  startIndex = pageNumber > 1 ? startIndex + 1 : startIndex;

  const reviewsOptions = {
    variables: {
      itemId,
      ...(pageNumber && {
        startIndex,
        pagesize: pageSize,
        recfirstpage: pageSize
      })
    },
  };

  const emtContentOptions = {
    variables: {
      contentId: itemId,
      pageName: 'pip'
    },
    skip: !itemId
  };

  const { data, loading, error, variables } = useDataModel('product', productOptions);
  const { data: reviewData, loading: reviewLoading, reviewError } = useDataModel('reviews', reviewsOptions);
  const { data: emtData, loading: emtLoading, error: emtError } = useDataModel('emtContent', emtContentOptions);

  const props = {
    reviewsCanonical,
    pageDescription,
    pageNumber,
    pageTitle,
    questionsCanonical,
    ppq,
    hideIfHDPPSku
  };

  const helmetData = {
    product: data?.product,
    reviews: !(props?.hideIfHDPPSku && data?.product?.identifiers?.skuClassification === 'hdpp') && reviewData?.reviews,
    emtContent: emtData?.emtContent,
  };

  const deps = data?.product && reviewData?.reviews && data?.product?.itemId;

  useHelmet('productMetadata', { data: helmetData, props, variables }, productMetaDataHandler, [deps]);
  return null;
};

ProductMetadata.displayName = 'ProductMetadata';

ProductMetadata.dataModel = {
  product: params({ itemId: stringType().isRequired() }).shape({
    itemId: stringType(),
    dataSources: stringType(),
    specificationGroup: arrayType(shapeType({
      specifications: arrayType(shapeType({
        specName: stringType(),
        specValue: stringType()
      })),
      specTitle: stringType()
    })),
    availabilityType: shapeType({
      discontinued: boolType(),
      status: boolType()
    }),
    details: shapeType({
      description: stringType()
    }),
    identifiers: shapeType({
      brandName: stringType(),
      canonicalUrl: stringType(),
      itemId: stringType(),
      modelNumber: stringType(),
      productLabel: stringType(),
      storeSkuNumber: stringType(),
      upcGtin13: stringType(),
      skuClassification: stringType()
    }),
    media: shapeType({
      image: shapeType({
        url: stringType()
      }).client(),
      images: arrayType(shapeType({
        url: stringType(),
        type: stringType(),
        subType: stringType(),
        sizes: arrayType(stringType())
      })),
      video: arrayType(shapeType({
        shortDescription: stringType(),
        thumbnail: stringType(),
        url: stringType(),
        videoStill: stringType(),
        uploadDate: stringType(),
        dateModified: stringType(),
      }))
    }),
    pricing: params({ storeId: stringType() }).shape({
      promotion: shapeType({
        dates: shapeType({
          end: stringType()
        })
      }),
      value: numberType({ float: true }),
      original: numberType({ float: true })
    }),
    reviews: shapeType({
      ratingsReviews: shapeType({
        averageRating: stringType(),
        totalReviews: stringType()
      })
    }),
    seo: shapeType({
      seoKeywords: stringType(),
      seoDescription: stringType()
    }),
    taxonomy: shapeType({
      breadCrumbs: arrayType(shapeType({
        label: stringType(),
        url: stringType()
      }))
    })
  }),
  reviews: params({ itemId: stringType().isRequired() }).shape({
    Results: arrayType(shapeType({
      Rating: numberType(),
      ReviewText: stringType(),
      Title: stringType(),
      UserNickname: stringType()
    }))
  }),
  emtContent: params({
    contentId: stringType().isRequired(),
    pageName: stringType().isRequired()
  }).shape({
    content: json()
  })
};

ProductMetadata.propTypes = {
  itemId: string.isRequired,
  reviewsCanonical: bool,
  pageNumber: string,
  pageTitle: string,
  pageDescription: string,
  questionsCanonical: bool,
  ppq: bool,
  hideIfHDPPSku: bool
};

ProductMetadata.defaultProps = {
  reviewsCanonical: false,
  pageNumber: null,
  pageTitle: null,
  pageDescription: null,
  questionsCanonical: false,
  ppq: false,
  hideIfHDPPSku: false
};

export { ProductMetadata };
