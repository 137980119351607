import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useThdCustomer } from '@thd-olt-functional/customer-information';

import { SponsoredSkyscraperBanner } from '@thd-olt-component-react/sponsored-content';
import './Column.scss';

const getColumnSize = (opts) => {
  const {
    defaultSize,
    extraSmallSize,
    smallSize,
    mediumSize,
    largeSize,
    extraLargeSize
  } = opts;
  let columnSize = '';
  if (defaultSize) {
    columnSize += `col__${defaultSize}`;
  }
  if (extraSmallSize) {
    columnSize += ` col__${extraSmallSize}--xs`;
  }
  if (smallSize) {
    columnSize += ` col__${smallSize}--sm`;
  }
  if (mediumSize) {
    columnSize += ` col__${mediumSize}--md`;
  }
  if (largeSize) {
    columnSize += ` col__${largeSize}--lg`;
  }
  if (extraLargeSize) {
    columnSize += ` col__${extraLargeSize}--xl`;
  }
  return columnSize;
};

/**
 * @group Layout
 * Displays legacy column component for deprecated content grid
 */

const Column = (props) => {
  const { opts = {}, children, channel, bannerData } = props;
  const thdCustomer = useThdCustomer();
  const { id, name, hideOnMobile } = opts;
  const columnSize = getColumnSize(opts);
  const gridClass = classNames(columnSize);
  const isMobile = channel === 'mobile';
  const isDirectParentOfColumnRail = opts?.content?.[0]?.content?.[0]?.isColumnRail;
  const showBanner = !isMobile && isDirectParentOfColumnRail && bannerData?.isCategory;

  if (isMobile && hideOnMobile) {
    return null;
  }

  return (
    <div
      className={gridClass}
      id={id}
    >
      {name
        && <div id={name} className="anchor" />}
      <div className="content">
        {children}
      </div>
      {showBanner && (
        <div className="content column clp-skyscraper-banner">
          <SponsoredSkyscraperBanner
            browserId={thdCustomer?.mcvisID || ''}
            pageContext={{ label: 'browse-search', ...bannerData }}
          />
        </div>
      )}
    </div>
  );
};

Column.propTypes = {
  children: PropTypes.node,
  opts: PropTypes.shape({
    id: PropTypes.string,
    isColumnRail: PropTypes.bool,
    name: PropTypes.string,
    hideOnMobile: PropTypes.bool,
    content: PropTypes.instanceOf(Array)
  }).isRequired,
  channel: PropTypes.string,
  bannerData: PropTypes.shape({
    isCategory: PropTypes.bool
  })
};
Column.defaultProps = {
  children: [],
  channel: 'desktop',
  bannerData: {}
};

export default Column;
