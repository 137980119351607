import {
  FREE_SHIPPING_THRESHOLD,
  ORDER_PROMO,
  ITEM_PROMO,
  DESKTOP_POD_BASE_HEIGHT,
  MOBILE_POD_BASE_HEIGHT,
  RADIO_BUTTON_HEIGHT,
  SUMMARY_HEIGHT,
  MAP_POLICY_RANK,
  PROD_DOMAIN
} from './constants';

// Gets the height in pixels of an individual product pod
export const getProductPodHeight = (productGrouping, isMobile) => {
  return (isMobile ? MOBILE_POD_BASE_HEIGHT : DESKTOP_POD_BASE_HEIGHT)
    + ((productGrouping.length - 1) * RADIO_BUTTON_HEIGHT);
};

// Gets the height in pixels of the product pods and summary
export const getProductSetHeight = (productGroups, isMobile) => {
  return Object.keys(productGroups).reduce((height, key) => {
    return height + getProductPodHeight(productGroups[key], isMobile);
  }, 0) + SUMMARY_HEIGHT;
};

// Checks if the passed products include the product with the passed itemId
export const includedInProducts = (products, itemId) => {
  return products.map((product) => {
    return product.itemId;
  }).includes(itemId);
};

// Flattens an object of grouped products into an array
export const flattenProductGroups = (productGroups) => {
  return Object.keys(productGroups).flatMap((key) => {
    return productGroups[key];
  });
};

// Flattens an object of grouped products into a filtered array
export const getFlatActiveProducts = (productGroups) => {
  return flattenProductGroups(productGroups).filter((product) => {
    return product.active && !product.removed;
  });
};

// Checks whether or not any of the products in the package are affected by MAP pricing
export const mapPriceExistsInPackage = (products) => {
  return products.some((product) => {
    return product?.pricing?.mapAboveOriginalPrice !== null;
  });
};

export const getMapAboveOriginalPrice = (products) => {
  return products.reduce((acc, product) => {
    if (!product?.pricing?.mapAboveOriginalPrice && acc === null) return false;
    if (product?.pricing?.mapAboveOriginalPrice && acc === false) return true;
    return acc;
  }, null);
};

// Calculates the total price of the package from the original field
export const originalPriceTotal = (products) => {
  return products.map((product) => {
    return product?.pricing?.original || product?.pricing?.value;
  }).reduce((previousValue, currentValue) => {
    return previousValue + currentValue;
  }, 0);
};

// Calculates the total price of the package from the value field
export const valuePriceTotal = (products) => {
  return products.map((product) => {
    return product?.pricing?.value;
  }).reduce((previousValue, currentValue) => {
    return previousValue + currentValue;
  }, 0);
};

// Totals promos of the given type
export const getPromosTotal = (promos, promoType) => {
  return Number.parseFloat((promos.filter((promo) => {
    return promo?.type === promoType;
  }).map((promo) => {
    return (promo?.dollarOff ?? 0);
  }).reduce((savings, dollarOff) => {
    return savings + dollarOff;
  }, 0) || 0).toFixed(2));
};

// Calculates the promotional adjustments
export const additionalPromotionsDiscount = (products) => {
  const promos = products.flatMap((product) => {
    return product?.pricing?.promotionalAdjustments;
  });
  return getPromosTotal(promos, ORDER_PROMO) + getPromosTotal(promos, ITEM_PROMO);
};

// Groups promotional discounts together
export const groupPromotionalDiscounts = (products) => {
  const groupedPromotions = products.flatMap((product) => {
    return product?.pricing?.promotionalAdjustments;
  }).reduce((groups, promo) => {
    const promoId = promo?.promoId;
    if (promo) {
      if (groups[promoId]) {
        groups[promoId].push(promo);
      } else {
        // eslint-disable-next-line no-param-reassign
        groups[promoId] = [promo];
      }
    }
    return groups;
  }, {});

  return Object.entries(groupedPromotions).map(([promoId, itemPromos]) => {
    return {
      promoId,
      description: itemPromos[0].description,
      totalDollarDiscount: itemPromos.reduce((total, promo) => {
        return total + (promo?.dollarOff ?? 0);
      }, 0)
    };
  });
};

// Totals all promotional discounts together
export const totalPromotionalDiscounts = (groupedPromos) => {
  return Number.parseFloat(groupedPromos.reduce((total, promo) => {
    return total + (promo?.totalDollarDiscount ?? 0);
  }, 0).toFixed(2));
};

// Calculates the discount in dollars
export const dollarOffTotal = (products) => {
  return products.reduce((total, product) => {
    const dollarOff = (product?.pricing?.promotion?.dollarOff ?? 0);
    return total + dollarOff;
  }, 0);
};

// Calculates the discount as a percentage
export const percentageOffTotal = (price, dollarOff) => {
  return Number((price === 0 ? 100 : (dollarOff / price) * 100).toFixed());
};

// Determines if the package qualifies for free shipping
export const hasFreeShipping = (price) => {
  return price >= FREE_SHIPPING_THRESHOLD;
};

// Collates the different products MAP Policies into the highest priority one
export const getCollatedMapPolicy = (products) => {
  const mapRanks = Object.values(MAP_POLICY_RANK);
  return mapRanks[products.reduce((rank, product) => {
    const mapRank = mapRanks.indexOf(product?.pricing?.mapDetail?.mapPolicy);
    return mapRank > rank ? mapRank : rank;
  }, -1)] ?? null;
};

// Calculates the discount in dollars from MAP
export const getDollarOffMapTotal = (products) => {
  return products.reduce((dollarOff, product) => {
    return dollarOff + (product?.pricing?.mapDetail?.dollarOff ?? 0);
  }, 0);
};

// Collates the different products MAP special price violations
export const getCollatedMapSpecialPriceViolation = (products) => {
  return products.reduce((violation, product) => {
    if (product?.pricing?.mapDetail?.mapSpecialPriceViolation === false) return false;
    if (product?.pricing?.mapDetail?.mapSpecialPriceViolation) return true;
    return violation;
  }, null);
};

// Collates the different products MAP original price violations
export const getCollatedMapOriginalPriceViolation = (products) => {
  return products.reduce((violation, product) => {
    if (product?.pricing?.mapDetail?.mapOriginalPriceViolation === false) return false;
    if (product?.pricing?.mapDetail?.mapOriginalPriceViolation) return true;
    return violation;
  }, null);
};

// Returns all pricing info in a single object for easy use
export const getPricingInfo = (
  products,
  enableApplianceDeliveryCharge = false,
  enableFreeDeliveryForExchange = false,
  applianceDeliveryChargeValue = 29,
  isExchangeCustomer = false
) => {
  const mapAboveOriginalPrice = getMapAboveOriginalPrice(products);
  const mapExists = mapPriceExistsInPackage(products);
  const originalPrice = originalPriceTotal(products);
  const valuePrice = valuePriceTotal(products);
  const dollarOffPromo = additionalPromotionsDiscount(products);
  const percentageOffPromo = percentageOffTotal(valuePrice, dollarOffPromo);
  const promotionalDiscounts = groupPromotionalDiscounts(products);
  const totaledPromotionalDiscounts = totalPromotionalDiscounts(promotionalDiscounts);
  const dollarOff = dollarOffTotal(products);
  const percentageOff = percentageOffTotal(originalPrice, dollarOff);
  const freeShipping = hasFreeShipping(originalPrice) || (isExchangeCustomer && enableFreeDeliveryForExchange);
  const showFlatDeliveryFee = !isExchangeCustomer && enableApplianceDeliveryCharge;
  const shippingCost = showFlatDeliveryFee ? applianceDeliveryChargeValue : 0;
  const collatedMapPolicy = getCollatedMapPolicy(products);
  const dollarOffMapTotal = getDollarOffMapTotal(products);
  const percentageOffMapTotal = percentageOffTotal(originalPrice, dollarOffMapTotal);
  const collatedMapSpecialPriceViolation = getCollatedMapSpecialPriceViolation(products);
  const collatedMapOriginalPriceViolation = getCollatedMapOriginalPriceViolation(products);

  return {
    mapAboveOriginalPrice,
    mapExists,
    originalPrice: originalPrice || 0,
    valuePrice: valuePrice || 0,
    dollarOffPromo: dollarOffPromo || 0,
    percentageOffPromo: percentageOffPromo || 0,
    promotionalDiscounts: promotionalDiscounts || [],
    totaledPromotionalDiscounts: totaledPromotionalDiscounts || 0,
    dollarOff: dollarOff || 0,
    percentageOff: percentageOff || 0,
    totalPrice: (valuePrice - totaledPromotionalDiscounts + shippingCost) || 0,
    freeShipping,
    showFlatDeliveryFee,
    hideTotal: collatedMapPolicy === 'showPriceInCart'
      && (mapExists || collatedMapSpecialPriceViolation || collatedMapOriginalPriceViolation),
    mapDetail: {
      mapPolicy: collatedMapPolicy,
      percentageOff: percentageOffMapTotal,
      dollarOff: dollarOffMapTotal,
      mapSpecialPriceViolation: collatedMapSpecialPriceViolation,
      mapOriginalPriceViolation: collatedMapOriginalPriceViolation
    }
  };
};

// Returns array of group names ('Range', 'Dishwasher', etc) in the order to be displayed on the page.
export const getProductGroupOrder = (productGroups, packagePriorities) => {
  const groupsInPriorityList = packagePriorities.filter((type) => !!productGroups[type]);
  const groupsNotInPriorityList = Object.keys(productGroups).filter((type) => !groupsInPriorityList.includes(type));

  return [...groupsInPriorityList, ...groupsNotInPriorityList];
};

// Custom nullish coalescing function since ?? is not allowed for some reason
export const nullishCoalescer = (leftHandValue, rightHandValue) => {
  if (leftHandValue === null || leftHandValue === undefined) {
    return rightHandValue;
  }
  return leftHandValue;
};

// Creates a new array with a defined length and filles it with a given value
export const buildNewFilledArray = (length, value) => {
  return new Array(length).fill(value);
};

// Builds the new loadingState array based off of the given product group
export const buildLoadingState = (loadingState, orderedProductGroups, productGroup) => {
  return loadingState.map((value, index) => {
    if (index === orderedProductGroups.indexOf(productGroup)) {
      return true;
    }
    return value;
  });
};

// Determines if the new Product Group contains products from the old Product Group, effectivley are the groups the same
export const hasPreviouslySelectedItem = (removedProductGroup, newProductGroup) => {
  return removedProductGroup.some((oldProduct) => {
    return newProductGroup.some((newProduct) => {
      return oldProduct.itemId === newProduct.itemId;
    });
  });
};

// Creates a new productGroup based on newProducts and removedProducts
export const createNewProductGroup = (newProducts, productGroup, removedProductGroup) => {
  if (removedProductGroup) {
    return removedProductGroup.map((product) => {
      return {
        ...product,
        swappedProducts: [],
        removed: false
      };
    });
  }
  return newProducts.map((product) => {
    return {
      ...product,
      active: true,
      removed: false,
      swappedProducts: [],
      info: {
        ...product.info,
        productGroup
      }
    };
  });
};

const getProtocol = () => {
  const protocolBase = window?.location?.protocol || '';
  if (protocolBase.length) {
    return protocolBase + '//';
  }
  return '';
};

const getUrl = (path) => {
  let url = '';
  const host = window?.location?.host;
  if (typeof host === 'string' && (
    host.includes('hd-qa74') || host.includes('hd-qa71') || host.includes('storefront')
  )) {
    url = getProtocol() + host + path;
  } else {
    url = PROD_DOMAIN + path;
  }
  return url;
};

export const signIn = () => {
  return window?.open(
    getUrl(`/auth/view/signin?redirect=${window?.location?.pathname}`), '_self', 'noopener, noreferrer'
  );
};
