import React, { useEffect } from 'react';
import { node, oneOf } from 'prop-types';
import { footerAnalytics } from '../../events/analytics.listener';
import { FooterContextProvider } from './FooterContextProvider';

const FooterController = (props) => {
  const {
    children,
    customerType
  } = props;

  useEffect(() => {
    footerAnalytics.listen();
  }, []);

  return (
    <FooterContextProvider customerType={customerType}>
      {children}
    </FooterContextProvider>
  );
};
FooterController.displayName = 'FooterController';

FooterController.propTypes = {
  children: node,
  customerType: oneOf(['default', 'b2b'])
};
FooterController.defaultProps = {
  children: null,
  customerType: 'default'
};

export { FooterController };