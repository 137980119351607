/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  string as stringType,
  number as numberType,
  bool as boolType,
  func as functionType
} from 'prop-types';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { RecentlyViewedTypeahead } from './RecentlyViewedTypeahead';

const RecentlyViewedTypeaheadWrapper = (props) => {

  const {
    brandTitleMaxLine,
    hideCarouselArrows,
    isMobile,
    moduleName,
    showLoading,
    skipCache,
    slidesPer,
    storeIdProp,
    userIdProp,
    zipCodeProp,
    onRVDataLoaded
  } = props;

  return (
    <ErrorBoundary id="recently-viewed-typeahead" name="header-content-menu-data">
      <QueryProvider dataSource="rv_rr_typeahead" cacheKey={`${moduleName}-recentlyViewed-typeAhead`} persist>
        <RecentlyViewedTypeahead
          brandTitleMaxLine={brandTitleMaxLine}
          hideCarouselArrows={hideCarouselArrows}
          isMobile={isMobile}
          moduleName={moduleName}
          showLoading={showLoading}
          skipCache={skipCache}
          slidesPer={slidesPer}
          storeIdProp={storeIdProp}
          userIdProp={userIdProp}
          zipCodeProp={zipCodeProp}
          onRVDataLoaded={onRVDataLoaded}
        />
      </QueryProvider>
    </ErrorBoundary>
  );
};

RecentlyViewedTypeaheadWrapper.propTypes = {
  brandTitleMaxLine: numberType,
  hideCarouselArrows: boolType,
  isMobile: boolType,
  moduleName: stringType,
  showLoading: boolType,
  skipCache: boolType,
  slidesPer: numberType,
  // supplying fallback props for dynamic data components for the carousel
  storeIdProp: stringType,
  userIdProp: stringType,
  zipCodeProp: stringType,
  onRVDataLoaded: functionType,
};

RecentlyViewedTypeaheadWrapper.defaultProps = {
  brandTitleMaxLine: 2,
  hideCarouselArrows: false,
  isMobile: false,
  moduleName: 'recentlyViewed',
  showLoading: false,
  skipCache: false,
  slidesPer: null,
  storeIdProp: null,
  userIdProp: null,
  zipCodeProp: null,
  onRVDataLoaded: null,
};

export default RecentlyViewedTypeaheadWrapper;
