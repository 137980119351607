import { SHOW_SPECIAL_PRICE_IF_AUTH, SHOW_PRICE_IN_CART } from '../constants';

const hasZeroPriceAndMapPolicy = (products) => {
  const productExists = products.find((product) => {
    return !product?.pricing?.value && !product?.pricing?.original;
  });
  return productExists;
};

export const getPrice = (products) => {
  let value = 0;
  let original = 0;
  let promotionalAdjustments = 0;

  /* eslint-disable no-unused-expressions */
  products?.forEach((product) => {
    value += product?.pricing?.value || product?.pricing?.original || 0;
    original += product?.pricing?.original || product?.pricing?.value || 0;

    const adjustments = product?.pricing?.promotionalAdjustments?.reduce((sum, promotionalAdjustment) => {
      return sum + (promotionalAdjustment?.dollarOff || 0);
    }, 0) || 0;

    promotionalAdjustments += adjustments;
  });

  if (promotionalAdjustments !== null && promotionalAdjustments !== undefined
    && promotionalAdjustments < value && promotionalAdjustments > 0) {

    value -= promotionalAdjustments;
  }

  if (hasZeroPriceAndMapPolicy(products)) {
    return {
      value: 0,
      original: 0
    };
  }
  return {
    value,
    original
  };
};

export const getDollarPercentOff = (prices) => {
  const dollarOffExists = prices?.original > prices?.value;
  const dollarOff = prices?.original - prices?.value;

  return {
    dollarOff: dollarOffExists ? dollarOff : 0,
    percentOff: dollarOffExists ? ((dollarOff / prices?.original) * 100) : 0
  };
};

export const roundNumber = ({ number, decimal = 0 }) => {
  if (!number) {
    return null;
  }

  const newRoundNumber = parseFloat(number)
    .toLocaleString('en-US', { minimumFractionDigits: decimal, maximumFractionDigits: decimal })
    .replaceAll(',', '');

  return newRoundNumber;
};

export const getPriceProductData = (
  features,
  originalPrice,
  discount,
  percentOff,
  mapAboveOriginalPrice,
  mapPolicyDetail,
  pricevalue,
  message
) => {
  return {
    pricing: {
      alternate: {
        bulk: {
          value: null,
          thresholdQuantity: null
        },
        unit: {
          unitsPerCase: null,
          caseUnitOfMeasure: null,
          unitsOriginalPrice: null,
          value: null
        }
      },
      alternatePriceDisplay: false,
      mapAboveOriginalPrice,
      message,
      original: roundNumber({ number: originalPrice, decimal: 2 }),
      preferredPriceFlag: null,
      mapDetail: mapPolicyDetail,
      promotion: {
        dates: {
          start: null,
          end: null
        },
        dollarOff: discount,
        percentageOff: percentOff,
        savingsCenter: 0,
        savingsCenterPromos: null,
        specialBuySavings: null,
        specialBuyDollarOff: null,
        specialBuyPercentageOff: null,
        type: 'DISCOUNT'
      },
      specialBuy: null,
      unitOfMeasure: features?.priceUnitOfMeasure,
      value: roundNumber({ number: pricevalue, decimal: 2 }),
      someSkusHaveZeroPrice: false,
      quantityLimit: null
    }
  };
};

export const mapPriceExistsInPackage = (products) => {
  const mapPolicyProducts = products?.filter((product) => {
    return product?.pricing?.mapDetail?.mapPolicy;
  });

  const showSpecialPriceIfAuthProducts = mapPolicyProducts?.find((product) => {
    return product?.pricing?.mapDetail?.mapPolicy === SHOW_SPECIAL_PRICE_IF_AUTH;
  });

  const showPriceinCart = mapPolicyProducts?.find((product) => {
    return product?.pricing?.mapDetail?.mapPolicy === SHOW_PRICE_IN_CART;
  });

  const mapAboveOriginalPriceProduct = mapPolicyProducts.find((product) => {
    return product?.pricing?.mapAboveOriginalPrice;
  });

  const specialAndOriginalPriceViolationProduct = hasZeroPriceAndMapPolicy(mapPolicyProducts);

  const mapProduct = specialAndOriginalPriceViolationProduct || showPriceinCart || showSpecialPriceIfAuthProducts;

  const mapDetails = {
    mapPolicy: mapProduct?.pricing?.mapDetail || null,
    mapAboveOriginalPrice: mapPolicyProducts ? undefined : (mapAboveOriginalPriceProduct || false),
    message: mapProduct?.pricing?.message || null
  };
  return mapDetails;
};