import React from 'react';
import { ContentSection } from '@thd-olt-component-react/content-section';
import { ImpressionProvider } from '@thd-olt-component-react/impression';
import { CapabilityCardSection } from '../components/CapCardSection/CapabilityCardSection';
import {
  ContentAccordionSection
} from '../components/ContentAccordionSection/ContentAccordionSection';

export const parseContent = ({ data, componentMapper, customProps = {} }) => {
  if (!data?.layouts?.content) {
    return {};
  }

  const { content } = data.layouts;

  const Content = {};
  const promoVisNavTitles = {};

  function renderComponent(component, position, componentProps = {}) {
    const typename = component.__typename;

    if (!componentMapper.getComponent(typename)) {
      return null;
    }

    const allComponentProps = {
      ...componentProps,
      ...(typename.toString().toLocaleLowerCase() === 'herocarousel' && { title: null })
    };

    if (typename === 'Hero') {
      allComponentProps.heightClass = 'hero__shorter-no-sidenav';
    }

    if (typename === 'CapabilityCard' && componentProps?.capCardsHorizontal) {
      allComponentProps.isHorizontal = true;
    }

    if (typename === 'VisualNavigation') {
      allComponentProps.isInSection = true;
    }

    let props = {
      componentId: component.id,
      componentClass: typename,
      componentPosition: position,
      key: position,
      ...allComponentProps,
      ...((typename === 'EndCap') && { entryId: component.id }),
      ...(typename === 'Description_New' && { title: component.title,
        description: component.descriptionText,
        useDefaultFontStylesForHeadings: true }),
      ...(typename === 'PromoVisualNavigation' && { title: promoVisNavTitles[component.id]
          || componentProps.dynamicPromoVisNavTitle }),
      ...customProps[typename] && customProps[typename](component)
    };

    const newDynamicComponent = React.createElement(
      componentMapper.getComponent(typename),
      props,
      component.components?.map((comp, index) => renderComponent(comp, index + 1))
    );

    const impressableComponents = ['HeroCarousel', 'Section', 'ProductShelf'];

    if (impressableComponents.includes(typename)) {
      // * Choose the name of the component based on the typename
      let componentName = null;
      switch (typename) {
      case 'ProductShelf':
      case 'HeroCarousel':
        componentName = typename;
        break;
      default:
        // * Defaults to Section
        componentName = 'Section';
      }
      // * 'component' attribute different for ProductShelf, 'Section' is defaulted
      const impressionData = {
        id: component.id,
        component: componentName === 'ProductShelf' ? componentName : 'Section',
        name: componentName,
        type: 'content'
      };

      return (
        <ImpressionProvider
          key={position || 1}
          data={impressionData}
        >
          {newDynamicComponent}
        </ImpressionProvider>

      );
    }

    return newDynamicComponent;
  }

  function addDynamicSection(dynamicSection, keyName) {
    Content[keyName] = [];

    dynamicSection.forEach((subSection) => {
      let { isCarousel, title, id } = subSection;

      let subSectionProps = { ...subSection };

      if (isCarousel) {
        subSectionProps = {
          ...subSectionProps,
          spaceBetweenXs: 16,
          spaceBetweenSm: 24,
          spaceBetweenMd: 24,
          spaceBetweenLg: 32,
          spaceBetweenXl: 32,
          slidesLg: 3,
          slidesXl: 3,
          slidesMd: 3,
          slidesSm: 2,
          slidesXs: 2,
        };
      }

      let capCardsHorizontal;
      const firstComponent = subSection.components !== null ? subSection.components[0] : [];
      let componentName = firstComponent?.__typename;
      const dynamicPromoVisNavTitle = componentName === 'PromoVisualNavigation' ? title : '';
      let SectionType;

      switch (componentName) {
      case 'ContentAccordion':
        SectionType = ContentAccordionSection;
        break;
      case 'CapabilityCard':
        SectionType = CapabilityCardSection;
        capCardsHorizontal = subSection.components?.length === 1;
        break;
      default:
        SectionType = ContentSection;
        break;
      }

      const childSection = React.createElement(
        SectionType,
        { isCarousel, title, ...subSectionProps },
        subSection.components?.map((comp, index) => renderComponent(comp, index + 1, { capCardsHorizontal,
          dynamicPromoVisNavTitle }))
      );
      Content[keyName].push(() => React.createElement(
        ImpressionProvider,
        {
          data: {
            id,
            component: 'Section',
            type: 'content',
            name: 'Section'
          }
        },
        childSection
      ));
    });
  }

  function addComponent(componentName, component) {
    let { isCarousel, title, ...otherProps } = component;
    let isSection = component.__typename === 'Section' || component.__typename === 'HeroCarousel';

    Content[componentName] = (props) => renderComponent(component, 1,
      { ...isSection && { isCarousel, title, ...otherProps }, ...props });
  }

  Object.keys(content).map((key) => {
    const contentSection = content[key];
    const typename = contentSection?.__typename;
    if (Array.isArray(contentSection)) {
      addDynamicSection(contentSection, key);
    } else if (componentMapper.getComponent(typename)) {
      const componentName = typename === 'Section' ? key : typename;
      addComponent(componentName, contentSection);
    } else if (!contentSection) {
      // Even though section content does not exist, if the key maps to an actual component, then it needs to be added
      // to the Content object with a default value set to an empty React Fragment. If we do not do this, the calling
      // code that tries to reference this component will cause an error and prevent the page from loading.
      if (componentMapper.isValidKey(key)) {
        const isDynamicSection = key.includes('dynamicSections');
        const contentKey = componentMapper.getContentKey(key);
        Content[contentKey] = isDynamicSection ? [] : () => React.Fragment;
      } else {
        Content[key] = () => React.Fragment;
      }
    }

    return null;
  });
  return {
    Content: {
      ...Content,
      heroCarousel: Content.heroCarousel || (() => React.Fragment),
      HeroCarousel: Content.HeroCarousel || (() => React.Fragment),
    }
  };
};

export const validateNValue = (slug) => {
  return slug ? slug.includes('N-') : false;
};
