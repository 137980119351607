import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardMedia,
  CardTitle,
} from '@one-thd/sui-atomic-components';
import { useImpression } from '@thd-olt-component-react/impression';

import { RichText } from '@thd-olt-component-react/rich-text';
import './capability_card.scss';

const CapabilityCardRenderer = ({
  componentId,
  isHorizontal,
  componentPosition,
  dataComponent,
  isLivePreview,
  richTextData,
  headline
}) => {
  useEffect(() => { LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('capability_card.ready'); }, []);

  const altText = dataComponent?.altText;
  const headlineData = headline ?? dataComponent?.headline;
  const hasImage = !!dataComponent?.previewImage;
  const hasIcon = !!dataComponent?.previewIcon;

  let imageUrl;

  if (hasImage) {
    const damDownloadedContentImage = dataComponent?.previewImage?.damDownloadedContent;
    const damContentSelectorImage = dataComponent?.previewImage?.damContentSelector?.assetData?.[0];

    imageUrl = damDownloadedContentImage?.url || damContentSelectorImage?.selectedImageUrl || imageUrl;
  }

  let iconUrl;

  if (hasIcon) {
    const damDownloadedContentImage = dataComponent?.previewIcon.damDownloadedContent;
    const damContentSelectorImage = dataComponent?.previewIcon.damContentSelector?.assetData?.[0];

    iconUrl = damDownloadedContentImage?.url || damContentSelectorImage?.selectedImageUrl || iconUrl;
  }

  const impressionProviderContextData = useImpression({
    data: {
      id: componentId,
      name: 'CapabilityCard',
      component: 'CapabilityCard',
      position: componentPosition,
      type: 'content'
    }
  });

  return (
    <div
      className="capability-card"
      data-component="CapabilityCard"
      ref={impressionProviderContextData.ref}
      // eslint-disable-next-line react/no-unknown-property
      clickid={impressionProviderContextData.clickID}
    >
      <Card
        data-contentful-entry-id={componentId}
        data-contentful-field-id="internalName"
      >
        <div className={`sui-flex sui-gap-4 ${isHorizontal ? 'sui-flex-col sm:sui-flex-row' : 'sui-flex-col'}`}>
          {hasImage && imageUrl !== 'String' && (
            <div className="sui-w-full">
              <CardMedia
                src={imageUrl}
                aspect="square"
                alt={`${altText || headlineData}`}
                title={`${altText || headlineData}`}
                data-contentful-entry-id={componentId}
                data-contentful-field-id="previewImage"
              />
            </div>
          )}
          <div className="sui-flex sui-flex-col sui-space-y-4 sui-w-full">
            <CardTitle
              leadingIcon={hasIcon && iconUrl !== 'String' && (
                <img
                  width={24}
                  height={24}
                  src={iconUrl}
                  alt="icon"
                  className="sui-w-6 sui-h-6 sui-object-contain"
                  data-contentful-entry-id={componentId}
                  data-contentful-field-id="previewIcon"
                />
              )}
              header={(
                <span
                  data-contentful-entry-id={componentId}
                  data-contentful-field-id="headline"
                >
                  {headlineData}
                </span>
              )}
              headerWeight="bold"
            />
            {richTextData && (
              <div
                data-contentful-entry-id={componentId}
                data-contentful-field-id="richTextContent"
              >
                <RichText
                  content={richTextData}
                  isLivePreview={isLivePreview}
                />
              </div>
            )}
          </div>
        </div>
      </Card>
    </div>
  );
};

CapabilityCardRenderer.propTypes = {
  componentId: PropTypes.string,
  isHorizontal: PropTypes.bool,
  richTextData: PropTypes.string,
  headline: PropTypes.string,
  componentPosition: PropTypes.number,
  isLivePreview: PropTypes.bool,
  dataComponent: PropTypes.shape({
    altText: PropTypes.string,
    headline: PropTypes.string,
    previewImage: PropTypes.shape({
      damDownloadedContent: PropTypes.shape({
        url: PropTypes.string
      }),
      damContentSelector: PropTypes.shape({
        assetData: PropTypes.arrayOf(PropTypes.shape({
          selectedImageUrl: PropTypes.string
        }))
      })
    }),
    previewIcon: PropTypes.shape({
      damDownloadedContent: PropTypes.shape({
        url: PropTypes.string
      }),
      damContentSelector: PropTypes.shape({
        assetData: PropTypes.arrayOf(PropTypes.shape({
          selectedImageUrl: PropTypes.string
        }))
      })
    })
  })
};

CapabilityCardRenderer.defaultProps = {
  componentId: undefined,
  componentPosition: 1,
  isHorizontal: false,
  headline: undefined,
  richTextData: undefined,
  isLivePreview: false,
  dataComponent: null
};

export { CapabilityCardRenderer };
