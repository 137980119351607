import React from 'react';
import classnames from 'classnames';
import { useDataModel } from '@thd-nucleus/data-sources';
import { Typography, Skeleton, SkeletonContent, SkeletonLine } from '@one-thd/sui-atomic-components';
import { ProductInfoBar } from '@thd-olt-component-react/product-info-bar';
import {
  bool, string
} from 'prop-types';
import { RetailMediaProductListing } from '@thd-olt-component-react/retail-media-pla-sponsored-placements';
import { dataModel } from '../../dataModel';
import { BulletList } from '../BulletList';
import { InfoGuides } from '../InfoGuides';
import { shouldDisplayAdditionalResources, getBullets } from '../../helpers';
import { RichContent } from '../RichContent/RichContent';

const DesktopContent = (props) => {
  const {
    itemId,
    hideRichContents,
    showRichContentsOnly,
    showReturnMessage,
    showPla
  } = props;

  const { data, loading, error } = useDataModel('product', { variables: { itemId } });

  if (loading) {
    return (
      <Skeleton
        grow
      >
        <SkeletonContent disablePadding>
          <SkeletonLine numberOfLines={8} fullWidth />
        </SkeletonContent>
      </Skeleton>
    );
  }

  if ((error && !data) || !data?.product) {
    return null;
  }
  const { product } = data;

  const {
    prop65Warning,
    prop65Message,
    returnable
  } = product?.info || {};

  const {
    description,
    additionalResources,
    descriptiveAttributes
  } = product?.details || {};

  const {
    brandName,
    brandMainPageUrl
  } = product?.identifiers;
  const bullets = getBullets({
    channel: 'desktop',
    descriptiveAttributes,
    showRichContentsOnly,
    showReturnMessage,
    prop65Warning,
    prop65Message,
    returnable
  });

  const displayAdditionalResources = !!(
    additionalResources
    && shouldDisplayAdditionalResources(additionalResources, brandName, brandMainPageUrl)
    && !showRichContentsOnly
  );
  const displayBullets = bullets && bullets.length > 0;

  return (
    <div id="product-overview-desktop-content" className="sui-w-full sui-text-base" data-testid="desktop-content">
      {!hideRichContents && <RichContent itemId={itemId} />}
      <div className="sui-flex sui-flex-col sui-gap-2 md:sui-flex-row md:sui-justify-between">
        <div className={classnames('sui-flex sui-flex-col sui-gap-4', {
          'sui-w-full md:sui-w-6/12': displayAdditionalResources,
          'sui-w-full': !displayAdditionalResources
        })}
        >
          <div className="sui-flex sui-flex-col sui-gap-2">
            <Typography variant="h3" weight="bold">
              About This Product
            </Typography>
            <p>{description}</p>
          </div>
          {displayBullets && (
            <div>
              <Typography variant="h3" weight="bold">
                Highlights
              </Typography>
              <BulletList bullets={bullets} margin isMobile={false} />
            </div>
          )}
          <div className="sui-flex sui-flex-col sui-gap-2">
            <Typography variant="h3" weight="bold">
              Product Information
            </Typography>
            <ProductInfoBar
              itemId={itemId}
              textSize="base"
              flexDirection="col"
              compactMobile
            />
          </div>
        </div>
        {displayAdditionalResources && (
          <div className="sui-w-full sui-flex sui-flex-col sui-gap-2 md:sui-w-5/12">
            <InfoGuides
              additionalResources={additionalResources}
              brandName={brandName}
              brandMainPageUrl={brandMainPageUrl}
              returnMessage={returnable}
              showReturnMessage={showReturnMessage}
            />
            {showPla && (
              <div data-testid="pla-wrapper">
                <RetailMediaProductListing
                  plaLocation="sponsoredPlaProductDetailsProductInformationPage"
                  pageContext={{
                    label: 'product-overview',
                    schema: 'pip_sponsored_pla',
                    data: { itemId }
                  }}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const areEqual = (prevProps, nextProps) => {
  return prevProps.itemId === nextProps.itemId
    && prevProps.returnMessage === nextProps.returnMessage;
};

DesktopContent.displayName = 'DesktopContent';
DesktopContent.dataModel = dataModel;

DesktopContent.propTypes = {
  itemId: string.isRequired,
  hideRichContents: bool,
  showRichContentsOnly: bool,
  showReturnMessage: bool,
  showPla: bool
};

DesktopContent.defaultProps = {
  hideRichContents: false,
  showRichContentsOnly: false,
  showReturnMessage: false,
  showPla: false
};

export default DesktopContent;
