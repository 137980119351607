import React, { useContext } from 'react';
import { Col, Row, Image } from '@thd-olt-component-react/core-ui';
import { QueryContext, QueryProvider } from '@thd-nucleus/data-sources';
import { BundlingProductTabs } from '@thd-olt-component-react/bundling-product-tabs';
import { useStoreId, ExperienceContext } from '@thd-nucleus/experience-context';
import { useTheme } from '@thd-olt-component-react/theme-provider';
import { PackagePriceSuite } from './PackagePriceSuite';
import { PackagesMiniContext } from '../PackagesMiniContext';
import { getImageUrl, getPackageUrl, getDefaultImage } from '../../utils';
import { FinancingOffer } from './FinancingOffer';
import './package-info.scss';
import THDCustomer from '~/@thd-olt-global/thd-customer';

const PackageInfo = () => {
  const storeId = useStoreId();
  const { defaultVariables } = useContext(QueryContext) || {};
  const theme = useTheme(PackagePriceSuite);
  const { hideFinancingOffer } = theme.props;
  const { channel } = useContext(ExperienceContext);
  const isMobile = channel === 'mobile';
  const { packageModel = {}, products, packageCategory } = useContext(PackagesMiniContext);
  const { isMajorAppliancePackage } = packageModel;
  const packageUrl = getPackageUrl(packageModel);
  const packageImageUrl = getImageUrl(packageModel);
  const defaultImageUrl = getDefaultImage(packageModel);
  const isExchangeCustomer = THDCustomer?.isExchangeCustomer;
  const newDefaultVariables = {
    storeId,
    ...(typeof defaultVariables?.current?.isBrandPricingPolicyCompliant === 'boolean' && {
      isBrandPricingPolicyCompliant: defaultVariables.current.isBrandPricingPolicyCompliant
    })
  };

  return (
    <Row className="package-info">
      <Col xs="12" sm="12" md="4" lg="4">
        <Col nopadding={isMobile} fallback="4" xs="4" sm="4" md="12" lg="12" className="package-info__image">
          <a href={packageUrl}>
            <Image
              className="package-info__image-main"
              src={packageImageUrl || defaultImageUrl}
              alt="Package Bundle"
              width={1}
              height={1}
            />
          </a>
        </Col>
        <Col nopadding fallback="8" xs="8" sm="8" md="12" lg="12">
          <PackagePriceSuite />
        </Col>
        {(!hideFinancingOffer && !isExchangeCustomer) && (
          <>
            <Col fallback="0" sm="4" md="0" nopadding>
              &nbsp;
            </Col>
            <Col
              className="package__financing-offer-wrapper"
              data-testid="financing-offer"
              nopadding
              fallback="12"
              sm="8"
              md="12"
            >
              <FinancingOffer products={products} isAppliance={isMajorAppliancePackage} />
            </Col>
          </>
        )}
      </Col>
      <Col xs="12" sm="12" md="8" lg="8">
        <QueryProvider dataSource="packages" defaultVariables={newDefaultVariables} cacheKey="package-products">
          <BundlingProductTabs
            dataSource="packages"
            products={products}
            packageCategory={packageCategory}
          />
        </QueryProvider>
      </Col>
    </Row>
  );
};

PackageInfo.displayName = 'PackageInfo';

export { PackageInfo };
