export const FREE_SHIPPING_THRESHOLD = 396;
export const ITEM_PROMO = 'ITEMPROMO';
export const ORDER_PROMO = 'ORDERPROMO';
export const MAP_CONTENT = `We've set an item's price lower than the manufacturer
 will let us advertise, so you'll have to add it to your cart to see our price. If you decide
  you don't want to purchase the item, you can remove it from your cart.`;
export const REMOVE_HEADER = 'Are you sure you want to remove this item?';
export const DESKTOP_POD_BASE_HEIGHT = 164.3;
export const MOBILE_POD_BASE_HEIGHT = 248.5;
export const RADIO_BUTTON_HEIGHT = 40;
export const SUMMARY_HEIGHT = 155;
export const TOTAL_TEXT = {
  FREE: 'Free',
  UNKNOWN: '-'
};

export const PROD_DOMAIN = typeof window !== 'undefined' && window?.location?.origin.includes('exchange')
  ? 'https://exchange.homedepot.com'
  : 'https://www.homedepot.com';

export const MAP_POLICY_VERBIAGE = {
  // eslint-disable-next-line
  showPriceInCart: 'We\'ve set an item\'s price lower than the manufacturer will let us advertise, so you\'ll have to add it to your cart to see our price. If you decide you don\'t want to purchase the item, you can remove it from your cart.',
  // eslint-disable-next-line
  showSpecialPriceIfAuth: 'The Home Depot price for one or more items is different from what the manufacturer allows us to advertise.'
};

// Ranked priority top to bottom from least to greatest
export const MAP_POLICY_RANK = {
  SHOW_PRICE_IN_CART: 'showPriceInCart',
  SHOW_SPECIAL_PRICE_IF_AUTH: 'showSpecialPriceIfAuth'
};
