import React from 'react';
import { number, object, bool, string } from 'prop-types';
import { useStore, useConfigService } from '@thd-nucleus/experience-context';
import { InfoFilled } from '@one-thd/sui-icons';
import { TileHead } from '../../TileHead';
import { getDefaultStoreHours, showBopisDate } from '../../../../helper/timeZoneUtils';
import { formatInventory } from '../../../../helper/utils';
import { NotAvailable } from '../../NotAvailable';

const ClearanceStore = ({
  quantity, pickupStoreHours, isLimitedQuantity, clearanceStoreName, isHideLimitedStockEnabled
}) => {
  let { storeHours } = useStore();
  const isHideLimitedStockClearanceEnabled = useConfigService('fs:isHideLimitedStockClearanceEnabled') || isHideLimitedStockEnabled;

  if (!storeHours) {
    storeHours = getDefaultStoreHours();
  }
  const pickupStoreHoursWithTZ = { ...storeHours, ...pickupStoreHours };
  const formattedInventoryQty = formatInventory(quantity);

  if (isLimitedQuantity && isHideLimitedStockClearanceEnabled) {
    return <NotAvailable type="BOPIS" />;
  }

  return (
    <div className="fulfillment-tile">
      <TileHead title={isLimitedQuantity ? 'Limited Stock' : 'At Your Store'} />
      <div className="fulfillment__sub-title fulfillment__primary_color">
        <div
          className="u__bold pickup-timeline"
        >
          {isLimitedQuantity ? 'On Clearance at' : showBopisDate(pickupStoreHoursWithTZ, true)}
        </div>
        <div className="fulfillment-qty-row qty-info-icon">
          {isLimitedQuantity ? (
            <span>
              {clearanceStoreName}
            </span>
          ) : (
            <span>
              <span className="u__text--success">
                <span>{formattedInventoryQty}</span>
              </span>&nbsp;in stock
            </span>
          )}
          <span>&nbsp;
            <InfoFilled size="small" style={{ color: '#3e7697' }} />
          </span>
        </div>
      </div>
    </div>
  );

};

ClearanceStore.propTypes = {
  quantity: number,
  // eslint-disable-next-line react/forbid-prop-types
  pickupStoreHours: object,
  isLimitedQuantity: bool,
  clearanceStoreName: string,
  isHideLimitedStockEnabled: bool
};
ClearanceStore.defaultProps = {
  pickupStoreHours: null,
  quantity: 0,
  isLimitedQuantity: false,
  clearanceStoreName: null,
  isHideLimitedStockEnabled: false
};

export { ClearanceStore };