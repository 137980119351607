import React, { useState } from 'react';

import { bool, node, shape } from 'prop-types';
import { HeaderContext, defaultHeaderContextValues } from './HeaderContext';

const HeaderProvider = ({
  children,
  featureSwitches
}) => {

  const headerContext = {
    ...defaultHeaderContextValues(),
    featureSwitches: {
      ...featureSwitches,
    }
  };

  return (
    <HeaderContext.Provider value={headerContext}>
      {children}
    </HeaderContext.Provider>
  );
};

HeaderProvider.propTypes = {
  children: node,
  featureSwitches: shape({
    isRecentlyViewedEnabled: bool
  })
};

HeaderProvider.defaultProps = {
  children: null,
  featureSwitches: {
    isRecentlyViewedEnabled: false
  }
};

HeaderProvider.displayName = 'HeaderProvider';

export { HeaderProvider };
