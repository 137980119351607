export const GROUP_GENERAL = 'General';
export const GROUP_SKUMAP = 'SKU Map';
export const GROUP_OPTIONS_CHOICES = 'Options/Choices';
export const GROUP_OPTIONS_CHOICES_CONFIGURATOR = 'Options/Choices - Configurator';
export const GROUP_OPTIONS_CHOICES_AVAILABILITY = 'Options/Choices - Availability';
export const GROUP_PRICING = 'Pricing';
export const GROUP_RULES = 'Rules';
export const GROUP_TEMPLATE = 'Presentation Template';

export const TYPE_GENERAL = 'General';
export const TYPE_CONFIGURATION = 'Configuration';