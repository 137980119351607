import React, { useContext, useRef, Fragment } from 'react';
import {
  arrayOf, string, func, bool, objectOf, number
} from 'prop-types';
import { Image } from '@thd-olt-component-react/core-ui';
import { Checkbox } from '@thd-olt-component-react/checkbox';
import { Button } from '@thd-olt-component-react/button';
import { Price } from '@thd-olt-component-react/price';
import { useStoreId, ExperienceContext } from '@thd-nucleus/experience-context';
import { useSearchDeprecated } from '@thd-nucleus/data-sources/react/dataModel/migration';
import { dataModel } from './dataModel';
import { useScrollPosition } from '../../hooks/useScrollPosition';
import { CompareATC } from '../compare-atc/CompareATC';
import './compare-sticky-header.scss';

const CompareStickyHeader = ({
  itemIds,
  onHideSimilar,
  hideSimilar,
  onRemove,
  scrollPos
}) => {

  const { channel } = useContext(ExperienceContext);
  const storeId = useStoreId();

  const domains = [
    'identifiers',
    'info',
    'media',
    'details',
    'badges',
    'reviews',
    'availabilityType',
    'pricing',
    'fulfillment',
    'favoriteDetail',
    'sizeAndFitDetail'
  ];
  const { error, loading, data } = useSearchDeprecated({
    domains,
    storeId,
    itemIds,
    ssr: false
  }, 'products');

  const ref = useRef();
  const scroll = useScrollPosition();

  if (ref.current && channel === 'mobile') {
    ref.current.style.left = `${20 - scrollPos.xpos}px`;
  }

  const getHeaderData = (products) => itemIds.map((itemId) => {
    const product = products.find((prod) => prod.itemId === itemId) || {};
    const image = product?.media?.images.find((img) => img.subType === 'PRIMARY');
    const { productLabel: label, brandName: brand } = product?.identifiers || {};
    const { discontinued } = product?.availabilityType || {};

    return {
      brand,
      itemId: product.itemId,
      label,
      product,
      discontinued,
      url: image?.url?.replace(/<SIZE>/, 100)
    };
  });

  if (loading || (error && !data) || !data?.products) {
    return null;
  }

  const headerData = getHeaderData(data.products);

  const truncate = (str = '', len) => {
    if (str.length <= len) return str;

    return str.substring(0, len) + '...';
  };

  const visibilityClass = scroll.ypos > 800 ? '' : 'u--hide';
  const shouldShowRemoveButton = itemIds.length > 2;

  const renderForDesktop = () => (
    <div
      className={`compare-sticky-header ${visibilityClass}`}
      data-component="CompareStickyHeaderDesktop"
    >
      <div className="compare-sticky-header__wrapper">
        <div className="compare-sticky-header__section">
          <div className="compare-sticky-header__section-title">
            Compare Products
          </div>
          <div data-testid="hide-similar-header" className="compare-sticky-header__hide-similar">
            <Checkbox
              id="hide-similar-header"
              onChange={onHideSimilar}
              checked={hideSimilar}
              value="hide-similar"
            >
              Hide Similarities
            </Checkbox>
          </div>
        </div>
        {headerData.map((item) => (
          <div
            className="compare-sticky-header__section compare-sticky-header__section--pod"
            key={`image-${item.itemId}`}
          >
            <div className="compare-sticky-header__pod-container">
              <div className="compare-sticky-header__pod-header">
                <div className="compare-sticky-header__remove">
                  {shouldShowRemoveButton && (
                    <Button onClick={() => { onRemove(item.itemId); }} remove link>
                      <span />
                    </Button>
                  )}
                </div>
              </div>
              <div className="compare-sticky-header__pod-body">
                <div className="compare-sticky-header__pod-body-details">
                  <div className="compare-sticky-header__pod-image">
                    <Image alt="product" src={item.url} height={50} width={50} />
                  </div>
                  <div className="compare-sticky-header__pod-description">
                    <span className="compare-sticky-header__pod-brand">{item.brand}</span>
                    &nbsp;
                    <span className="compare-sticky-header__pod-label">{truncate(item.label, 90)}</span>
                  </div>
                </div>
                <div className="compare-sticky-header__pod-body-price">
                  <div className="compare-sticky-header__pod-price">
                    {!item.discontinued && <Price itemId={item.itemId} storeId={storeId} large={false} />}
                  </div>
                </div>
              </div>
              <div className="compare-sticky-header__pod-footer">
                <div className="compare-sticky-header__pod-atc">
                  <CompareATC product={item.product} />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  const renderForMobile = () => (
    <div
      ref={ref}
      className={`compare-sticky-header
      compare-sticky-header__items--${itemIds.length}
      ${visibilityClass}`}
      data-component="CompareStickyHeaderMobile"
    >
      <div className="compare-sticky-header__wrapper">
        {headerData.map((item) => (
          <Fragment key={`image-${item.itemId}`}>
            <div className="compare-sticky-header__section compare-sticky-header__section--pod">
              <div className="compare-sticky-header__pod-body">
                <div className="compare-sticky-header__pod-image">
                  <Image alt="product" src={item.url} height={50} width={50} />
                </div>
                <div className="compare-sticky-header__pod-description">
                  <span className="compare-sticky-header__pod-brand">{item.brand}</span>
                  &nbsp;
                  <span className="compare-sticky-header__pod-label">{truncate(item.label, 30)}</span>
                </div>
              </div>
            </div>
          </Fragment>
        ))}
      </div>
    </div>
  );

  if (channel === 'desktop') return renderForDesktop();

  return renderForMobile();
};

CompareStickyHeader.displayName = 'CompareStickyHeader';

CompareStickyHeader.propTypes = {
  itemIds: arrayOf(string).isRequired,
  onHideSimilar: func.isRequired,
  hideSimilar: bool.isRequired,
  onRemove: func.isRequired,
  scrollPos: objectOf({
    ypos: number,
    xpos: number
  }).isRequired
};

CompareStickyHeader.defaultProps = {
};

CompareStickyHeader.dataModel = dataModel;

export { CompareStickyHeader };
