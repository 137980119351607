import React from 'react';
import { string, func } from 'prop-types';
import { withDynamicComponent } from '@thd-nucleus/app-render';
import { withErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { withHydrator } from '@thd-olt-component-react/hydrator';
import { PackageHeader } from './subcomponents/PackageHeader';
import { PackageInfo } from './subcomponents/PackageInfo';
import { dataModel } from './dataModel';
import { PackagesMiniPlaceholder } from './loader/packagesMiniLoader';
import PackagesMiniProvider from './PackagesMiniProvider';
import './packages-mini.scss';

const PackagesMiniComponent = ({ itemId, callOnLoaded }) => (
  <div className="packages-mini" data-testid="packages-mini" data-component="PackagesMini">
    <PackagesMiniProvider
      itemId={itemId}
      callOnLoaded={callOnLoaded}
      placeholder={<PackagesMiniPlaceholder />}
    >
      <PackageHeader />
      <PackageInfo />
    </PackagesMiniProvider>
  </div>
);

const propTypes = {
  itemId: string,
  callOnLoaded: func
};

const defaultProps = {
  itemId: '',
  callOnLoaded: () => {},
};

PackagesMiniComponent.displayName = 'PackagesMini';
PackagesMiniComponent.propTypes = propTypes;
PackagesMiniComponent.defaultProps = defaultProps;
PackagesMiniComponent.dataModel = dataModel;

const HydratedComponent = withHydrator({
  id: 'product-section-packages-mini',
  scrollBuffer: 100,
  delay: 1500,
  preserveCtxVal: 'clientStore',
  placeholder: <PackagesMiniPlaceholder />
}, PackagesMiniComponent);

const DynamicComponent = withDynamicComponent(HydratedComponent, { placeholder: <PackagesMiniPlaceholder /> });
const PackagesMini = withErrorBoundary(DynamicComponent);
PackagesMini.displayName = PackagesMiniComponent.displayName;

export { PackagesMini };
