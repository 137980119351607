import React from 'react';
import { oneOf } from 'prop-types';
import { BRANDS } from './footer-data';
import { useFooterContext } from './FooterContext';

const BrandLinks = () => {
  const { customerType } = useFooterContext();

  const isProCustomer = customerType === 'b2b';

  const BRANDS_TITLE = 'Shop Our Brands';

  // eslint-disable-next-line max-len
  const brandLogosWrapperClasses = `${isProCustomer ? 'sui-hidden' : 'sui-block'} sui-bg-primary lg:sui-max-w-screen-2xl lg:sui-m-auto`;

  // eslint-disable-next-line max-len
  const footerBrandLogosClasses = 'sui-flex sui-flex-row sui-flex-wrap sui-justify-center sui-list-none sui-p-2 sui-p-2px';

  return (
    <div className={brandLogosWrapperClasses}>
      <div className="sui-pt-2 sui-pb-4 sui-text-lg sui-text-center">
        {BRANDS_TITLE}
      </div>
      <div id="footer" className="sui-pb-4">
        <ul className={footerBrandLogosClasses}>
          {BRANDS.map((brand) => (
            <li className="sui-ml-2 sui-mr-2 sui-p-1" key={brand.name}>
              <a href={brand.href} title={brand.name} alt={brand.name}>
                <img
                  src={brand.src}
                  alt={brand.name}
                  height="50"
                  width="120"
                  loading="lazy"
                  className="sui-h-14 sui-w-32 sui-object-scale-down"
                />
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

BrandLinks.propTypes = {};
BrandLinks.defaultProps = {};
BrandLinks.dataModel = {};
BrandLinks.displayName = 'Footer Brand Links';

export { BrandLinks };