import React from 'react';
import PropTypes, {
  arrayOf, bool, shape, func, string, number
} from 'prop-types';
import { Fulfillment, Discontinued } from '@thd-olt-component-react/fulfillment';
import { AddToCart } from '@thd-olt-component-react/add-to-cart';
import { TrackOutOfStock } from '@thd-olt-component-react/email-signup';
import { BuyboxActions } from './BuyboxActions';
import * as helper from '../../BuyboxHelper';
import { isMobile } from '../../util';

const BuyboxLite = ({
  itemId,
  configurableItems,
  unavailable,
  storeId,
  isShowATC,
  covidAlert,
  storeZip,
  isATCDisabled,
  channel,
  zipCode,
  fulfillmentMethod,
  fulfillment,
  availabilityType,
  paypalEligible,
  onFulfillmentChange,
  onDeliveryOptionsZipCodeChange,
  onATCClick,
  bopisHolidayTiming,
  hideInstantCheckout,
  hidePaypalCheckout,
  directData,
  configuratorData,
  quantityLimit,
  currentSelectedFulfillment,
  configurableProductDetails,
  isTwoTileEnabledFS
}) => {
  if (isMobile(channel)) {
    const shippingTemplate = helper.renderShippingFulfillment({ fulfillment, availabilityType });
    const cart = configuratorData?.product?.configuration?.cart;
    return (
      <div
        // eslint-disable-next-line tailwindcss/no-arbitrary-value
        className="sui-grid sui-ml-[-10px] sui-mr-[-10px] sui-w-auto sui-bg-primary sui-mb-1"
        data-component="BuyboxLiteMobile"
      >
        <div className="sui-grid-cols-12">
          {shippingTemplate.showATC && (
            <div className="sui-p-2 sui-overflow-hidden">
              <AddToCart
                cartOptions={cart
                  || helper.getCartOptions({
                    configurableItems,
                    channel,
                    fulfillmentMethod: shippingTemplate.method,
                    alterBrowserHistory: true
                  })}
                cartReqParams={cart
                  || helper.getCartReqParams({
                    itemId,
                    configurableItems,
                    storeZip,
                    fulfillmentMethod: shippingTemplate.method,
                    quantity: 1,
                    zipCode,
                    storeId,
                    fulfillment
                  })}
                onClick={onATCClick}
                disabled={isATCDisabled}
              >
                Add to Cart
              </AddToCart>
            </div>
          )}
          {shippingTemplate.discontinued && (
            <Discontinued twoTileToggle />
          )}
          <div className="sui-p-2 sui-overflow-hidden">
            {shippingTemplate.template}
          </div>
          {shippingTemplate.oos && (
            <TrackOutOfStock itemId={itemId} />
          )}
        </div>
      </div>
    );
  }

  return (
    // eslint-disable-next-line tailwindcss/no-arbitrary-value
    <div className="sui-grid sui-ml-[-10px] sui-mr-[-10px] sui-w-auto" data-component="BuyboxLite">
      <div className="sui-grid-cols-12">
        <div className="sui-p-2 sui-bg-primary sui-overflow-hidden">
          <div>
            {!unavailable && (
              <div>
                <div>
                  <Fulfillment
                    itemId={itemId}
                    covidAlert={covidAlert}
                    onChange={onFulfillmentChange}
                    onDeliveryZipCodeChange={onDeliveryOptionsZipCodeChange}
                    bopisHolidayTiming={bopisHolidayTiming}
                    directData={directData}
                    isBuyBoxLite
                    configurableProductDetails={configurableProductDetails}
                    currentSelectedFulfillment={currentSelectedFulfillment}
                    isTwoTileEnabledFS={isTwoTileEnabledFS}
                  />
                </div>
              </div>
            )}
          </div>
          {isShowATC && (
            <div className="sui-mt-3">
              <BuyboxActions
                alterBrowserHistory
                itemId={itemId}
                storeId={storeId}
                storeZip={storeZip}
                disableATC={isATCDisabled}
                channel={channel}
                zipCode={zipCode}
                fulfillmentMethod={fulfillmentMethod}
                fulfillment={fulfillment}
                paypalEligible={paypalEligible}
                onATCClick={onATCClick}
                hideQuantity
                hideInstantCheckout={hideInstantCheckout}
                hidePaypalCheckout={hidePaypalCheckout}
                configuratorData={configuratorData}
                quantityLimit={quantityLimit}
              />
            </div>
          )}
        </div>
      </div>
      <div className="sui-bg-subtle sui-p-2 sui--ml-1" data-component="buybox-divider" />
    </div>
  );
};

BuyboxLite.propTypes = {
  itemId: string,
  unavailable: bool,
  covidAlert: bool,
  bopisHolidayTiming: number,
  storeId: string,
  isShowATC: bool,
  storeZip: string,
  isATCDisabled: bool,
  channel: string,
  zipCode: string,
  configurableItems: arrayOf(
    shape({
      itemId: string,
      isAnchor: bool
    })
  ),
  fulfillmentMethod: string,
  fulfillment: shape({
    backordered: bool
  }),
  availabilityType: shape({
    discontinued: bool,
    status: bool,
    buyable: bool,
    type: string
  }),
  paypalEligible: bool,
  onFulfillmentChange: func,
  onDeliveryOptionsZipCodeChange: func,
  onATCClick: func,
  hideInstantCheckout: bool,
  hidePaypalCheckout: bool,
  /* eslint-disable-next-line react/forbid-prop-types */
  directData: PropTypes.objectOf(PropTypes.any),
  // eslint-disable-next-line react/forbid-prop-types
  configuratorData: arrayOf(PropTypes.any),
  quantityLimit: number,
  currentSelectedFulfillment: string,
  isTwoTileEnabledFS: bool,
  configurableProductDetails: PropTypes.shape({
    isSpecialOrder: bool,
    configuratorHideQuantity: bool,
    /* eslint-disable-next-line react/forbid-prop-types */
    configuratorData: PropTypes.objectOf(PropTypes.any),
    isConfigurableBlinds: bool
  })
};

BuyboxLite.defaultProps = {
  itemId: null,
  configurableItems: null,
  unavailable: false,
  storeId: null,
  isShowATC: true,
  covidAlert: false,
  storeZip: null,
  isATCDisabled: false,
  channel: 'desktop',
  zipCode: null,
  fulfillmentMethod: null,
  fulfillment: null,
  availabilityType: null,
  paypalEligible: false,
  onFulfillmentChange: () => {},
  onDeliveryOptionsZipCodeChange: () => {},
  onATCClick: () => {},
  bopisHolidayTiming: null,
  hideInstantCheckout: false,
  hidePaypalCheckout: false,
  directData: null,
  configuratorData: null,
  quantityLimit: 0,
  currentSelectedFulfillment: '',
  isTwoTileEnabledFS: false,
  configurableProductDetails: {
    isSpecialOrder: false,
    configuratorHideQuantity: false,
    configuratorData: {},
    isConfigurableBlinds: false
  }
};

export { BuyboxLite };
