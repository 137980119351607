import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useDataModel } from '@thd-nucleus/data-sources';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { Etch as Etch1 } from './etch1/Etch1';
import { Etch as Etch2 } from './etch2/Etch2';
import { dataModel } from './dataModel';
import './etch-widgets.scss';

const EtchWidgets = (props) => {
  const { channel, store, clientStore } = useContext(ExperienceContext);
  const {
    contentName,
    dpdNewTab,
    shouldPrioritizeCtaImageFetch,
    keyword,
    navParam,
    content,
    showFavoritesCount,
    storeId,
    exclude,
    include,
    schemaName,
    data: propData,
    loading: propLoading,
    error: propError,
    showDpdPod,
    isCategory,
    isBrowse,
    isSearch,
    previewName
  } = props;

  const { storeId: defaultStoreId } = clientStore || store;

  if (content) {
    const templates = [];
    try {
      templates.push(JSON.parse(content));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('Failed to parse rich content');
    }
    const etch1Templates = templates && templates[0]?.Content ? templates : [];
    const etch2Templates = templates && templates[0]?.content ? templates : [];

    if (!(etch1Templates.length || etch2Templates.length)) {
      return null;
    }

    return (
      <div className="etch-widgets" data-component="EtchWidgets">
        <Etch2
          dpdNewTab={dpdNewTab}
          shouldPrioritizeCtaImageFetch={shouldPrioritizeCtaImageFetch}
          templates={etch2Templates}
          isCategory={false}
          channel={channel}
          storeId={storeId || defaultStoreId}
          keyword={keyword}
          navParam={navParam}
          showFavoritesCount={showFavoritesCount}
          exclude={exclude}
          include={include}
          showDpdPod={showDpdPod}
          bannerData={{
            data: propData,
            keyword,
            isCategory,
            isBrowse,
            isSearch
          }}
          schemaName={schemaName}
        />
        <Etch1
          dpdNewTab={dpdNewTab}
          templates={etch1Templates}
          isCategory={false}
          channel={channel}
          storeId={storeId || defaultStoreId}
          keyword={keyword}
          navParam={navParam}
          showFavoritesCount={showFavoritesCount}
          exclude={exclude}
          include={include}
          showDpdPod={showDpdPod}
          bannerData={{
            data: propData,
            keyword,
            isCategory,
            isBrowse,
            isSearch
          }}
        />
      </div>
    );
  }

  /* eslint-disable-next-line react-hooks/rules-of-hooks */
  const searchResponse = useDataModel('searchModel', {
    skip: (contentName || propData || propLoading || propError),
    variables: {
      keyword,
      storeId: storeId || defaultStoreId,
      navParam
    }
  });

  /* eslint-disable-next-line react-hooks/rules-of-hooks */
  const contentResponse = useDataModel('content', {
    skip: !contentName,
    variables: {
      name: contentName,
      channel: channel?.toUpperCase() || 'DESKTOP',
      contentName: previewName
    }
  });

  let data;
  let loading;
  let error;

  if (propData || propLoading || propError) {
    data = propData;
    loading = propLoading;
    error = propError;
  } else {
    ({ data, loading, error } = contentName ? contentResponse : searchResponse);
  }

  if (loading || !data) return null;

  // useEffect(() => { LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('etch-widgets.ready'); }, []);
  const { dimensions, relatedResults } = data?.searchModel || {};

  const stringifiedTemplates = data?.content?.templates || data?.searchModel?.templates;
  const stringifiedPartialTemplates = data?.searchModel?.partialTemplates || [];
  const etch2PartialData = stringifiedPartialTemplates.length ? JSON.parse(stringifiedPartialTemplates[0]) : {};
  const etch2PartialTemplate = etch2PartialData.PLP_Banner ? etch2PartialData.PLP_Banner : {};

  let templates = [];
  let partialTemplates = [];

  try {
    templates = stringifiedTemplates?.map((template) => JSON.parse(template)) || [];
    partialTemplates = etch2PartialTemplate.content
      ? [] : stringifiedPartialTemplates?.map((template) => JSON.parse(template)) || [];
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('Error parsing etch templates');
  }

  const etch1Templates = templates && templates[0]?.Content ? templates : [];
  const etch2TemplateContent = etch2PartialTemplate.content ? [etch2PartialTemplate] : [];
  const etch2Templates = templates && templates[0]?.content ? templates : etch2TemplateContent;

  if (!(etch1Templates.length || etch2Templates.length)) {
    return null;
  }

  return (
    <div className="etch-widgets" data-component="EtchWidgets">
      <Etch2
        shouldPrioritizeCtaImageFetch={shouldPrioritizeCtaImageFetch}
        dpdNewTab={dpdNewTab}
        templates={etch2Templates}
        isCategory={false}
        channel={channel}
        storeId={storeId || defaultStoreId}
        keyword={keyword}
        navParam={navParam}
        showFavoritesCount={showFavoritesCount}
        exclude={exclude}
        include={include}
        showDpdPod={showDpdPod}
        bannerData={{
          data,
          keyword,
          isCategory,
          isBrowse,
          isSearch
        }}
        schemaName={schemaName}
      />
      <Etch1
        dpdNewTab={dpdNewTab}
        templates={etch1Templates}
        partialTemplates={partialTemplates}
        isCategory={false}
        channel={channel}
        storeId={storeId || defaultStoreId}
        keyword={keyword}
        navParam={navParam}
        showFavoritesCount={showFavoritesCount}
        exclude={exclude}
        include={include}
        showDpdPod={showDpdPod}
        dimensions={dimensions}
        relatedResults={relatedResults}
        bannerData={{
          data: propData,
          keyword,
          isCategory,
          isBrowse,
          isSearch
        }}
      />
    </div>
  );
};

EtchWidgets.displayName = 'EtchWidgets';

EtchWidgets.dataModel = dataModel;

EtchWidgets.propTypes = {
  contentName: PropTypes.string,
  keyword: PropTypes.string,
  storeId: PropTypes.string,
  navParam: PropTypes.string,
  content: PropTypes.string,
  showFavoritesCount: PropTypes.bool,
  exclude: PropTypes.arrayOf(PropTypes.string),
  include: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.shape({}),
  loading: PropTypes.bool,
  error: PropTypes.shape({}),
  showDpdPod: PropTypes.bool,
  isCategory: PropTypes.bool,
  isBrowse: PropTypes.bool,
  isSearch: PropTypes.bool,
  schemaName: PropTypes.string,
  dpdNewTab: PropTypes.bool,
  shouldPrioritizeCtaImageFetch: PropTypes.bool,
  previewName: PropTypes.string
};

EtchWidgets.defaultProps = {
  contentName: null,
  keyword: '',
  storeId: null,
  navParam: '',
  content: '',
  showFavoritesCount: false,
  exclude: [],
  include: [],
  data: undefined,
  loading: undefined,
  error: undefined,
  showDpdPod: false,
  isCategory: false,
  isBrowse: false,
  isSearch: false,
  schemaName: null,
  dpdNewTab: false,
  shouldPrioritizeCtaImageFetch: false,
  previewName: ''
};

export { EtchWidgets };
