import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import map from 'lodash/map';
import { updateChoiceSuccess, persistChoicesOnLoad } from '@gcc/configurator';
import { mapChoicesToSkus } from '../../api/skuMapperApi';
import {
  UPDATE_SKUMAP,
  UPDATE_SKUMAP_STARTED,
  UPDATE_SKUMAP_FINISHED,
  CART_MODAL_HIDE,
  UPDATE_SKUMAP_CONFIGURATION_ERRORS,
  GET_SKUMAP_ERROR,
  GET_SKUMAP_SUCCESS,
} from '../actionTypes';
import getSelectedChoices from './_getSelectedChoices';
import doAnyOptionsHaveErrors from './_doAnyOptionsHaveErrors';
import { CUSTOM_CONFIG_BASED } from '../../utils/pipFiguratorType';
import { clearPricing } from '../pricingActions';
import addConfigurationToCartV2 from '../cartActions/addConfigurationToCartV2';
import { cartProductCartSelector } from '../../selectors';
import { LIFE_CYCLE_EVENT_CONFIGURATOR_LOADING, LIFE_CYCLE_EVENT_CONFIGURATOR_UNAVALIABLE } from '../hydration/constants';
import { GetSkuMapError } from '../../customErrors';

// action creators
const getSkuMapFinished = () => ({
  type: UPDATE_SKUMAP_FINISHED,
});

const updateSkuMap = (newSkus) => ({
  type: UPDATE_SKUMAP,
  newSkus,
});

const clearSkuMap = () => ({
  type: UPDATE_SKUMAP,
  newSkus: {
    sku: '',
    skuType: '',
    hash: '',
    subSkus: [],
    isValid: true,
    isSpecialOrder: false,
    errors: [],
    allSkus: [],
  },
});

const getSkuMapError = (err) => ({
  type: GET_SKUMAP_ERROR,
  err,
});

// thunks
const getSkuMapStarted = () => (dispatch, getState) => {
  const { options } = getState().configurator;
  const configurationError = doAnyOptionsHaveErrors(options);

  if (configurationError) {
    dispatch({
      type: CART_MODAL_HIDE
    });
  }

  dispatch({
    type: UPDATE_SKUMAP_STARTED,
    configurationError,
  });
};

export const recheckSkuConfigurationError = () => (dispatch, getState) => {
  const { options } = getState().configurator;
  const configurationError = doAnyOptionsHaveErrors(options);

  dispatch({
    type: UPDATE_SKUMAP_CONFIGURATION_ERRORS,
    configurationError,
  });
};

export const hideBuyBelt = () => (dispatch, getState) => {
  const { pipfiguratorType } = getState().product.details.attributes || '';
  if (!pipfiguratorType || pipfiguratorType !== CUSTOM_CONFIG_BASED) {
    const { configurationError } = getState().skuMap;
    // to set isLoading to true so that buybelt and cart button are disabled
    dispatch({
      type: UPDATE_SKUMAP_STARTED,
      configurationError,
    });
  }
  LIFE_CYCLE_EVENT_BUS.trigger(LIFE_CYCLE_EVENT_CONFIGURATOR_LOADING, true);
  dispatch({
    type: UPDATE_SKUMAP_STARTED,
  });
};

const getSku = () => (dispatch, getState) => {
  const {
    configurator: { options, choices, productInfo },
    config: { siteId },
    thdStoreInfo: { storeNumber },
    skuMap: { configurationError },
    product: { details },
  } = getState();
  const errorActions = {};
  const errorPromise = new Promise((resolve, reject) => {
    errorActions.resolve = resolve;
    errorActions.reject = reject;
  });
  const { productId } = productInfo;
  const selectedChoices = getSelectedChoices(options, choices);

  // Check here the condition for ranges
  if (productInfo?.choiceValueRanges && productInfo?.choiceValueRanges.length > 0) {
    const optionId = [...new Set(productInfo?.choiceValueRanges.map((item) => item.minMaxOptionId))];
    if (optionId && optionId.length > 0) {
      const eighthInchChoice = filter(choices, (choice) => choice.optionId === optionId[0]);
      const actualValue = eighthInchChoice.length > 0 ? filter(productInfo?.choiceValueRanges, (choiceValueRange) => eighthInchChoice[0].value >= choiceValueRange.minChoiceValue && eighthInchChoice[0].value <= choiceValueRange.maxChoiceValue) : [];
      // eslint-disable-next-line lodash/matches-shorthand
      const actualChoice = actualValue.length > 0 ? filter(choices, (choice) => choice.optionId === actualValue[0].optionId && choice.internalName === actualValue[0].actualChoiceValue) : [];
      if (actualChoice.length > 0) {
        const spliceIndex = selectedChoices.findIndex((choice) => choice.optionId === actualValue[0].optionId);
        if (spliceIndex >= 0) {
          selectedChoices.splice(spliceIndex, 1);
        }
        selectedChoices.push(actualChoice[0]);
      }
    }
  }

  const selections = map(selectedChoices, (choice) => {
    const option = options[choice.optionId];

    return {
      choiceId: choice.id,
      choiceValue: choice.shortLabel,
      choiceName: choice.internalName,
      optionId: choice.optionId,
      optionTags: option.tags,
      optionName: option.internalName,
    };
  });

  if (!configurationError) {
    return mapChoicesToSkus(siteId, productId, storeNumber, selections);
  }
  LIFE_CYCLE_EVENT_BUS.trigger(LIFE_CYCLE_EVENT_CONFIGURATOR_UNAVALIABLE, { configuratorUnavailable: true });
  LIFE_CYCLE_EVENT_BUS.trigger(LIFE_CYCLE_EVENT_CONFIGURATOR_LOADING, false);
  errorActions.resolve();
  return errorPromise;

};

const updatePrimarySku = (skuMap) => (dispatch) => {
  let sku = null;
  let allSkus = [];

  const nonStoreSkus = skuMap?.skus?.filter((x) => x.skuType !== 'StoreSku');
  if (!nonStoreSkus) {
    LIFE_CYCLE_EVENT_BUS.trigger(LIFE_CYCLE_EVENT_CONFIGURATOR_UNAVALIABLE, { configuratorUnavailable: true });
    LIFE_CYCLE_EVENT_BUS.trigger(LIFE_CYCLE_EVENT_CONFIGURATOR_LOADING, false);
    return;
  }
  allSkus = skuMap.skus.map((sk) => {
    if (sk.subSkus.length) return sk.skuType;
    return null;
  });
  // Find the primary sku
  if (nonStoreSkus?.length > 0) {
    const p = nonStoreSkus.find((x) => x.skuType === 'HomeDepot_OMSID');
    sku = p || nonStoreSkus[0];
    LIFE_CYCLE_EVENT_BUS.trigger('configurator.configurator_buybox_event', { sku });
  }

  if (!sku) {
    dispatch(clearSkuMap);
  }

  const newSku = {
    ...sku,
    hash: skuMap.hash,
    errors: skuMap.errors,
    isValid: skuMap.isValid,
    allSkus,
  };
  dispatch(updateSkuMap(newSku));
};

const updateStoreSku = (skuMap) => (dispatch, getState) => {
  const storeSku = skuMap.skus.find((s) => s.skuType === 'StoreSku');
  let sku = '';
  if (!isEmpty(storeSku) && !isEmpty(storeSku.sku)) {
    // eslint-disable-next-line prefer-destructuring
    sku = storeSku.sku;
  }

  const { configurator: { choices } } = getState();
  const storeSkuChoice = find(choices, (x) => x.tags.includes('store-sku'));
  if (storeSkuChoice) {
    dispatch(updateChoiceSuccess(storeSkuChoice.optionId, storeSkuChoice.id, sku));
  }
};

const updateSavedProductChoices = () => (dispatch, getState) => {
  const {
    configurator: {
      choices,
      options,
      savedProductChoices,
    },
    config: {
      productId,
      partnerProductId,
    },
  } = getState();
  if (!savedProductChoices?.[productId]?.[partnerProductId]) {
    return;
  }
  const allSelectedChoices = getSelectedChoices(options, choices);
  const savedChoices = { ...savedProductChoices[productId][partnerProductId] };

  // get selected choices where choice in state > configuration > choices, but not in state > configuration > savedProductChoices
  const selectedChoicesNotInSavedProductChoices = allSelectedChoices
    .filter((choice) => Object.keys(savedChoices)
      .filter((savedChoiceId) => savedChoices[savedChoiceId].choiceId === choice.id).length === 0
    );
  if (selectedChoicesNotInSavedProductChoices && selectedChoicesNotInSavedProductChoices.length > 0) {
    const persistedChoices = {};
    // eslint-disable-next-line no-return-assign
    selectedChoicesNotInSavedProductChoices.forEach((c) => persistedChoices[c.optionId] = { choiceId: c.id, choiceValue: c.value });
    dispatch(persistChoicesOnLoad(persistedChoices));
  }
};

// public
const getSkuMap = () => (dispatch, getState) => {
  const cart = cartProductCartSelector(getState());
  const { pipfiguratorType } = getState().product.details.attributes || '';
  if (!pipfiguratorType || pipfiguratorType !== CUSTOM_CONFIG_BASED) {
    dispatch(getSkuMapStarted());
    return dispatch(getSku())
      .then(
        (newSkus) => {
          dispatch({ type: GET_SKUMAP_SUCCESS });
          if (!isEmpty(newSkus)) {
            dispatch(updatePrimarySku(newSkus));
            dispatch(updateStoreSku(newSkus));
          }
        },
        (error) => {
          if (error instanceof GetSkuMapError) {
            dispatch(getSkuMapError(error));
          }
          if (error.response.status === 404) {
            dispatch(clearSkuMap());
            dispatch(clearPricing());
          }
        },
      )
      .then(() => {
        dispatch(getSkuMapFinished());
        if ((cart && cart?.length === 0) || !cart?.[0]?.itemId) {
          dispatch(addConfigurationToCartV2());
        }
      });
  }
  dispatch(updateSavedProductChoices());

  return Promise.resolve(dispatch(clearSkuMap()));
};

export default getSkuMap;
