import React from 'react';
import { shape, string } from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';
import { useFooterContext } from './FooterContext';
import { HOST_CONSTANTS } from '../../constants';

const NeedHelpBanner = () => {
  const { contactCenter } = useFooterContext();
  const { DEFAULT_HOST } = HOST_CONSTANTS;
  const phoneNumber = contactCenter?.number ? contactCenter.number : '1-800-466-3337';
  const textNumber = contactCenter?.smsNumber ? contactCenter.smsNumber : '38698';
  const footerNeedHelpWrapper = [
    'sui-bg-subtle lg:sui-hidden sui-border-primary sui-border-solid',
    'sui-border-b-1 sui-text-xs sui-flex sui-justify-center sui-text-center sui-p-2'
  ].join(' ');

  return (
    <div className={footerNeedHelpWrapper}>
      <div>
        <Typography weight="bold">Need Help?</Typography>
        Visit our
        <a href={`${DEFAULT_HOST}/c/customer_service`} title="Customer Service Center">
          <b>Customer Service Center </b>
        </a>
        or
        <a className="sui-ml-1" id="contact-center-sms-number" href={`sms:${textNumber}`}>
          Text <span>{textNumber}</span>
        </a>
      </div>
    </div>
  );
};

NeedHelpBanner.propTypes = {};
NeedHelpBanner.defaultProps = {};
NeedHelpBanner.dataModel = {};
NeedHelpBanner.displayName = 'Need Help Banner';

export { NeedHelpBanner };