import React, { useState, useEffect, useContext } from 'react';
import { string } from 'prop-types';

import { podFulFillmentUtils } from '@thd-olt-component-react/fulfillment';
import { extend, useDataModel, QueryProvider, QueryContext } from '@thd-nucleus/data-sources';
import { useConfigService, ExperienceContext, useStoreId } from '@thd-nucleus/experience-context';

import {
  shouldDisplayRecsFBT,
  shouldDisplayFbtbundle,
  shouldDisplayFBTPMV,
  shouldDisplayPipInStock,
  isPackageRecommendationFlag,
  isProductAvailable,
  shouldDisplayConfigurator,
  getBundleId,
  shouldDisplayBundleMini
} from '../utils/product-utils';
import { BundleMini } from './BundleMini';
import { FbtGm } from '../subcomponents/FbtGm';
import { ProductDataModel } from '../models/dataModel';
import { PackageMiniGM } from '../subcomponents/PackageMiniGM';
import { PackagesMiniAbTest } from '../subcomponents/PackagesMiniAbTest';

const BundlePmvFbtLayoutGmAndHdh = ({ itemId }) => {

  const storeId = useStoreId();
  const { data: productData } = useDataModel('clientOnlyProduct', {
    variables: {
      itemId,
      storeId
    },
    skip: !itemId,
    ssr: false
  });

  const bundleId = getBundleId(productData?.product?.bundleSpecificationDetails?.parents);

  const ctx = useContext(ExperienceContext);
  const { defaultVariables } = useContext(QueryContext);
  const { referer, isServer } = ctx;
  const [isPMVEnabled, setIsPMVEnabled] = useState(false);
  const [isFBTEnabled, setIsFBTEnabled] = useState(false);
  const [isPMVAndFBTEnabled, setIsPMVAndFBTEnabled] = useState(false);
  const fsRecsFBT = useConfigService('fs:isFbtRecsEnable');
  const abcTestEnable = useConfigService('fs:isABCTestEnable');
  const isBundlePackageEnabled = useConfigService('fs:isBundleEntryEnabled');
  const hasOneActiveABCTest = [isPMVEnabled, isFBTEnabled, isPMVAndFBTEnabled].filter((val) => !!val).length === 1;
  const displayConfigurator = shouldDisplayConfigurator(productData, isServer);
  const shouldDisplayRecs = !shouldDisplayFbtbundle(productData) && shouldDisplayRecsFBT(productData, referer)
    && fsRecsFBT;
  const shouldDisplayPipInStocks = !shouldDisplayPipInStock(productData, referer);
  const [showBundleMini, setShowBundleMini] = useState(
    shouldDisplayBundleMini(isBundlePackageEnabled, productData?.product)
  );

  useEffect(() => {
    setShowBundleMini(shouldDisplayBundleMini(isBundlePackageEnabled, productData?.product));
  }, [productData]);

  useEffect(() => {
    shouldDisplayFBTPMV(setIsPMVEnabled, setIsFBTEnabled, setIsPMVAndFBTEnabled);
  }, []);

  if (!productData || displayConfigurator) { return null; }
  const { product } = productData || {};
  const availableProduct = isProductAvailable(product, abcTestEnable);
  const isPackage = isPackageRecommendationFlag(product);
  if (!availableProduct) {
    return null;
  }

  if (showBundleMini) {
    return (
      <QueryProvider
        cacheKey="bundle-mini-provider"
        defaultVariables={defaultVariables.current}
      >
        <BundleMini
          bundleId={bundleId}
          onRender={({ isElementRendered }) => setShowBundleMini(isElementRendered)}
        />
      </QueryProvider>
    );
  }

  if (abcTestEnable && (isPackage)) {
    if (hasOneActiveABCTest) {
      return (
        <>
          {isPMVEnabled && (
            <PackageMiniGM itemId={itemId} />
          )}
          {isFBTEnabled && (
            <FbtGm
              itemId={itemId}
              shouldDisplayRecs={shouldDisplayRecs}
              shouldDisplayPipInStocks={shouldDisplayPipInStocks}
            />
          )}
          {isPMVAndFBTEnabled && (
            <>
              <PackageMiniGM itemId={itemId} />
              <FbtGm
                itemId={itemId}
                shouldDisplayRecs={shouldDisplayRecs}
                shouldDisplayPipInStocks={shouldDisplayPipInStocks}
              />
            </>
          )}
        </>
      );
    }
    return null;
  }
  if (isPackage) {
    return <PackageMiniGM itemId={itemId} />;
  }
  return (
    <FbtGm
      itemId={itemId}
      shouldDisplayRecs={shouldDisplayRecs}
      shouldDisplayPipInStocks={shouldDisplayPipInStocks}
    />
  );
};

BundlePmvFbtLayoutGmAndHdh.propTypes = {
  itemId: string
};

BundlePmvFbtLayoutGmAndHdh.defaultProps = {
  itemId: ''
};

BundlePmvFbtLayoutGmAndHdh.dataModel = extend(
  ProductDataModel,
  PackagesMiniAbTest,
  podFulFillmentUtils
);

BundlePmvFbtLayoutGmAndHdh.displayName = 'BundlePmvFbtLayoutGmAndHdh';

export { BundlePmvFbtLayoutGmAndHdh };