import React from 'react';
import { bool, string } from 'prop-types';
import { RetailMediaProductListing } from '@thd-olt-component-react/retail-media-pla-sponsored-placements';
import { useDataModel } from '@thd-nucleus/data-sources';
import { Typography, Skeleton, SkeletonContent, SkeletonLine } from '@one-thd/sui-atomic-components';
import { ProductInfoBar } from '@thd-olt-component-react/product-info-bar';
import { BulletList } from '../BulletList';
import { InfoGuides } from '../InfoGuides';
import { RichContent } from '../RichContent/RichContent';
import {
  getBullets,
  shouldDisplayAdditionalResources
} from '../../helpers';
import { dataModel } from '../../dataModel';

export const MobileContent = (props) => {
  const {
    hideRichContents,
    itemId,
    showReturnMessage,
    showRichContentsOnly,
    showPla,
    isMiniPip
  } = props;

  const { data, loading, error } = useDataModel('product', { variables: { itemId } });

  if (loading) {
    return (
      <Skeleton
        grow
      >
        <SkeletonContent disablePadding>
          <SkeletonLine numberOfLines={8} fullWidth />
        </SkeletonContent>
      </Skeleton>
    );
  }

  if ((error && !data) || !data?.product) {
    return null;
  }
  const { product } = data;

  const {
    prop65Warning,
    prop65Message,
    returnable
  } = product?.info || {};

  const {
    description,
    additionalResources,
    descriptiveAttributes
  } = product?.details || {};

  const {
    brandName,
    brandMainPageUrl
  } = product?.identifiers;

  const displayAdditionalResources = shouldDisplayAdditionalResources(additionalResources, brandName, brandMainPageUrl);

  const bullets = getBullets({
    channel: 'desktop',
    descriptiveAttributes,
    showRichContentsOnly,
    showReturnMessage,
    prop65Warning,
    prop65Message,
    returnable
  });

  return (
    <div className="sui-flex sui-flex-col sui-gap-4">
      {!hideRichContents && !isMiniPip
        && (
          <RichContent itemId={itemId} />
        )}
      <Typography variant="h3" weight="bold">
        About This Product
      </Typography>
      <p className="sui-mb-4 sui-text-base">{description}</p>
      {isMiniPip && (
        <Typography variant="h3" weight="bold">
          Highlights
        </Typography>
      )}
      <BulletList
        bullets={bullets}
        margin
        isMobile
        showRichContentsOnly={showRichContentsOnly}
        hideRichContents={hideRichContents}
      />
      <div className="sui-flex sui-flex-col sui-gap-2">
        <Typography variant="h3" weight="bold">
          Product Information
        </Typography>
        <ProductInfoBar
          itemId={itemId}
          textSize="base"
          flexDirection="col"
          compactMobile
        />
      </div>
      {displayAdditionalResources && (
        <InfoGuides
          brandName={brandName}
          brandMainPageUrl={brandMainPageUrl}
          additionalResources={additionalResources}
          channel="mobile"
          returnMessage={returnable}
          showReturnMessage={showReturnMessage}
        />
      )}
      {showPla && (
        <div className="sui-flex sui-justify-center" data-testid="pla-wrapper">
          <RetailMediaProductListing
            plaLocation="sponsoredPlaProductDetailsProductInformationPage"
            pageContext={{
              label: 'product-overview',
              schema: 'pip_sponsored_pla',
              data: { itemId }
            }}
          />
        </div>
      )}
    </div>
  );
};

MobileContent.displayName = 'MobileContent';
MobileContent.dataModel = dataModel;

MobileContent.propTypes = {
  hideRichContents: bool,
  itemId: string.isRequired,
  showReturnMessage: bool,
  showRichContentsOnly: bool,
  showPla: bool,
  isMiniPip: bool
};

MobileContent.defaultProps = {
  hideRichContents: false,
  showReturnMessage: false,
  showRichContentsOnly: false,
  showPla: false,
  isMiniPip: false
};
