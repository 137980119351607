import {
  combineReducers,
} from 'redux';
import extendedReducer from './configurator';
import product from './product';
import productInfo from './productInfo';
import skuMap from './skuMap';
import config from './config';
import thdStoreInfo from './thdStoreInfo';
import deliveryInfo from './deliveryInfo';
import canonicalUrl from './canonicalUrl';
import choiceAndSkuAvailability from './choiceAndSkuAvailability';
import choiceInit from './choiceInit';
import earliestDeliveryInfo from './earliestDeliveryInfo';
import cartProduct from './cartProduct';
import pageMetaOverview from './pageMeta';
import errorGroups from './errorGroups';
import {
  hydrationReducer,
} from './hydrationReducer';
import {
  analyticsReducer,
} from './analytics';

const rootReducer = combineReducers({
  configurator: extendedReducer,
  product,
  productInfo,
  skuMap,
  config,
  thdStoreInfo,
  deliveryInfo,
  canonicalUrl,
  choiceAndSkuAvailability,
  choiceInit,
  earliestDeliveryInfo,
  hydration: hydrationReducer,
  cartProduct,
  pageMetaOverview,
  errorGroups,
  analytics: analyticsReducer
});

export default rootReducer;
