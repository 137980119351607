import React, { useContext, useRef } from 'react';
import { bool, string } from 'prop-types';
import { extend } from '@thd-nucleus/data-sources';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { Typography, Link } from '@one-thd/sui-atomic-components';
import { ArrowForward } from '@one-thd/sui-icons';
import { BrandRating } from '../BrandRating/BrandRating';
import * as analytics from '../../analytics';
import { ProductDetailsContext } from '../ProductDetailsContext';

const analyticsData = (event, pageType, element) => {
  analytics.track('click', {
    element
  });
};

const CollectionURL = ({
  link, pageType, showCollection
}) => {

  const { channel } = useContext(ExperienceContext);
  const { product } = useContext(ProductDetailsContext);
  const { url } = product?.details?.collection || {};

  if (!url) return null;
  if (!showCollection) return null;

  return (
    showCollection
    && (
      <Link
        href={url}
        onClick={(event) => analyticsData(event, pageType, 'more in this collection')}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="sui-inline-flex sui-pr-1 sui-items-center">
          {link && <Typography variant="body-base" weight="bold">Shop this Collection </Typography>}
          {!link && <Typography variant="body-base" weight="bold">Shop the Collection </Typography>}
          <ArrowForward size="small" />
        </div>
      </Link>
    )
  );
};

CollectionURL.propTypes = {
  link: bool,
  pageType: string,
  showCollection: bool
};

CollectionURL.defaultProps = {
  link: false,
  pageType: 'PEP',
  showCollection: true
};

const ProductDetailsBrandCollection = ({
  link, pageType, showCollection, showBrandHyperLink, hideRatingsIfHDPPSku
}) => {

  const { channel } = useContext(ExperienceContext);
  const { product } = useContext(ProductDetailsContext);
  const { identifiers, taxonomy } = product || {};
  const { brandLinkUrl } = taxonomy || {};
  const { brandName, skuClassification } = identifiers || {};
  const isUnbranded = brandName && brandName.toLowerCase().includes('unbranded');
  const isMobile = channel === 'mobile';

  const { itemId } = product;
  const brandRef = useRef();

  if ((!taxonomy && !showCollection) || isUnbranded) return null;

  return (
    <div
      className="sui-flex sui-flex-wrap sui-mt-2 md:max-sm:sui-mt-2 sui-items-center"
      data-component="ProductDetailsBrandCollection"
    >
      {brandName && (
        <>
          {brandLinkUrl && (
            <div ref={brandRef}>
              <Link
                href={brandLinkUrl}
                onClick={(event) => analyticsData(event, pageType, 'clickable brand link')}
                rel="noopener noreferrer"
                target="_blank"
              >
                <span>
                  {showBrandHyperLink
                    && (
                      <div className="sui-pr-2 sui-inline-flex">
                        <Typography variant="body-base" component="h2" translate="no">{brandName}</Typography>
                      </div>
                    )}
                  {!showBrandHyperLink
                    && (
                      <>
                        <span className="sui-inline-flex sui-pr-1">
                          <Typography variant="body-base">by</Typography>
                        </span>
                        <div className="sui-inline-flex sui-pr-1 sui-items-center">
                          <Typography variant="body-base" component="h2" weight="bold" translate="no">
                            {brandName}
                          </Typography>
                          <ArrowForward size="small" />
                        </div>
                      </>
                    )}
                </span>
              </Link>
              {isMobile && (
                <>
                  {!(hideRatingsIfHDPPSku && skuClassification === 'hdpp') && (
                    <span className="sui-pr-2">
                      <BrandRating
                        channel={channel}
                        brandName={brandName}
                        itemId={itemId}
                        parentNode={brandRef.current}
                      />
                    </span>
                  )}
                  {showCollection && (
                    <CollectionURL
                      channel={channel}
                      link={link}
                      product={product}
                      showBrandHyperLink={showBrandHyperLink}
                    />
                  )}
                </>
              )}
            </div>
          )}
          {!isMobile && (
            <>
              {showCollection
                && (
                  <CollectionURL
                    channel={channel}
                    link={link}
                    product={product}
                    showBrandHyperLink={showBrandHyperLink}
                  />
                )}
              {!(hideRatingsIfHDPPSku && skuClassification === 'hdpp') && (
                <BrandRating
                  channel={channel}
                  brandName={brandName}
                  itemId={itemId}
                  parentNode={brandRef.current}
                />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

ProductDetailsBrandCollection.propTypes = {
  link: bool,
  pageType: string,
  showCollection: bool,
  showBrandHyperLink: bool,
  hideRatingsIfHDPPSku: bool
};

ProductDetailsBrandCollection.defaultProps = {
  link: false,
  pageType: 'PEP',
  showCollection: false,
  showBrandHyperLink: false,
  hideRatingsIfHDPPSku: false
};

ProductDetailsBrandCollection.dataModel = extend(BrandRating);

export default ProductDetailsBrandCollection;
