/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { useDataModel } from '@thd-nucleus/data-sources';
import { Typography } from '@one-thd/sui-atomic-components';

import ContentSkeleton from '../subcomponents/Skeletons/ContentSkeleton';

import { emtContentDataModel } from '../dataModels';
import { PAGE_NAMES } from '../../constants';

const HomeServicesGuides = ({ storeId }) => {
  const { data, loading, error } = useDataModel('emtContent', {
    variables: {
      pageName: PAGE_NAMES.HOME_SERVICES,
      contentId: storeId,
    }
  });

  if (loading) return <ContentSkeleton />;

  const homeServicesContentResponse = data?.emtContent?.content?.homeServices;
  const isArray = Array.isArray(homeServicesContentResponse);
  const homeServicesGuides = isArray ? homeServicesContentResponse?.[1] : homeServicesContentResponse?.learnMore;

  const header = homeServicesGuides?.header || '';
  const guides = homeServicesGuides?.content || [];

  if (!homeServicesContentResponse || !header || !guides?.length || error) return null;

  return (
    <div className="sui-bg-primary sui-p-4 sui-flex sui-flex-col sui-gap-4">
      <Typography variant="h2" weight="display">{header}</Typography>
      <div className="sui-grid">
        <div className="sui-relative">
          {
            guides.map((guide, index) => (
              <div key={index}>
                <div
                  className="sui-relative sui-mb-4"
                  dangerouslySetInnerHTML={{ __html: guide.text }}
                />
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
};

HomeServicesGuides.propTypes = {
  storeId: PropTypes.string.isRequired
};

HomeServicesGuides.displayName = 'HomeServicesGuides';
HomeServicesGuides.dataModel = emtContentDataModel;

export default HomeServicesGuides;