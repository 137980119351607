import React from 'react';
import PropTypes from 'prop-types';
import {
  params, string, arrayOf, shape, extend, bool
} from '@thd-nucleus/data-sources';
import { WhatWeOffer } from '@thd-olt-component-react/what-we-offer';

/**
 * Props
 *
 * @typedef {object} Props
 * @property {string} itemId The identifier of the element
 * @property {bool} isForMA Whether the component is for MA
 */

/**
 * Returns a formated Product List of the provided data showing title and a related-table
 * @param {Props} Props
 * @returns {React.ReactElement} JSX
 */

const BundleWhatWeOffer = ({ itemId, product, products }) => {

  const { whatWeOffer } = product?.features ?? {};

  const enableWhatWeOfferInProducts = products?.some((prod) => !!prod?.info?.displayWhatWeOffer);

  if (!itemId) {
    return null;
  }

  return (
    <WhatWeOffer
      itemId={itemId}
      productData={{
        info: {
          displayWhatWeOffer: whatWeOffer && enableWhatWeOfferInProducts
        }
      }}
    />
  );
};

BundleWhatWeOffer.propTypes = {
  itemId: PropTypes.string.isRequired,
  product: PropTypes.shape({ features: PropTypes.shape({ whatWeOffer: PropTypes.string.isRequired }) }).isRequired,
  products: PropTypes.arrayOf(PropTypes.shape({
    info: PropTypes.shape({
      displayWhatWeOffer: PropTypes.bool
    })
  })).isRequired
};

BundleWhatWeOffer.propTypes = {
  itemId: PropTypes.string.isRequired
};

BundleWhatWeOffer.displayName = 'BundleWhatWeOffer';

BundleWhatWeOffer.dataModel = extend(
  WhatWeOffer,
  {
    products: params({ itemIds: arrayOf(string().isRequired()).isRequired() }).arrayOf({
      info: shape({
        displayWhatWeOffer: bool()
      })
    })
  }
);

export { BundleWhatWeOffer };
