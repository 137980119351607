import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  params,
  shape,
  string as stringType,
  namedFragment,
  typename,
  customType,
  arrayOf,
  useDataModel,
} from '@thd-nucleus/data-sources';
import './capability_card.scss';
import { CapabilityCardRenderer } from './CapabilityCardRenderer';

const CapabilityCard = ({
  componentId, componentClass, isHorizontal, headline, richText, componentPosition
}) => {
  useEffect(() => { LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('capability_card.ready'); }, []);

  const { data } = useDataModel('component', {
    variables: {
      id: componentId,
      componentClass,
    },
    skip: (!!headline || !!richText),
  });
  const richTextData = richText ?? data?.component?.richTextContent;

  return (
    <CapabilityCardRenderer
      componentId={componentId}
      componentClass={componentClass}
      isHorizontal={isHorizontal}
      componentPosition={componentPosition}
      dataComponent={data?.component}
      headline={headline}
      richTextData={richTextData}
    />
  );
};

CapabilityCard.propTypes = {
  componentId: PropTypes.string,
  componentClass: PropTypes.string,
  isHorizontal: PropTypes.bool,
  richText: PropTypes.string,
  headline: PropTypes.string,
  componentPosition: PropTypes.number
};

CapabilityCard.defaultProps = {
  componentId: undefined,
  componentClass: 'CapabilityCard',
  componentPosition: 1,
  isHorizontal: false,
  richText: undefined,
  headline: undefined,
};

CapabilityCard.displayName = 'CapabilityCard';

// Do not touch
const DamMediaFragment = namedFragment({
  inline: false,
  fragmentType: 'DamMedia',
  fragmentAlias: 'DamMediaV1'
}).shape({
  damContentSelector: shape({
    assetData: arrayOf(
      shape({
        selectedImageUrl: stringType()
      })
    )
  }),
  damDownloadedContent: shape({
    url: stringType()
  }),
  __typename: typename('DamMedia')
});

CapabilityCard.dataModel = {
  component: params({
    id: stringType().isRequired(),
    componentClass: customType('ComponentClass').enum(['CapabilityCard']).isRequired()
  }).shape({
    CapabilityCard: namedFragment({
      inline: true,
      fragmentType: 'CapabilityCard'
    }).shape({
      id: stringType(),
      altText: stringType(),
      previewIcon: DamMediaFragment,
      previewImage: DamMediaFragment,
      headline: stringType(),
      richTextContent: stringType(),
    })
  })
};

export { CapabilityCard };
