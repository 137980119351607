import React from 'react';
import {
  arrayOf, bool, func, node, number, oneOfType, shape, string
} from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';
import { Calculator as CalculatorIcon } from '@one-thd/sui-icons';

export const CalculatorHeader = ({ children }) => (
  <div className="sui-flex sui-flex-col">
    <div className="sui-flex sui-flex-row">
      <CalculatorIcon color="brand" />
      <span className="sui-ml-2">
        <Typography variant="body-lg" weight="bold">How much will you need?</Typography>
      </span>
    </div>
    {children}
  </div>
);

CalculatorHeader.propTypes = {
  children: oneOfType([arrayOf(node), node]),
};

CalculatorHeader.defaultProps = {
  children: null
};

CalculatorHeader.displayName = 'CalculatorHeader';