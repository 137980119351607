import React from 'react';
import { Skeleton, SkeletonContent, SkeletonLine, SkeletonBlock } from '@one-thd/sui-atomic-components';

const BundleMiniPlaceholder = () => {
  return (
    <div
      data-testid="bundle-mini-content-placeholder"
      // eslint-disable-next-line max-len, tailwindcss/no-arbitrary-value
      className="sui-grid sui-align-center sui-bg-primary sui-mb-2 sui-h-[580px] md:sui-h-[640px] lg:sui-h-[380px] lg:sui-mt-2 lg:sui-mb-4 sui-w-full sui-border-solid sui-border-1 sui-border-primary sui-p-4 md:sui-p-6 lg:sui-p-8 "
    >

      <Skeleton>
        <div className="sui-grid sui-grid-cols-1 md:sui-grid-cols-2 sui-w-full sui-gap-4 lg:sui-gap-8">
          <div>
            <SkeletonContent density="loose">
              <SkeletonBlock aspect="wide" height={60} />
            </SkeletonContent>
          </div>

          <div>
            <SkeletonContent density="loose">
              <SkeletonLine numberOfLines={4} />
            </SkeletonContent>
          </div>
        </div>
      </Skeleton>
    </div>
  );

};

BundleMiniPlaceholder.displayName = 'BundleMiniPlaceholder';

export { BundleMiniPlaceholder };
