import React, { useCallback, useRef, useEffect, useContext } from 'react';
import { bool, string, shape, number } from 'prop-types';
import { extend } from '@thd-nucleus/data-sources';
import { Link, Typography } from '@one-thd/sui-atomic-components';
import { ProductDetails } from '@thd-olt-component-react/product-details';
import { Sharebelt } from '@thd-olt-component-react/thd-sharebelt';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { BundleShare } from './BundleShare';
import { ratingToggleFromProductOverview, getIsMobile } from '../utils/product-bundle-utils';
/**
 * Props
 *
 * @typedef {object} Props
 * @property {itemId} itemId Item Id
 * @property {hideRatingsIfHDPPSku} hideRatingsIfHDPPSku hideRatingsIfHDPPSku
 * @property {ratingIndex} ratingIndex index of rating and review accordion
 * @property {qaIndex} qaIndex index of question and answer accordion
 * @property {useExternalRef} useExternalRef if true, use fusion logic to scroll to the accordion
 * @property {target} target target accordion to expand
 * @property {offsetselector} offsetselector offsetselector
 * @property {{bundleSpecificationDetails: object}} product Bundle product
 * @property {qaselector} qaselector Id of question and answer accordion
 */

/**
 * Returns a component, Bundle Product Overview
 * @param {Props} Props
 * @returns {React.ReactElement} JSX
 */

export const BundleProductOverview = ({
  product,
  itemId,
  hideRatingsIfHDPPSku,
  ratingIndex = 3,
  qaIndex = 2,
  bypassInternalRef
}) => {

  const { starRatingProductOverview, brand, brandUrl } = product?.features ?? {};

  const qaSelector = '#product-section-qa';
  const reviewTarget = '#product-section-rr';
  const setExpandedRef = useRef();
  const ratingsRef = useRef();
  const qaRef = useRef();
  const experienceContext = useContext(ExperienceContext);
  const { channel } = experienceContext;
  const isMobile = getIsMobile(channel);
  const marginClass = isMobile ? 'sui-ml-2' : 'sui-ml-4';

  const onClick = useCallback((evt, target, index) => {
    if (!bypassInternalRef) {
      ratingToggleFromProductOverview({ accordionId: evt });
    } else {
      window.LIFE_CYCLE_EVENT_BUS.trigger('product-details.ratings-click');
      let element = ratingsRef.current;
      let targetIndex = ratingIndex || index;
      if (target === qaSelector) {
        element = qaRef.current;
        targetIndex = qaIndex;
      }
      if (setExpandedRef.current) {
        setExpandedRef.current(targetIndex)(evt, true);
      }
      const top = element.getBoundingClientRect().top + window.scrollY - 125;
      window.scroll({
        top,
        behavior: 'smooth'
      });
    }
  }, []);

  useEffect(() => {
    window.LIFE_CYCLE_EVENT_BUS.lifeCycle.on('accordion.mount', ({ output }) => {
      const { wrapperIds, setExpanded } = output;
      setExpandedRef.current = setExpanded;
      ratingsRef.current = document.querySelector('#' + wrapperIds[ratingIndex]);
      qaRef.current = document.querySelector('#' + wrapperIds[qaIndex]);
    });

  }, []);

  return (
    <div data-component="BundleProductOverview" data-testid="BundleProductOverview">
      <ProductDetails itemId={itemId}>
        <ProductDetails.Badge itemId={itemId} />
        <div className="sui-flex sui-items-start sui-flex-col sui-w-5/6">
          <div className="sui-pr-2 sui-inline-flex">
            {brandUrl ? (
              <Link
                href={brandUrl}
                target="_blank"
                rel="noopener noreferrer"
                underline="none"
              >
                <Typography variant="body-base" component="h2">{brand}</Typography>
              </Link>
            ) : (
              <Typography variant="body-base" component="h2">{brand}</Typography>
            )}
          </div>
          <ProductDetails.Title />
        </div>
        <div className="sui-absolute sui-right-1 sui-top-0">
          <Sharebelt itemId={itemId}>
            <BundleShare itemId={itemId} showCircle showFavoritesCount />
          </Sharebelt>
        </div>
        {starRatingProductOverview && (
          <div data-testid="BundleProductDetailsRatings" className={marginClass}>
            <ProductDetails.Ratings
              showBadge
              target={reviewTarget}
              offsetselector="#sticky-nav"
              qaselector={qaSelector}
              onClick={onClick}
              hideRatingsIfHDPPSku={hideRatingsIfHDPPSku}
              useHeaderIndex={bypassInternalRef && (!!(ratingIndex || qaIndex))}
            />
          </div>
        )}
      </ProductDetails>
    </div>
  );
};

BundleProductOverview.propTypes = {
  hideRatingsIfHDPPSku: bool,
  product: shape({
    bundleSpecificationDetails: shape({
      type: string
    })
  }),
  itemId: string.isRequired,
  ratingIndex: number,
  qaIndex: number,
  bypassInternalRef: bool
};

BundleProductOverview.defaultProps = {
  hideRatingsIfHDPPSku: false,
  product: undefined,
  ratingIndex: undefined,
  qaIndex: undefined,
  bypassInternalRef: false
};

BundleProductOverview.dataModel = extend(ProductDetails, Sharebelt);

BundleProductOverview.displayName = 'BundleProductOverview';
