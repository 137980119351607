/* eslint-disable tailwindcss/no-arbitrary-value */
import React, { useEffect } from 'react';
import { bool, number as numberType, string as stringType } from 'prop-types';
import {
  arrayOf,
  customType,
  namedFragment,
  typename,
  params,
  shape,
  string,
  useDataModel,
} from '@thd-nucleus/data-sources';
import { useImpression } from '@thd-olt-component-react/impression';
import { withImpressions } from '../hocs/withImpressions';
import { PiccolaComposableBannerRenderer } from './PiccolaComposableBannerRenderer';

const PiccolaComposableBanner = withImpressions(({ componentClass, componentId, componentPosition, lazyLoad }) => {
  const { ref, clickID } = useImpression({
    data: {
      id: componentId,
      name: PiccolaComposableBanner.displayName,
      component: PiccolaComposableBanner.displayName,
      position: componentPosition,
      type: 'content'
    }
  });

  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('piccola-composable-banner.ready');
  }, []);

  const { data, loading, error } = useDataModel('component', {
    variables: {
      componentClass,
      id: componentId
    }
  });

  if (!data || loading || error) {
    return null;
  }

  return (
    <div
      ref={ref}
      // eslint-disable-next-line react/no-unknown-property
      clickid={clickID}
      data-component-id={componentId}
      data-component-name="PiccolaComposableBanner"
      data-component-position={componentPosition}
      data-component="PiccolaComposableBanner"
      id={`${componentClass}-${componentPosition}-${componentId}`}
    >
      <PiccolaComposableBannerRenderer data={data.component} lazyLoad={lazyLoad} />
    </div>
  );
});

PiccolaComposableBanner.propTypes = {
  componentClass: stringType,
  componentId: stringType.isRequired,
  componentPosition: numberType,
  lazyLoad: bool,
};

PiccolaComposableBanner.defaultProps = {
  componentClass: '',
  componentPosition: 1,
  lazyLoad: false,
};

PiccolaComposableBanner.displayName = 'PiccolaComposableBanner';

// Do not touch
const DamMediaFragment = namedFragment({
  inline: false,
  fragmentType: 'DamMedia',
  fragmentAlias: 'DamMediaV1',
}).shape({
  damContentSelector: shape({
    assetData: arrayOf(
      shape({
        selectedImageUrl: string(),
      })
    ),
  }),
  damDownloadedContent: shape({
    url: string(),
  }),
  id: string(),
  __typename: typename('DamMedia'),
});

PiccolaComposableBanner.dataModel = {
  component: params({
    id: string().isRequired(),
    componentClass: customType('ComponentClass').enum(['PiccolaComposableBanner']).isRequired(),
  }).shape({
    PiccolaComposableBanner: namedFragment({
      inline: true,
      fragmentType: 'PiccolaComposableBanner',
    }).shape({
      id: string(),
      title: string(),
      subtitle: string(),
      destinationUrl: string(),
      smallMedia: DamMediaFragment,
      altText: string(),
      legalCopy: shape({
        legalText: string(),
        legalUrl: string(),
      }),
    }),
  }),
};

export { PiccolaComposableBanner };
