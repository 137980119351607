import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import { Typography } from '@one-thd/sui-atomic-components';

const componentOverrides = (defaultVariant) => ({
  // eslint-disable-next-line id-length
  a(props) {
    return <a className="sui-underline hover:sui-underline" href={props.href}>{props.children}</a>;
  },
  // eslint-disable-next-line id-length
  p(props) {
    return (
      <div className="sui-pb-5 sui-whitespace-pre-wrap">
        <Typography paragraph>{props.children}</Typography>
      </div>
    );
  },
  h1(props) {
    return (
      <div className="sui-my-4 sui-mx-0">
        <Typography component="h1" variant={defaultVariant ? 'h1' : 'h5'}>{props.children}</Typography>
      </div>
    );
  },
  h2(props) {
    return (
      <div className="sui-my-4 sui-mx-0">
        <Typography component="h2" variant={defaultVariant ? 'h2' : 'h5'}>{props.children}</Typography>
      </div>
    );
  },
  h3(props) {
    return (
      <div className="sui-my-4 sui-mx-0">
        <Typography component="h3" variant={defaultVariant ? 'h3' : 'h5'}>{props.children}</Typography>
      </div>
    );
  },
  ul(props) {
    return <ul className="sui-mb-5">{props.children}</ul>;
  },
  ol(props) {
    return <ol className="sui-mb-5 sui-py-2 sui-ml-4 sui-pl-4 sui-list-decimal">{props.children}</ol>;
  },
  li(props) {
    return <Typography component="li">{props.children}</Typography>;
  }
});

export const EmtDescription = ({ title, description, useDefaultFontStylesForHeadings }) => {

  const markDownComponents = componentOverrides(useDefaultFontStylesForHeadings);

  return (
    <div data-component="EmtDescription">
      <Typography variant="h3" color="primary" weight="bold">{title}</Typography>
      <div className="sui-line-clamp-unset sui-my-4 sui-mx-0 sui-order-1 sui-flex-none sui-grow-0">
        <ReactMarkdown components={markDownComponents}>
          {description}
        </ReactMarkdown>
      </div>
    </div>
  );
};

EmtDescription.displayName = 'EmtDescription';

EmtDescription.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  useDefaultFontStylesForHeadings: PropTypes.bool
};

EmtDescription.defaultProps = {
  useDefaultFontStylesForHeadings: false
};