import React from 'react';
import {
  shape,
  string,
  bool,
  number,
  arrayOf
} from 'prop-types';
import { BackorderedAlert } from '../../alerts/BackorderedAlert';

const DefaultBoSSDetails = ({
  fulfillment,
  backordered,
  quantity,
  maxInventoryAvail,
  isCartTiles
}) => {

  return (
    <>
      {backordered && <BackorderedAlert />}
    </>
  );
};

DefaultBoSSDetails.propTypes = {
  fulfillment: shape({
    type: string,
    locations: arrayOf(shape({
      isAnchor: bool,
      inventory: shape({
        quantity: number,
        isInStock: bool,
        isLimitedQuantity: bool,
        isBackordered: bool
      })
    }))
  }).isRequired,
  backordered: bool,
  quantity: number,
  maxInventoryAvail: number,
  isCartTiles: bool
};

DefaultBoSSDetails.defaultProps = {
  backordered: false,
  quantity: 1,
  maxInventoryAvail: 0,
  isCartTiles: false
};

export { DefaultBoSSDetails };
