import React from 'react';
import { DefaultShipping } from '../partials/shipping/DefaultShipping';
import { FULFILLMENT_METHODS } from '../../components/constants';
import {
  getLiveGoodsStock,
  isApplianceDeliveryError,
  isApplianceDeliveryOutOfStock,
  isApplianceDeliveryUnavailable
} from '../../components/helper/utils';
import {
  applianceDeliveryThreshold,
  getApplianceEarliestDeliveryDate,
  getApplianceEarliestDeliveryDateFromSearch,
  getApplianceInventory,
  getBODFSFulfillment,
  getDeliveryService,
  getDisplayableInventory,
  getExcludedStateSth,
  getSTHFulfillment,
  getShippingThreshold,
  getStateCode,
  hasFreeShipping,
  isAppliance,
  isBODFS,
  isBackordered,
  isBackorderedWithDate,
  isBodfsWithQuantityAvailable,
  isBodfsWithQuantityUnknown,
  isDiscontinued,
  isItemGCC,
  isSTH,
  isStateExcluded,
  isSthOutOfStock,
  meetsApplianceDeliveryThreshold
} from './pod-fulfillment-utils';
import { dataModel } from '../../components/dataModel';
import { EmptyShippingMessage } from '../partials/shipping/EmptyShippingMessage';

export const getApplianceScenarios = ({
  availabilityData,
  checkAvailabilityButton,
  customRender,
  enableApplianceDeliveryCharge,
  enableFreeDeliveryForExchange,
  isExchangeCustomer,
  isPuertoRicoZip,
  product
}) => {
  const earliestDeliveryDate = !isPuertoRicoZip
    ? getApplianceEarliestDeliveryDateFromSearch(product)
    : null;

  let meetsThreshold = false;
  let shippingMessage = checkAvailabilityButton;
  let shippingMessageStyle = 'sui-text-success';
  let shippingThreshold = null;
  if (isExchangeCustomer) {
    meetsThreshold = !!enableFreeDeliveryForExchange;
  } else {
    meetsThreshold = meetsApplianceDeliveryThreshold(product?.pricing);
    // FEATURE SWITCH OVERRIDE
    if (!!enableApplianceDeliveryCharge) {
      shippingMessageStyle = 'sui-text-primary';
    }
  }

  if (!meetsThreshold) {
    shippingThreshold = applianceDeliveryThreshold();
  }

  if (availabilityData || earliestDeliveryDate) {
    shippingMessage = meetsThreshold ? 'Free' : '';
    const deliveryAvailability =
      availabilityData?.DeliveryAvailabilityResponse?.deliveryAvailability;
    const showUnavailable = [
      isApplianceDeliveryOutOfStock,
      isApplianceDeliveryUnavailable,
      isApplianceDeliveryError
    ].some((helper) => helper(deliveryAvailability));

    if (showUnavailable) {
      return () => (
        <DefaultShipping
          iconType="close"
          shippingMessage="Unavailable"
          product={product}
          shippingThreshold={shippingThreshold}
          shippingMessageStyle="sui-text-subtle"
        />
      );
    }
    return () => (
      <DefaultShipping
        iconType="success"
        applianceDeliveryDate={
          getApplianceEarliestDeliveryDate(availabilityData) || earliestDeliveryDate
        }
        shippingMessage={enableApplianceDeliveryCharge ? 'Available' : shippingMessage}
        shippingMessageStyle={shippingMessageStyle}
        shippingThreshold={shippingThreshold}
        product={product}
        isAppliance
      />
    );
  }

  return () => (
    <DefaultShipping
      iconType="success"
      shippingMessageStyle={shippingMessageStyle}
      shippingMessage={shippingMessage}
      customRender={customRender}
      applianceQuantity={getApplianceInventory(product)}
      product={product}
      shippingThreshold={shippingThreshold}
      isAppliance={typeof shippingMessage !== 'object'}
    />
  );
};

const getSthOnlyScenarios = ({ customRender, excludedState, hasSameDayFilter, product }) => {
  if (hasSameDayFilter) {
    return null;
  }
  if (excludedState) {
    return () => (
      <DefaultShipping
        excludedState={excludedState}
        customRender={customRender}
        product={product}
      />
    );
  }
  if (isBackorderedWithDate(product) || isBackordered(product)) {
    return () => (
      <DefaultShipping
        iconType="success"
        shippingMessage="Standard"
        customRender={customRender}
        product={product}
      />
    );
  }
  if (isSthOutOfStock(product)) {
    return () => (
      <DefaultShipping
        shippingMessage="Unavailable"
        customRender={customRender}
        product={product}
        shippingMessageStyle="sui-text-subtle"
      />
    );
  }
};

const getMixedScenarios = ({
  bodfsFulfillment,
  customRender,
  customer,
  hasSameDayFilter,
  product,
  sthFulfillment
}) => {
  if (!isSTH(product)) {
    return null;
  }
  const bodfsDisplayInventory = getDisplayableInventory(product, customer, FULFILLMENT_METHODS.BODFS);
  const sthDisplayInventory = getDisplayableInventory(product, customer, FULFILLMENT_METHODS.STH);
  const shippingThreshold = getShippingThreshold(product);
  const excludedState = getExcludedStateSth(product);

  if (isBODFS(product) && (excludedState || hasSameDayFilter)) {
    return () => (
      <DefaultShipping
        iconType="success"
        shippingMessage="Scheduled"
        displayQuantity={bodfsDisplayInventory}
        customRender={customRender}
        product={product}
        deliveryMessage={bodfsFulfillment?.deliveryMessage}
        hasSameDayFilter={!!hasSameDayFilter}
      />
    );
  }

  const sthScenario = getSthOnlyScenarios({
    customRender,
    excludedState,
    hasSameDayFilter,
    product
  });

  if (sthScenario) {
    return sthScenario;
  }

  if (isBODFS(product) || !hasSameDayFilter) {
    if (hasFreeShipping(product)) {
      return () => (
        <DefaultShipping
          iconType="success"
          shippingMessageStyle="sui-text-success"
          shippingMessage="Free"
          displayQuantity={sthDisplayInventory}
          customRender={customRender}
          product={product}
          deliveryMessage={sthFulfillment?.deliveryMessage}
        />
      );
    }
    if (typeof shippingThreshold === 'number') {
      const shippingMessage =
        shippingThreshold > 0 ? `Free with $${shippingThreshold} order` : 'Free';
      return () => (
        <DefaultShipping
          iconType="success"
          shippingMessageStyle="sui-text-success"
          shippingMessage={shippingMessage}
          shippingThreshold={shippingThreshold}
          displayQuantity={sthDisplayInventory}
          customRender={customRender}
          product={product}
          deliveryMessage={sthFulfillment?.deliveryMessage}
        />
      );
    }
    return () => (
      <DefaultShipping
        iconType="success"
        shippingMessage="Standard"
        displayQuantity={sthDisplayInventory}
        customRender={customRender}
        product={product}
        deliveryMessage={sthFulfillment?.deliveryMessage}
        hasSameDayFilter={hasSameDayFilter}
      />
    );
  }
};

const getBodfsScenarios = ({ bodfsFulfillment, customRender, customer, product }) => {
  if (!isBODFS(product)) {
    return null;
  }
  if (isBodfsWithQuantityUnknown(product) || !isBodfsWithQuantityAvailable(product)) {
    return () => (
      <DefaultShipping
        shippingMessage="Unavailable"
        customRender={customRender}
        product={product}
      />
    );
  }
  return () => (
    <DefaultShipping
      iconType="success"
      shippingMessage="Scheduled"
      displayQuantity={getDisplayableInventory(product, customer, FULFILLMENT_METHODS.BODFS)}
      customRender={customRender}
      product={product}
      deliveryMessage={bodfsFulfillment?.deliveryMessage}
    />
  );
};

const getUnavailableScenarios = ({ customRender, product }) => {
  /* -----not STH and not BODFS means no delivery to customer directly----- */
  if (isDiscontinued(product)) {
    return () => <EmptyShippingMessage customRender={customRender} product={product} />;
  }
  return () => (
    <DefaultShipping
      shippingMessage="Unavailable"
      customRender={customRender}
      product={product}
      shippingMessageStyle="sui-text-subtle"
    />
  );
};

const BundleFulfillmentMessage = () => (
  <span data-component="FulfillmentPodShipping" className="sui-leading-tight">
    <div data-component="bundlePodMessageDelivery" className="sui-flex sui-mt-2">
      <div className="sui-pr-2 sui-mt-2">
        {AvailableIcon}
      </div>
      <p className="sui-font-regular sui-text-sm sui-tracking-normal sui-normal-case sui-line-clamp-unset sui-font-normal sui-text-primary">
        <div className="sui-pt-1">
          <span className="sui-font-bold">Delivery</span>
          <Typography variant="body-sm">
            <div data-testid="fulfillment-bundle-messsage">
              Details available in Cart
            </div>
          </Typography>
        </div>
      </p>
    </div>
  </span>
);

export const getShippingTemplate = ({
  availabilityData,
  product,
  customer,
  customRender,
  checkAvailabilityButton,
  isPuertoRicoZip,
  enableApplianceDeliveryCharge,
  enableFreeDeliveryForExchange,
  isExchangeCustomer,
  hasSameDayFilter = false,
  bundleFlag,
  showBundleFulfillmentMessage
}) => {
  const isGCC = isItemGCC(product);

  if (!product || isGCC) {
    return () => (
      <EmptyShippingMessage isGCC={isGCC} customRender={customRender} product={product} />
    );
  }
  /* -----MA Bundle----- */
  // Note, this is a temporary fix to show the bundle fulfillment message for Major Appliances. There is more backend work needed before we can remove.
  if (bundleFlag && showBundleFulfillmentMessage) {
    return <BundleFulfillmentMessage />;
  }

  /* -----LiveGoods----- */
  if (getLiveGoodsStock(product?.fulfillment) === 0) {
    return () => <EmptyShippingMessage customRender={customRender} product={product} />;
  }

  if (isDiscontinued(product)) {
    return () => (
      <DefaultShipping
        shippingMessage="No longer available"
        customRender={customRender}
        product={product}
        shippingMessageStyle="sui-text-subtle"
      />
    );
  }

  /* -----Appliance----- */
  if (isAppliance(product)) {
    return getApplianceScenarios({
      availabilityData,
      checkAvailabilityButton,
      customRender,
      enableApplianceDeliveryCharge,
      enableFreeDeliveryForExchange,
      isExchangeCustomer,
      isPuertoRicoZip,
      product
    });
  }

  if (!getDeliveryService(product)) {
    const zipcodeStateCode = getStateCode(null, 'sth');
    const excludedStateName = isStateExcluded(zipcodeStateCode, 'sth', product?.fulfillment);
    if (excludedStateName) {
      return () => (
        <DefaultShipping
          excludedState={excludedStateName}
          customRender={customRender}
          product={product}
        />
      );
    }
  }

  const bodfsFulfillment = getBODFSFulfillment(product);
  const sthFulfillment = getSTHFulfillment(product);

  return (
    getMixedScenarios({
      bodfsFulfillment,
      customRender,
      customer,
      hasSameDayFilter,
      product,
      sthFulfillment
    }) ||
    getBodfsScenarios({ bodfsFulfillment, customRender, customer, product }) ||
    getUnavailableScenarios({ customRender, product })
  );
};

getShippingTemplate.dataModel = dataModel;
