import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { QuantityPicker } from '@one-thd/sui-atomic-components';

const Quantity = ({
  onChange,
  value,
  minimumQuantity,
  maximumQuantity,
  limitPerOrder,
  minimumMessage,
  maximumMessage,
  fullWidth,
  disabled,
  helpMessage,
  statusMessage,
  disableDefaultStatus,
}) => {
  const [quantity, setQuantity] = useState(() => Math.min(value, 9999));
  const [isUserInput, setIsUserInput] = useState(false);

  const maxQuantity = Math.min(maximumQuantity > 0 ? maximumQuantity : 9999, 9999);

  useEffect(() => {
    setQuantity(value > 9999 ? 9999 : value);
  }, [value]);

  const onQuantityChange = (event) => {
    let newQuantity = event.target ? event.target.value : event;
    if (newQuantity === '0') {
      newQuantity = minimumQuantity;
    }

    if (newQuantity.length > 4) {
      newQuantity = newQuantity.substring(0, 4);
    }

    setQuantity(newQuantity);
    setIsUserInput(true);
    onChange({ quantity: newQuantity });
  };

  useEffect(() => {
    const checkQuantity = () => {

      let validatedQuantity = Math.max(quantity, minimumQuantity);
      validatedQuantity = Math.min(validatedQuantity, limitPerOrder > 0 ? limitPerOrder : validatedQuantity);

      if (validatedQuantity !== quantity) {
        setQuantity(validatedQuantity);
        if (!isUserInput) {
          onChange({ quantity: validatedQuantity });
        }
      }
    };

    const intervalId = setInterval(checkQuantity, 1000);

    return () => clearInterval(intervalId);
  }, [quantity, minimumQuantity, limitPerOrder, onChange, isUserInput]);

  useEffect(() => {
    if (isUserInput) {
      const timeoutId = setTimeout(() => {
        setIsUserInput(false);
      }, 500);

      return () => clearTimeout(timeoutId);
    }
    return undefined;
  }, [quantity, isUserInput]);

  const onBlur = () => {
    if (quantity === '' || quantity < minimumQuantity) {
      setQuantity(minimumQuantity);
      onChange({ quantity: minimumQuantity });
    }
  };

  return (
    <div data-component="Quantity" data-testid="quantity-picker">
      <QuantityPicker
        fullWidth={fullWidth}
        disabled={disabled}
        min={minimumQuantity}
        max={maxQuantity}
        value={quantity}
        onChange={onQuantityChange}
        onBlur={onBlur}
        disableDefaultStatus={disableDefaultStatus}
        statusMessage={statusMessage}
        helpMessage={helpMessage}
        minimumMessage={minimumMessage}
        maximumMessage={maximumMessage}
      />
    </div>
  );
};

Quantity.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  minimumQuantity: PropTypes.number,
  maximumQuantity: PropTypes.number,
  limitPerOrder: PropTypes.number,
  minimumMessage: PropTypes.string,
  maximumMessage: PropTypes.string,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  helpMessage: PropTypes.string,
  statusMessage: PropTypes.string,
  disableDefaultStatus: PropTypes.bool,
};

Quantity.defaultProps = {
  onChange: () => {},
  value: 1,
  minimumQuantity: 1,
  maximumQuantity: 9999,
  limitPerOrder: 0,
  minimumMessage: '',
  maximumMessage: '',
  fullWidth: false,
  disabled: false,
  helpMessage: '',
  statusMessage: '',
  disableDefaultStatus: false,
};

export default Quantity;
