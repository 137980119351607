import React from 'react';
import PropTypes from 'prop-types';
import { json, params, string, useDataModel } from '@thd-nucleus/data-sources';

const getCurrentPromo = (data, pageName) => {
  const promotions = data?.emtContent?.content?.storeFinderPromotions || {};
  const today = new Date();
  const currentPromo = Object.values(promotions).find((promo) => {
    const { starts, expires, pageExemptions } = promo || {};
    if (!starts || !expires) {
      return false;
    }
    return new Date(starts) <= today && today < new Date(expires) && !pageExemptions?.includes(pageName);
  });

  return currentPromo || {};
};

const PromoPod = ({ contentId, pageName }) => {
  const { loading, data, error } = useDataModel(
    'emtContent', { variables: { contentId, pageName } }
  );

  if (!data || !data?.emtContent?.content?.storeFinderPromotions || loading || error) {
    return null;
  }

  const {
    description, dateText, eventDetailsUrl, image, title
  } = getCurrentPromo(data, pageName);

  if (!description || !dateText || !eventDetailsUrl || !title || !image || !image.source) {
    return null;
  }

  return (
    <div
      data-component="PromoPod"
      className="sui-paper sui-paper-outlined sui-rounded-base sui-flex sui-bg-brand sui-text-inverse"
    >
      <a
        className={`sui-lab-btn-base sui-block sui-text-inherit sui-w-full sui-h-full
        focus-visible:sui-outline-button-focus focus-visible:sui-outline-4
        focus-visible:sui-outline-offset-0 hover:sui-shadow-lg hover:sui-no-underline`}
        href={eventDetailsUrl}
      >
        <div
          className={`sui-flex sui-h-full sui-overflow-hidden
          sui-flex-col-reverse sm:sui-flex-row sui-justify-between`}
        >
          <div className="sui-flex sui-flex-col md:sui-flex-row sui-w-full sui-basis-3/4 sui-p-4 sui-gap-4">
            <div className="sui-w-full sui-flex sui-flex-col sui-gap-2 sui-pl-2">
              <h2
                className={`sui-font-w-bold sui-text-3xl sm:sui-text-4xl
                md:sui-text-5xl lg:sui-text-6xl sui-line-clamp-3`}
              >
                {title.toUpperCase()}
              </h2>
              <div className="">
                <h3
                  className={`sui-font-regular sui-text-base md:sui-text-lg
                  sui-tracking-normal sui-normal-case sui-line-clamp-unset`}
                >
                  {description}
                </h3>
              </div>
              <div className="sui-flex sui-flex-col sui-justify-end sui-grow">
                <h3 className="sui-text-xs sui-line-clamp-unset">
                  {dateText.toUpperCase()}
                </h3>
              </div>
            </div>
            <div className="sui-flex sui-items-center sui-pl-2 md:sui-pl-0">
              <p className={`sui-font-regular sui-text-base md:sui-text-lg
                sui-whitespace-nowrap sui-border-white sui-border-solid sui-border-b-2`}
              >
                Shop Now
              </p>
            </div>
          </div>
          <div
            className={`sui-flex sui-overflow-hidden sui-flex-col sui-basis-1/3
            md:sui-basis-1/3 lg:sui-basis-1/4 sui-border sui-border-accent-strong`}
          >
            <img
              className="sui-object-cover sui-h-full sui-w-full sui-aspect-video"
              width="480"
              height="270"
              src={image.source}
              alt={image.altDescription}
            />
          </div>
        </div>
      </a>
    </div>
  );
};

PromoPod.dataModel = {
  emtContent: params({
    contentId: string().isRequired(),
    pageName: string().isRequired()
  }).shape({
    content: json()
  })
};

PromoPod.propTypes = {
  contentId: PropTypes.string.isRequired,
  pageName: PropTypes.oneOf(['storeDetails', 'gardenCenter', 'rentals', 'lcp']).isRequired
};

PromoPod.displayName = 'PromoPod';

export default PromoPod;