import { createSelector } from 'reselect';
import * as errConstants from '../../constants/errGroupConstants';

const getError = (errors, name, type) => {

  return {
    name,
    type,
    errorMessages: Array.isArray(errors) ? errors.map((x) => x.errorMessage) : [errors.errorMessage],
  };
};

export const errorGroupsSelector = createSelector(
  (state) => state.errorGroups,
  (errorGroups) => {

    const errors = [];
    const generalErrors = errorGroups.filter((x) => x.statusCode >= 500 && x.statusCode < 600);
    if (generalErrors.length > 0) {
      const generalError = getError(generalErrors, errConstants.GROUP_GENERAL, errConstants.TYPE_GENERAL);
      errors.push(generalError);
    }
    const errNames = generalErrors.map((x) => x.name);
    const otherErrors = errorGroups.filter((x) => !errNames.includes(x.name));

    otherErrors.forEach((x) => {
      if (x.name.startsWith(errConstants.GROUP_OPTIONS_CHOICES)) {
        const error = errors.find((y) => y.name === errConstants.GROUP_OPTIONS_CHOICES);
        if (error) {
          error.errorMessages.push(x.errorMessage);
        } else {
          errors.push(getError(x, errConstants.GROUP_OPTIONS_CHOICES, errConstants.TYPE_CONFIGURATION));
        }
      } else {
        errors.push(getError(x, x.name, errConstants.TYPE_CONFIGURATION));
      }
    });

    return errors;
  });
