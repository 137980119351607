import React, { useContext } from 'react';
import { func } from 'prop-types';
import { Image } from '@thd-olt-component-react/core-ui';
import { Button } from '@thd-olt-component-react/button';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import './compare-page-header.scss';

const ComparePageHeader = ({ onBack, navigate }) => {
  const { channel } = useContext(ExperienceContext);

  // eslint-disable-next-line consistent-return
  const handleClick = (evt) => {
    if (onBack) {
      return onBack(evt);
    }

    return navigate(-1);
  };

  return (
    <div className="compare-page-header" data-component="ComparePageHeader">
      <Button
        link
        className="compare-page-header__back-button"
        onClick={handleClick}
        role="button"
        aria-label="Back to Product List"
      >
        {channel !== 'desktop'
          ? (
            <span>
              <Image
                className="compare-page-header__caret-image"
                src="https://assets.thdstatic.com/images/v1/back-arrow-orange.svg"
                alt="Back"
                height={50}
                width={50}
              />
            </span>
          )
          : ''}
        Back to Product List
      </Button>
      {channel === 'desktop'
        ? (
          <Button link className="compare-page-header__print-button" onClick={() => { window.print(); }}>
            <span>
              <Image
                className="compare-page-header__print-image"
                src="https://assets.thdstatic.com/images/v1/print-icon.svg"
                alt="Print"
                height={50}
                width={50}
              />
            </span>
            Print
          </Button>
        )
        : ''}
    </div>
  );
};

ComparePageHeader.displayName = 'ComparePageHeader';

ComparePageHeader.propTypes = {
  onBack: func.isRequired,
  navigate: func.isRequired,
};

export { ComparePageHeader };
