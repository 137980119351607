import React, {
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import {
  bool,
  func,
  string
} from 'prop-types';
import {
  Drawer, DrawerBody, DrawerHeader, DrawerFooter, Typography,
  useBreakpoint, Tile, TileContent, TileMedia, TileText
} from '@one-thd/sui-atomic-components';
import { ExperienceContext, useStore } from '@thd-nucleus/experience-context';
import { AddToCart } from '@thd-olt-component-react/add-to-cart';
import { getCents, getDollars } from '../../helpers';
import { SampleTotal } from './components/SampleTotal';
import { TraySample } from './components/TraySample';
import { useMultipleSamples } from '../../hooks/useMultipleSamples';

const SampleDrawer = ({
  anchorSampleId,
  onClose,
  onSampleData,
  open,
  parentId,
}) => {
  const { channel } = useContext(ExperienceContext);
  const { storeZip: zipCode } = useStore();

  const [traySamples, setTraySamples] = useState([]);
  const [subtotal, setSubtotal] = useState(0);
  const [variant, setVariant] = useState('body-base');
  const touchDevice = useRef();

  const response = useMultipleSamples({ parentId });

  const { data: samplesData, loading: samplesLoading } = response;

  const mdBreakpoint = useBreakpoint('md');
  const lgBreakpoint = useBreakpoint('lg');

  useEffect(() => {
    if (document && document.documentElement) {
      touchDevice.current = 'ontouchstart' in document.documentElement;
    }
    
    if (samplesData?.length) {
      const defaultSample = samplesData.find((sample) => sample.itemId === anchorSampleId) || samplesData[0];
      const sampleInStock = !!defaultSample?.inStockStatus;

      if (sampleInStock) {
        setTraySamples([defaultSample]);
        if (defaultSample.price) {
          setSubtotal(defaultSample.price);
        }
      }
    }
    
    onSampleData({ loading: samplesLoading, orderSamples: samplesData });

    if ((mdBreakpoint?.exact || mdBreakpoint?.greater) && lgBreakpoint?.lesser) {
      setVariant('body-xs');
    }

    // remove selected samples on unmount
    return () => {
      setTraySamples([]);
    };
  }, [samplesLoading]);

  const handleAddToCartClick = () => onClose();

  const computeSubtotal = (amount) => {
    const calculatedSubtotal = amount + subtotal;
    setSubtotal(Math.abs(calculatedSubtotal));
  };

  const addSampleToTray = (sample, amount) => {
    setTraySamples([...traySamples, sample]);
    computeSubtotal(amount);
  };

  const removeSampleFromTray = (sample, amount) => {
    const { itemId } = sample;
    const updatedTray = traySamples.filter((samp) => samp.itemId !== itemId);
    setTraySamples(updatedTray);
    computeSubtotal(-amount);
  };

  const handleSampleClick = (sample) => {
    const { itemId, price } = sample;
    const sampleInTray = traySamples.find((samp) => samp.itemId === itemId);
    if (!sampleInTray) {
      addSampleToTray(sample, price);
    } else {
      removeSampleFromTray(sample, price);
    }
  };

  const getCartReqParams = (samples) => samples.map((sample) => {
    return {
      fulfillmentMethod: 'ShipToHome',
      fulfillmentLocation: zipCode,
      itemId: sample?.itemId,
      quantity: 1
    };
  });

  const renderTraySamples = (samples) => samples.map((sample, sampleIndex) => {
    const { imgUrl, price } = sample;

    return (
      <div key={`tray-sample-${sampleIndex}`}>
        <TraySample
          onClick={() => removeSampleFromTray(sample, price)}
          imageUrl={imgUrl}
        />
      </div>
    );
  });

  const renderInStockScenario = (amount) => (
    <TileText align="left" variant="body-base" color="subtle">
      {amount < 1
        ? <span>{getCents(amount)}&cent;</span>
        : <span>${getDollars(amount)}</span>}
    </TileText>
  );

  const otherScenarioMessage = (message) => (
    <TileText variant={variant} color={(message === 'Discontinued' ? 'danger' : 'primary')}>
      {message}
    </TileText>
  );

  const renderSamples = (samples) => samples.map((sample, sampleIndex) => {
    let scenario;
    const {
      name,
      imgUrl,
      discontinued,
      inStockStatus,
      itemId,
      price,
    } = sample;

    const imageElements = imgUrl.split('/');
    if (imageElements.find((element) => element.includes('No_Image'))) {
      return null;
    }
    const buyable = inStockStatus && !discontinued;

    if (buyable) {
      scenario = renderInStockScenario(price);
    } else if (discontinued) {
      scenario = otherScenarioMessage('Discontinued');
    } else {
      scenario = otherScenarioMessage('Out of Stock');
    }

    const selected = traySamples.some((samp) => samp.itemId === itemId);

    return (
      <Tile
        key={sampleIndex}
        selected={selected}
        onClick={buyable ? () => handleSampleClick(sample) : null}
        unavailable={!buyable}
      >
        <TileContent orientation="vertical" alignItems="start">
          <TileMedia
            src={imgUrl}
            width="300px"
          />
          <TileText align="left">{name}</TileText>
          {scenario}
        </TileContent>
      </Tile>
    );
  });

  if (!samplesData?.length) {
    return null;
  }
  
  return (
    <Drawer
      open={open}
      onClose={onClose}
      position="right"
    >
      <DrawerHeader
        title="Choose Samples"
        onClose={onClose}
      />
      <DrawerBody>
        <div className="sui-grid sui-grid-cols-2 sui-gap-4">
          {renderSamples(samplesData)}
        </div>
      </DrawerBody>
      <DrawerFooter>
        <SampleTotal textVariant={variant} sampleTotal={traySamples?.length} subtotal={subtotal} />
        <div
          className={
            'sui-flex sui-flex-row sui-h-20 sui-items-center sui-mt-2 '
              + 'sui-mb-4 sui-overflow-x-auto sui-overflow-y-hidden'
          }
        >
          {!traySamples.length
            ? (<Typography>You currently have no samples selected.</Typography>)
            : (renderTraySamples(traySamples))}
        </div>
        <AddToCart
          cartOptions={{
            channel,
            directCheckout: true,
            host: window.location.origin,
            section: 'order sample'
          }}
          cartReqParams={getCartReqParams(traySamples)}
          disabled={!traySamples.length}
          onClick={() => handleAddToCartClick(traySamples)}
        >
          Add to Cart
        </AddToCart>
        <div className="sui-flex sui-justify-center sui-mt-4">
          <Typography variant="body-xs">Fast and Free Delivery</Typography>
        </div>
      </DrawerFooter>
    </Drawer>
  );
};

SampleDrawer.displayName = 'SampleDrawer';

SampleDrawer.propTypes = {
  anchorSampleId: string,
  onClose: func.isRequired,
  onSampleData: func.isRequired,
  open: bool,
  parentId: string.isRequired
};

SampleDrawer.defaultProps = {
  anchorSampleId: null,
  open: false,
};

export default SampleDrawer;