import React from 'react';
import { Row, Col, Placeholder, Carousel } from '@thd-olt-component-react/core-ui';
import './transactional-placeholder.scss';

export const TransactionalPlaceholder = () => {
  const ProductPodPlaceholder = () => (
    <Row>
      <Col className="transactional-placeholder__product-pod-image">
        <Placeholder height="100%" type="text" />
      </Col>
      <Col>
        {new Array(6).fill('').map((_, key) => (
          <Placeholder
            key={`text-${key}`}
            type="text"
            className="transactional-placeholder__product-pod-text-block"
          />
        ))}
      </Col>
    </Row>
  );

  return (
    <Row data-component="PackagesMiniTransactionalPlaceholder">
      <Row>
        <Col className="transactional-placeholder__heading-container">
          <Placeholder height="100%" type="text" />
        </Col>
      </Row>
      <Col className="transactional-placeholder__package-image-container" xs="5" sm="4">
        <Placeholder height="100%" type="text" />
      </Col>
      <Col xs="7" sm="8" className="transactional-placeholder__transactional-container">
        <Carousel
          breakpoints={{
            sm: { slidesPerGroup: 2, slidesPerView: 2 },
            md: { slidesPerGroup: 2, slidesPerView: 2 },
            lg: { slidesPerGroup: 4, slidesPerView: 4 },
            xl: { slidesPerGroup: 4, slidesPerView: 4 }
          }}
        >
          {new Array(5).fill('').map((_, key) => (
            <ProductPodPlaceholder key={`pod-${key}`} />
          ))}
        </Carousel>

        <Col className="transactional-placeholder__package-summary">
          <Placeholder height="100%" type="text" />
        </Col>
      </Col>
    </Row>
  );
};

TransactionalPlaceholder.displayName = 'PackagesMiniPlaceholder';
