import React from 'react';
import PropTypes from 'prop-types';
import { Carousel } from '@one-thd/sui-carousel';
import classNames from 'classnames/bind';
import { Button, Link, Typography, ThemeProvider } from '@one-thd/sui-atomic-components';
import { getThemeByName } from '@thd-olt-component-react/stencil-themes';
import { promoVisNavDataModel } from './dataModel';
import { PromoVisualNavigationCard } from './PromoVisualNavigationCard';
import styles from './promo-visual-navigation.scss';

const PromoVisualNavigationRenderer = ({
  componentId,
  componentClass = '',
  componentPosition = 1,
  dataComponent = null,
  enableTheme = false,
  experienceHasSideNav = false,
  fontWeight = 'display',
  isHomePage = false,
  lazyLoad = false,
  promoVisNavList = [],
  title = ''
}) => {

  const { overflowBehavior } = dataComponent || {};
  let { theme, itemTheme } = dataComponent || {};
  const { callToAction, destinationLink } = dataComponent?.sectionCTAButton || {};

  theme = enableTheme ? theme : 'DefaultTheme';
  itemTheme = enableTheme ? itemTheme : 'DefaultTheme';

  const promoVisNavListLength = promoVisNavList?.length;
  const promoVisNavListLength4 = promoVisNavListLength === 4;
  const promoVisNavListLength5 = promoVisNavListLength === 5;
  const promoVisNavListLength6 = promoVisNavListLength === 6;
  const expShouldRenderFour = experienceHasSideNav && (promoVisNavListLength4 || promoVisNavListLength6);
  const cardsArray = promoVisNavList.map((item, idx) => (
    <PromoVisualNavigationCard
      item={item}
      key={idx + 1}
      position={idx + 1}
      lazyLoad={lazyLoad}
      theme={getThemeByName(itemTheme)}
    />
  ));

  const wrapContainer = (
    <div
      className={classNames(
        'card-tile__collection',
        (overflowBehavior !== 'Wrap' ? 'sui-hidden' : 'sui-grid'),
        'sm:sui-grid',
        'sui-gap-4',
        'sui-grid-cols-2',
        'sm:sui-grid-cols-3',
        'md:sui-grid-cols-4',
        {
          'lg:sui-grid-cols-6': !experienceHasSideNav && !isHomePage,
          'xl:sui-grid-cols-6': !experienceHasSideNav && !isHomePage,
          '2xl:sui-grid-cols-6': !experienceHasSideNav && !isHomePage,
          'lg:sui-grid-cols-4': experienceHasSideNav || isHomePage,
          'xl:sui-grid-cols-4': experienceHasSideNav || isHomePage,
          '2xl:sui-grid-cols-4': expShouldRenderFour || isHomePage,
          '2xl:sui-grid-cols-5': experienceHasSideNav && promoVisNavListLength5,
        }
      )}
      id={`${componentClass}-${componentPosition}-${componentId}`}
      data-testid="promo-visual-navigation"
      data-component-id={componentId}
      data-component-name={componentClass}
      data-component-position={componentPosition}
    >
      {cardsArray}
    </div>
  );

  const mobileCarouselContainer = (
    <div className="sm:sui-hidden">
      <Carousel
        slidesPerGroup={2}
        slidesPerView={2}
        breakpoints={{
          sm: {
            slidesPerView: 3,
            slidesPerGroup: 3,
            spaceBetween: 16
          },
          md: {
            slidesPerView: 2,
            slidesPerGroup: 2,
            spaceBetween: 16
          },
          lg: {
            slidesPerView: 2,
            slidesPerGroup: 2,
            spaceBetween: 16
          },
          xl: {
            slidesPerView: 2,
            slidesPerGroup: 2,
            spaceBetween: 16
          }
        }}
      >
        {cardsArray.slice(0, 12)}
      </Carousel>
    </div>
  );

  const legalCopy = dataComponent?.legalCopy;
  const cxLegal = classNames.bind(styles);

  const headerSection = (
    <>
      {title && (
        <div className="sui-flex sui-flex-col md:sui-flex-row sui-justify-between sui-pb-4">
          <div className="sui-pb-4 md:sui-p-0">
            <Typography
              variant="h2"
              weight={fontWeight}
              height="snug"
              color="primary"
              uppercase
              data-contentful-entry-id={componentId}
              data-contentful-field-id="title"
            >
              {title}
            </Typography>
          </div>
          {destinationLink && callToAction && (
            <div className="sui-w-fit">
              <Button
                href={destinationLink}
                variant="primary"
                aria-label={`Navigate to ${title}`}
                data-contentful-entry-id={componentId}
                data-contentful-field-id="sectionCTAButton"
              >
                {callToAction}
              </Button>
            </div>
          )}
        </div>
      )}
      {legalCopy && (
        <div
          className={cxLegal('promo-visual-navigation__legal-copy', 'sui-pb-4')}
        >
          {legalCopy?.legalUrl ? (
            <Link
              href={legalCopy?.legalUrl}
              target="_blank"
              data-contentful-entry-id={componentId}
              data-contentful-field-id="legalCopy"
            >
              {legalCopy?.legalText}
            </Link>
          ) : (
            <Typography
              data-contentful-entry-id={componentId}
              data-contentful-field-id="legalCopy"
            >
              {legalCopy?.legalText}
            </Typography>
          )}
        </div>
      )}
    </>
  );

  return (
    <ThemeProvider theme={getThemeByName(theme)}>
      <div className="sui-bg-primary sui-text-primary">
        {headerSection}
        {overflowBehavior !== 'Wrap' && mobileCarouselContainer}
        {wrapContainer}
      </div>
    </ThemeProvider>
  );
};

PromoVisualNavigationRenderer.propTypes = {
  componentId: PropTypes.string.isRequired,
  componentClass: PropTypes.string,
  componentPosition: PropTypes.number,
  experienceHasSideNav: PropTypes.bool,
  title: PropTypes.string,
  lazyLoad: PropTypes.bool,
  enableTheme: PropTypes.bool,
  isHomePage: PropTypes.bool,
  dataComponent: PropTypes.shape({
    componentName: PropTypes.string,
    id: PropTypes.string,
    itemTheme: PropTypes.string,
    legalCopy: PropTypes.shape({
      legalText: PropTypes.string,
      legalUrl: PropTypes.string,
    }),
    overflowBehavior: PropTypes.string,
    promoVisualNavigationList: PropTypes.arrayOf(
      PropTypes.shape({
        altText: PropTypes.string,
        id: PropTypes.string,
        previewImage: PropTypes.shape({
          damContentSelector: PropTypes.shape({
            assetData: PropTypes.arrayOf(
              PropTypes.shape({
                selectedImageUrl: PropTypes.string,
              })
            ),
          }),
          damDownloadedContent: PropTypes.shape({}),
        }),
        promotionalOffer: PropTypes.shape({
          simpleOfferHeadline: PropTypes.string,
          simpleOfferSubhead: PropTypes.string,
        }),
      })
    ),
    sectionCTAButton: PropTypes.shape({
      callToAction: PropTypes.string,
      destinationLink: PropTypes.string,
    }),
    theme: PropTypes.string,
    title: PropTypes.string,
  }),
  promoVisNavList: PropTypes.arrayOf(PropTypes.shape({})),
  fontWeight: PropTypes.string,
};

PromoVisualNavigationRenderer.dataModel = promoVisNavDataModel;

export { PromoVisualNavigationRenderer };
