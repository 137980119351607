/* eslint-disable max-len */
import React from 'react';
import { Link } from '@one-thd/sui-atomic-components';
import { bool, shape, string } from 'prop-types';
import { LEGAL_LINKS, DESKTOP_LEGAL_LINKS, EXCHANGE_LEGAL_LINKS } from './footer-data';
import { useFooterContext } from './FooterContext';

const LegalLinks = ({ isStorefront, isExchange }) => {
  const { contactCenter } = useFooterContext();

  const LEGAL_COPYRIGHT = `© 2000-${ new Date().getFullYear() } Home Depot. All Rights Reserved. Use of this site is subject to certain `;
  const LEGAL_USE = 'Terms Of Use.';
  const LEGAL_PRICE_AND_AVAILABILITY = 'Local store prices may vary from those displayed. Products shown as available are normally stocked but inventory levels cannot be guaranteed.';
  const PHONE_NUMBER = contactCenter?.number ? contactCenter.number : '1-800-430-3376';
  const TEXT_NUMBER = contactCenter?.smsNumber ? contactCenter.smsNumber : '38698';
  const URL_PROVIDE_FEEDBACK = 'https://homedepot.az1.qualtrics.com/jfe/form/SV_eqRT88p3wyjVYVf?Q_CHL=si&QMREPLAY=https%3A%2F%2Fhomedepot.'
  + 'quantummetric.com%2F%23%2Fusers%2Fsearch%3Fts%3D1month%26autoreplay%3Dtrue%26replaysecond%3D-20'
  + '%26qmsessioncookie%3D869964ed20844b513eccbd270f365fdf&Tab%20Intercept=SI_ehszkP3JIwnjF77&Beta_Origin='
  + 'origin&URL_Current=https%3A%2F%2Fwww.homedepot.com%2F&URL_Previous=https%3A%2F%2Fwww.homedepot.com%2Fc%2'
  + 'Fsubscription_service_terms_and_conditions&AB%20Test=guest_hp&Cart%20ID=undefined&Cart%20ID2=&Sku=&Product'
  + '%20Type=&Search%20Term=undefined&Header%20Crumb=undefined&Site%20Experience=desktop&Auth%20Status=guest&'
  + 'Store%20Number=6892&Store%20Zip=78723&Page%20Name=homepage&Page%20Type=homepage&Category=homepage&Sub'
  + 'Category=homepage&B2B=&Pro_Consumer=&Business%20Type=&B2B%20User%20Type=undefined&B2B%20User%20Trade='
  + 'undefined&B2B%20User%20ID=undefined&B2B%20Account%20ID=undefined&Auto%20Value=&InviteDeclined='
  + '&SponsoredProducts=&DeviceAAv59=&CustomerIDv30=&b2bvb2x=null&callCenterType=&App_Exp_Feature_Version'
  + '=&App_Exp_Name=my-homepage&App_Exp_Version=3.19.11';

  const footerLegalWrapper = 'sui-bg-subtle sui-p-2 sui-h-full lg:sui-bg-primary lg:sui-max-w-screen-2xl lg:sui-m-auto';
  const footerLegalClasses = 'sui-hidden lg:sui-block sui-text-xs sui-text-center sui-w-full sui-p-0 sui-m-0 sui-leading-6';
  const footerLegalLinksClasses = 'sui-flex sui-flex-wrap sui-gap-2 sui-leading-3 sui-text-xs sui-text-primary sui-list-none sui-justify-center';

  const getLegalLinks = () => {
    if (isExchange) return { primary: EXCHANGE_LEGAL_LINKS };
    return {primary: LEGAL_LINKS, desktop: DESKTOP_LEGAL_LINKS}
  };

  const legalLinks = getLegalLinks();

  return (
    <div className={footerLegalWrapper}>
      <div className={footerLegalClasses}>
        <div id="footer">
          {LEGAL_COPYRIGHT}
          <a
            className="sui-underline sui-whitespace-pre"
            href="/c/Terms_of_Use"
            title="terms of use"
            alt="Terms of use"
          >
            {LEGAL_USE}
          </a>
        </div>
        <div>
          {LEGAL_PRICE_AND_AVAILABILITY}
        </div>
        <div>
          For screen reader problems with this website, please call
          <span id="contact-center-number">{` ${PHONE_NUMBER}`}</span> or text
          <span id="contact-center-sms-number">{` ${TEXT_NUMBER}`}</span> (standard carrier rates apply to texts).
        </div>
      </div>
      <div id="footer" className="sui-pt-4">
        <ul className={footerLegalLinksClasses}>
          {legalLinks?.primary?.map((link, index) => {
            return (
              <li key={link.label}>
                { link.href
                  ? (
                    <a className="sui-ml-1 md:sui-underline" href={link.href} title={link.label} alt={link.label} data-testid={link?.testid} onClick={link?.onClick}>
                      {link.label}
                    </a>
                  )
                  : (<span className="sui-ml-1">{link.label}</span>)}
                <span className="sui-pl-1 md:sui-hidden">
                  {index < legalLinks?.primary?.length - 1 ? '|' : null}
                </span>
              </li>
            );
          }
          )}
          {legalLinks?.desktop?.map((link) => {
            return (
              <li key={link.label}>
                <a
                  className="sui-hidden md:sui-block sui-ml-1 md:sui-underline"
                  href={link.href}
                  title={link.label}
                  alt={link.label}
                >
                  {link.label}
                </a>
              </li>
            );
          }
          )}
        </ul>
      </div>
      {!isStorefront && (
        <div className="md:sui-hidden sui-flex sui-justify-center sui-text-center sui-text-lg sui-py-2">
          <Link
            href={URL_PROVIDE_FEEDBACK}
            role="button"
            underline="none"
            onClick={() => { document.getElementsByClassName('QSIFeedBackLink')[0].click(); }}
          >
            Provide Feedback
          </Link>
        </div>
      )}

    </div>
  );
};

LegalLinks.propTypes = {
  isStorefront: bool,
  isExchange: bool
};
LegalLinks.defaultProps = {
  isStorefront: false,
  isExchange: false
};
LegalLinks.dataModel = {};
LegalLinks.displayName = 'Footer Legal Links';

export { LegalLinks };
