import { actionTypes } from '@gcc/configurator';
import {
  GET_PRICING_ERROR,
  GET_MSRP_PRICING_SUCCESS,
  GET_SO_PRICING_SUCCESS,
  GET_SKUMAP_ERROR,
  GET_SKUMAP_SUCCESS,
  THD_PRODUCT_CHOICE_SKU_AVAILABILITY_SUCCESS,
  THD_PRODUCT_CHOICE_SKU_AVAILABILITY_ERROR,
  GET_GCC_PRODUCT_DETAILS_ERROR,
  GET_GCC_PRODUCT_DETAILS_SUCCESS,
  CLEAR_ERROR_GROUPS,
  UPDATE_CART_PRODUCT_CONFIGURATION,
  CONFIGURATOR_SET_HYDRATION_COMPLETE
} from '../actions/actionTypes';

import { GetPricingError } from '../customErrors';
import * as errConstants from '../constants/errGroupConstants';

export const initialState = [];

const getErrors = (state, err, errorGroup, customErrMessage) => {
  let errorMessage = customErrMessage;
  if (err?.response?.status >= 500 && err?.response?.status < 600) {
    errorMessage = `${err.response.status}: ${err.message}`;
  }
  if (!errorMessage) {
    let errGrp = errorGroup.startsWith(errConstants.GROUP_OPTIONS_CHOICES) ? errConstants.GROUP_OPTIONS_CHOICES : errorGroup;

    errorMessage = `Error in ${errGrp} for the selected configuration.`;
  }

  const newError = { name: errorGroup, errorMessage, statusCode: err?.response?.status || 0 };

  const otherErrors = state.filter((x) => x.name !== errorGroup);

  return [...otherErrors, newError];
};

const getSkumapError = (err) => {
  if (err?.response?.status === 404) {
    const selectionArray = err?.payload?.choices?.map((c) => `${c.optionName} : ${c.choiceName}`);
    const selections = selectionArray.join(', ');

    return `${selections}\nnot found in SKU Map`;
  }
  return err.message;
};

export default (state = initialState, { type, err }) => {
  switch (type) {

  case GET_PRICING_ERROR: {
    if (err instanceof GetPricingError) {
      return getErrors(state, err, errConstants.GROUP_PRICING);
    }
    return state;
  }

  case GET_SO_PRICING_SUCCESS:
  case GET_MSRP_PRICING_SUCCESS: {
    return state.filter((x) => x.name !== errConstants.GROUP_PRICING);
  }

  case GET_SKUMAP_ERROR: {
    const errorMessage = getSkumapError(err);
    return getErrors(state, err, errConstants.GROUP_SKUMAP, errorMessage);
  }

  case GET_SKUMAP_SUCCESS: {
    return state.filter((x) => x.name !== errConstants.GROUP_SKUMAP);
  }

  case actionTypes.EXECUTE_RULES_ERROR: {
    return getErrors(state, err, errConstants.GROUP_RULES);
  }

  case actionTypes.EXECUTE_RULES_SUCCESS: {
    return state.filter((x) => x.name !== errConstants.GROUP_RULES);
  }

  case THD_PRODUCT_CHOICE_SKU_AVAILABILITY_ERROR: {
    return getErrors(state, err, errConstants.GROUP_OPTIONS_CHOICES_AVAILABILITY);
  }

  case THD_PRODUCT_CHOICE_SKU_AVAILABILITY_SUCCESS: {
    return state.filter((x) => x.name !== errConstants.GROUP_OPTIONS_CHOICES_AVAILABILITY);
  }

  case actionTypes.GET_OPTIONS_ERROR: {
    return getErrors(state, err, errConstants.GROUP_OPTIONS_CHOICES_CONFIGURATOR);
  }

  case actionTypes.LOAD_OPTIONS_SUCCESS: {
    return state.filter((x) => x.name !== errConstants.GROUP_OPTIONS_CHOICES_CONFIGURATOR);
  }

  case GET_GCC_PRODUCT_DETAILS_ERROR: {
    return getErrors(state, err, errConstants.GROUP_TEMPLATE);
  }

  case GET_GCC_PRODUCT_DETAILS_SUCCESS: {
    return state.filter((x) => x.name !== errConstants.GROUP_TEMPLATE);
  }

  case CLEAR_ERROR_GROUPS: {
    return [];
  }

  default:
    return state;
  }
};

export const PREVIEW_ERROR_ACTIONS = [
  GET_PRICING_ERROR,
  GET_SKUMAP_ERROR,
  actionTypes.EXECUTE_RULES_ERROR,
  THD_PRODUCT_CHOICE_SKU_AVAILABILITY_ERROR,
  actionTypes.GET_OPTIONS_ERROR,
  GET_GCC_PRODUCT_DETAILS_ERROR,
  CLEAR_ERROR_GROUPS,
  // This two action added here cuz something causing re-render showing the buybox, need some investigation
  UPDATE_CART_PRODUCT_CONFIGURATION,
  CONFIGURATOR_SET_HYDRATION_COMPLETE
];
