export const COMBO_EXPERIENCE_FEATURES = {
  questionsAndAnswers: true,
  ratingsAndReview: true,
  starRatingProductOverview: true,
  starRatingStickyNav: true,
  questionStickyNav: true,
  whatWeOffer: false,
  consumerCard: false,
  quantity: true,
  paypalCheckout: true,
  addToCart: true,
  salientPoint: true,
  starRatingStickyNavTitle: true,
  swapToaster: false,
  dynamicImageGrid: false,
  productListTitle: 'Items in this bundle',
  priceUnitOfMeasure: 'bundle',
  bundleSummaryTitle: 'Bundle Summary',
  deliveryInfo: false,
  deliveryExpress: true,
  deliveryAndTax: false,
  summaryRegularPriceType: 'items',
  calculateDeliveryFee: false,
  showBundleSavings: false,
  showUnavailableMessage: true,
  totalPriceText: 'items',
  singleTotalPriceText: 'item'
};

export const LAUNDRY_EXPERIENCE_FEATURES = {
  questionsAndAnswers: false,
  ratingsAndReview: false,
  starRatingProductOverview: false,
  starRatingStickyNav: false,
  questionStickyNav: false,
  whatWeOffer: true,
  consumerCard: true,
  quantity: false,
  paypalCheckout: false,
  addToCart: false,
  salientPoint: false,
  starRatingStickyNavTitle: false,
  swapToaster: true,
  dynamicImageGrid: true,
  productListTitle: 'Appliances in this package',
  priceUnitOfMeasure: 'bundle',
  bundleSummaryTitle: 'Bundle Summary',
  deliveryInfo: true,
  deliveryExpress: false,
  deliveryAndTax: false,
  summaryRegularPriceType: 'appliances',
  calculateDeliveryFee: false,
  showBundleSavings: false,
  showUnavailableMessage: false,
  totalPriceText: 'items',
  singleTotalPriceText: 'item'
};

export const KITCHEN_EXPERIENCE_FEATURES = {
  questionsAndAnswers: false,
  ratingsAndReview: false,
  starRatingProductOverview: false,
  starRatingStickyNav: false,
  questionStickyNav: false,
  whatWeOffer: true,
  consumerCard: true,
  quantity: false,
  paypalCheckout: false,
  addToCart: false,
  salientPoint: false,
  starRatingStickyNavTitle: false,
  swapToaster: true,
  dynamicImageGrid: true,
  productListTitle: 'Appliances in this package',
  priceUnitOfMeasure: 'package',
  bundleSummaryTitle: 'Package Summary',
  deliveryInfo: true,
  deliveryExpress: false,
  deliveryAndTax: true,
  summaryRegularPriceType: 'appliances',
  calculateDeliveryFee: true,
  showBundleSavings: true,
  showUnavailableMessage: false,
  totalPriceText: 'appliances',
  singleTotalPriceText: 'appliance'
};