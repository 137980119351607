/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useContext, useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import { extend } from '@thd-nucleus/data-sources';
import {
  number, string, func, bool, shape, object, arrayOf
} from 'prop-types';
import { ExperienceContext, useStore, useConfigService } from '@thd-nucleus/experience-context';
import { CheckAvailability } from '@thd-olt-component-react/check-availability';
import { shipping } from '@thd-olt-component-react/delivery-options';
import { useThdCustomer } from '@thd-olt-functional/customer-information';
import { useTheme } from '@thd-olt-component-react/theme-provider';
import { dataModel } from '../dataModel';
import { PickupHeader } from './PickupHeader';
import { DeliveryHeader } from './DeliveryHeader';
import { PickupTile } from './PickupTile';
import { DeliveryTile } from './DeliveryTile';
import {
  useFulfillmentGetData,
  useShippingGetData,
  useApplianceGetData
} from '../../hooks/useFulfillmentTiles';
import { getFulfillmentInfoFromData } from '../../hooks/useFulfillmentGetTemplates';
import { useFulfillmentListeners } from '../../hooks/useFulfillmentListeners';
import { useHandleOnChange } from '../../hooks/useHandleOnChange';
import { FulfillmentPlaceholder } from './Placeholder/FulfillmentPlaceholder';
import {
  setDeliveryZip,
  getDeliveryZip,
  isNonMajorAppliance,
  getPickupStoreName,
  isMobile,
  sth,
  bodfs,
  isMajorApplianceProductType,
  isBackorderedSelection,
  isExchangeCustomerFallback,
  isItemOnClearance,
  isBopisLimitedQty,
  isBopisFalse,
  IsPickupUnavailable
} from '../helper/utils';
import { isSthInStock } from '../../pod/helpers/pod-fulfillment-utils';
import './fulfillmentTiles.scss';
import { FULFILLMENT_METHODS } from '../constants';
import { CheckNearByStore } from './partials/store/CheckNearByStore';
import { DeliveryMessageUnderTile } from './DeliveryMessageUnderTile';
import { ApplianceAlerts } from './alerts/ApplianceAlerts';
import { NonMajorApplianceAlert } from './alerts/NonMajorApplianceAlert';
import useSelectedFulfillmentHasChanged from '../../hooks/useSelectedFulfillmentHasChanged';
import { ClearancePickupMessageUnderTile } from './partials/store/ClearancePickupMessageUnderTile';
import { getEdgeCase, shouldLoadPlaceholder } from './FulfillmentTilesHelper';

const FulfillmentTiles = (props) => {
  const {
    itemId,
    lineItemId,
    storeId: primaryStoreId,
    deliveryZip,
    quantity,
    originalConfigId,
    covidAlert,
    dualPath,
    category,
    onChange,
    onDeliveryZipCodeChange,
    thresholds,
    directData,
    applianceDirectData,
    configurableApplianceItemIds,
    isBuyBoxLite,
    isCartTiles,
    localizerStoreDetails,
    quantityExceeded,
    addOnsAssemblyAndMore,
    cartAction,
    bypassQuantitySkip = false,
    disableSTH,
    disableBODFS,
    disableBOSS,
    openApplianceCheckAvailability,
    disablePickupNearBy,
    currentSelectedFulfillment,
    leadTime,
    hasCustomATC,
    configurableProductDetails,
    hideShippingThreshold
  } = props;
  const theme = useTheme(FulfillmentTiles);
  const { hideDeliveryMessageUnderTile, hidePickupTileHeader } = theme.props;
  const { channel, isConsumerApp } = useContext(ExperienceContext);
  const enableApplianceDeliveryCharge = !!useConfigService('enableApplianceDeliveryCharge');
  const enableFreeDeliveryForExchange = !!useConfigService('enableFreeDeliveryForExchange');
  const isHideLimitedStockClearanceEnabled = !!useConfigService('fs:isHideLimitedStockClearanceEnabled');
  const { isExchangeCustomer } = useThdCustomer() || isExchangeCustomerFallback();
  const { storeId: secondaryStoreId, storeName: defaultStoreName = '', storeZip } = useStore();
  const storeId = primaryStoreId || secondaryStoreId;
  const storeName = getPickupStoreName(directData) || defaultStoreName;
  const [zipCode, setZipCode] = useState(deliveryZip || getDeliveryZip() || storeZip);
  const [selectedFulfillmentMethod, setSelectedFulfillmentMethod] = useState(
    currentSelectedFulfillment
  );
  const [showCheckAvailability, setShowCheckAvailability] = useState(false);
  const [ffmData, setFfmData] = useState({});
  const [secondaryFFM, setPreferSecondaryFFM] = useState({
    isServiceOptedIn: false,
    prefer: false
  });
  const [applianceDeliveryData, setApplianceDeliveryData] = useState(
    isCartTiles && applianceDirectData ? applianceDirectData : null
  );
  const [isNativeDeliveryZipEventTriggered, setIsNativeDeliveryZipEventTriggered] = useState(false);
  const currentDeliveryFfm = useRef('');
  const numberOfAddOnsAndMore = addOnsAssemblyAndMore
    ? Object.keys(addOnsAssemblyAndMore).length
    : 0;
  const updateZipCode = (newZipCode) => {
    if (newZipCode) {
      setDeliveryZip(newZipCode);
      setZipCode(newZipCode);
    }
  };
  const [updatedQuantity, setQuantityToQuery] = useState(quantity);

  const onChangeOfZipCode = (data) => {
    const shippingModel = shipping(data?.shipping);
    updateZipCode(data?.shipping?.zipCode);
    // setShippingModel(updatedModel);
    if (shippingModel) {
      if (onDeliveryZipCodeChange) {
        onDeliveryZipCodeChange(
          isCartTiles
            ? data?.shipping?.zipCode
            : { ...shippingModel, zipCode: data?.shipping?.zipCode }
        );
      }
    }
  };
  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.on('NATIVE_APP.DELIVERY_ZIP', ({ output }) => {
      if (output?.deliveryZip) {
        setZipCode(output?.deliveryZip);
      }
      setIsNativeDeliveryZipEventTriggered(true);
    });
    let deliveryZipEventTimer;
    if (isConsumerApp && !isCartTiles) {
      deliveryZipEventTimer = setTimeout(() => {
        setIsNativeDeliveryZipEventTriggered((isTriggered) => {
          if (!isTriggered) {
            if (window?.newrelic) {
              window.newrelic?.addPageAction(
                'DeliveryZip was not received by fulfillment component from native app within 7 seconds.',
                {
                  id: 'fulfillment',
                  name: '',
                  message: '',
                  stack: ''
                }
              );
            }
            return true;
          }
          return isTriggered;
        });
      }, 7000);
    }
    return () => {
      if (deliveryZipEventTimer) {
        clearTimeout(deliveryZipEventTimer);
      }
    };
  }, []);

  useEffect(() => {
    // for cart
    if (isCartTiles) {
      setZipCode(deliveryZip);
    }

    // call to set quantity with a delay
    const timer = setTimeout(() => {
      // Update the quantity after a wait so as to avoid unnecessary re-renders.
      const { fulfillment = {} } = ffmData;
      const fallbackMode = !!fulfillment?.fallbackMode;
      if (!fallbackMode && !quantityExceeded) {
        setQuantityToQuery(quantity);
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [deliveryZip, isCartTiles, quantity, ffmData.fulfillment]);

  const { data } = useFulfillmentGetData({
    itemId,
    zipCode,
    storeId,
    directData,
    // send updated Quantity set after a delay to improve performance
    quantity: updatedQuantity,
    bypassQuantitySkip
  });

  const isMajorAppliance = !!applianceDirectData
    || !!applianceDeliveryData
    || isMajorApplianceProductType(data?.product?.identifiers?.productType);

  const { shippingData, shippingLoading } = useShippingGetData({
    itemId,
    storeId,
    zipCode,
    quantity: updatedQuantity,
    original: data?.product?.pricing?.original,
    value: data?.product?.pricing?.value,
    isSthAvailable: sth(data?.product?.fulfillment),
    directData,
    isMajorAppliance,
    configId: originalConfigId
  });

  useApplianceGetData({
    itemId,
    itemIds: configurableApplianceItemIds,
    storeId,
    zipCode,
    isMajorAppliance,
    applianceDirectData,
    setApplianceDeliveryData
  });

  // for cart tiles, need to preserve selected ffm on initial load
  const getCurrentDeliveryFfm = () => {
    if (!currentDeliveryFfm?.current && isCartTiles) {
      return selectedFulfillmentMethod;
    }
    return currentDeliveryFfm?.current;
  };

  useEffect(() => {
    if (data?.product) {
      setFfmData(
        getFulfillmentInfoFromData({
          data,
          itemId,
          covidAlert,
          quantity,
          originalConfigId,
          zipCode,
          storeId,
          storeName,
          channel,
          thresholds,
          setShowCheckAvailability,
          currentDeliveryFfm: getCurrentDeliveryFfm(),
          shippingData,
          isMajorAppliance,
          applianceDeliveryData,
          preferSecondaryFFM: secondaryFFM.prefer,
          isCartTiles,
          addOnsAssemblyAndMore,
          disableSTH,
          disableBODFS,
          disableBOSS,
          disablePickupNearBy,
          enableApplianceDeliveryCharge,
          enableFreeDeliveryForExchange,
          isExchangeCustomer,
          isSpecialOrder: configurableProductDetails.isSpecialOrder,
          configuratorHideQuantity: configurableProductDetails.configuratorHideQuantity,
          isConfigurableBlinds: configurableProductDetails.isConfigurableBlinds,
          disableTimer: configurableProductDetails.disableTimer,
          hideShippingThreshold
        })
      );
    }
  }, [
    data.product,
    quantity,
    shippingData,
    applianceDeliveryData,
    secondaryFFM,
    numberOfAddOnsAndMore,
    configurableProductDetails.isSpecialOrder,
    configurableProductDetails.disableTimer,
    configurableProductDetails.configuratorHideQuantity,
    configurableProductDetails.isConfigurableBlinds
  ]);

  const {
    fulfillment = {},
    info,
    identifiers,
    original,
    value,
    pricing,
    availabilityType,
    fulfillmentModels,
    optimalFulfillmentFromAPI
  } = ffmData;

  const isClearanceItem = isItemOnClearance(fulfillment) && data?.product?.pricing?.clearance;
  const fallbackMode = !!fulfillment?.fallbackMode;  
  const isBackorderedApplied = isBackorderedSelection(fulfillment?.backordered, selectedFulfillmentMethod);
  const getCurrentFulfillment = (selectedFulfillment, ffms) => {
    if (selectedFulfillment) {
      return ffms.find((option) => option.method === selectedFulfillment);
    }
    return fulfillmentModels.find((option) => option.method === selectedFulfillment);
  };

  const { pushNewFulfillmentMethod } = useSelectedFulfillmentHasChanged();

  const isPickupFfmMethod = (currentFulfillmentMethod = '') => {
    return !!(
      !currentFulfillmentMethod
      || [FULFILLMENT_METHODS.BOPIS, FULFILLMENT_METHODS.BOSS].includes(currentFulfillmentMethod)
    ); // on page load variable will be empty
  };

  useEffect(() => {
    if (fulfillmentModels) {
      const currentFulfillment = getCurrentFulfillment(
        selectedFulfillmentMethod,
        fulfillmentModels
      );
      const currentFulfillmentMethod = currentFulfillment?.method || selectedFulfillmentMethod;
      const [storeObj = {}, shippingObj = {}] = fulfillmentModels;
      currentDeliveryFfm.current = shippingObj?.method;
      if (!currentFulfillment?.enabled) {
        if (info?.hasSubscription && shippingObj?.enabled && isSthInStock(data?.product)) {
          setSelectedFulfillmentMethod(shippingObj.method);
        } else if (
          storeObj?.enabled
          && isPickupFfmMethod(currentFulfillmentMethod)
          && !storeObj?.isPickupNearby
        ) {
          setSelectedFulfillmentMethod(storeObj.method);
        } else if (shippingObj?.enabled) {
          setSelectedFulfillmentMethod(shippingObj.method);
        } else if (storeObj?.enabled) {
          // in case the delivery FFM (currentFFM) is not available, then default to the pickup
          setSelectedFulfillmentMethod(storeObj.method);
        }
      }
    }
  }, [fulfillmentModels, secondaryFFM]);

  useEffect(() => {
    // for open delivery zip overlay from cart
    if (window.LIFE_CYCLE_EVENT_BUS && isCartTiles) {
      window.LIFE_CYCLE_EVENT_BUS.off('cart.delivery-zip-modal');
      window.LIFE_CYCLE_EVENT_BUS.on('cart.delivery-zip-modal', () => {
        setShowCheckAvailability(true);
      });
    }
  }, [cartAction]);

  useHandleOnChange({
    onChange,
    getCurrentFulfillment,
    fulfillmentModels,
    availabilityType,
    selectedFulfillmentMethod,
    itemId,
    quantity,
    primaryStoreNum: applianceDeliveryData?.primaryStrNbr,
    isCartTiles,
    isBackorderedApplied
  });

  useFulfillmentListeners({
    fulfillmentModels,
    setSelectedFulfillmentMethod,
    setPreferSecondaryFFM,
    optimalFulfillmentFromAPI
  });

  if (
    shouldLoadPlaceholder({
      applianceDeliveryData,
      fulfillmentModels,
      isCartTiles,
      isConsumerApp,
      isMajorAppliance,
      isNativeDeliveryZipEventTriggered
    })
  ) {
    return <FulfillmentPlaceholder channel={channel} />;
  }

  const [storeObj = {}, shippingObj = {}] = fulfillmentModels;

  const checkAvailabilityComponent = showCheckAvailability ? (
    <span data-testid="check-availability">
      <CheckAvailability
        useDrawerModal
        showCheckAvailability={showCheckAvailability}
        setShowCheckAvailability={setShowCheckAvailability}
        itemId={itemId}
        price={parseInt((value * quantity).toFixed(2), 10)}
        zipCode={zipCode}
        onSubmitForm={(response) => {
          if (response) {
            onChangeOfZipCode(response);
          }
          setShowCheckAvailability(false);
        }}
        quantity={quantity}
        inputHeaderTitle="Zip Code"
      />
    </span>
  ) : null;

  const edgeCase = getEdgeCase({
    addOnsAssemblyAndMore,
    availabilityType,
    category,
    checkAvailabilityComponent,
    dualPath,
    fulfillment,
    isExchangeCustomer,
    isMajorAppliance,
    itemId,
    originalConfigId,
    product: data?.product,
    setShowCheckAvailability,
    shippingObj,
    showCheckAvailability,
    storeObj,
    storeName
  });

  if (edgeCase) {
    return edgeCase;
  }

  const getWrapperClasses = (fulfillmentModel, removeMargin = false) => {
    const { enabled, method, tooltip } = fulfillmentModel;

    let cardSelected = false;
    let cardUnselected = false;
    if (enabled) {
      if (selectedFulfillmentMethod === method) {
        cardSelected = true;
      } else {
        cardUnselected = true;
      }
    }

    return classNames({
      card: true,
      u__legal: isMobile(channel),
      'mobile--margin-right': true,
      'card-container': true,
      'u__text--primary': !enabled,
      'card-selected': cardSelected,
      'card-unselected': cardUnselected,
      'card-enabled': enabled,
      'card-unclickable': !enabled,
      'card-info': tooltip,
      'u__m-right-none': removeMargin
    });
  };

  const showAvailableText = isClearanceItem || isBopisFalse(fulfillment);

  const onFulfillmentMethodChanged = (newMethod) => {
    setSelectedFulfillmentMethod(newMethod);
    pushNewFulfillmentMethod({ selectedFulfillmentMethod: newMethod });
  };

  const hideClearanceExperienceForLQTY = (isHideLimitedStockClearanceEnabled && isClearanceItem
    && isBopisLimitedQty(fulfillment));

  const pickupUnavailable = IsPickupUnavailable(
    { fulfillment, isClearanceItem, storeObj, hideClearanceExperienceForLQTY });

  return (
    <div className="fulfillment-tiles" data-component="FulfillmentTiles">
      <div className="fulfillment-tile-headers">
        {/* TODO: Determine best way to show either store from zipcode or nearby store */}
        <PickupHeader
          storeName={storeName}
          itemId={itemId}
          originalConfigId={originalConfigId}
          quantity={quantity}
          lineItemId={lineItemId}
          pickupUnavailable={pickupUnavailable}
          isMajorAppliance={isMajorAppliance}
          isCartTiles={isCartTiles}
          localizerStoreName={localizerStoreDetails?.name}
          addOnsAssemblyAndMore={addOnsAssemblyAndMore}
          isExchangeCustomer={isExchangeCustomer}
          storeId={storeId}
          leadTime={leadTime}
          hasCustomATC={hasCustomATC}
          disablePickupNearBy={disablePickupNearBy}
          showAvailableText={showAvailableText}
          isConfigurator={configurableProductDetails?.isConfigurableBlinds}
          hideHeader={hidePickupTileHeader && pickupUnavailable}
        />
        <DeliveryHeader
          itemId={itemId}
          zipCode={zipCode}
          showCheckAvailability={showCheckAvailability}
          setShowCheckAvailability={setShowCheckAvailability}
          isCartTiles={isCartTiles}
          lineItemId={lineItemId}
          fulfillmentMethod={selectedFulfillmentMethod}
          applianceCountInCart={
            applianceDirectData?.DeliveryAvailabilityResponse?.deliveryAvailability
              ?.applianceCountInCart
          }
          channel={channel}
          isMajorAppliance={isMajorAppliance}
          openApplianceCheckAvailability={openApplianceCheckAvailability}
        />
      </div>
      <div className="fulfillment__change-zip-container" />
      {checkAvailabilityComponent}
      <div className="fulfillment__content-wrapper card-deck">
        {storeObj.isPickupNearby ? (
          <CheckNearByStore
            itemId={itemId}
            quantity={quantity}
            originalConfigId={originalConfigId}
            lineItemId={lineItemId}
            addOnsAssemblyAndMore={addOnsAssemblyAndMore}
            isCartTiles={isCartTiles}
            storeIdFromCart={storeId}
            leadTime={leadTime}
            hasCustomATC={hasCustomATC}
          >
            <PickupTile
              getWrapperClasses={getWrapperClasses}
              fulfillmentObj={storeObj}
              originalConfigId={originalConfigId}
              lineItemId={lineItemId}
              currentFulfillmentMethod={selectedFulfillmentMethod}
              setSelectedFulfillmentMethod={onFulfillmentMethodChanged}
              isCartTiles={isCartTiles}
            />
          </CheckNearByStore>
        ) : (
          <PickupTile
            getWrapperClasses={getWrapperClasses}
            fulfillmentObj={storeObj}
            originalConfigId={originalConfigId}
            lineItemId={lineItemId}
            currentFulfillmentMethod={selectedFulfillmentMethod}
            setSelectedFulfillmentMethod={onFulfillmentMethodChanged}
            isCartTiles={isCartTiles}
            storeIdFromCart={storeId}
          />
        )}
        <DeliveryTile
          getWrapperClasses={getWrapperClasses}
          fulfillmentObj={shippingObj}
          currentFulfillmentMethod={selectedFulfillmentMethod}
          setSelectedFulfillmentMethod={onFulfillmentMethodChanged}
          lineItemId={directData?.lineItemId}
        />
      </div>
      <div className="fulfillment__fullcontent">
        { !hideClearanceExperienceForLQTY && isClearanceItem && (
          <ClearancePickupMessageUnderTile
            isClearanceItem={isClearanceItem}
            localstore={storeName}
            pricing={data?.product?.pricing}
            isCartTiles={isCartTiles}
            itemId={itemId}
          />
        )}
        {getCurrentFulfillment(selectedFulfillmentMethod, fulfillmentModels)?.subTemplate
          && !getCurrentFulfillment(selectedFulfillmentMethod, fulfillmentModels)?.unavailable && (
            <>
              {getCurrentFulfillment(selectedFulfillmentMethod, fulfillmentModels).subTemplate}
              {isNonMajorAppliance(identifiers.productType, info) && <NonMajorApplianceAlert />}
            </>
          )}
        <ApplianceAlerts applianceDeliveryData={applianceDeliveryData} storeObj={storeObj} />
      </div>
      {!fallbackMode && !hideDeliveryMessageUnderTile
        && (
          <DeliveryMessageUnderTile
            shippingData={shippingData}
            bodfsFulfillment={bodfs(fulfillment)}
            currDeliveryTileFfm={shippingObj?.method}
            isShippingLoading={shippingLoading}
            isMajorAppliance={isMajorAppliance}
            applianceDeliveryData={applianceDeliveryData}
            original={original}
            value={value}
            pricing={pricing}
            enableApplianceDeliveryCharge={enableApplianceDeliveryCharge}
            enableFreeDeliveryForExchange={enableFreeDeliveryForExchange}
            isExchangeCustomer={isExchangeCustomer}
            isBuyBoxLite={isBuyBoxLite}
            isCartTiles={isCartTiles}
          />
        )}
    </div>
  );
};

FulfillmentTiles.displayName = 'FulfillmentTiles';

FulfillmentTiles.themeProps = {
  hideDeliveryMessageUnderTile: bool,
  hidePickupTileHeader: bool
};

FulfillmentTiles.defaultThemeProps = {
  hideDeliveryMessageUnderTile: false,
  hidePickupTileHeader: false,
};

FulfillmentTiles.propTypes = {
  /** An Item that has product highlights */
  itemId: string.isRequired,
  storeId: string,
  deliveryZip: string,
  covidAlert: bool,
  quantity: number,
  originalConfigId: string,
  onChange: func,
  dualPath: bool,
  onDeliveryZipCodeChange: func,
  thresholds: shape({
    bodfsMinimumThreshold: number,
    bopisMinimumThreshold: number,
    bopisMinimumThresholdStores: string,
    bopisHolidayTiming: number
  }),
  // eslint-disable-next-line react/forbid-prop-types
  directData: object,
  // eslint-disable-next-line react/forbid-prop-types
  applianceDirectData: object,
  configurableApplianceItemIds: arrayOf(string),
  isBuyBoxLite: bool,
  isCartTiles: bool,
  // eslint-disable-next-line react/forbid-prop-types
  localizerStoreDetails: object,
  quantityExceeded: bool,
  // eslint-disable-next-line react/forbid-prop-types
  addOnsAssemblyAndMore: object,
  cartAction: string,
  bypassQuantitySkip: bool,
  category: string,
  lineItemId: string,
  disableSTH: bool,
  disableBODFS: bool,
  disableBOSS: bool,
  openApplianceCheckAvailability: func,
  disablePickupNearBy: bool,
  currentSelectedFulfillment: string,
  leadTime: number,
  hasCustomATC: bool,
  configurableProductDetails: shape({
    isSpecialOrder: bool,
    configuratorHideQuantity: bool,
    isConfigurableBlinds: bool,
    disableTimer: bool
  }),
  hideShippingThreshold: bool
};

FulfillmentTiles.defaultProps = {
  quantity: 1,
  originalConfigId: null,
  storeId: null,
  deliveryZip: '',
  covidAlert: false,
  onChange: () => {},
  onDeliveryZipCodeChange: () => {},
  dualPath: false,
  thresholds: {},
  directData: null,
  applianceDirectData: null,
  configurableApplianceItemIds: null,
  isBuyBoxLite: false,
  isCartTiles: false,
  localizerStoreDetails: {},
  quantityExceeded: false,
  addOnsAssemblyAndMore: null,
  cartAction: '',
  bypassQuantitySkip: false,
  category: '',
  lineItemId: '',
  disableSTH: false,
  disableBODFS: false,
  disableBOSS: false,
  openApplianceCheckAvailability: () => {},
  disablePickupNearBy: false,
  currentSelectedFulfillment: '',
  leadTime: 0,
  hasCustomATC: null,
  configurableProductDetails: {
    isSpecialOrder: false,
    configuratorHideQuantity: false,
    isConfigurableBlinds: false,
    disableTimer: false
  },
  hideShippingThreshold: false
};

FulfillmentTiles.dataModel = extend(CheckAvailability, dataModel);

export { FulfillmentTiles };
