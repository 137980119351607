import React, { useContext, useState, useEffect } from 'react';
import classNames from 'classnames';
import {
  arrayOf,
  number,
  string,
  bool
} from 'prop-types';
import { useWatch } from 'react-hook-form';
import {
  FormController,
  FormLabel,
  Radio, RadioGroup, SelectionControlLabel,
  useBreakpoint
} from '@one-thd/sui-atomic-components';

import { CalculatorContext } from './subcomponents/CalculatorContext';

export const CalculateByDimensions = ({
  dimensionNames,
  selectedArea,
  hideLabel
}) => {
  const {
    control,
    calculatorType,
    setCalcByArea: setCalcByAreaEntries
  } = useContext(CalculatorContext);
  const [calcByArea, setCalcByArea] = useState(false);
  const { lesser: isMobile } = useBreakpoint('md');
  const handleToggleText = (calc) => {
    setCalcByAreaEntries(JSON.parse(calc), selectedArea);
  };

  const [dimension1, dimension2] = dimensionNames;
  const depthToggleLabel = calculatorType === 'Volume' ? ' x Depth' : '';
  const lwToggleLabel = `${dimension1} x ${dimension2}${depthToggleLabel}`;
  const sqFtToggleLabel = `Square Footage${depthToggleLabel}`;

  const areas = useWatch({
    control,
    name: 'entries'
  });
  const filteredAreas = selectedArea !== null ? [areas[selectedArea]] : areas;

  useEffect(() => {
    setCalcByArea(filteredAreas[0]?.calcByArea);
  }, [filteredAreas]);

  return (
    <div className="sui-flex sui-self-stretch">
      <FormController fullWidth>
        {!hideLabel && <span className="sui-pt-1"><FormLabel>Calculate by:</FormLabel></span>}
        <RadioGroup
          onChange={(event) => handleToggleText(event.target.value)}
          orientation={isMobile ? 'horizontal' : 'vertical'}
        >
          <SelectionControlLabel value={lwToggleLabel} label={lwToggleLabel}>
            <Radio
              checked={!calcByArea}
              value="false"
            />
          </SelectionControlLabel>
          <SelectionControlLabel value={sqFtToggleLabel} label={sqFtToggleLabel}>
            <Radio
              checked={calcByArea}
              value="true"
            />
          </SelectionControlLabel>
        </RadioGroup>
      </FormController>
    </div>
  );
};

CalculateByDimensions.displayName = 'CalculateByDimensions';

CalculateByDimensions.propTypes = {
  dimensionNames: arrayOf(string),
  selectedArea: number,
  hideLabel: bool
};

CalculateByDimensions.defaultProps = {
  dimensionNames: ['Width', 'Height'],
  selectedArea: null,
  hideLabel: false
};

export default CalculateByDimensions;