/* eslint-disable @harmony/stencil/no-component-imports */
import React, { useState, Suspense, useContext } from 'react';
import { string, bool, func } from 'prop-types';
import {
  extend, QueryProvider, QueryContext
} from '@thd-nucleus/data-sources';
import { DrawerHeader, DrawerBody, Drawer, Typography } from '@one-thd/sui-atomic-components';
import {
  ProductPod,
  PodColumn,
  ProductRating,
  ProductBadge,
  ProductImage,
  PodSticker,
  PodSection
} from '@thd-olt-component-react/product-pod';
import { Price } from '@thd-olt-component-react/price';
import { ProductHeader } from '@thd-olt-component-react/product-details';
import { getBundleDrawerRootProps, isFromBundleMini } from '../../utils/product-bundle-utils';
import { SwapButton } from './SwapButton';
import { RemoveButton } from './RemoveButton';
import { QuickViewLazy } from './QuickViewLazy';
/**
 * Props
 *
 * @typedef {Object} Props
 * @property {Function} closeDrawer - Function to close the drawer.
 * @property {string} itemId - The identifier for the item, used in various child components within the drawer.
 * @property {string} storeId - The store identifier, used for data fetching or analytics within child components.
 * @property {boolean} isDrawerOpen - Controls the visibility of the drawer.
 * @property {boolean} loading - Indicates if the drawer should display loading placeholders instead of content.
 * @property {Function} swapProducts - Function to handle swapping the item with another.
 * @property {Function} removeProduct - Function to remove the product from the list
 *
 * @param {Props} props The properties passed to the RemoveDrawer component.
 * @returns {React.ReactElement} A drawer component configured for removing an item.
 */
const ProductPodWrapper = ({ children }) => {
  return children;
};
ProductPodWrapper.dataModel = extend(
  ProductPod,
  ProductBadge,
  ProductImage,
  Price,
  ProductRating,
  ProductHeader
);
export const RemoveDrawer = ({
  closeDrawer,
  itemId,
  storeId,
  isDrawerOpen,
  loading,
  isMobile,
  swapProducts,
  removeProduct,
  setSelectedItemId
}) => {
  const { defaultVariables } = useContext(QueryContext) || {};
  const drawerDefaultVariables = {
    storeId,
    ...(typeof defaultVariables?.current?.isBrandPricingPolicyCompliant === 'boolean' && {
      isBrandPricingPolicyCompliant: defaultVariables.current.isBrandPricingPolicyCompliant
    })
  };
  const [isQuickViewOpen, setIsQuickViewOpen] = useState(false);
  const [isRatingOpen, setIsRatingOpen] = useState(false);
  const triggerViewFullDetailsAnalytics = isFromBundleMini();
  const openQuickView = (event) => {
    event.preventDefault();
    if (!isQuickViewOpen) {
      setIsQuickViewOpen(true);
    }
  };
  return (
    <div data-component="RemoveDrawer" data-testid="remove-drawer">
      <Drawer
        open={isDrawerOpen && !loading}
        onClose={closeDrawer}
        DrawerRootProps={getBundleDrawerRootProps(isMobile)}
      >
        <div>
          <DrawerHeader
            endAdornment
            title={(
              <Typography variant="body-base" weight="bold">Are you sure you want to remove this item?</Typography>
            )}
            onClose={closeDrawer}
          />

          <DrawerBody>
            <QueryProvider cacheKey="remove-drawer-product-body" defaultVariables={drawerDefaultVariables}>
              <ProductPodWrapper>
                <ProductPod
                  itemId={itemId}
                  storeId={storeId}
                  analyticsData={{ parent: 'product-set', position: 1 }}
                  render={({ itemId: podItemId = '' }) => (
                    <div className="sui-p-3">
                      <PodSticker position="top-left">
                        <ProductBadge itemId={itemId} storeId={storeId} />
                      </PodSticker>
                      <div className="sui-grid sui-grid-cols-1 md:sui-grid-cols-2 lg:sui-grid-cols-2">
                        <div className="sui-flex sui-flex-col" data-testid="drawer-image">
                          <ProductImage itemId={itemId} onClick={openQuickView} />
                        </div>
                        <div className="sui-flex sui-flex-col">
                          <PodColumn>
                            <PodSection columnAlign alignTop>
                              <div className="sui-mt-0 sui-ml-0" data-testid="drawer-price">
                                <Price
                                  itemId={podItemId}
                                  large={false}
                                  storeId={storeId}
                                  displayEachUom={false}
                                />
                              </div>
                              <ProductRating
                                itemId={itemId}
                                onClick={(event) => {
                                  event.preventDefault();
                                  setIsRatingOpen(true);
                                  openQuickView(event);
                                }}
                              />
                              <div className="sui-mt-1" data-testid="drawer-header">
                                <ProductHeader
                                  brand="above"
                                  itemId={itemId}
                                  onClick={openQuickView}
                                />
                              </div>
                            </PodSection>
                          </PodColumn>
                        </div>
                      </div>
                    </div>
                  )}
                />
              </ProductPodWrapper>
            </QueryProvider>
            <hr />
            <div
              className="sui-grid sui-grid-cols-1 md:sui-grid-cols-2 lg:sui-grid-cols-2
                  sui-gap-4 sui-m-2 cta-buttons"
              data-testid="cta-buttons"
            >
              <SwapButton
                itemId={itemId}
                swapProducts={swapProducts}
                closeDrawer={closeDrawer}
                buttonText="Swap for Similar"
                setSelectedItemId={setSelectedItemId}
                isMobile={isMobile}
              />
              <RemoveButton
                itemId={itemId}
                onRemove={() => {
                  removeProduct(itemId);
                  closeDrawer();
                }}
                buttonVariant="primary"
              />
            </div>
          </DrawerBody>
        </div>
      </Drawer>
      {typeof window !== 'undefined' && isQuickViewOpen && (
        <Suspense fallback={<div />}>
          <QuickViewLazy
            isOpen={isQuickViewOpen}
            onClose={() => {
              setIsQuickViewOpen(false);
              setIsRatingOpen(false);
            }}
            fetch={isQuickViewOpen}
            itemId={itemId}
            isRatingsOpen={isRatingOpen}
            paginateCarousel={false}
            openDetailsInNewTab
            triggerViewFullDetailsAnalytics={triggerViewFullDetailsAnalytics}
            defaultVariables={defaultVariables}
          />
        </Suspense>
      )}
    </div>
  );
};

RemoveDrawer.displayName = 'RemoveDrawer';

RemoveDrawer.propTypes = {
  closeDrawer: func.isRequired,
  removeProduct: func.isRequired,
  swapProducts: func.isRequired,
  itemId: string.isRequired,
  storeId: string.isRequired,
  isDrawerOpen: bool.isRequired,
  loading: bool.isRequired,
  isMobile: bool.isRequired,
  setSelectedItemId: func.isRequired
};