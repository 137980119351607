import { useDataModel } from '@thd-nucleus/data-sources';
import { validateNValue } from '../utils/utils';
import { HybridLayoutContainer } from '../components/HybridLayoutContainer';

export const useHybridPLP = ({
  slug,
  customerType,
  instance,
  storeId,
  skip,
  isShowProducts,
}) => {

  const { data, loading, error } = useDataModel('layouts', {
    variables: {
      slug: isShowProducts ? slug + '-ShowProducts' : slug,
      customerType,
      isHybrid: validateNValue(slug)
    },
    ssr: true,
    skip
  });

  return HybridLayoutContainer(data, loading, error, slug, customerType, instance, storeId);

};
