/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDataModel } from '@thd-nucleus/data-sources';
import { string, func } from 'prop-types';
import { withDynamicComponent } from '@thd-nucleus/app-render';
import { withErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { withHydrator } from '@thd-olt-component-react/hydrator';
import { useStoreId } from '@thd-nucleus/experience-context';
import { PackageLite } from './subcomponents/PackageLite';
import { PackagesMiniContext } from './PackagesMiniContext';
import './packages-mini.scss';
import { dataModel } from './dataModel';
import { PackagesMiniPlaceholder } from './loader/packagesMiniLoader';
import { getPackageData } from '../utils';

const PackagesMiniLiteComponent = ({ itemId, callOnLoaded }) => {
  useEffect(() => { LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('packages-mini.ready'); }, []);
  const storeId = useStoreId();
  let previousUrl = '';
  if (typeof window === 'object') {
    previousUrl = document.referrer;
  }
  const previousUrlMatch = previousUrl.match(/package_([a-zA-Z]|[0-9])*/);

  const { loading, data, error } = useDataModel('packages', {
    variables: {
      packageId: previousUrlMatch ? previousUrlMatch?.[0] : undefined,
      itemId: previousUrlMatch ? undefined : itemId,
      storeId,
      limit: previousUrlMatch ? 1 : null,
    },
    context: {
      withAuth: true
    },
    ssr: true
  });

  const filteredData = data?.packages?.filter((bundle) => bundle?.products?.length > 1 && bundle?.metadata);
  const hasEnoughPackageData = !!filteredData?.length;

  useEffect(() => {
    if (data) {
      callOnLoaded({ didRender: hasEnoughPackageData });
    }
  }, [data]);

  if (loading) return <PackagesMiniPlaceholder isPackageLite />;

  if (!data?.packages?.[0]?.metadata || !data?.packages?.[0]?.products || error) {
    return null;
  }

  const { selectedProducts, packageModel, itemMap } = getPackageData(data);

  return (
    <div className="packages-mini" data-testid="packages-mini-lite" data-component="PackagesMiniLite">
      <PackagesMiniContext.Provider value={{
        products: selectedProducts,
        packageModel
      }}
      >
        <PackageLite itemMap={itemMap} />
      </PackagesMiniContext.Provider>
    </div>
  );
};

const propTypes = {
  itemId: string,
  callOnLoaded: func
};

const defaultProps = {
  itemId: '',
  callOnLoaded: () => {},
};

PackagesMiniLiteComponent.displayName = 'PackagesMiniLite';
PackagesMiniLiteComponent.propTypes = propTypes;
PackagesMiniLiteComponent.defaultProps = defaultProps;
PackagesMiniLiteComponent.dataModel = dataModel;

const HydratedComponent = withHydrator({
  id: 'product-section-packages-mini',
  scrollBuffer: 100,
  delay: 1500,
  preserveCtxVal: 'clientStore',
  placeholder: <PackagesMiniPlaceholder isPackageLite />
}, PackagesMiniLiteComponent);

const DynamicComponent = withDynamicComponent(HydratedComponent,
  { placeholder: <PackagesMiniPlaceholder isPackageLite /> }
);

const PackagesMiniLite = withErrorBoundary(DynamicComponent);

export { PackagesMiniLite };
