import React from 'react';
import { func, number, arrayOf, string, oneOf } from 'prop-types';
import classNames from 'classnames';
import { SpecialSavings, RadioButtonChecked } from '@one-thd/sui-icons';
import { imageStyle } from '../../helpers';
import { HOTSPOT_TYPE } from '../../constants';

const LifeStyleHotspot = ({
  currentTagIndex, index, xCoordinate, yCoordinate, type, handleClick, collapsedCount
}) => {

  return (
    <button
      tabIndex="0"
      type="button"
      className={currentTagIndex === index ? 'tag tippy-active' : 'tag'}
      id="hotspot-container"
      onClick={handleClick}
      style={imageStyle(xCoordinate, yCoordinate)}
      aria-label={type === HOTSPOT_TYPE.PIP_HOTSPOTS ? 'PIP Hotspot Tag' : 'Drawer Hotspot Tag'}
    >
      <span
        className={
          // eslint-disable-next-line max-len
          classNames('sui-bg-primary sui-absolute sui-inset-0 sui-opacity-100 sui-pointer-events-none sui-rounded-full',
            {
              'sui-m-auto sui-h-3 sui-w-3': currentTagIndex !== index,
              'sui-h-10 sui-w-10': currentTagIndex === index
            })
        }
        style={currentTagIndex === index ? { margin: '-8px' } : {}}
      />
      {!!collapsedCount && (
        <div
          tabIndex="-1"
          className={classNames('collapsed-circle-tag-icon', {
              'sui-relative': currentTagIndex !== index,
              'sui-hidden': currentTagIndex === index,
            },
            'sui-w-6',
            'sui-h-6',
            'sui-bg-inverse',
            'sui-text-inverse',
            'sui-font-bold',
            'sui-text-lg',
            'sui-leading-tight',
            'sui-border-1',
            'sui-border-solid',
            'sui-border-white',
            'sui-rounded-full',
          )}
          role={
            type === HOTSPOT_TYPE.PIP_HOTSPOTS
              ? 'circleIcon collapsedCircleIcon'
              : 'drawerCircleIcon drawerCollapsedCircleIcon'
          }
          aria-label="collapsed tag icon"
          // TODO: Remove the style override below as soon as the Stencil utility class `sui-bg-inverse` is translating
          // to the correct color value. Should be after this story is done: https://thd.atlassian.net/browse/BRO1-388
          style={{ backgroundColor: 'rgb(51,51,51)' }}
        >
          {collapsedCount}
        </div>
      )}
      {!collapsedCount && (
        <div
          tabIndex="-1"
          className={classNames('circle-tag-icon', {
            'sui-relative': currentTagIndex !== index,
            'sui-hidden': currentTagIndex === index,
          })}
          role={type === HOTSPOT_TYPE.PIP_HOTSPOTS ? 'circleIcon' : 'drawerCircleIcon'}
          aria-label="default tag icon"
        >
          <RadioButtonChecked />
        </div>
      )}
      <div
        tabIndex="-1"
        className={classNames('shoppable-tag-icon', {
          'sui-relative': currentTagIndex === index,
          'sui-hidden': currentTagIndex !== index,
        })}
        role={type === HOTSPOT_TYPE.PIP_HOTSPOTS ? 'hotspotIcon' : 'drawerHotspotIcon'}
        aria-label="clicked hotspot tag icon"
      >
        <SpecialSavings />
      </div>
    </button>
  );
};

LifeStyleHotspot.displayName = 'LifeStyleHotspot';

LifeStyleHotspot.propTypes = {
  xCoordinate: number.isRequired,
  yCoordinate: number.isRequired,
  currentTagIndex: number,
  index: number,
  type: oneOf([HOTSPOT_TYPE.DRAWER_HOTSPOTS, HOTSPOT_TYPE.PIP_HOTSPOTS]),
  handleClick: func,
  collapsedCount: number,
};

LifeStyleHotspot.defaultProps = {
  currentTagIndex: null,
  index: 0,
  type: HOTSPOT_TYPE.PIP_HOTSPOTS,
  handleClick: null,
  collapsedCount: null,
};

export { LifeStyleHotspot };
