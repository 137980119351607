/* eslint-disable no-param-reassign */
import React, { useContext, useState, useEffect } from 'react';
import { bool, string, object } from 'prop-types';
import { ExperienceContext, useStore, useConfigService } from '@thd-nucleus/experience-context';
import {
  arrayOf as arrayOfType,
  bool as boolType,
  number as numberType,
  shape as shapeType,
  string as stringType,
  useDataModel, params, client
} from '@thd-nucleus/data-sources';
import { useImpression } from '@thd-olt-component-react/impression';
import { FulfillmentPodPlaceholder } from './FulfillmentPodPlaceholder';
import { getStoreTemplate } from '../helpers/store-helper';
import {
  isBrioProduct,
  isCustomKitchenCabinetProduct,
  isPodStoreEqual
} from '../helpers/pod-fulfillment-utils';
import { EmptyStoreMessage } from '../partials/store/EmptyStoreMessage';
import { BundleFulfillmentMessage } from '../partials/store/BundleFulfillmentMessage';
import { renderPickupTemplates } from '../templates/pickUpTemplate';
import { CHECK_NEARBY_STORE } from '../../components/constants';

export const FulfillmentPodStore = React.memo(({
  itemId = '', storeId: primaryStoreId, zipCode, useCustomTemplate, hideForChooseYourOptions, directData
}) => {
  const { storeId: secondaryStoreId } = useStore();
  const storeId = primaryStoreId || secondaryStoreId;
  const { customer } = useContext(ExperienceContext);
  const [product, setProduct] = useState(null);
  const isHideLimitedStockClearanceEnabled = !!useConfigService('fs:isHideLimitedStockClearanceEnabled');
  const { additionalData = {} } = useImpression({
    data: {
      id: itemId,
      component: 'ProductStore',
      name: 'ProductStore',
      position: 0
    }
  });
  const opts = {
    variables: {
      itemId,
      storeId,
      zipCode
    },
    skip: !itemId || !!directData,
    ssr: false
  };
  const { data, loading, error } = useDataModel('product', opts);

  const productData = data?.product || directData;

  useEffect(() => {
    if (productData) {
      setProduct(productData);
    }
  }, [productData]);

  if (loading || (
    typeof (data) === 'undefined' && !error && !product
  )) return <FulfillmentPodPlaceholder />;
  if (!product) { return EmptyStoreMessage; }

  const bundleFlag = !!product?.bundleFlag;
  const fulfillmentBundleMessage = product?.fulfillment?.fulfillmentBundleMessage;

  const nullConditions = [
    hideForChooseYourOptions && product?.info?.isGenericProduct,
    product?.info?.productSubType?.name === 'HDQC',
    !!product?.installServices?.scheduleAMeasure,
    isCustomKitchenCabinetProduct(product),
    isBrioProduct(product)
  ];

  if (nullConditions.some((nullCondition) => nullCondition)) {
    return null;
  }

  const { parent } = additionalData || {};

  const handleClick = () => {
    LIFE_CYCLE_EVENT_BUS.trigger('product-pod-v7.click', {
      podAction: CHECK_NEARBY_STORE,
      podAnchorSku: itemId,
      parent,
      target: ''
    });
  };

  const StoreComponent = getStoreTemplate(
    {
      product,
      storeId,
      customer,
      isHideLimitedStockClearanceEnabled,
      customRender: useCustomTemplate ? renderPickupTemplates : null
    });

  if (!StoreComponent) { return EmptyStoreMessage; }

  if (bundleFlag) {
    return (
      <span className="sui-leading-tight" data-component="FulfillmentPodStore">
        <BundleFulfillmentMessage
          fulfillmentBundleMessage={fulfillmentBundleMessage}
        />
      </span>
    );
  }

  return (
    <span className="sui-leading-tight" data-component="FulfillmentPodStore">
      <StoreComponent
        product={product}
        onCheckNearbyStoresClick={handleClick}
      />
    </span>
  );
}, isPodStoreEqual);

FulfillmentPodStore.propTypes = {
  itemId: string.isRequired,
  storeId: string,
  zipCode: string,
  useCustomTemplate: bool,
  hideForChooseYourOptions: bool,
  // eslint-disable-next-line react/forbid-prop-types
  directData: object
};

FulfillmentPodStore.defaultProps = {
  useCustomTemplate: false,
  storeId: undefined,
  zipCode: undefined,
  hideForChooseYourOptions: false,
  directData: null
};

const installServices = params({ storeId: stringType(), zipCode: stringType() }).shape({
  scheduleAMeasure: boolType()
});
if (installServices.skip) {
  installServices.skip('skipInstallServices', true);
}

const Service = shapeType({
  hasFreeShipping: boolType(),
  freeDeliveryThreshold: numberType({ float: true }),
  earliestDeliveryDate: stringType(),
  locations: arrayOfType(shapeType({
    curbsidePickupFlag: boolType(),
    isBuyInStoreCheckNearBy: boolType(),
    distance: numberType({ float: true }),
    inventory: shapeType({
      isOutOfStock: boolType(),
      isInStock: boolType(),
      isLimitedQuantity: boolType(),
      isUnavailable: boolType(),
      quantity: numberType(),
      maxAllowedBopisQty: numberType(),
      minAllowedBopisQty: numberType()
    }),
    isAnchor: boolType(),
    locationId: stringType(),
    state: stringType(),
    storeName: stringType(),
    storePhone: stringType(),
    type: stringType()
  })),
  type: stringType(),
  totalCharge: numberType({ float: true })
});

FulfillmentPodStore.dataModel = {
  product: params({
    itemId: stringType().isRequired(),
    dataSource: stringType()
  })
    .shape({
      itemId: stringType(),
      bundleFlag: boolType(),
      info: shapeType({
        globalCustomConfigurator: shapeType({
          customExperience: stringType()
        }),
        productSubType: shapeType({
          name: stringType()
        }),
        isBrioSku: boolType(),
        isCustomKitchenCabinet: boolType(),
        isGenericProduct: boolType()
      }),
      dataSources: stringType(),
      availabilityType: shapeType({
        buyable: boolType(),
        discontinued: boolType(),
        status: boolType(),
        type: stringType()
      }),
      fulfillment: client(params({ storeId: stringType(), zipCode: stringType() }).shape({
        anchorStoreStatus: boolType(),
        anchorStoreStatusType: stringType(),
        backordered: boolType(),
        backorderedShipDate: stringType(),
        bossExcludedShipStates: stringType(),
        excludedShipStates: stringType(),
        seasonStatusEligible: boolType(),
        fulfillmentOptions: arrayOfType(shapeType({
          type: stringType(),
          fulfillable: boolType(),
          services: arrayOfType(Service)
        })),
        fulfillmentBundleMessage: stringType()
      })),
      installServices
    })
};

FulfillmentPodStore.displayName = 'FulfillmentPodStore';