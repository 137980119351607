import React, { useContext } from 'react';
import classNames from 'classnames/bind';

import { Breadcrumbs } from '@thd-olt-component-react/breadcrumbs';
import { Row } from '@thd-olt-component-react/core-ui';

import { ColorTile } from '../ColorTile/ColorTile';
import { ColorDetailsContext, ColorFamilyContext, LayoutContext } from '../../context/Contexts';
import { getColorFamilyName } from '../../utils/utils';
import SeoCopyBlock from '../SeoCopyBlock/SeoCopyBlock';
import ValueMessagingBanner from '../ValueMessagingBanner/ValueMessagingBanner';
import { ColorFamilies } from '../ColorFamilies/ColorFamilies';
import { PromoBanners } from '../PromoBanners/PromoBanners';
import { RelatedItemsSection } from '../RelatedItemsSection/RelatedItemsSection';
import LoadingOverlay from '../LoadingOverlay/LoadingOverlay';
import { CompareFooter } from '../Compare/CompareFooter/CompareFooter';
import { Title } from '../Title/Title';
import { STEP_2 } from '../../constants/Constants';
import NavBar from '../NavBar/NavBar';
import { BrandFilters } from '../BrandFilters/BrandFilters';

import metadataStyles from './MetaData.module.scss';
import colorGridStyles from './ColorGrid.module.scss';

import './colorwall.scss';

const cx = classNames.bind({ ...metadataStyles, ...colorGridStyles });

const ColorWall = () => {
  const { channel } = useContext(LayoutContext);

  const {
    activeBrand,
    colorFamilyData,
    colorFamilyLoading,
    currentColorFamily,
    setNavBarIndex
  } = useContext(ColorFamilyContext);
  const { colorDetailsLoading, setColorNumber, setColorDetailBrand } = useContext(ColorDetailsContext);

  const colorFamilyName = getColorFamilyName(currentColorFamily);
  const { label: brand } = activeBrand;

  const staticCrumbs = [
    {
      label: 'Home',
      dimensionName: 'Home',
      url: '',
    }
  ];
  if (colorFamilyData?.paint?.breadCrumbs) {
    colorFamilyData.paint.breadCrumbs.forEach((crumb) => {
      staticCrumbs.push(crumb);
    });
  }

  return (
    <div className="sui-w-full sui-flex sui-flex-auto sui-flex-wrap" data-component="ColorWall">
      <Row>
        <Breadcrumbs staticCrumbs={staticCrumbs} />
      </Row>
      <ValueMessagingBanner />
      <div className="sui-bg-primary sui-w-full">
        <Row>
          <h1 className={cx('seoHeader')} data-bugbear-id="seoHeader">
            {`Explore ${colorFamilyName || ''} Paint Colors ${brand ? `from ${brand}` : ''}`}
          </h1>
        </Row>
        <ColorFamilies />
        {colorFamilyData?.paint?.navBars?.length > 1
          && (
            <>
              <Row>
                <Title step={STEP_2} title={`Select a shade of ${currentColorFamily} ${channel === 'DESKTOP' ? 'below' : ''}`} />
              </Row>
              <Row className="sui-justify-center">
                <NavBar
                  data={colorFamilyData?.paint?.navBars || []}
                  onNavBarClick={(navItem) => {
                    const { navBarIndex } = navItem;
                    setNavBarIndex(navBarIndex);
                    setColorNumber(null);
                    setColorDetailBrand(null);
                  }}
                  maxTiles="15"
                />
              </Row>
            </>
          )}
        <Row>
          <BrandFilters />
        </Row>
        {colorFamilyData?.paint?.colors?.length > 0 && (
          <div className={cx('colorGridContainer')} data-bugbear-id="colorGridContainer">
            {colorFamilyData.paint.colors.map((tile, index) => (
              <ColorTile
                key={`${tile.colorNumber}-${index}`}
                tileColorName={tile.colorName}
                tileRgb={tile.rgb}
                tileColorBrand={tile.vendor}
                tileColorNumber={tile.colorNumber}
              />
            ))}
          </div>
        )}
      </div>
      <PromoBanners channel={channel || 'mobile'} />
      <RelatedItemsSection />
      <SeoCopyBlock />
      { (colorFamilyLoading || colorDetailsLoading) && <LoadingOverlay /> }
      <CompareFooter />
    </div>
  );
};

ColorWall.displayName = 'ColorWall';

export { ColorWall };
