import React, { useEffect, useRef, useState } from 'react';
import { useDataModel } from '@thd-nucleus/data-sources';
import THDCustomer from '@thd-olt-global/thd-customer';
import {
  string as stringType,
  number as numberType,
  bool as boolType,
  func as functionType
} from 'prop-types';
import classNames from 'classnames';
import { RecentlyViewedCarousel } from './RecentlyViewedCarousel';
import { recentlyViewedDataModel } from '../datamodel';
import { CONSTANTS } from '../../utils/constants';

const RecentlyViewedTypeahead = (props) => {
  const {
    brandTitleMaxLine,
    hideCarouselArrows,
    isMobile,
    moduleName,
    storeIdProp,
    userIdProp,
    zipCodeProp,
    onRVDataLoaded
  } = props;

  const userId = userIdProp || THDCustomer?.svocID || THDCustomer?.userID || THDCustomer?.guestUserID;

  const pAPIKey = CONSTANTS.API_KEY;

  const localizerRef = useRef(null);

  const [storeId, setStoreId] = useState(null);
  const [zipCode, setZipCode] = useState(null);

  useEffect(() => {
    import('Localizer')
      .then((localizer) => {
        localizerRef.current = localizer;
        const localizedStore = storeIdProp ? { storeId: storeIdProp } : localizerRef.current.getLocalizedStore();
        const zipDetails = zipCodeProp ? { zipcode: zipCodeProp } : localizerRef.current?.getDeliveryZipcodeDetails();
        setStoreId(localizedStore.storeId);
        setZipCode(zipDetails?.zipcode);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.warn(`Error occurred while loading Localizer - ${error}`);
      });
  }, []);

  const opts = {
    variables: {
      storeId,
      key: pAPIKey,
      dataSource: 'rv_typeahead_rr',
      userId,
      unique: true,
      apiName: 'views',
      appId: 'desktop',
    },
    skip: !userId,
    ssr: true,
    fetchPolicy: 'network-only'
  };

  const { loading: recsLoading, data, error: recsError } = useDataModel('uds', opts);

  useEffect(() => {
    if (data?.uds?.products?.length > 0) {
      onRVDataLoaded({ hasData: true });
    }
  }, [data?.uds]);

  // skip rendering if userId is not available
  if (!userId) {
    return null;
  }

  const wrapperClasses = classNames(
    'sui-w-full', 'sui-pt-0', 'sui-pb-0',
    'sui-p-4',
    moduleName,
  );

  if (recsError || (data === undefined) || (!(data?.uds?.products?.length > 0) && !recsLoading)) {
    return null;
  }

  const { products, metadata } = data?.uds || {};
  const productsToRender = products?.length > 0 ? products.slice(0, CONSTANTS.MAX_NO_PRODUCTS) : null;
  const title = metadata?.title;

  return (
    <>
      {
        productsToRender && (
          <div
            id={`${moduleName}-typeahead`}
            className={wrapperClasses}
            data-testid="recently-viewed-typeahead"
            data-component="RecentlyViewed"
          >
            <meta data-prop="name" content={`${moduleName}-typeahead`} />
            <RecentlyViewedCarousel
              brandTitleMaxLine={brandTitleMaxLine}
              hideCarouselArrows={hideCarouselArrows}
              isMobile={isMobile}
              moduleName={moduleName}
              parent="recently-viewed"
              products={productsToRender}
              title={title}
              storeId={storeId}
              userId={userId}
              zipCode={zipCode}
            />
          </div>
        )
      }
    </>
  );
};

RecentlyViewedTypeahead.propTypes = {
  brandTitleMaxLine: numberType,
  moduleName: stringType,
  isMobile: boolType,
  hideCarouselArrows: boolType,
  storeIdProp: stringType,
  userIdProp: stringType,
  zipCodeProp: stringType,
  onRVDataLoaded: functionType
};

RecentlyViewedTypeahead.defaultProps = {
  brandTitleMaxLine: 2,
  hideCarouselArrows: false,
  isMobile: false,
  moduleName: 'recentlyViewed',
  storeIdProp: null,
  userIdProp: null,
  zipCodeProp: null,
  onRVDataLoaded: () => { }
};

RecentlyViewedTypeahead.displayName = 'RecentlyViewedTypeahead';

RecentlyViewedTypeahead.dataModel = recentlyViewedDataModel;
export { RecentlyViewedTypeahead };
