import React, { useContext } from 'react';
import classNames from 'classnames';
import {
  arrayOf,
  bool,
  number,
  string,
} from 'prop-types';
import {
  IconButton,
  Typography
} from '@one-thd/sui-atomic-components';
import { TrashCan } from '@one-thd/sui-icons';
import { CalculatorContext } from './subcomponents/CalculatorContext';
import { CalculatorInputElement } from './subcomponents/CalculatorInputElement';

// Don't import this into FlooringPicker
export const CalculatorInputSections = ({
  dimensionNames,
  hideAreaText,
  showNameInputs,
  selectedArea
}) => {
  const {
    fields,
    calculatorType,
    removeInputElement
  } = useContext(CalculatorContext);

  const [dimension1, dimension2, dimension3] = dimensionNames;

  return (
    <div id="calc-input-sections" className="sui-flex sui-flex-col sui-gap-2 sui-overflow-auto sui-max-h-80">
      {fields.map((field, index) => {
        if (selectedArea !== null && index !== selectedArea) {
          return null;
        }

        return (
          <div key={field.id}>
            <div className="sui-flex sui-items-center">
              {!hideAreaText && (
                <div className="sui-mr-4">
                  <Typography weight="bold">{field.name}</Typography>
                </div>
              )}
              {selectedArea === null && (fields.length !== 1) && (
                <IconButton
                  data-testid="delete-area"
                  icon={TrashCan}
                  color="brand"
                  onClick={() => removeInputElement(index)}
                />
              )}
            </div>
            <div className={
              classNames('sui-mt-1 sui-flex sui-gap-4 sui-flex-col',
                {
                  'sm:sui-flex-row': selectedArea === null
                }
              )
            }
            >
              {showNameInputs && (
                <CalculatorInputElement
                  index={index}
                  propName="name"
                  labelName="Description"
                  propValue={field.name}
                />
              )}
              {!field.calcByArea && (
                <>
                  <CalculatorInputElement
                    index={index}
                    labelName={dimension1}
                    propName="length"
                    propValue={field.length}
                    endAdornment="ft."
                  />
                  <CalculatorInputElement
                    index={index}
                    labelName={dimension2}
                    propName="width"
                    propValue={field.width}
                    endAdornment="ft."
                  />
                </>
              )}
              {field.calcByArea && (
                <CalculatorInputElement
                  index={index}
                  labelName="Square Footage"
                  propName="sqFootage"
                  propValue={field.sqFootage}
                  endAdornment="sq. ft."
                />
              )}
              {calculatorType === 'Volume' && (
                <CalculatorInputElement
                  index={index}
                  labelName={dimension3}
                  propName="height"
                  propValue={field.height}
                  endAdornment="in."
                />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

CalculatorInputSections.displayName = 'CalculatorInputSections';

CalculatorInputSections.propTypes = {
  dimensionNames: arrayOf(string),
  hideAreaText: bool,
  selectedArea: number,
  showNameInputs: bool,
};

CalculatorInputSections.defaultProps = {
  dimensionNames: ['Width', 'Height'],
  hideAreaText: false,
  selectedArea: null,
  showNameInputs: false
};

export default CalculatorInputSections;