import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDataModel } from '@thd-nucleus/data-sources';
import { ThemeProvider } from '@one-thd/sui-atomic-components';
import { getThemeByName } from '@thd-olt-component-react/stencil-themes';
import { useThdCustomer } from '@thd-olt-functional/customer-information';
import { bool, string, oneOf } from 'prop-types';
import { HeaderSection } from './HeaderSection';
import { CategoryTabs } from './CategoryTabs';
import { CategoryCarousel } from './CategoryCarousel';
import { EndcapPlaceholder } from './EndcapPlaceholder';
import { dataModel } from './dataModel';

const Endcap = ({
  categoryGuid = null,
  channel = 'DESKTOP',
  customerType = 'B2C',
  entryId = '',
  headerWeight = 'display',
  isABTesting = false,
  isUppercase = true,
  narrowBottom = false,
  pageUrl,
  personalizedDealsUserid = '',
  runQueryServerSide = true,
  storeId = '121',
  theme = ''
}) => {
  const [navParam, setNavParam] = useState(null);
  const [newCategoryGuid, setNewCategoryGuid] = useState(categoryGuid);
  const [recsAlgorithm, setRecsAlgorithm] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const eventsMetadata = useRef({});
  const endCapMetadata = useRef({});
  const [mounted, setMounted] = useState(false);
  const { isCustomerIdentified } = useThdCustomer() || {};

  const options = {
    variables: {
      customerType,
      pageUrl,
      storeId,
      personalizedDealsUserid,
      categoryGuid: newCategoryGuid,
      maxProducts: 15,
      shouldSkipMetadata: !!navParam,
      skipInstallServices: true,
      navParam,
      recsAlgorithm,
      isABTesting
    },
    ssr: runQueryServerSide
  };

  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('endcap.ready');
    LIFE_CYCLE_EVENT_BUS.lifeCycle.on('endcap.tnt', () => { options.variables.isABTesting = true; });
    setMounted(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data, loading, error } = useDataModel('endCap', options);

  if (data?.endCap?.eventsMetadata) {
    eventsMetadata.current = {
      ...data?.endCap?.eventsMetadata,
      categories: data?.endCap.personalizedDealsEndCap
      && data?.endCap?.eventsMetadata?.categories?.length > 1
        ? [
          {
            category: 'All',
            nValue: null,
            imageUrl: null,
            url: null,
            shopAllLink: null,
            exclude: null,
            displayImageOnMobileWeb: false,
          },
          ...data?.endCap?.eventsMetadata?.categories,
        ]
        : data?.endCap?.eventsMetadata?.categories,
    };
  }
  if (data?.endCap?.topDealsEndCap) {
    endCapMetadata.current = data?.endCap?.topDealsEndCap;
  } else if (data?.endCap?.personalizedDealsEndCap?.products) {
    endCapMetadata.current = data?.endCap?.personalizedDealsEndCap;
  } else {
    endCapMetadata.current = {};
  }

  const handleTabClick = useCallback(
    (event, index, nValue, nameOfEventAttribute) => {
      event.preventDefault();
      let eventAttributeName = nameOfEventAttribute !== 'Model2' ? nameOfEventAttribute : 'Model2';
      setActiveIndex(index);
      setNavParam(nValue);
      setNewCategoryGuid(nValue);
      setRecsAlgorithm(eventAttributeName);

      if (typeof window !== 'undefined') {
        const swiperElement = document.querySelector(
          '.endcap .swiper-container'
        );
        if (swiperElement) swiperElement.swiper.slideTo(0);
      }
    },
    []
  );

  if ((mounted && !data && !error) || (!mounted && !runQueryServerSide)) {
    return <EndcapPlaceholder />;
  }

  if (handleTabClick && loading && data) {
    return (
      <div
        className="sui-w-full sui-h-full sui-border-none"
        data-component="Endcap"
      >
        <div className="sui-flex sui-flex-col">
          {data?.endCap?.eventsMetadata !== null && (
            <div className="sui-grow">
              <HeaderSection
                header={eventsMetadata.current?.header}
                start={eventsMetadata.current?.start}
                end={eventsMetadata.current?.end}
                bannerImage={eventsMetadata.current?.bannerImage}
                headerWeight={headerWeight}
                isUppercase={isUppercase}
                mobileAppBannerImg={eventsMetadata.current?.mobileAppBannerImg}
                headerLinkText={eventsMetadata.current?.headerLinkText}
                headerLinkUrl={eventsMetadata.current?.headerLinkUrl}
                timerDuration={eventsMetadata.current?.timerDuration}
                badgeUrl={eventsMetadata.current?.badgeUrl}
                channel={channel}
                timer={eventsMetadata.current?.timer}
                subHeader={eventsMetadata.current?.subHeader}
                endCapData={eventsMetadata.current}
              />
            </div>
          )}
          {eventsMetadata.current?.categories?.length > 1 && (
            <div className="sui-grow">
              <CategoryTabs
                categories={eventsMetadata.current?.categories}
                endCapData={endCapMetadata.current}
                nameOfEventAttribute={
                    eventsMetadata.current?.nameOfEventAttribute || 'Model2'
                }
                channel={channel}
                title={eventsMetadata.current?.header}
                handleTabClick={handleTabClick}
                activeIndex={activeIndex}
              />
              <EndcapPlaceholder tabClick />
            </div>
          )}
          {eventsMetadata.current?.categories?.length === 1 && (
            <div className="sui-grow">
              <EndcapPlaceholder tabClick />
            </div>
          )}
        </div>
      </div>
    );
  }

  if ((!data && !loading) || (error && !data)) {
    return null;
  }

  if (Object.keys(eventsMetadata.current).length === 0) return null;

  return (
    <ThemeProvider theme={getThemeByName(theme)}>
      <div
        className="sui-w-full sui-h-full sui-border-none sui-p-4 sui-bg-primary"
        data-component="Endcap"
      >
        <div className="sui-flex sui-flex-col">
          {data?.endCap?.eventsMetadata !== null && (
            <div className="sui-grow">
              <HeaderSection
                header={eventsMetadata.current?.header}
                start={eventsMetadata.current?.start}
                end={eventsMetadata.current?.end}
                bannerImage={eventsMetadata.current?.bannerImage}
                headerWeight={headerWeight}
                isUppercase={isUppercase}
                mobileAppBannerImg={eventsMetadata.current?.mobileAppBannerImg}
                headerLinkText={eventsMetadata.current?.headerLinkText}
                headerLinkUrl={eventsMetadata.current?.headerLinkUrl}
                timerDuration={eventsMetadata.current?.timerDuration}
                badgeUrl={eventsMetadata.current?.badgeUrl}
                channel={channel}
                timer={eventsMetadata.current?.timer}
                subHeader={eventsMetadata.current?.subHeader}
                endCapData={eventsMetadata.current}
              />
            </div>
          )}
          {eventsMetadata.current?.categories?.length > 1 && (
            <div className="sui-grow">
              <CategoryTabs
                categories={eventsMetadata.current?.categories}
                nameOfEventAttribute={
                  eventsMetadata.current?.nameOfEventAttribute || 'Model2'
                }
                handleTabClick={handleTabClick}
                activeIndex={activeIndex}
              />
              <CategoryCarousel
                endCapData={endCapMetadata.current}
                exclude={eventsMetadata.current?.categories[activeIndex].exclude}
                categoryName={
                  eventsMetadata.current?.categories[activeIndex].category
                } // eslint-disable-line
                categoryImageURL={
                  eventsMetadata.current?.categories[activeIndex].imageUrl
                } // eslint-disable-line
                categoryURL={eventsMetadata.current?.categories[activeIndex].url}
                categoryShopAllLink={eventsMetadata.current?.categories[activeIndex].shopAllLink || ''}
                title={eventsMetadata.current?.header}
                entryId={entryId}
                storeId={storeId}
                theme={theme}
              />
            </div>
          )}
          {eventsMetadata.current?.categories?.length === 1 && (
            <div className="sui-grow">
              <CategoryCarousel
                endCapData={endCapMetadata.current}
                exclude={eventsMetadata.current?.categories[0].exclude}
                categoryName={eventsMetadata.current?.categories[0].category}
                categoryImageURL={eventsMetadata.current?.categories[0].imageUrl}
                categoryURL={eventsMetadata.current?.categories[0].url}
                title={eventsMetadata.current?.header}
                entryId={entryId}
                storeId={storeId}
                theme={theme}
              />
            </div>
          )}
        </div>
      </div>
    </ThemeProvider>
  );
};

Endcap.displayName = 'Endcap';
Endcap.propTypes = {
  categoryGuid: string,
  channel: oneOf(['DESKTOP', 'MOBILE']),
  customerType: oneOf(['B2C', 'B2B', 'B2X']),
  entryId: string,
  headerWeight: string,
  isABTesting: bool,
  isUppercase: bool,
  narrowBottom: bool,
  pageUrl: string.isRequired,
  personalizedDealsUserid: string,
  runQueryServerSide: bool,
  storeId: string,
  theme: string,
};
Endcap.dataModel = dataModel;

export { Endcap };