import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import getGccProductDetails from '../actions/getGccProductDetails';
import getSkuMap from '../actions/getSkuMap';
import { addGccConfigurationToCart } from '../actions/cartActions';
import { updatePath } from '../actions/pathActions';
import { getPricing } from '../actions/pricingActions';
import { updateConfigurationId, updateConfiguratorMode, updateIsCopy } from '../actions/configAction';
import { updatelineItemId } from '../actions/lineItemAction';
import choiceAndSkuAvailability from '../actions/outOfStock/choiceAndSkuAvailability';
import { setHydrationStart, setHydrationComplete } from '../actions/hydration/orchestration';
import ProductConfigurator from './ProductConfigurator';
import { CONFIGURATOR, LIFE_CYCLE_EVENT_CONFIGURATOR_DATA } from '../actions/hydration/constants';
import { hydrationSelector } from '../selectors/hydration/hydrationSelector';
import { getParamsFromUrl, getConfiguratorModeFromUrl } from '../utils/pathUtils';
import {
  UPDATE_CART_PRODUCT,
  CLEAR_ERROR_GROUPS,
} from '../actions/actionTypes';
import { cartProductSelector } from '../selectors';

const mapDispatchToProps = (dispatch) => ({
  onMount: () => {
    dispatch(setHydrationStart(CONFIGURATOR));
    const { id: configId, lineItemId: lineItemGuid, quantity } = getParamsFromUrl();
    if (configId) {
      dispatch(updateConfigurationId(configId));
    }

    if (lineItemGuid) {
      dispatch(updatelineItemId(lineItemGuid));
    }

    if (getConfiguratorModeFromUrl('edit')) {
      dispatch(updateConfiguratorMode('edit'));
    }

    LIFE_CYCLE_EVENT_BUS.trigger('configurator.initial_quantity', quantity);

    dispatch(updateIsCopy(getConfiguratorModeFromUrl('copy')));
    dispatch(getGccProductDetails());
  },
  onLoadSuccess: () => {
    dispatch(updatePath());
  },
  onChangeGraphData: (data) => {
    LIFE_CYCLE_EVENT_BUS.trigger(LIFE_CYCLE_EVENT_CONFIGURATOR_DATA, { configuratorData: data });

    dispatch({
      type: UPDATE_CART_PRODUCT,
      data,
    });
  },
  onSaveConfiguration: (data) => dispatch(addGccConfigurationToCart(data.configurationGuid)),
  onSetHydrationStart: (feature) => dispatch(setHydrationStart(feature)),
  onSetHydrationComplete: (feature) => dispatch(setHydrationComplete(feature)),
  afterChoiceSelectionUpdate: () => {
    dispatch({ type: CLEAR_ERROR_GROUPS });
  },
  afterRulesExecuted: () => {
    return Promise.resolve(dispatch(getSkuMap()))
      .then(() => {
        Promise.all([
          dispatch(getPricing()),
          dispatch(choiceAndSkuAvailability())
        ]);
      }
      );
  }
});

const mapStateToProps = (state) => {
  const {
    config,
    thdStoreInfo,
    pricing,
    product,
    skuMap,
    deliveryInfo: {
      specialOrderDeliveryTime,
    },
    productInfo,
    thdStoreInfo: {
      zipcode,
      storeNumber,
    },
    product: {
      details: {
        productName,
        attributes,
        isDraft,
      },
    },
  } = state;

  const cartProduct = cartProductSelector(state);
  const hydration = hydrationSelector(state);

  if (!isEmpty(attributes)) {
    cartProduct.attributes.pipfiguratorType = attributes.pipfiguratorType;
  }

  let deliveryTime = {};
  if (!isEmpty(specialOrderDeliveryTime) && skuMap.isSpecialOrder) {
    deliveryTime = {
      startDate: specialOrderDeliveryTime.startDate,
      endDate: specialOrderDeliveryTime.endDate,
    };
  }

  return {
    productName,
    config,
    thdStoreInfo,
    pricing,
    attributes,
    isDraft,
    hydration,
    product,
    cartProduct,
    zipcode,
    storeNumber,
    deliveryTime,
    skuMap,
    productInfo,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProductConfigurator);
