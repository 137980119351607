import React, { useState, useContext, useEffect } from 'react';
import { useParams } from '@thd-olt-component-react/router';
import { withErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { withHydrator } from '@thd-olt-component-react/hydrator';
import { Placeholder } from '@thd-olt-component-react/core-ui';
import { withDynamicComponent, AppContext } from '@thd-nucleus/app-render';
import { bool, string } from 'prop-types';
import { Accordion, AccordionBody, AccordionHeader } from '@one-thd/sui-atomic-components';
import { dataModel } from '../dataModel';
import { QuestionsAndAnswersAccordionTitle } from '../QuestionsAndAnswersAccordionTitle';
import { QuestionsAndAnswersAccordionBody } from '../QuestionsAndAnswersAccordionBody';

const QuestionsAndAnswersAccordion = ({ itemId, noSpacing }) => {
  const itemIdParam = useParams();
  const itemIdFinal = itemId || itemIdParam?.itemId;
  const { instance } = useContext(AppContext);
  const thdSeoBotDetected = instance?.thdSEOBotDetection;

  const [expanded, setExpanded] = useState(thdSeoBotDetected || false);

  const handleAccordionChange = (canCloseAccordion = true) => {
    if (canCloseAccordion) {
      setExpanded(!expanded);
    } else if (!canCloseAccordion && !expanded) {
      setExpanded(true);
    }
  };

  useEffect(() => {
    window.LIFE_CYCLE_EVENT_BUS.on('product-section-qa.click', ({ output = true }) => {
      handleAccordionChange(output);
    });
  }, []);

  const seoPageNumber = 1;

  return (
    <Accordion
      key="questions-answers-accordion"
      expanded={expanded}
      onChange={() => handleAccordionChange()}
    >
      <AccordionHeader>
        <QuestionsAndAnswersAccordionTitle
          itemId={itemIdFinal}
          noSpacing={noSpacing}
          seoPageNumber={seoPageNumber}
        />
      </AccordionHeader>
      <AccordionBody>
        <QuestionsAndAnswersAccordionBody
          itemId={itemIdFinal}
          seoPageNumber={seoPageNumber}
        />
      </AccordionBody>
    </Accordion>
  );
};

QuestionsAndAnswersAccordion.displayName = 'QuestionsAndAnswersAccordion';

QuestionsAndAnswersAccordion.propTypes = {
  itemId: string,
  noSpacing: bool,
};

QuestionsAndAnswersAccordion.defaultProps = {
  itemId: null,
  noSpacing: false,
};

QuestionsAndAnswersAccordion.dataModel = dataModel;

const HydratedComponent = withHydrator({
  id: 'product-section-questions-answers',
  scrollBuffer: 900,
  delay: 200,
  placeholder: (
    <div data-component="QuestionsAndAnswersAccordionPlaceholder">
      <Placeholder
        width="100%"
        height="700px"
        color="#F5F5F5"
      />
    </div>
  ),
}, QuestionsAndAnswersAccordion);
const DynamicComponent = withDynamicComponent(HydratedComponent);
const ErrorComponent = withErrorBoundary(DynamicComponent);

export { ErrorComponent as QuestionsAndAnswersAccordion };