import { useState, useEffect } from 'react';

export const useLanguagePreference = () => {
  const LANGUAGE_KEY = 'language';
  const DEFAULT_LANGUAGE = 'en';

  const getLanguage = () => {
    if (typeof window !== 'undefined' && window.localStorage) {
      return localStorage.getItem(LANGUAGE_KEY) || DEFAULT_LANGUAGE;
    }
    return DEFAULT_LANGUAGE;
  };

  const [language, setLanguage] = useState(getLanguage);

  useEffect(() => {
    localStorage.setItem(LANGUAGE_KEY, language);
  }, [language]);

  return [language, setLanguage];
};