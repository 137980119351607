import axios from 'axios';
import config from '../config';
import toCamelCase from '../utils/camelCase';
import { mergeRules } from '../utils/compatibilityRules';

export const getMsrpPricingFromAutobahn = (params) => {
  const url = `${config.eleanorBaseUrl}/api/pricing/product`;
  return axios.post(url, params)
    .then((response) => toCamelCase(response.data));
};

export const getSpecialOrderDeliveryTime = (siteId, partnerProductId) => {
  const url = `${config.eleanorBaseUrl}/api/shipping/estimatedelivery/specialorder`;

  const params = { siteId, partnerProductId };

  return axios.post(url, params)
    .then((response) => toCamelCase(response.data));
};

export const executeAllRules = (selectionData) => {
  const url = {
    base: `${config.eleanorBaseUrl}/catalog/productconfiguration`,
    productRulesEndpoint: 'ExecuteRulesViaPost',
    compatibilityRulesEndpoint: 'ExecuteCompatibilityRulesViaPost',
  };

  const promiseProductRules = axios({
    data: selectionData,
    url: `${url.base}/${url.productRulesEndpoint}`,
    method: 'POST',
    crossDomain: true,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const promiseCompatibilityRules = axios({
    data: selectionData,
    url: `${url.base}/${url.compatibilityRulesEndpoint}`,
    method: 'POST',
    crossDomain: true,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return Promise.all([promiseProductRules, promiseCompatibilityRules])
    .then(mergeRules);
};
