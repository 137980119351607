/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { useDataModel } from '@thd-nucleus/data-sources';
import { Typography } from '@one-thd/sui-atomic-components';

import { ContentSkeleton } from '../subcomponents/Skeletons';

import { emtContentDataModel } from '../dataModels';
import { PAGE_NAMES } from '../../constants';

const HomeServicesContent = ({ storeId }) => {
  const { data, loading, error } = useDataModel('emtContent', {
    variables: {
      pageName: PAGE_NAMES.HOME_SERVICES,
      contentId: storeId,
    }
  });

  if (loading) return <ContentSkeleton />;

  const homeServicesContentResponse = data?.emtContent?.content?.homeServices;
  const isArray = Array.isArray(homeServicesContentResponse);
  const homeServicesContent = isArray ? homeServicesContentResponse?.[0] : homeServicesContentResponse?.storeDetails;

  const header = homeServicesContent?.header || '';
  const content = homeServicesContent?.content || [];

  if (!homeServicesContentResponse || !content?.length || !header || error) return null;

  return (
    /* eslint-disable tailwindcss/no-arbitrary-value */
    <div className="sui-bg-primary sui-p-4 sui-flex sui-flex-col sui-gap-4">
      <Typography variant="h2" weight="display">{header}</Typography>
      {content.map(({ imageUrl, text, category }, index) => (
        <div key={index} className="sui-grid sui-grid-cols-12 sui-gap-4">
          <div className="sui-col-span-12 sm:sui-col-span-3">
            <img
              loading="lazy"
              className="sui-w-[150px] sui-h-[150px] sm:sui-w-[200px] sm:sui-h-[200px]"
              src={imageUrl}
              alt={category || ''}
              width="200"
              height="200"
            />
          </div>
          <div className="sui-col-span-12 sm:sui-col-span-9 sui-relative">
            {category && (
              <div className="sui-mb-2">
                <Typography variant="h3" weight="display">{category}</Typography>
              </div>
            )}
            <div className="sui-relative [&_a]:sui-underline">
              <Typography
                dangerouslySetInnerHTML={{ __html: text }}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

HomeServicesContent.propTypes = {
  storeId: PropTypes.string.isRequired
};

HomeServicesContent.displayName = 'HomeServicesContent';
HomeServicesContent.dataModel = emtContentDataModel;

export default HomeServicesContent;