/* eslint-disable max-len */
import React from 'react';
import { oneOf } from 'prop-types';
import { NavigationLinks } from './NavigationLinks';
import { BrandLinks } from './BrandLinks';
import { LegalLinks } from './LegalLinks';
import { FooterTagLine } from './FooterTagLine';
import { NeedHelpBanner } from './NeedHelpBanner';
import { FooterController } from './FooterController';

const Footer = ({ customerType }) => {
  return (
    <FooterController customerType={customerType}>
      <div className="sui-pt-7 sui-pb-20 sui-bg-primary" data-component="Footer">
        <FooterTagLine />
        <div className="sui-flex sui-flex-col lg:sui-max-w-screen-2xl lg:sui-m-auto">
          <NavigationLinks />
          <BrandLinks />
          <NeedHelpBanner />
          <LegalLinks />
        </div>
      </div>
    </FooterController>
  );
};

Footer.propTypes = {
  customerType: oneOf(['default', 'b2b'])
};
Footer.defaultProps = {
  customerType: 'default'
};
Footer.dataModel = {};
Footer.displayName = 'Footer';

export { Footer };
