import React, { useEffect, useContext, useMemo, useState } from 'react';
import {
  string, func, bool, arrayOf, PropTypes
} from 'prop-types';
import classNames from 'classnames';
import { InfoFilled } from '@one-thd/sui-icons';
import { useTheme } from '@thd-olt-component-react/theme-provider';
import { ExperienceContext, useStoreId, useConfigService } from '~/@thd-nucleus/experience-context';
import { useProductAddOnsFlow } from '../hooks/useProductAddOnsFlow';
import { HomeDepotProtectionPlan } from './add-ons/hdpp/HomeDepotProtectionPlan';
import { AttachLabor } from './add-ons/attach-labor/AttachLabor';
import { ProReferral } from './add-ons/pro-referral/ProReferral';
import { DualPath } from './add-ons/dual-path/DualPath';
import { SAMDualPath } from './add-ons/SAM-dual-path/SAMDualPath';
import { VirtualAssistance } from './add-ons/virtual-assistance/VirtualAssistance';
import { displayAttachLabor as attachLaborEligible } from '../utils/product-add-on-utils';
import { DUAL_PATH_CATEGORIES, getDualPathHeading } from './add-ons/dual-path/dualPathCategories';
import { dataModel } from './dataModel';
import styles from './product-add-ons.scss';
import './tooltip.scss';

const ProductAddOns = ({
  itemId,
  fulfillment,
  availableFulfillments,
  onChange,
  showCards,
  hideProReferral,
  defaultProps,
  isTwoTileEnabled,
  isShowATC,
  zipCode,
  bodfsGrillsAssemblyEnabledFS
}) => {
  const theme = useTheme(ProductAddOns);
  const { hideExpertInHome } = theme.props;

  const expData = useContext(ExperienceContext);
  const { channel } = expData;
  const [isProReferralDisplayable, setIsProReferralDisplayable] = useState(false);
  const storeId = useStoreId();
  const [errorProtectionPlanArray, setErrorProtectionPlan] = useState([]);

  const {
    loading,
    data,
    optionsForProtectionPlan,
    isProReferral,
    isAssembly,
    isDualPath,
    isInstallationSku,
    inStoreAssemblyAvailable,
    bodfsAssemblyEligible,
    isSAMDualPath,
    isVirtualAssistance,
    discontinued,
    applicableFulfillments
  } = useProductAddOnsFlow({ itemId, storeId, zipCode });

  const shouldShowProtectionPlan = (
    optionsForProtectionPlan?.protectionPlanSkuArr?.length > 0
    && errorProtectionPlanArray.length < optionsForProtectionPlan.protectionPlanSkuArr.length
    && optionsForProtectionPlan?.variables?.price > 0
    && !discontinued && !defaultProps.dualPath
  );

  const isBodfsGrillsAssemblyEnabledFS = useConfigService('bodfsGrillsAssemblyEnabled') || bodfsGrillsAssemblyEnabledFS;
  const bodfsGrillsAssemblyEnabled = isBodfsGrillsAssemblyEnabledFS === 1 || isBodfsGrillsAssemblyEnabledFS === '1';
  const isInstallVirtualAssistance = data?.serviceAddOns?.installationDetails?.virtualAssistanceFlag || false;
  const installationCategory = data?.serviceAddOns?.installationDetails?.installationCategory || null;

  const isGarbageDisposalVirtualAssistance = data?.product?.info?.productDepartment === '29'
  && data?.product?.info?.classNumber === '14'
    ? !data?.serviceAddOns?.attachLabor?.installSkus[0]?.doItForMe
    : false;

  const displayAttachLabor = attachLaborEligible({
    isInstallationSku,
    inStoreAssemblyAvailable,
    availableFulfillments,
    currentFulfillment: fulfillment,
    isTwoTileEnabled,
    // Flag from backend on if the item is eligible for BODFS assembly
    bodfsAssemblyEligible,
    // FS for BODFS Grill Assembly
    bodfsGrillsAssemblyEnabled,
    applicableFulfillments
  });

  const { installationProgramCategory } = data?.serviceAddOns?.sellFurnishInstall || {};
  const bathRemodelCategory = ['BATHROOM_REMODEL'].includes(DUAL_PATH_CATEGORIES[installationProgramCategory]);

  useEffect(() => {
    setIsProReferralDisplayable(
      !bathRemodelCategory && !hideProReferral && !displayAttachLabor && (isProReferral || isAssembly)
    );
  }, [bathRemodelCategory, hideProReferral, displayAttachLabor, isProReferral, isAssembly]);

  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('product-add-ons.ready', {
      isProReferralDisplayable
    });
  }, [isProReferralDisplayable]);

  const getCategory = (serviceName) => {
    const PR_INSTALL = 'PRInstall';
    const PAINTER = 'Painter';
    const ASSEMBLY = 'Assembly';

    if (serviceName === 'interior_painting' || serviceName === 'exterior_painting') return PAINTER;
    if (serviceName === 'barbeque_assembly' || serviceName === 'outdoor_furniture_assembly') return ASSEMBLY;

    return PR_INSTALL;
  };

  const { installProcess, priceIncludes } = useMemo(() => {
    const doItForMe = data?.serviceAddOns?.attachLabor?.installSkus?.[0]?.doItForMe;
    return {
      priceIncludes: (doItForMe?.priceIncludes || []).filter(
        (priceInclude) => priceInclude.shortText
      ),
      installProcess: (doItForMe?.installProcess || []).filter(
        (installProcessItem) => installProcessItem.longText
      ),
    };
  }, [data?.serviceAddOns?.attachLabor?.installSkus?.[0]?.doItForMe]);

  if (!data || loading) {
    return null;
  }

  if (!displayAttachLabor && !data?.serviceAddOns
    && optionsForProtectionPlan?.protectionPlanSkuArr?.length === 0
    && !isSAMDualPath && !isVirtualAssistance && !isInstallVirtualAssistance && !isGarbageDisposalVirtualAssistance) {
    return null;
  }

  const { serviceName } = data?.serviceAddOns?.proReferrals?.[0] || {};
  const { attachLabor } = data?.serviceAddOns || {};
  const installSkus = attachLabor?.installSkus?.[0] || {};
  const { serviceType, installSku } = installSkus || {};
  const { price: installRetailPrice } = attachLabor?.installSkus?.[0]?.installRetailPrice || {};

  const modelNumber = data?.product?.identifiers?.modelNumber || {};
  const { leadGenUrl } = data?.product?.details?.installation || {};

  const type = serviceType?.toLowerCase();

  let cx = classNames.bind(styles);

  const wrapperClasses = cx({
    'product-add-ons': !showCards,
    'product-add-ons--card-background': showCards,
  });
  const cardClasses = cx({ 'product-add-ons__card': showCards });

  const oosAlertMsg = (
    <p className="sui-flex sui-py-5 sui-text-info">
      <div className="sui-mt-1"><InfoFilled size="small" /></div>
      <div className="sui-ml-2">
        <b>You&apos;ve Still Got Options!</b>&nbsp;You can request in-home installation
        services for a similar product. This item is currently out of stock.
      </div>
    </p>
  );

  const isOutOfStockOnline = availableFulfillments.length === 0;

  const themeHideExpertInHome = isDualPath && hideExpertInHome
    && getDualPathHeading(installationProgramCategory) === 'Get Expert In-Home Installation';

  if (themeHideExpertInHome) return null;
  return (
    <section className={wrapperClasses} data-component="ProductAddOns">
      {isDualPath && (
        <div>{isOutOfStockOnline && (oosAlertMsg)}
          <div className={cardClasses}>
            <DualPath
              channel={channel}
              onChange={onChange}
              itemId={itemId}
              category={installationProgramCategory}
              serviceUrl={leadGenUrl}
              value={defaultProps?.dualPath}
            />
          </div>
        </div>
      )}

      {isSAMDualPath && (
        <div>{isOutOfStockOnline && (oosAlertMsg)}
          <div className={cardClasses}>
            <SAMDualPath
              channel={channel}
              onChange={onChange}
              itemId={itemId}
              modelNumber={modelNumber}
            />
          </div>
        </div>
      )}

      {!isOutOfStockOnline && isShowATC && (
        <>
          {displayAttachLabor && (
            <div className={cardClasses}>
              <AttachLabor
                channel={channel}
                onChange={onChange}
                itemId={installSku}
                type={type}
                price={installRetailPrice}
                installProcess={installProcess}
                priceIncludes={priceIncludes}
                fulfillment={fulfillment}
                availableFulfillments={availableFulfillments}
                isInstallationSku={isInstallationSku}
                inStoreAssemblyAvailable={inStoreAssemblyAvailable}
                // Item is eligible for bodfs assembly and FS is ON
                bodfsAssemblyEligible={bodfsAssemblyEligible && bodfsGrillsAssemblyEnabled}
                isTwoTileEnabled={isTwoTileEnabled}
                zipCode={zipCode}
                applicableFulfillments={applicableFulfillments}
              />
            </div>
          )}

          {isVirtualAssistance && !isInstallVirtualAssistance && !isGarbageDisposalVirtualAssistance && (
            <div id="mva-buybox" className={cardClasses}>
              <VirtualAssistance
                channel={channel}
                onChange={onChange}
                itemId={itemId}
                value={defaultProps?.virtualAssistance}
              />
            </div>
          )}

          {isInstallVirtualAssistance && (
            <div id="mva-buybox" className={cardClasses}>
              <VirtualAssistance
                channel={channel}
                onChange={onChange}
                itemId={itemId}
                isInstallVirtualAssistance={isInstallVirtualAssistance}
                installationCategory={installationCategory}
              />
            </div>
          )}

          {isGarbageDisposalVirtualAssistance && (
            <div id="mva-buybox" className={cardClasses}>
              <VirtualAssistance
                channel={channel}
                onChange={onChange}
                itemId={itemId}
                isGarbageDisposalVirtualAssistance
              />
            </div>
          )}

          {isProReferralDisplayable && (
            <div className={cardClasses}>
              <ProReferral
                onChange={onChange}
                channel={channel}
                category={getCategory(serviceName)}
                isAssembly={isAssembly}
              />
            </div>
          )}

          {shouldShowProtectionPlan && (
            <div className={cardClasses}>
              <HomeDepotProtectionPlan
                channel={channel}
                onChange={onChange}
                optionsForProtectionPlan={optionsForProtectionPlan}
                setErrorProtectionPlan={setErrorProtectionPlan}
              />
            </div>
          )}
        </>
      )}
    </section>
  );
};

ProductAddOns.displayName = 'ProductAddOns';

ProductAddOns.defaultProps = {
  availableFulfillments: [],
  fulfillment: null,
  hideProReferral: false,
  showCards: false,
  defaultProps: { virtualAssistance: false, dualPath: false },
  isTwoTileEnabled: false,
  isShowATC: true,
  zipCode: '',
  bodfsGrillsAssemblyEnabledFS: 0
};
ProductAddOns.propTypes = {
  /** Product Number */
  itemId: string.isRequired,
  /** Function to call when checkbox is clicked */
  onChange: func.isRequired,
  /** Fulfillment method selected */
  fulfillment: string,
  /** List of currently available fulfillment types  */
  availableFulfillments: arrayOf(string),
  /** Boolean to remove the Pro referral component */
  hideProReferral: bool,
  showCards: bool,
  defaultProps: PropTypes.shape({
    virtualAssistance: PropTypes.bool,
    dualPath: PropTypes.bool,
  }),
  /** Determine if the incoming fulfillment is a 2 Tile */
  isTwoTileEnabled: bool,
  isShowATC: bool,
  zipCode: string,
  bodfsGrillsAssemblyEnabledFS: PropTypes.number
};

ProductAddOns.themeProps = {
  hideExpertInHome: bool,
};
ProductAddOns.defaultThemeProps = {
  hideExpertInHome: false,
};

ProductAddOns.dataModel = dataModel;

export { ProductAddOns };
