import React from 'react';
import classNames from 'classnames/bind';
import {
  oneOfType, arrayOf, node,
  bool, string, number
} from 'prop-types';
import { Typography, ThemeProvider } from '@one-thd/sui-atomic-components';
import { getThemeByName } from '@thd-olt-component-react/stencil-themes';
import { Carousel } from '@one-thd/sui-carousel';
import styles from './content-section.module.scss';

const cx = classNames.bind(styles);

const ContentSection = ({
  children = null,
  isCarousel = false,
  isAutoplay = false,
  title = null,
  slidesXs = 1,
  slidesSm = null,
  slidesMd = null,
  slidesLg = null,
  slidesXl = null,
  spaceBetweenXs = 0,
  spaceBetweenSm = 16,
  spaceBetweenMd = 16,
  spaceBetweenLg = 16,
  spaceBetweenXl = 16,
  anchorId = null,
  theme = 'default'
}) => {

  const contents = [];
  if (isCarousel) {
    for (let i = 0; i < children?.length; i += 1) {
      contents.push(
        <div
          key={`item-${i}`}
          className={cx('content-section__carousel', 'sui-w-full', 'sui-bg-primary', 'sui-flex', 'sui-flex-col')}
        >
          {children[i]}
        </div>
      );
    }
  }

  // TODO: The below line is a temporary workaround for PromoVisualNavigation
  const isPromoVisNav = children[0]?.props?.componentClass === 'PromoVisualNavigation';
  const sectionTitle = (
    <>
      {title && !isPromoVisNav && (
        <div className={cx('sui-pb-4')}>
          <Typography variant="h2" weight="display" height="snug" color="primary" uppercase>{title}</Typography>
        </div>
      )}
    </>
  );

  return (
    <ThemeProvider theme={getThemeByName(theme)}>
      <section id={anchorId} className="sui-bg-primary sui-text-primary">
        {sectionTitle}
        {
          contents.length > 0
            ? (
              <Carousel
                autoplay={isAutoplay}
                peek={false}
                slidesPerGroup={slidesXs}
                slidesPerView={slidesXs}
                spaceBetween={spaceBetweenXs}
                breakpoints={{
                  sm: {
                    slidesPerView: slidesSm || 1,
                    slidesPerGroup: slidesSm || 1,
                    spaceBetween: spaceBetweenSm,
                  },
                  md: {
                    slidesPerView: slidesMd || 1,
                    slidesPerGroup: slidesMd || 1,
                    spaceBetween: spaceBetweenMd,
                  },
                  lg: {
                    slidesPerView: slidesLg || 1,
                    slidesPerGroup: slidesLg || 1,
                    spaceBetween: spaceBetweenLg,
                  },
                  xl: {
                    slidesPerView: slidesXl || 1,
                    slidesPerGroup: slidesXl || 1,
                    spaceBetween: spaceBetweenXl,
                  },
                }}
              >
                {contents}
              </Carousel>
            )
            : (
              <div className={cx('content-section__container', 'sui-grid', 'sui-gap-5')}>
                {children}
              </div>
            )
        }
      </section>
    </ThemeProvider>
  );
};

ContentSection.propTypes = {
  children: oneOfType([node, arrayOf(node)]),
  isCarousel: bool,
  spaceBetweenXs: number,
  spaceBetweenSm: number,
  spaceBetweenMd: number,
  spaceBetweenLg: number,
  spaceBetweenXl: number,
  isAutoplay: bool,
  title: string,
  slidesXs: number,
  slidesSm: number,
  slidesMd: number,
  slidesLg: number,
  slidesXl: number,
  anchorId: string,
  theme: string
};

export { ContentSection };
