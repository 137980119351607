import {
  COMBO_EXPERIENCE_FEATURES,
  LAUNDRY_EXPERIENCE_FEATURES,
  KITCHEN_EXPERIENCE_FEATURES,
  KITCHEN_PRODUCTS_FEATURE,
  COMBO_PRODUCTS_FEATURE,
  LAUNDRY_PRODUCTS_FEATURE
} from '../bundle-features-constants';
import { EXPERIENCE_TYPE_KITCHEN, EXPERIENCE_TYPE_COMBO, EXPERIENCE_TYPE_LAUNDRY } from '../constants';

const getExperienceType = (product) => {
  return product?.bundleSpecificationDetails?.experienceType;
};

const getBrandName = (product, products) => {
  const { brand } = product?.bundleSpecificationDetails ?? {};
  if (brand) {
    return brand;
  }

  const { brandName: productBrandName } = product?.identifiers ?? {};
  if (productBrandName) {
    return productBrandName;
  }

  const { brandName: bundleBrandName } = products?.[0]?.identifiers ?? {};
  const showBrandName = products?.every((bundleProduct) => bundleProduct?.identifiers?.brandName === bundleBrandName);
  return showBrandName ? bundleBrandName : null;
};

const getBrandUrl = (product) => {
  const lastBreadCrumb = product?.taxonomy?.breadCrumbs?.length - 1;
  return product?.taxonomy?.breadCrumbs?.[lastBreadCrumb]?.url || null;
};

const getProductFeatures = (experienceType) => {
  switch (experienceType) {
  case EXPERIENCE_TYPE_COMBO:
    return COMBO_EXPERIENCE_FEATURES;
  case EXPERIENCE_TYPE_KITCHEN:
    return KITCHEN_EXPERIENCE_FEATURES;
  case EXPERIENCE_TYPE_LAUNDRY:
    return LAUNDRY_EXPERIENCE_FEATURES;
  default:
    return COMBO_EXPERIENCE_FEATURES;
  }
};

const getProductComponent = (bundleProduct) => {
  return bundleProduct?.bundleSpecificationDetails?.components?.map((component) => {
    return {
      defaultProductId: component?.defaultProductId,
      allowSwap: component?.allowSwap,
      allowRemove: component?.allowRemove,
      quantity: component?.quantity,
      priority: component?.priority
    };
  });
};
export const getUpdatedProduct = (bundleProduct, bundleProducts) => {
  const experienceType = getExperienceType(bundleProduct);
  return {
    ...bundleProduct,
    features: {
      brand: getBrandName(bundleProduct, bundleProducts),
      brandUrl: getBrandUrl(bundleProduct),
      ...getProductFeatures(experienceType)
    },
    productComponent: getProductComponent(bundleProduct)
  };
};

const getSwapAndRemoveFlag = (filteredProduct, experienceType) => {
  const { allowSwap, allowRemove } = filteredProduct || {};
  const swapRemoveObject = { allowSwap: !!allowSwap, allowRemove: !!allowRemove };
  switch (experienceType) {
  case EXPERIENCE_TYPE_COMBO:
    return filteredProduct ? swapRemoveObject : { allowSwap: false, allowRemove: false };
  case EXPERIENCE_TYPE_KITCHEN:
    return filteredProduct ? swapRemoveObject : { allowSwap: true, allowRemove: true };
  case EXPERIENCE_TYPE_LAUNDRY:
    return filteredProduct ? swapRemoveObject : { allowSwap: true, allowRemove: true };
  default:
    return filteredProduct ? swapRemoveObject : { allowSwap: false, allowRemove: false };
  }
};

const getProductsFeature = (filteredProduct, experienceType) => {
  const { allowSwap, allowRemove } = getSwapAndRemoveFlag(filteredProduct, experienceType);
  switch (experienceType) {
  case EXPERIENCE_TYPE_COMBO:
    return {
      ...COMBO_PRODUCTS_FEATURE,
      allowSwap,
      allowRemove
    };
  case EXPERIENCE_TYPE_KITCHEN:
    return {
      ...KITCHEN_PRODUCTS_FEATURE,
      allowSwap,
      allowRemove
    };
  case EXPERIENCE_TYPE_LAUNDRY:
    return {
      ...LAUNDRY_PRODUCTS_FEATURE,
      allowSwap,
      allowRemove
    };
  default:
    return {
      ...COMBO_PRODUCTS_FEATURE,
      allowSwap,
      allowRemove
    };
  }
};

export const getUpdatedProducts = (bundleProduct, bundleProducts) => {
  const experienceType = getExperienceType(bundleProduct);
  const updatedProducts = bundleProducts?.map((product) => {
    const filteredProduct = bundleProduct?.productComponent?.find((component) =>
      component.defaultProductId === product.itemId);
    return {
      ...product,
      features: getProductsFeature(filteredProduct, experienceType),
      quantity: filteredProduct?.quantity || 1,
      priority: filteredProduct?.priority ? Number(filteredProduct.priority) : 0
    };
  });

  const sortedProducts = updatedProducts.sort((product1, product2) => product1.priority - product2.priority);
  return sortedProducts;
};

export const getFuelType = (product) => {
  const features = product?.keyProductFeatures?.keyProductFeaturesItems[0]?.features;
  if (!Array.isArray(features) || features.length === 0) {
    return null;
  }
  const fuelTypeFeature = features?.find((feature) => feature?.name?.toLowerCase() === 'fuel type');

  return fuelTypeFeature?.value || null;
};