import { createTheme } from '@one-thd/sui-atomic-components';

const SecondarySpringDarkGreen = createTheme({
  palette: {
    mode: 'dark',
    background: {
      primary: 'brand'
    }
  }
});

SecondarySpringDarkGreen.palette['--sui-palette-background-primary'] = '73 121 50';

export { SecondarySpringDarkGreen };