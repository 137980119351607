import React, { Suspense } from 'react';
import {
  string,
  func,
  number
} from 'prop-types';
import { Typography, Skeleton, SkeletonBlock } from '@one-thd/sui-atomic-components';
import { useConfigService } from '@thd-nucleus/experience-context';
import { usePipCalculatorData } from '../../../hooks/usePipCalculatorData';
import { DEFAULT_CALC_CLASSES, DEFAULT_DESCRIPTION } from '../../../constants';
import { CalculatorHeader } from '../../CalculatorHeader';
import { pipCalculatorDataModel } from '../../dataModel';

const CalculatorProvider = React.lazy(() => import(
  /* webpackChunkName: "calculator-pipcalculator" */'../CalculatorProvider'
));
const CalculatorInputSections = React.lazy(() => import(
  /* webpackChunkName: "calculator-pipcalculator" */'../../CalculatorInputSections'
));
const CalculatorFooter = React.lazy(() => import(
  /* webpackChunkName: "calculator-pipcalculator" */'../../CalculatorFooter'
));
const CalculateByDimensions = React.lazy(() => import(
  /* webpackChunkName: "calculator-pipcalculator" */'../../CalculateByDimensions'
));
const Results = React.lazy(() => import(
  /* webpackChunkName: "calculator-pipcalculator" */'./Results/Results'
));

export const PIPCalculator = ({ itemId, onCalculate, quantityOverride }) => {
  const {
    shouldDisplay,
    info,
    pipCalculatorProps,
    pricing,
    taxonomy
  } = usePipCalculatorData({ itemId, isInstalledCarpet: false });

  if (!shouldDisplay) {
    return null;
  }

  const { calcProps, resultsProps } = pipCalculatorProps;

  return (
    <div data-component="PIPCalculator">
      <Suspense fallback={
        (
          <Skeleton>
            <SkeletonBlock />
          </Skeleton>
        )
      }
      >
        <CalculatorProvider
          allowAdditionalCoverage
          calculatorType={calcProps.calculatorType}
          info={info}
          lineItemName={calcProps.lineItemName}
          onCalculate={onCalculate}
          unitsPerCase={resultsProps.unitsPerCase}
          taxonomy={taxonomy}
        >
          <div className={DEFAULT_CALC_CLASSES}>
            <CalculatorHeader>
              <Typography>{DEFAULT_DESCRIPTION}</Typography>
            </CalculatorHeader>
            <CalculateByDimensions dimensionNames={calcProps.dimensionNames} />
            <CalculatorInputSections dimensionNames={calcProps.dimensionNames} />
            <CalculatorFooter />
          </div>
          <Results
            additionalCoveragePercentage={resultsProps.additionalCoveragePercentage}
            caseUnitOfMeasure={resultsProps.caseUnitOfMeasure}
            pricing={pricing}
            quantityOverride={quantityOverride}
            unitsPerCase={resultsProps.unitsPerCase}
            uom={resultsProps.uom}
          />
        </CalculatorProvider>
      </Suspense>
    </div>
  );
};

PIPCalculator.displayName = 'PIPCalculator';

PIPCalculator.dataModel = pipCalculatorDataModel;

PIPCalculator.propTypes = {
  itemId: string.isRequired,
  onCalculate: func.isRequired,
  quantityOverride: number
};

PIPCalculator.defaultProps = {
  quantityOverride: 0
};
