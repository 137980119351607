import {
  alias, params, string, extend, arrayOf, shape, bool, number, customType, client
} from '@thd-nucleus/data-sources';
import { RecentlyViewedProductPod } from './recently-viewed/RecentlyViewedProductPod';

const productModel = extend({}, {
  itemId: string(),
  dataSource: string(),
}, RecentlyViewedProductPod.dataModel.product);

const queryParams = {
  apiName: string().isRequired(),
  appId: string(),
  key: string().isRequired(),
  storeId: string(),
};

const metadata = {
  apiName: string(),
  title: string(),
  modelName: string(),
  fallbackModelName: string(),
  type: string(),
  version: string(),
};

export const wrappedProduct1 = shape({
  category: string(),
  description: string(),
  idEndDate: string(),
  idPromoDetails: string(),
  idStartDate: string(),
  strategy: string(),
  isAnchor: bool(),
  product: params({
    dataSource: string()
  })
    .shape(productModel),
  slot: shape({
    description: string(),
    isRequired: bool(),
    name: string(),
  })
});

export const recentlyViewedDataModel = {
  uds: params({
    userId: string().isRequired(),
    unique: bool(),
    ...queryParams,
  }).shape({
    metadata: shape({
      ...metadata
    }),
    products: arrayOf(wrappedProduct1)
  })
};
