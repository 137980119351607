import React, { useContext } from 'react';
import { useStore, ExperienceContext } from '@thd-nucleus/experience-context';
import { AddToCart } from '@thd-olt-component-react/add-to-cart';
import { ProductPodUtils } from '@thd-olt-component-react/product-pod';

export const CompareATC = (props) => {

  const {
    channel
  } = useContext(ExperienceContext);

  const store = useStore();
  const { product } = props;

  const { itemId } = product || {};
  const cartOptions = ProductPodUtils.getCartOptions({ channel, hidden: false });
  const cartReqParams = ProductPodUtils.getCartReqParams({
    store,
    quantity: 1,
    product,
    channel,
    noATCFulfillment: true
  });

  const hover = false;
  const showBuildAndBuyButton = ProductPodUtils.isBuildAndBuyProduct(product, hover);
  const showViewDetailsButton = ProductPodUtils.isLiveGoodsOOSProduct(product);
  const isScheduleAMeasure = ProductPodUtils.checkScheduleAMeasureEligibility(product);
  const showRequestAQuoteButton = ProductPodUtils.isBrioProduct(product);
  const showScheduleAConsultationButton = ProductPodUtils.isCustomKitchenCabinetProduct(product);

  const conditions = [
    showBuildAndBuyButton,
    showViewDetailsButton,
    isScheduleAMeasure,
    showRequestAQuoteButton,
    showScheduleAConsultationButton
  ];

  const shouldHide = conditions.some((condition) => condition);

  if (shouldHide) {
    return null;
  }

  return (
    <AddToCart
      itemId={itemId}
      cartReqParams={cartReqParams}
      cartOptions={cartOptions}
      outline
    >
      Add To Cart
    </AddToCart>
  );
};

CompareATC.propTypes = {
  product: ProductPodUtils.productShape
};

CompareATC.defaultProps = {
  product: ProductPodUtils.productDefaults
};
