import React, { useContext } from 'react';
import { shape } from 'prop-types';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import {
  Heading,
  Col,
  Row,
  Image,
  Button
} from '@thd-olt-component-react/core-ui';
import classNames from 'classnames';
import { PackagesMiniContext } from '../PackagesMiniContext';
import { TotalPrice } from './TotalPrice';
import {
  getPackageTitle,
  getPackageUrl,
  getBrandName,
  getImageUrl,
  getBrandImage
} from '../../utils';
import './package-lite.scss';

const PackageLite = ({ itemMap }) => {
  const { channel } = useContext(ExperienceContext);
  const isMobile = channel === 'mobile';
  const { packageModel = {}, products } = useContext(PackagesMiniContext);
  const title = getPackageTitle(packageModel);
  const packageUrl = getPackageUrl(packageModel);
  const brandName = getBrandName(packageModel);
  const packageImageUrl = getImageUrl(packageModel, '400');
  const brandUrl = getBrandImage(packageModel);

  const headerWrapperClasses = classNames('package-lite__header', {
    'package-lite__header-mobile': isMobile
  });

  const headingClasses = classNames('packages-header__heading', {
    'packages-header__heading-border': !isMobile,
    'packages-header__mobile': isMobile
  });

  const priceSuiteClasses = classNames('package-price-suite', {
    'package-price-suite__mobile': isMobile
  });

  const infoClasses = classNames('package-lite__info', {
    'package-lite__info-mobile': isMobile
  });

  const headingSection = (
    <div className={headerWrapperClasses} data-testid="package-lite-heading">
      <Image
        className="package-info_brand-img"
        src={brandUrl}
        alt={`${brandName} logo`}
        width="100"
      />
      <Heading
        className={headingClasses}
        title={title}
        underline={false}
        flatten
        noMargin
      />
    </div>
  );

  return (
    <Row className="package-lite" data-testid="package-lite">
      {isMobile && headingSection}
      <Col
        sm="12"
        md="7"
        className="package-lite__package-img"
        data-testid="package-lite-package-image"
      >
        <Image
          src={packageImageUrl}
          alt="Package Bundle"
        />
      </Col>
      <Col
        sm="12"
        md="4"
        className="package-lite__desc"
        flatten
      >
        {!isMobile && headingSection}
        <div className={infoClasses} data-testid="package-lite-info">
          <p>
            {/* eslint-disable-next-line max-len */}
            This appliance is part of the {brandName || ''} {products.length ? `${products.length}-Piece` : ''} kitchen package.
          </p>
        </div>
        <div className={priceSuiteClasses}>
          <div className="package-lite__price">
            <TotalPrice products={products} itemMapProp={itemMap} isPackageLite />
          </div>
          <Button tag="a" href={packageUrl} data-testid="package-lite-button">
            View Package
          </Button>
        </div>
      </Col>
      {!isMobile && <Col fallback="1" />}
    </Row>
  );
};

PackageLite.propTypes = {
  itemMap: shape({}).isRequired
};

export { PackageLite };
