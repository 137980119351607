/* eslint-disable no-param-reassign */
import React from 'react';
import { PRODUCT_ADD_ON_IDENTIFIER } from '@thd-olt-functional/utils';
import { formatDate, formatWeek } from './util';
import {
  FULFILLMENT_METHOD_APPLIANCE,
  FULFILLMENT_METHOD_BODFS,
  FULFILLMENT_METHOD_BOPIS,
  FULFILLMENT_METHOD_BOSS,
  FULFILLMENT_METHOD_STH,
  ATTACH_LABOR_TYPE,
  MA,
  STORE_ASSEMBLY_VALUES
} from './constants';

const {
  HDPP,
  ATTACH_LABOR,
  PRO_ASSEMBLY,
  ATTACH_LABOR_STORE_ASSEMBLY,
  PRO_REFERRAL,
  DUAL_PATH,
  SAM_DUAL_PATH,
  VIRTUAL_ASSISTANCE
} = PRODUCT_ADD_ON_IDENTIFIER;

export const getNumberItemsInCart = () => {
  return cookieUtils.getTHDNumberItemsInCart();
};

export const isLoggedIn = () => {
  return !!(typeof window !== 'undefined' && window?.THDCustomer?.default?.isLoggedIn);
};

export const isPaypalEligible = (isB2BCustomer, isB2CCustomer, isPaypalB2BEnabled) => {
  // Initializing cookie manager
  if (typeof cookieUtils === 'undefined') {
    return false;
  }
  cookieUtils.initializeCookieManager();
  let isEligible = true;
  if (getNumberItemsInCart() > 0) {
    isEligible = false;
  } else if (isLoggedIn() && !isB2CCustomer && (!isPaypalB2BEnabled || !isB2BCustomer)) {
    isEligible = false;
  }
  return isEligible;
};

export const shouldDisplaySubbuybox = ({ fulfillment, fulfillmentMethod }) => {
  return !!((fulfillment?.fulfillmentOptions || []).length);
};

export const convertFulfillmentConst = ({ cartFulfillmentConst, apiFulfillmentConst }) => {
  if (cartFulfillmentConst) {
    switch (cartFulfillmentConst) {
    case FULFILLMENT_METHOD_STH:
      return 'sth';
    case FULFILLMENT_METHOD_BOSS:
      return 'boss';
    case FULFILLMENT_METHOD_BODFS:
      return 'express delivery';
    default:
      return cartFulfillmentConst.toLowerCase();
    }
  } else if (apiFulfillmentConst) {
    switch (apiFulfillmentConst) {
    case 'sth':
      return FULFILLMENT_METHOD_STH;
    case 'boss':
      return FULFILLMENT_METHOD_BOSS;
    case 'bopis':
      return FULFILLMENT_METHOD_BOPIS;
    case 'express delivery':
      return FULFILLMENT_METHOD_BODFS;
    default:
      return apiFulfillmentConst;
    }
  }
  return null;
};

export const checkInventory = (fulfillmentOptions, apiFulfillmentConst) => {
  if (apiFulfillmentConst === 'express delivery') {
    return fulfillmentOptions.some((fulfillmentOption) => {
      return (fulfillmentOption?.services || []).some((service) => service.type === apiFulfillmentConst);
    });
  }
  return fulfillmentOptions.some((fulfillmentOption) => {
    return (fulfillmentOption?.services || []).some((service) => {
      return service?.type === apiFulfillmentConst
        && (service?.locations || []).some((location) => location?.inventory?.quantity > 0);
    });
  });
};

const getBogoFulfillmentMethod = ({ fulfillmentOptions, fulfillmentMethod }) => {
  const apiFulfillmentMethod = convertFulfillmentConst({ cartFulfillmentConst: fulfillmentMethod });
  const selectedFulfillmentHasInventory = checkInventory(fulfillmentOptions, apiFulfillmentMethod);

  if (selectedFulfillmentHasInventory) {
    return fulfillmentMethod;
  }
  const fulfillmentsPriority = ['sth', 'bopis', 'boss', 'express delivery'];
  const firstAvailableFulfillment = fulfillmentsPriority.find((fulfillmentType) => {
    return checkInventory(fulfillmentOptions, fulfillmentType);
  });

  return firstAvailableFulfillment
    ? convertFulfillmentConst({ apiFulfillmentConst: firstAvailableFulfillment })
    : null;
};

export const getBogoCartReqParams = (bogoSelectedProduct, {
  fulfillmentMethod,
  storeZip,
  zipCode,
  storeId,
  primaryStoreNum
}) => {
  const cartReqParamsOptions = {
    itemId: bogoSelectedProduct.itemId,
    quantity: 1,
    zipCode,
    storeId
  };

  if (bogoSelectedProduct?.identifiers?.productType === MA) {
    cartReqParamsOptions.fulfillmentMethod = FULFILLMENT_METHOD_APPLIANCE;
    cartReqParamsOptions.primaryStoreNum = primaryStoreNum;
  } else if (bogoSelectedProduct?.fulfillment?.fulfillmentOptions?.length > 0) {
    const bogoFulfillment = getBogoFulfillmentMethod({
      fulfillmentMethod,
      fulfillmentOptions: bogoSelectedProduct.fulfillment.fulfillmentOptions
    });

    if (bogoFulfillment) {
      cartReqParamsOptions.storeZip = storeZip;
      cartReqParamsOptions.fulfillmentMethod = bogoFulfillment;
      cartReqParamsOptions.fulfillment = bogoSelectedProduct.fulfillment;
    } else {
      return null;
    }
  } else {
    return null;
  }

  // eslint-disable-next-line no-use-before-define
  return getCartReqParams(cartReqParamsOptions);
};

export const getBogoCartOptions = (bogoInfo, { fulfillmentMethod, storeZip, zipCode, storeId }) => {
  if (!bogoInfo) {
    return null;
  }
  const { bogoSelectedProduct, isFreeProduct } = bogoInfo;
  // The "bogo" key in cartOptions tells cart overlay to render a message for a free item
  // but there are some BOGOs where you get a dollar/percent off the second item instead
  const bogo = (isFreeProduct)
    ? { itemDetails: getBogoCartReqParams(bogoSelectedProduct, { fulfillmentMethod, storeZip, zipCode, storeId }) }
    : null;
  const component = bogoSelectedProduct ? 'add both' : null;
  const sharedSection = bogoSelectedProduct ? 'bogo' : null;

  return {
    bogo,
    component,
    sharedSection
  };
};

export const getCartOptions = ({
  channel,
  configurableItems,
  fulfillmentMethod = '',
  alterBrowserHistory,
  zipCode,
  storeId,
  storeZip,
  bogoInfo,
  isConfigurableProduct = false
}) => {

  if (isConfigurableProduct) {
    return {
      bogoInfo,
      channel,
      fulfillmentMethod,
      storeId,
      storeZip,
      zipCode
    };
  }

  const misship = configurableItems ? false : [
    FULFILLMENT_METHOD_BOPIS,
    FULFILLMENT_METHOD_BOSS
  ].indexOf(fulfillmentMethod) > -1;
  const BODFS = fulfillmentMethod === FULFILLMENT_METHOD_BODFS;
  const directCheckout = !BODFS && fulfillmentMethod !== FULFILLMENT_METHOD_APPLIANCE;

  let host = 'https://www.homedepot.com';
  if (typeof window !== 'undefined') {
    const origin = window.location.origin;
    host = origin.match(/local/g) ? 'https://www.homedepot.com' : origin;
  }
  return {
    host,
    channel,
    misship,
    directCheckout,
    alterBrowserHistory,
    bypassBss: misship,
    bss: misship,
    hasConfigurableItems: !!configurableItems,
    component: 'product pod',
    ...(getBogoCartOptions(bogoInfo, { fulfillmentMethod, storeZip, zipCode, storeId }) || {})
  };
};

const getNearByStore = (fulfillment) => {
  const locations = fulfillment?.locations || [];
  if (locations.length > 1) {
    const nearbyStore = locations.find((location) => !location.isAnchor);
    return nearbyStore;
  }
  return null;
};

const getLocalStore = (fulfillment) => {
  const locations = fulfillment?.locations || [];
  const localStore = locations.find((location) => location.isAnchor);
  return localStore;
};

export const getFulfillmentLocation = (fulfillment, fulfillmentMethod, storeZip, storeId, zipCode) => {
  let location = storeZip;

  const pickupService = (fulfillment?.fulfillmentOptions || []).find((option) => option.type === 'pickup');
  const bossFulfillment = (pickupService?.services || []).find((store) => store.type === 'boss');
  const bopisFulfillment = (pickupService?.services || []).find((store) => store.type === 'bopis');
  const nearbyStore = getNearByStore(bopisFulfillment);
  let deliveryZipcode = '';

  if (typeof window !== 'undefined') {
    let zipDetails;
    if (typeof window !== 'undefined' && window?.THD_LOCALIZER_AUTO_INIT?.Localizer?.getDeliveryZipcodeDetails) {
      zipDetails = window?.THD_LOCALIZER_AUTO_INIT?.Localizer?.getDeliveryZipcodeDetails();
    }
    deliveryZipcode = zipDetails?.zipcode;
  }

  let localStore = { locationId: storeId };
  if (bopisFulfillment) {
    localStore = getLocalStore(bopisFulfillment);
  }
  if (bossFulfillment) {
    localStore = getLocalStore(bossFulfillment);
  }

  switch (fulfillmentMethod) {
  case FULFILLMENT_METHOD_STH:
    location = deliveryZipcode || zipCode;
    break;
  case FULFILLMENT_METHOD_BOSS:
    location = localStore.locationId;
    break;
  case FULFILLMENT_METHOD_BOPIS:
    location = nearbyStore ? nearbyStore.locationId : localStore.locationId;
    break;
  case FULFILLMENT_METHOD_BODFS:
    location = deliveryZipcode || storeZip;
    break;
  default:
    break;
  }

  return location;
};

function addAddOnsToCartParams(cartReqParams, warrantyItem, attachedLabor, service, subscriptions) {
  let cartRequestParams = { ...cartReqParams };
  // Protection Plan (HDPP) Add on
  if (warrantyItem?.itemId) {
    cartRequestParams.warrantyItem = warrantyItem;
  }
  // Attach Labor Add on
  if (attachedLabor.length && attachedLabor[0].attachedLaborSku) {
    cartRequestParams.attachedLabor = attachedLabor;
  }
  // Assembly or ProReferral Add on
  if (service?.type) {
    cartRequestParams.service = { type: service.type, selected: service.selected };
  }
  // subscriptions & Save Add On
  if (subscriptions.frequency) {
    cartRequestParams.subscriptions = subscriptions;
  }
  return cartRequestParams;
}

function handleAssemblyAddOn(cartReqParams, isAssembleItemAtStore, fulfillmentMethod, fulfillment) {
  const bodfsAssemblyEligible = fulfillment?.bodfsAssemblyEligible;
  const inStoreAssemblyEligible = fulfillment?.inStoreAssemblyEligible;
  let cartRequestParams = { ...cartReqParams };
  if (isAssembleItemAtStore
    && fulfillmentMethod !== FULFILLMENT_METHOD_STH
    && fulfillmentMethod !== FULFILLMENT_METHOD_BODFS
    && inStoreAssemblyEligible) {
    cartRequestParams.assembleItemAtStore = STORE_ASSEMBLY_VALUES.SELECTED;
  } else if (!isAssembleItemAtStore && fulfillmentMethod !== FULFILLMENT_METHOD_STH) {
    cartRequestParams.assembleItemAtStore = STORE_ASSEMBLY_VALUES.UN_SELECTED;
  } else if (isAssembleItemAtStore && fulfillmentMethod === FULFILLMENT_METHOD_BODFS && bodfsAssemblyEligible) {
    // BODFS assemlby selected
    cartRequestParams.assembleItemAtStore = STORE_ASSEMBLY_VALUES.SELECTED;
  } else {
    delete cartRequestParams.assembleItemAtStore;
  }
  return cartRequestParams;
}

export const getCartReqParams = ({
  itemId,
  storeZip,
  fulfillmentMethod,
  quantity,
  originalConfigId,
  productAddOns,
  zipCode,
  storeId,
  fulfillment,
  isAssembleItemAtStore,
  bogoInfo,
  primaryStoreNum
}) => {
  const { warrantyItem = {}, attachedLabor = [], service = {}, subscriptions = {} } = productAddOns || {};

  let cartReqParams = {
    itemId,
    quantity,
    fulfillmentMethod
  };

  cartReqParams = addAddOnsToCartParams(cartReqParams, warrantyItem, attachedLabor, service, subscriptions);

  const pickupService = (fulfillment?.fulfillmentOptions || []).find((option) => option.type === 'pickup');
  const bossFulfillment = (pickupService?.services || []).find((store) => store.type === 'boss');
  const bopisFulfillment = (pickupService?.services || []).find((store) => store.type === 'bopis');
  const nearbyStore = getNearByStore(bopisFulfillment);
  let deliveryZipcode = '';

  if (typeof window !== 'undefined') {
    let zipDetails;
    if (typeof window !== 'undefined' && window?.THD_LOCALIZER_AUTO_INIT?.Localizer?.getDeliveryZipcodeDetails) {
      zipDetails = window?.THD_LOCALIZER_AUTO_INIT?.Localizer?.getDeliveryZipcodeDetails();
    }
    deliveryZipcode = zipDetails?.zipcode;
  }
  let localStore = { locationId: storeId };
  if (bopisFulfillment) {
    localStore = getLocalStore(bopisFulfillment);
  }
  if (bossFulfillment) {
    localStore = getLocalStore(bossFulfillment);
  }

  switch (fulfillmentMethod) {
  case FULFILLMENT_METHOD_STH:
    if (cartReqParams.constructor === Object) {
      cartReqParams.fulfillmentLocation = deliveryZipcode || zipCode;
    } else {
      cartReqParams = cartReqParams.map((cart) => {
        cartReqParams.fulfillmentLocation = deliveryZipcode || zipCode;
        return cart;
      });
    }
    break;
  case FULFILLMENT_METHOD_BOSS:
    if (cartReqParams.constructor === Object) {
      cartReqParams.fulfillmentLocation = localStore.locationId;
    } else {
      cartReqParams = cartReqParams.map((cart) => {
        cartReqParams.fulfillmentLocation = localStore.locationId;
        return cart;
      });
    }
    break;
  case FULFILLMENT_METHOD_BOPIS:
    if (cartReqParams.constructor === Object) {
      cartReqParams.fulfillmentLocation = nearbyStore ? nearbyStore.locationId : localStore.locationId;
    } else {
      cartReqParams = cartReqParams.map((cart) => {
        cartReqParams.fulfillmentLocation = nearbyStore ? nearbyStore.locationId : localStore.locationId;
        return cart;
      });
    }
    break;
  case FULFILLMENT_METHOD_BODFS:
    if (cartReqParams.constructor === Object) {
      cartReqParams.fulfillmentLocation = deliveryZipcode || storeZip;
      cartReqParams.deliveryStore = storeId;
    } else {
      cartReqParams = cartReqParams.map((cart) => {
        cartReqParams.fulfillmentLocation = deliveryZipcode || storeZip;
        cartReqParams.deliveryStore = storeId;
        return cart;
      });
    }
    break;
  case FULFILLMENT_METHOD_APPLIANCE:
    if (cartReqParams.constructor === Object) {
      cartReqParams.fulfillmentLocation = zipCode;
      if (primaryStoreNum) {
        cartReqParams.applianceDeliveryStore = primaryStoreNum;
      }
    } else {
      cartReqParams = cartReqParams.map((cart) => {
        cartReqParams.fulfillmentLocation = zipCode;
        if (primaryStoreNum) {
          cartReqParams.applianceDeliveryStore = primaryStoreNum;
        }
        return cart;
      });
    }
    break;
  default:
    break;
  }

  cartReqParams = handleAssemblyAddOn(cartReqParams, isAssembleItemAtStore, fulfillmentMethod, fulfillment);

  if (originalConfigId) {
    cartReqParams.paintConfiguration = { configurationId: originalConfigId };
  }
  if (bogoInfo && bogoInfo.bogoSelectedProduct) {
    const bogoItemReqParams = getBogoCartReqParams(
      bogoInfo.bogoSelectedProduct,
      {
        fulfillmentMethod,
        storeZip,
        zipCode,
        storeId,
        primaryStoreNum
      }
    );
    if (bogoItemReqParams) {
      return [cartReqParams, bogoItemReqParams];
    }
  }

  return cartReqParams;
};

export const useATCLocationDDOStash = ({ stash, itemId }) => {
  const {
    component, pageType, sharedSection, section
  } = stash;

  // stash
  return {
    pageType: pageType || 'pip',
    sharedSection: sharedSection || '',
    section: section || 'buybox',
    component: component || '',
    target: 'atc',
    items: [{
      productID: itemId,
      index: ''
    }]
  };
};

export const renderShippingFulfillment = ({ fulfillment, availabilityType }) => {
  const deliveryService = (fulfillment?.fulfillmentOptions || []).find((option) => option?.type === 'delivery');
  const shippingFulfillment = (deliveryService?.services || []).find((service) => service?.type === 'sth');
  const expressFulfillment = (deliveryService?.services || []).find((service) => service?.type === 'express delivery');
  if (shippingFulfillment) {
    const deliveryStartDate = shippingFulfillment?.deliveryDates?.startDate;
    const deliveryEndDate = shippingFulfillment?.deliveryDates?.endDate;

    const startDate = formatDate(deliveryStartDate);
    const endDate = formatDate(deliveryEndDate);
    const sthTemplate = (
      <>
        {shippingFulfillment.hasFreeShipping && (
          <span className="sui-text-success">Free Delivery</span>
        )}
        {!shippingFulfillment.hasFreeShipping
          && shippingFulfillment.freeDeliveryThreshold && (
          <span className="sui-text-success">
            {`Free Delivery on $${parseInt(shippingFulfillment.freeDeliveryThreshold, 10)} order`}
          </span>
        )}
        {!shippingFulfillment.hasFreeShipping
          && !shippingFulfillment.freeDeliveryThreshold && (
          <span className="sui-text-success">Standard Delivery</span>
        )}
        <span> - </span>
        {startDate && (
          <>
            {startDate === endDate && (
              <>
                <span>Get it by </span>
                <span className="sui-font-bold">
                  {`${formatWeek(deliveryEndDate)}, ${endDate}`}
                </span>
              </>
            )}
            {startDate !== endDate && (
              <>
                <span>Get it </span>
                <span className="sui-font-bold">
                  {startDate}
                </span>
                {endDate && (
                  <span className="sui-font-bold"> - {endDate}</span>
                )}
              </>
            )}
          </>
        )}
      </>
    );
    return {
      method: FULFILLMENT_METHOD_STH,
      showATC: true,
      template: sthTemplate
    };
  }
  if (expressFulfillment) {
    let bodfsTemplate = <span className="sui-text-success">Schedule Delivery</span>;
    if (expressFulfillment.deliveryTimeline.toUpperCase() === 'TODAY') {
      bodfsTemplate = (
        <span className="sui-text-success">Same-day delivery</span>
      );
    } else if (expressFulfillment.deliveryTimeline.toUpperCase() === 'TOMORROW') {
      bodfsTemplate = (
        <span className="sui-text-success">Next-day delivery</span>
      );
    }
    return {
      method: FULFILLMENT_METHOD_BODFS,
      showATC: true,
      template: bodfsTemplate
    };
  }
  if (!fulfillment?.fulfillmentOptions && availabilityType?.discontinued) {
    return {
      method: FULFILLMENT_METHOD_STH,
      showATC: false,
      discontinued: true,
      template: ''
    };
  }
  if (!fulfillment?.fulfillmentOptions && availabilityType?.type === 'Online') {
    return {
      method: FULFILLMENT_METHOD_STH,
      showATC: false,
      oos: true,
      template: (<span className="sui-text-danger">Out of stock online</span>)
    };
  }
  return {
    method: FULFILLMENT_METHOD_STH,
    showATC: false,
    template: (<div> Delivery is <span className="sui-text-danger">unavailable</span> for this product </div>)
  };
};

const handleService = (checked, category, serviceType) => ({
  service: {
    type: checked ? serviceType : null,
    selected: checked,
    category
  }
});
const handleAttachLaborStoreAssembly = (checked, fulfillmentMethod, isBODFSAssembly) => {
  let assembleItemAtStore = (fulfillmentMethod === FULFILLMENT_METHOD_BOPIS) ? checked : null;
  if (isBODFSAssembly) {
    assembleItemAtStore = (fulfillmentMethod === FULFILLMENT_METHOD_BODFS) ? checked : null;
  }
  return assembleItemAtStore;
};
export const getSelectedAddOnInfo = (addOnData = {}, fulfillmentMethod = '') => {
  let addOn = {};
  let flags = {};
  const {
    checked, addOnType, category, type = '', isBODFSAssembly
  } = addOnData;

  switch (addOnType) {
  case HDPP:
    addOn = {
      warrantyItem: {
        itemId: checked ? addOnData.itemId : null
      }
    };
    break;
  case ATTACH_LABOR:
    addOn = {
      attachedLabor: [{
        attachedLaborSku: checked ? addOnData.itemId : null
      }]
    };
    flags.assembleItemAtHome = fulfillmentMethod === FULFILLMENT_METHOD_STH
      && type?.toLowerCase() === ATTACH_LABOR_TYPE.ASSEMBLY ? checked : null;
    break;
  case ATTACH_LABOR_STORE_ASSEMBLY:
    flags.assembleItemAtStore = handleAttachLaborStoreAssembly(checked, fulfillmentMethod, isBODFSAssembly);
    break;
  case PRO_ASSEMBLY:
    addOn = handleService(checked, PRO_ASSEMBLY, 'Assembly');
    break;
  case PRO_REFERRAL:
    addOn = handleService(checked, 'proReferral', category);
    break;
  case DUAL_PATH:
    addOn = {
      dualPath: checked,
      category
    };
    break;
  case SAM_DUAL_PATH:
    addOn = {
      dualPath: checked
    };
    break;
  case VIRTUAL_ASSISTANCE:
    addOn = {
      virtualAssistance: checked
    };
    break;
  default:
    addOn = {};
  }
  return { addOn, flags };
};

const getPickupFulfillment = (fulfillment) => {
  const pickupFulfillment = (fulfillment?.fulfillmentOptions || []).find((option) => option.type === 'pickup');
  return pickupFulfillment;
};

const getDeliveryFulfillment = (fulfillment) => {
  const deliveryFulfillment = (fulfillment?.fulfillmentOptions || []).find((option) => option.type === 'delivery');
  return deliveryFulfillment;
};

const getBopisPrimaryStoreLocation = (fulfillment) => {
  const pickupFulfillment = getPickupFulfillment(fulfillment);
  const bopisFulfillment = (pickupFulfillment?.services || []).find((service) => service.type === 'bopis');
  const bopisPrimaryStoreLocation = (bopisFulfillment?.locations || []).find(
    (location) => location.isAnchor && location?.type === 'store');
  return bopisPrimaryStoreLocation;
};

const getBopisNearbyStoreLocation = (fulfillment) => {
  const pickupFulfillment = getPickupFulfillment(fulfillment);
  const bopisFulfillment = (pickupFulfillment?.services || []).find((service) => service.type === 'bopis');
  const bopisNearbyStoreLocation = (bopisFulfillment?.locations || []).find(
    (location) => !location.isAnchor && location?.type === 'store');
  return bopisNearbyStoreLocation;
};

const getBossLocation = (fulfillment) => {
  const pickupFulfillment = getPickupFulfillment(fulfillment);
  const bossFulfillment = (pickupFulfillment?.services || []).find((service) => service.type === 'boss');
  const bossLocation = (bossFulfillment?.locations || []).find((location) => location?.type === 'online');
  return bossLocation;
};

const getSthLocation = (fulfillment) => {
  const deliveryFulfillment = getDeliveryFulfillment(fulfillment);
  const sthFulfillment = (deliveryFulfillment?.services || []).find((service) => service.type === 'sth');
  const sthLocation = (sthFulfillment?.locations || []).find((location) => location?.type === 'online');
  return sthLocation;
};

const getBodfsLocation = (fulfillment) => {
  const deliveryFulfillment = getDeliveryFulfillment(fulfillment);
  const bodfsFulfillment = (deliveryFulfillment?.services || []).find((service) => service.type === 'express delivery');
  const bodfsLocation = (bodfsFulfillment?.locations || []).find((location) => location?.type === 'store');
  return bodfsLocation;
};

const getQuantityFromLocation = (location) => {
  return location?.inventory?.quantity || 0;
};

export const getFulfillmentMaxQuantity = ({ fulfillment = {}, isTwoTileEnabledFS = false, qty = 1 }) => {
  if (!isTwoTileEnabledFS) {
    return qty;
  }
  let maximumInventoryQuantity = 1;
  // extract the inventory quantity for the buyboxState.fulfillmentMethod
  const sthLocation = getSthLocation(fulfillment);
  const sthInventory = getQuantityFromLocation(sthLocation);

  // get the inventory quantity for bodfs
  const bodfsLocation = getBodfsLocation(fulfillment);
  const bodfsInventory = getQuantityFromLocation(bodfsLocation);

  // use the maximum quantity provided for the delivery option
  maximumInventoryQuantity = bodfsInventory > sthInventory ? bodfsInventory : sthInventory;

  const bopisPrimaryStoreLocation = getBopisPrimaryStoreLocation(fulfillment);
  const bopisNearbyStoreLocation = getBopisNearbyStoreLocation(fulfillment);

  const bopisPrimaryStoreInventory = getQuantityFromLocation(bopisPrimaryStoreLocation);
  const bopisNearbyInventory = getQuantityFromLocation(bopisNearbyStoreLocation);
  const bopisInventory = bopisPrimaryStoreInventory > bopisNearbyInventory ? bopisPrimaryStoreInventory
    : bopisNearbyInventory;

  const bossLocation = getBossLocation(fulfillment);
  const bossInventory = getQuantityFromLocation(bossLocation);
  const maxPickupInventory = bopisInventory > bossInventory ? bopisInventory : bossInventory;

  // use the maximum quantity provided for pickup and delivery
  maximumInventoryQuantity = maximumInventoryQuantity > maxPickupInventory ? maximumInventoryQuantity
    : maxPickupInventory;

  return maximumInventoryQuantity;
};

// this is used to determine the flags to send to fulfillment-store-selector drawer
export const getAddOnsFlagsForFssDrawer = ({
  productAddOns = {},
  assembleItemAtStore = false,
  assembleItemAtHome = false,
  fulfillmentMethod = '',
  availableFulfillments = []
}) => {
  const flags = {};

  // hdpp insurance
  if (productAddOns?.warrantyItem?.itemId) {
    flags.warrantyItemId = productAddOns?.warrantyItem?.itemId;
  }

  // services (pro-referral, painter referral, etc)
  if (productAddOns?.service?.selected) {
    flags.serviceType = productAddOns?.service?.type;
    flags.serviceCategory = productAddOns?.service?.category;
  }

  // bopis store assembly
  const isBopisAssemblySelected = !!(assembleItemAtStore && fulfillmentMethod === FULFILLMENT_METHOD_BOPIS);

  const isSthAssemblySelectedWithBopisAssemblyAvailable = !!(assembleItemAtHome
    && fulfillmentMethod === FULFILLMENT_METHOD_STH
    && availableFulfillments.find((ffmType) => ffmType === FULFILLMENT_METHOD_BOPIS));

  const isBodfsAssemblySelected = !!(assembleItemAtStore && fulfillmentMethod === FULFILLMENT_METHOD_BODFS);

  if (isBopisAssemblySelected || isSthAssemblySelectedWithBopisAssemblyAvailable || isBodfsAssemblySelected) {
    flags.assembleItemAtStore = true;
  }

  return flags;
};

const getAddOns = ({ warrantyItem, attachedLabor, service, isAssembleItemAtStore }) => {
  const addons = [];
  // for HDPP
  if (warrantyItem?.itemId) {
    addons.push({
      configAttr: warrantyItem.itemId,
      category: 'protectionPlan',
      selected: true
    });
  }
  if (attachedLabor?.[0]?.attachedLaborSku) {
    addons.push({
      configAttr: attachedLabor?.[0]?.attachedLaborSku,
      category: 'service',
      selected: true
    });
  }
  if (isAssembleItemAtStore) {
    addons.push({
      configAttr: 'freeAssemblyAtStore',
      category: 'service',
      selected: true
    });
  }
  if (service?.type) {
    addons.push({
      configAttr: service?.type,
      category: 'service',
      selected: true
    });
  }
  return (addons.length ? addons : null);
};

const buildItemRequest = (itemDetails) => {
  const pickup = [];
  const delivery = [];

  try {
    const {
      itemId,
      quantity,
      fulfillmentMethod,
      configurableItems,
      productAddOns: { warrantyItem, attachedLabor, service },
      storeId,
      zipCode,
      isAssembleItemAtStore,
      originalConfigId,
      configId
    } = itemDetails;
    const item = {
      itemId,
      quantity: quantity.toString()
    };

    item.configId = configId || configurableItems?.itemId;

    if (originalConfigId) {
      item.paintConfiguration = { configurationId: originalConfigId };
    }
    // get addons
    const addons = getAddOns({
      warrantyItem, attachedLabor, service, isAssembleItemAtStore
    });
    if (addons) {
      item.addons = addons;
    }
    if (fulfillmentMethod === 'ShipToStore' || fulfillmentMethod === 'BOPIS') {
      item.location = storeId;
      item.type = fulfillmentMethod === 'ShipToStore' ? 'boss' : 'bopis';
      pickup.push(item);
    } else {
      item.location = zipCode;
      item.type = fulfillmentMethod === 'DeliverFromStore' ? 'express delivery' : 'sth';
      delivery.push(item);
    }
  } catch (err) {
    console.error(err);
  }
  return { pickup, delivery };
};

const createNewItem = (item, itemId, originalConfigId, productAddOns, isAssembleItemAtStore) => {
  const { quantity } = item;
  const newItem = { itemId: item.itemId, quantity: quantity.toString() };

  if (item.itemId === itemId) {
    if (originalConfigId) {
      newItem.paintConfiguration = { configurationId: originalConfigId };
    }
    const addons = getAddOns({ ...productAddOns, isAssembleItemAtStore });
    if (addons) {
      newItem.addons = addons;
    }
  }
  return newItem;
};

const isPickup = (fulfillmentMethod) => {
  return fulfillmentMethod === 'ShipToStore' || fulfillmentMethod === 'BOPIS';
};

export const buildItemsRequest = (itemDetails) => {
  const pickup = [];
  const delivery = [];
  try {
    const {
      itemId,
      isAssembleItemAtStore,
      originalConfigId,
      configuratorCart
    } = itemDetails;

    configuratorCart.forEach((item) => {
      const {
        storeId, zipCode, fulfillmentMethod
      } = item;
      const newItem = createNewItem(item, itemId, originalConfigId, itemDetails.productAddOns, isAssembleItemAtStore);

      if (isPickup(fulfillmentMethod)) {
        newItem.location = storeId;
        newItem.type = fulfillmentMethod === 'ShipToStore' ? 'boss' : 'bopis';
        pickup.push(newItem);
      } else {
        newItem.location = zipCode;
        newItem.type = fulfillmentMethod === 'DeliverFromStore' ? 'express delivery' : 'sth';
        delivery.push(newItem);
      }
    });
  } catch (err) {
    console.error(err);
  }
  return { pickup, delivery };
};

const buildRequest = (itemDetails) => {

  return itemDetails.configuratorCart ? buildItemsRequest(itemDetails) : buildItemRequest(itemDetails);
};

export const buildAddToCartRequest = (params) => {
  let cartRequest = {};
  try {
    cartRequest = {
      filterItem: true,
      localization: { primaryStoreId: parseInt(params.storeId, 10) || 121 },
      cartAttr: {
        essentialAccessories: true
      },
      items: {}
    };
    const { pickup, delivery } = params?.bipCartItems ? params?.bipCartItems : buildRequest(params);
    if (pickup.length) {
      cartRequest.items.pickup = pickup;
    }
    if (delivery.length) {
      cartRequest.items.delivery = delivery;
    }

  } catch (err) {
    console.error(err);
  }
  return cartRequest;
};

export const getIsConfigurableBlinds = ({ data }) => {
  const hasConfigurationType = data?.product?.identifiers?.productType === 'CONFIGURABLE_BLINDS';
  const hasConfiguration = !!data?.product?.configuration;
  const isConfigurableBlinds = hasConfiguration || hasConfigurationType;

  if (typeof window !== 'undefined') {
    window.LIFE_CYCLE_EVENT_BUS.trigger('configurator.isConfigurableBlinds', { isConfigurableBlinds });
  }

  return isConfigurableBlinds;
};
