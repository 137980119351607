import React, { useEffect } from 'react';
import Proptypes from 'prop-types';
import {
  useDataModel,
  params,
  bool as boolType,
  shape as shapeType,
  string as stringType
} from '@thd-nucleus/data-sources';
import { Typography } from '@one-thd/sui-atomic-components';
import { WhatWeOfferContent } from './WhatWeOfferContent';
import { WhatWeOfferPlaceholder } from './WhatWeOfferPlaceholder';

export const WhatWeOffer = (props) => {
  const { itemId, productData } = props;

  const isValidProductData = typeof productData?.info?.displayWhatWeOffer === 'boolean';

  const options = {
    variables: {
      itemId
    },
    skip: isValidProductData
  };

  useEffect(() => { 
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('what-we-offer.ready'); 
  }, []);

  const { error, loading, data } = useDataModel('product', options);

  const whatWeOfferFlag = isValidProductData
    ? productData?.info?.displayWhatWeOffer
    : data?.product?.info?.displayWhatWeOffer;

  const displayWhatWeOffer = !error && !!whatWeOfferFlag;

  if (!loading && !displayWhatWeOffer) {
    return null;
  }
  const content = loading ? <WhatWeOfferPlaceholder /> : <WhatWeOfferContent />;

  return (
    <div
      className="sui-flex sui-flex-col sui-w-auto sui-m-4 lg:sui-px-0"
      data-component="WhatWeOffer"
    >
      {!loading && (
        <div className="sui-mb-4">
          <Typography variant="h2">
            What We Offer
          </Typography>
        </div>

      )}
      {content}
    </div>
  );
};

WhatWeOffer.propTypes = {
  itemId: Proptypes.string.isRequired,
  productData: Proptypes.shape({
    info: Proptypes.shape({
      displayWhatWeOffer: Proptypes.bool
    })
  })
};

WhatWeOffer.defaultProps = {
  productData: null
};

WhatWeOffer.displayName = 'WhatWeOffer';

WhatWeOffer.dataModel = {
  product: params({ itemId: stringType().isRequired() }).shape({
    itemId: stringType(),
    dataSources: stringType(),
    info: shapeType({
      displayWhatWeOffer: boolType()
    })
  })
};