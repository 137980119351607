import { useMemo, useContext } from 'react';
import { QueryContext, useDataModel } from '@thd-nucleus/data-sources';

import { getBrandName, getBundleMedia } from '../utils/product-utils';

export const useBundleProducts = ({ bundleId, storeId }) => {

  const { defaultVariables } = useContext(QueryContext);

  const { data: productData, loading: productLoading, error: productError } = useDataModel('product', {
    variables: {
      itemId: bundleId,
      storeId
    },
    skip: !bundleId
  });

  const bundleProductIds = productData?.product?.bundleSpecificationDetails?.components;

  const { data, loading: productsLoading, error: productsError } = useDataModel('products', {
    variables: {
      itemIds: bundleProductIds?.map(({ defaultProductId }) => defaultProductId),
      ...(typeof defaultVariables?.current?.isBrandPricingPolicyCompliant === 'boolean' && {
        isBrandPricingPolicyCompliant: defaultVariables.current.isBrandPricingPolicyCompliant
      })
    },
    skip: !bundleProductIds?.map(({ defaultProductId }) => defaultProductId)
  });

  const image = useMemo(() => {
    if (!productData?.product?.media) return '';
    const bundleMedia = getBundleMedia(productData?.product, data?.products);
    return bundleMedia?.media?.image?.url;
  }, [productData, data]);

  const url = productData?.product?.identifiers?.canonicalUrl;
  const label = productData?.product?.identifiers?.productLabel;
  const brand = getBrandName(productData?.product, data?.products);

  const hasBundleData = !!productData && !!data;
  const hasErrorMessage = !!productError || !!productsError;

  return {
    bundleProduct: productData?.product,
    bundleProducts: data?.products,
    loading: productLoading || productsLoading,
    url,
    label,
    brand,
    image,
    hasBundleData,
    hasErrorMessage
  };
};
