/* eslint-disable max-len */
import React, { useContext, Fragment } from 'react';
import { arrayOf, string, bool } from 'prop-types';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { useProductDeprecated } from '@thd-nucleus/data-sources/react/dataModel/migration';
import { dataModel } from './dataModel';
import './compare-specifications.scss';

const CompareSpecifications = ({ itemIds, hideSimilar }) => {

  const GROUP_TYPES = {
    DIMENSIONS: 'Dimensions',
    DETAILS: 'Details',
    WARRANTY: 'Warranty / Certifications'
  };
  const { channel } = useContext(ExperienceContext);

  const equal = (array) => array.every((value) => value === array[0]);

  const getSpecificationGroup = (product, title) => {
    return (product?.specificationGroup).find((group) => group?.specTitle === title)?.specifications || [];
  };

  const getSpecifications = (data, title) => {
    return data.map((product) => getSpecificationGroup(product, title));
  };

  const getSpecNames = (data, title) => {
    return getSpecifications(data, title).map((specifications) => {
      return specifications.map((specification) => {
        return specification?.specName;
      });
    });
  };

  const getSpecValues = (data, title, name) => {
    return getSpecifications(data, title).map((specifications) => {
      const spec = specifications.find((specification) => specification?.specName === name);
      return spec ? spec.specValue : '-';
    });
  };

  const getSpecData = (data, title, name) => {
    const values = getSpecValues(data, title, name) || [];
    const isEqual = equal(values);
    return {
      name,
      isEqual,
      values,
      columns: values.length
    };
  };

  const getProductById = (products, itemId) => {
    const product = products.find((prod) => prod.itemId === itemId);

    return product || {};
  };

  const getSpecificationData = (products) => {
    const specProducts = itemIds.map((itemId) => getProductById(products, itemId));

    const detailsNames = [...new Set(getSpecNames(specProducts, GROUP_TYPES.DETAILS).join(',').split(','))].sort();
    const warrantyNames = [...new Set(getSpecNames(specProducts, GROUP_TYPES.WARRANTY).join(',').split(','))].sort();
    const dimensionNames = [...new Set(getSpecNames(specProducts, GROUP_TYPES.DIMENSIONS).join(',').split(','))].sort();

    const details = detailsNames.map((name) => getSpecData(specProducts, GROUP_TYPES.DETAILS, name));
    const warranty = warrantyNames.map((name) => getSpecData(specProducts, GROUP_TYPES.WARRANTY, name));
    const dimensions = dimensionNames.map((name) => getSpecData(specProducts, GROUP_TYPES.DIMENSIONS, name));

    return {
      DETAILS: details,
      WARRANTY: warranty,
      DIMENSIONS: dimensions
    };
  };

  const domains = ['specificationGroup'];
  const { error, loading, data } = useProductDeprecated({
    domains,
    itemIds
  }, 'products');

  if (loading || (error && !data) || !data?.products) {
    return null;
  }

  const specificationData = getSpecificationData(data.products);

  const shouldRenderGroup = (group) => {
    return !(hideSimilar && group.isEqual);
  };

  const renderGroupRowsDesktop = (groupData) => (
    groupData.filter(shouldRenderGroup).map((group, key) => (
      <div className="compare-specifications__table-row" key={key}>
        <div className="compare-specifications__table-cell">{group.name}</div>
        {group.values.map((value, idx) => {
          const groupKey = `value-${idx}`;
          return <div className="compare-specifications__table-cell" key={groupKey}>{value}</div>;
        })}
      </div>
    ))
  );

  const renderGroupRowsMobile = (groupData) => (
    groupData.filter(shouldRenderGroup).map((group, key) => (
      <Fragment key={key}>
        <div className="compare-specifications__table-row">
          <div className="compare-specifications__table-title">
            <span>{group.name}</span>
          </div>
        </div>
        <div className="compare-specifications__table-row">
          {group.values.map((value, idx) => {
            const groupKey = `value-${idx}`;
            return <div className="compare-specifications__table-cell" key={groupKey}>{value}</div>;
          })}
        </div>
      </Fragment>
    ))
  );

  const renderSpecificationGroupDesktop = (groupData, groupType) => (
    <div className="compare-specifications__card">
      <h2 className="compare-specifications__card-title">{groupType}</h2>
      <div className="compare-specifications__table">
        {renderGroupRowsDesktop(groupData)}
      </div>
    </div>
  );

  const renderSpecificationGroupMobile = (groupData, groupType) => (
    <div className="compare-specifications__card">
      <h2 className="compare-specifications__card-title"><span>{groupType}</span></h2>
      <div className="compare-specifications__table">
        {renderGroupRowsMobile(groupData)}
      </div>
    </div>
  );

  const renderForDesktop = () => (
    <div className="compare-specifications" data-component="CompareSpecificationsDesktop">
      <h1 className="compare-specifications__title">Specifications</h1>
      {renderSpecificationGroupDesktop(specificationData.DIMENSIONS, GROUP_TYPES.DIMENSIONS)}
      {renderSpecificationGroupDesktop(specificationData.DETAILS, GROUP_TYPES.DETAILS)}
      {renderSpecificationGroupDesktop(specificationData.WARRANTY, GROUP_TYPES.WARRANTY)}
    </div>
  );

  const renderForMobile = () => (
    <div className="compare-specifications" data-component="CompareSpecificationsMobile">
      <h1 className="compare-specifications__title"><span>Specifications</span></h1>
      {renderSpecificationGroupMobile(specificationData.DIMENSIONS, GROUP_TYPES.DIMENSIONS)}
      {renderSpecificationGroupMobile(specificationData.DETAILS, GROUP_TYPES.DETAILS)}
      {renderSpecificationGroupMobile(specificationData.WARRANTY, GROUP_TYPES.WARRANTY)}
    </div>
  );

  return channel === 'desktop' ? renderForDesktop() : renderForMobile();
};

CompareSpecifications.displayName = 'CompareSpecifications';

CompareSpecifications.propTypes = {
  itemIds: arrayOf(string).isRequired,
  hideSimilar: bool
};

CompareSpecifications.defaultProps = {
  hideSimilar: false
};

CompareSpecifications.dataModel = dataModel;

export { CompareSpecifications };
