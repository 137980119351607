import { PACKAGE_URL_BASE_PATH, ITEM_PROMO, ORDER_PROMO, MAJOR_APPLIANCE_TYPE } from './constants';

export const groupProducts = (products) => {
  return products?.reduce((acc, item) => {
    const productIdentifier = item.info.productGroup;
    if (!acc[productIdentifier]) {
      acc[productIdentifier] = [{ ...item, selected: true }];
    } else {
      acc[productIdentifier].push({ ...item, selected: false });
    }
    return acc;
  }, {});
};

export const getPackageData = (data) => {
  const filteredData = { packages: data?.packages?.filter((dataPackage) => !!dataPackage?.products?.length) };
  const groupedProducts = groupProducts(filteredData.packages[0].products);
  const selectedProducts = Object.keys(groupedProducts).flatMap((key) => {
    return groupedProducts[key].filter((product) => product.selected);
  });
  const packageModel = {
    metadata: filteredData?.packages?.[0]?.metadata,
    totalPricing: {},
    selectedProductIds: selectedProducts.map((product) => product.itemId),
    isMajorAppliancePackage: selectedProducts.some((product) => {
      return product.identifiers.productType === MAJOR_APPLIANCE_TYPE;
    }),
    products: new Map(Object.keys(groupedProducts).flatMap((key) => {
      return groupedProducts[key].map((product) => [product.itemId, product]);
    }))
  };
  const packageCategory = filteredData?.packages[0]?.metadata?.packageCategory;
  const itemMap = selectedProducts.reduce((productMap, product) => {
    return {
      ...productMap,
      [product.itemId]: { ...productMap[product.itemId], selectedQty: 1 }
    };
  }, {});
  return {
    selectedProducts,
    packageModel,
    packageCategory,
    itemMap
  };
};

export const getPackageUrl = (packageModel) => {
  const {
    metadata: {
      name,
      packageId,
    },
    isMajorAppliancePackage
  } = packageModel;

  if (!name || !packageId) return '';
  const seo1 = isMajorAppliancePackage ? 'appliances' : 'general-merchandise';
  const slug = name ? name.split(' ').join('-') : 'package-items';
  return `${PACKAGE_URL_BASE_PATH}/${seo1}/package/${slug}/${packageId}`;
};

export const getImageUrl = (packageModel, imageSize = '600') => {
  const {
    metadata: {
      image
    }
  } = packageModel;
  return image?.url ? image.url.replace(/<SIZE>/g, imageSize) : '';
};

export const getDefaultImage = (packageModel) => {
  const {
    metadata: {
      defaultImage
    }
  } = packageModel;
  return defaultImage?.url ? defaultImage.url.replace(/<SIZE>/g, '400') : '';
};

export const getPackageName = (packageModel) => {
  const {
    metadata: {
      name
    }
  } = packageModel;
  return name;
};

export const getBrandName = (packageModel) => {
  const {
    metadata: {
      brandName
    }
  } = packageModel;
  return brandName;
};

export const getBrandImage = (packageModel) => {
  return packageModel.metadata.brandImage?.url || '';
};

export const getPackageTitle = (packageModel) => {
  const name = getPackageName(packageModel);
  return name ? `Shop the ${name}` : 'Shop the Package Bundle';
};

const parsePriceValue = (price) => {
  const priceParts = {};
  if (!price) {
    return '';
  }
  const priceSplit = price.toString().split('.');
  priceParts.dollars = priceSplit[0]; // eslint-disable-line
  priceParts.cents = priceSplit[1] || '00';
  if (priceParts.cents.length === 1) {
    priceParts.cents += '0';
  }
  return priceParts;
};

const getUniquePromoIds = (promos) => {
  const promoIds = promos?.filter((promo) => !!promo.promoId).map((promo) => promo.promoId) || [];
  return [...new Set(promoIds)];
};

const getSavingLabels = (promos) => {
  const promoIds = getUniquePromoIds(promos);
  return promoIds?.map((id) => promos.find((promo) => promo.promoId === id).description?.shortDesc || ''
  ) || [];
};

const getSavings = (promos) => {
  return promos?.map((promotion) => promotion.dollarOff)
    .reduce((savings, dollarOff) => savings + Number.parseFloat(dollarOff), 0) || 0;
};

const calculatePromotionalAdjustments = (ids, products) => {

  const promotionalAdjustments = Array.from(products.values())
    .filter((product) => ids.includes(product.identifiers?.itemId) && !!product.pricing?.promotionalAdjustments)
    .flatMap((product) => product.pricing.promotionalAdjustments);

  const filteredPromos = promotionalAdjustments?.filter((promo) => {
    return (!!promo?.dollarOff && !Number.isNaN(promo.dollarOff));
  });

  const bundlePromos = filteredPromos?.filter((promotion) => ITEM_PROMO === promotion.type);
  const additionalPromos = filteredPromos?.filter((promotion) => ORDER_PROMO === promotion.type);
  const bundleSavings = getSavings(bundlePromos);
  const additionalSavings = getSavings(additionalPromos);

  return {
    bundleSavings,
    additionalSavings,
    hasBundleSavings: bundleSavings > 0,
    hasAdditionalSavings: additionalSavings > 0,
    bundleSavingsLabels: bundleSavings > 0 ? getSavingLabels(bundlePromos) : [],
    additionalSavingsLabels: additionalSavings > 0 ? getSavingLabels(additionalPromos) : []
  };
};

export const calculateTotal = (products, itemMap) => {
  const template = {
    showMapMessage: false,
    original: 0,
    savings: 0,
    bundleSavings: 0,
    additionalSavings: 0,
    percentage: 0,
    value: 0
  };

  let calculatedTotal = products.reduce((total, item) => {
    const pricing = item.pricing || null;
    if (!pricing) return total;
    const {
      mapAboveOriginalPrice,
      original,
      value,
      promotion = {},
      mapDetail = {},
      promotionalAdjustments = []
    } = pricing || {};
    const mapPolicy = mapDetail?.mapPolicy || null;

    let perItemPrice;

    if (mapPolicy !== null) {
      perItemPrice = value || original;
    } else if (mapAboveOriginalPrice !== null && mapAboveOriginalPrice !== undefined) {
      perItemPrice = original;
    } else {
      perItemPrice = value;
    }

    /* eslint-disable */
    total.original += (original || perItemPrice || 0) * item.selectedQty;
    total.value += (value || perItemPrice || 0) * itemMap[item?.itemId]?.selectedQty;
    total.savings += promotion?.dollarOff || 0;

    const filteredPromos = promotionalAdjustments?.filter(promotion => {
      return (!!promotion?.dollarOff && !Number.isNaN(promotion.dollarOff));
    });

    total.bundleSavings += filteredPromos?.filter(promotion => 'ITEMPROMO' === promotion.type)
      .map(promotion => promotion.dollarOff)
      .reduce((bundleSavings, dollarOff) => bundleSavings + Number.parseFloat(dollarOff), 0) || 0;

    total.additionalSavings += filteredPromos?.filter(promotion => 'ORDERPROMO' === promotion.type)
      .map(promotion => promotion.dollarOff)
      .reduce((additionalSavings, dollarOff) => additionalSavings + Number.parseFloat(dollarOff), 0) || 0;

      if (mapAboveOriginalPrice !== null || mapPolicy !== null) {
        total.showMapMessage = true;
      }
    /* eslint-enable */
    return total;
  }, template);

  const itemIds = products.map((product) => product.itemId);
  const adjustments = calculatePromotionalAdjustments(itemIds, products);
  const totalValue = calculatedTotal.value - adjustments.bundleSavings - adjustments.additionalSavings;
  const totalSavings = calculatedTotal.savings + adjustments.bundleSavings + adjustments.additionalSavings;
  const [bundleSavingLabel = ''] = adjustments.bundleSavingsLabels;
  const [additionalSavingLabel = ''] = adjustments.additionalSavingsLabels;

  return {
    value: parsePriceValue(calculatedTotal.value.toFixed(2)),
    original: calculatedTotal.original.toFixed(2),
    totalValue: parsePriceValue(totalValue.toFixed(2)),
    totalSavings: totalSavings.toFixed(2),
    bundleLabel: bundleSavingLabel,
    additionalLabel: additionalSavingLabel,
    hasTotalSavings: totalSavings > 0,
    percentage: ((totalSavings / calculatedTotal.value) * 100).toFixed(0),
    showMapMessage: calculatedTotal.showMapMessage,
  };
};

export const containsOOSItems = (itemMap) => Object.values(itemMap).filter(({ availableQty }) => !availableQty).length;
