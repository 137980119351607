import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ExperienceContext, useConfigService } from '@thd-nucleus/experience-context';
import { Banner } from './banner/banner.component';
import { CARD_IMAGE } from '../utils/constants';
import {
  getDefaultApplyNowURL,
  getPaymentEstimatorDetails,
  loadEstimatorScript
} from '../utils/payment-estimator-utils';
import { clickEvent } from '../utils/publisher';
import TermsAndConditionsDrawer from '../common/terms-and-conditions-drawer.component';
import ProXtraDrawer from '../common/pro-xtra-drawer.component';

export const PLCCBanner = (props) => {
  const { isB2B, plccEligibleAmount, saleAmount } = props;
  const cardImage = isB2B ? CARD_IMAGE.PLCR_HOMEDEPOT : CARD_IMAGE.PLCN_HOMEDEPOT;
  const experienceContext = useContext(ExperienceContext);
  const paymentEstimatorVersion = useConfigService('PaymentEstimatorVersion') || 'v1.2.0';
  const [paymentEstimatorDetails, setPaymentEstimatorDetails] = useState(null);
  const [paymentEstimatorLoaded, setPaymentEstimatorLoaded] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const { channel } = experienceContext;
  const isMobile = channel === 'mobile';
  const {
    activeEndDate, activeStartDate, applyNowUrl, displayPrice, promotionTerm
  } = paymentEstimatorDetails || {};

  useEffect(() => {
    const loadPaymentEstimator = () => {
      setPaymentEstimatorDetails(getPaymentEstimatorDetails(saleAmount, isMobile, false, false, 'cart'));
      setPaymentEstimatorLoaded(true);
    };
    const loadIntroOffer = () => {
      setPaymentEstimatorDetails({ applyNowUrl: getDefaultApplyNowURL(isMobile, 'cart', isB2B) });
      setPaymentEstimatorLoaded(true);
    };

    loadEstimatorScript(
      paymentEstimatorVersion,
      isB2B ? loadIntroOffer : loadPaymentEstimator,
      isMobile
    );
  }, [saleAmount]);

  const analyticEvent = () => {
    clickEvent('plcc', 'right rail');
  };

  const getDisplayTotal = (price) => {
    return price.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const getTitle = () => {
    return (
      isB2B ? <p className="sui-font-w-bold">Earn Perks <span className="sui-text-brand">4X</span> Faster*</p>
        : <p className="sui-font-w-bold">Pay ${displayPrice} per Month** When You Use Your Card</p>
    );
  };

  const getDescription = () => {
    if (isB2B) {
      const boldText = `$${getDisplayTotal((Number(plccEligibleAmount) * 4))} in Perks Spend`;
      return (
        <span>Earn <span className="sui-font-w-bold">{boldText}</span> on a
          ${getDisplayTotal(plccEligibleAmount)} qualifying purchase when you join Pro Xtra, register
          and use your card.
        </span>
      );
    }

    const promoDateRange = activeStartDate && activeEndDate ? ` Valid ${activeStartDate} - ${activeEndDate}` : '';

    return (
      <span>${displayPrice} per month** suggested payments with {promotionTerm} months
        financing on this ${saleAmount} qualifying purchase.{promoDateRange}
      </span>
    );
  };

  const getApplyNowLink = () => {
    return applyNowUrl + `&saleAmt=${saleAmount}` || '';
  };
  return (
    <>
      {isB2B ? (
        <ProXtraDrawer applyNowUrl={getApplyNowLink()} closeDrawer={() => setShowDrawer(false)} open={showDrawer} />
      )
        : (
          <TermsAndConditionsDrawer
            closeDrawer={() => setShowDrawer(false)}
            open={showDrawer}
            isB2B={isB2B}
            showFinancing
            applyNowClick={() => clickEvent('plcc-est')}
            price={saleAmount}
          />
        )}
      {isB2B || (!isB2B && saleAmount > 300) ? (
        <Banner
          cardImage={cardImage}
          title={getTitle()}
          description={getDescription()}
          applyNow={getApplyNowLink()}
          viewDetails={() => setShowDrawer(true)}
          applyClick={analyticEvent}
          isMobile={isMobile}
        />
      ) : null}
    </>
  );
};

PLCCBanner.propTypes = {
  isB2B: PropTypes.bool,
  plccEligibleAmount: PropTypes.number,
  saleAmount: PropTypes.number
};

PLCCBanner.defaultProps = {
  isB2B: false,
  plccEligibleAmount: 0,
  saleAmount: 0
};
