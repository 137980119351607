import React, { useContext, Suspense } from 'react';
import classNames from 'classnames';
import { QueryContext, QueryProvider } from '@thd-nucleus/data-sources';
import { AddToCart } from '@thd-olt-component-react/add-to-cart';
import { QuantityAdjustmentsContext, FulfillmentLazy } from '@thd-olt-component-react/bundling-product-tabs';
import { Col, Row, Button } from '@thd-olt-component-react/core-ui';
import { PackagesMiniContext } from '../PackagesMiniContext';
import { TotalPrice } from './TotalPrice';
import { useAddToCartProps } from '../../hooks/useAddToCartProps';
import { getPackageUrl, containsOOSItems } from '../../utils';
import './transactional-package-summary.scss';

const TransactionalPackageSummary = () => {
  const { products, packageModel } = useContext(PackagesMiniContext);
  const { defaultVariables } = useContext(QueryContext) || {};
  const { itemMap, fulfillmentFetched } = useContext(QuantityAdjustmentsContext);
  const { cartReqParams, cartOptions } = useAddToCartProps();
  const packageUrl = getPackageUrl(packageModel);
  const hasOutOfStockItems = fulfillmentFetched && containsOOSItems(itemMap);
  const isMajorAppliancePackage = packageModel.isMajorAppliancePackage;
  const displayAddBundleToCart = isMajorAppliancePackage || !hasOutOfStockItems;
  const fulfillmentAlertClasses = classNames('package-summary__fulfillment-alert-container', {
    'package-summary__fulfillment-alert-container-hidden': displayAddBundleToCart
  });
  const newDefaultVariables = {
    ...(typeof defaultVariables?.current?.isBrandPricingPolicyCompliant === 'boolean' && {
      isBrandPricingPolicyCompliant: defaultVariables.current.isBrandPricingPolicyCompliant
    })
  };

  return (
    <Row>
      <Col flatten>
        <span className="package-summary__message">
          {`Total Price for ${products.length} items`}
        </span>
      </Col>
      <Col sm={6} md={5} flatten>
        <TotalPrice products={products} isPackageTransactional />
        {typeof window !== 'undefined' && (
          <Suspense fallback={<div />}>
            <Col className={fulfillmentAlertClasses}>
              <QueryProvider
                defaultVariables={newDefaultVariables}
                dataSource="catalog"
                cacheKey="transactional-fulfillment"
              >
                <FulfillmentLazy displayTotalCountAlert />
              </QueryProvider>
            </Col>
          </Suspense>
        )}
        {hasOutOfStockItems && !isMajorAppliancePackage ? (
          <Col className="package-summary__out-of-stock-link-container">
            <a href={packageUrl} className="package-summary__out-of-stock-link">
              View bundle details
            </a> to swap out of stock items.
          </Col>
        ) : <div />}
      </Col>
      <Col xs={8} sm={5} md={4}>
        {displayAddBundleToCart ? (
          <AddToCart
            outline
            cartReqParams={cartReqParams}
            cartOptions={cartOptions}
          >
            Add Bundle to Cart
          </AddToCart>
        ) : (
          <Button outline tag="a" href={packageUrl} target="_self">
            View Bundle Details
          </Button>
        )}
      </Col>
    </Row>
  );
};

TransactionalPackageSummary.displayName = 'TransactionalPackageSummary';

export { TransactionalPackageSummary };
