import React from 'react';

export function GlobeIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="language">
        <path
          id="icon"
          d="
            M7.99992 14.6668C7.08881 14.6668 6.2277 14.4918 5.41659 14.1418C4.60547 13.7918 3.89714 13.3141
            3.29159 12.7085C2.68603 12.1029 2.20825 11.3946 1.85825 10.5835C1.50825 9.77238 1.33325 8.91127
            1.33325 8.00016C1.33325 7.07794 1.50825 6.21405 1.85825 5.4085C2.20825 4.60294 2.68603 3.89739
            3.29159 3.29183C3.89714 2.68627 4.60547 2.2085 5.41659 1.8585C6.2277 1.5085 7.08881 1.3335
            7.99992 1.3335C8.92214 1.3335 9.78603 1.5085 10.5916 1.8585C11.3971 2.2085 12.1027 2.68627
            12.7083 3.29183C13.3138 3.89739 13.7916 4.60294 14.1416 5.4085C14.4916 6.21405 14.6666 7.07794
            14.6666 8.00016C14.6666 8.91127 14.4916 9.77238 14.1416 10.5835C13.7916 11.3946 13.3138 12.1029
            12.7083 12.7085C12.1027 13.3141 11.3971 13.7918 10.5916 14.1418C9.78603 14.4918 8.92214 14.6668
            7.99992 14.6668ZM7.99992 13.3002C8.28881 12.9002 8.53881 12.4835 8.74992 12.0502C8.96103 11.6168
            9.13325 11.1557 9.26659 10.6668H6.73325C6.86659 11.1557 7.03881 11.6168 7.24992 12.0502C7.46103
            12.4835 7.71103 12.9002 7.99992 13.3002ZM6.26659 13.0335C6.06659 12.6668 5.89159 12.2863 5.74159
            11.8918C5.59159 11.4974 5.46659 11.0891 5.36659 10.6668H3.39992C3.72214 11.2224 4.12492 11.7057
            4.60825 12.1168C5.09159 12.5279 5.64436 12.8335 6.26659 13.0335ZM9.73325 13.0335C10.3555 12.8335
            10.9083 12.5279 11.3916 12.1168C11.8749 11.7057 12.2777 11.2224 12.5999 10.6668H10.6333C10.5333
            11.0891 10.4083 11.4974 10.2583 11.8918C10.1083 12.2863 9.93325 12.6668 9.73325 13.0335ZM2.83325
            9.3335H5.09992C5.06659 9.11127 5.04159 8.89183 5.02492 8.67516C5.00825 8.4585 4.99992 8.2335
            4.99992 8.00016C4.99992 7.76683 5.00825 7.54183 5.02492 7.32516C5.04159 7.1085 5.06659
            6.88905 5.09992 6.66683H2.83325C2.7777 6.88905 2.73603 7.1085 2.70825 7.32516C2.68047 7.54183
            2.66659 7.76683 2.66659 8.00016C2.66659 8.2335 2.68047 8.4585 2.70825 8.67516C2.73603 8.89183 2.7777
            9.11127 2.83325 9.3335ZM6.43325 9.3335H9.56659C9.59992 9.11127 9.62492 8.89183 9.64159 8.67516C9.65825
            8.4585 9.66659 8.2335 9.66659 8.00016C9.66659 7.76683 9.65825 7.54183 9.64159 7.32516C9.62492 7.1085
            9.59992 6.88905 9.56659 6.66683H6.43325C6.39992 6.88905 6.37492 7.1085 6.35825 7.32516C6.34159 7.54183
            6.33325 7.76683 6.33325 8.00016C6.33325 8.2335 6.34159 8.4585 6.35825 8.67516C6.37492 8.89183 6.39992
            9.11127 6.43325 9.3335ZM10.8999 9.3335H13.1666C13.2221 9.11127 13.2638 8.89183 13.2916 8.67516C13.3194
            8.4585 13.3333 8.2335 13.3333 8.00016C13.3333 7.76683 13.3194 7.54183 13.2916 7.32516C13.2638 7.1085
            13.2221 6.88905 13.1666 6.66683H10.8999C10.9333 6.88905 10.9583 7.1085 10.9749 7.32516C10.9916 7.54183
            10.9999 7.76683 10.9999 8.00016C10.9999 8.2335 10.9916 8.4585 10.9749 8.67516C10.9583 8.89183 10.9333
            9.11127 10.8999 9.3335ZM10.6333 5.3335H12.5999C12.2777 4.77794 11.8749 4.29461 11.3916 3.8835C10.9083
            3.47239 10.3555 3.16683 9.73325 2.96683C9.93325 3.3335 10.1083 3.71405 10.2583 4.1085C10.4083 4.50294
            10.5333 4.91127 10.6333 5.3335ZM6.73325 5.3335H9.26659C9.13325 4.84461 8.96103 4.3835 8.74992
            3.95016C8.53881 3.51683 8.28881 3.10016 7.99992 2.70016C7.71103 3.10016 7.46103 3.51683 7.24992
            3.95016C7.03881 4.3835 6.86659 4.84461 6.73325 5.3335ZM3.39992 5.3335H5.36659C5.46659 4.91127
            5.59159 4.50294 5.74159 4.1085C5.89159 3.71405 6.06659 3.3335 6.26659 2.96683C5.64436 3.16683
            5.09159 3.47239 4.60825 3.8835C4.12492 4.29461 3.72214 4.77794 3.39992 5.3335Z"
          fill="#1D1B20"
        />
      </g>
    </svg>
  );
}