import React, { lazy } from 'react';
import {
  QueryProvider
} from '@thd-nucleus/data-sources';
import '../../styles/productListContent.scss';

export const QuickViewLazy = lazy(() => import(
/* webpackChunkName: "quick-view" */
  '@thd-olt-component-react/quick-view'
).then((mod) => {
  const { QuickView } = mod;
  return {
    default: ({ defaultVariables, ...props }) => {
      return (
        <QueryProvider
          dataSource="catalog"
          cacheKey="bundle-list-item-quick-view"
          defaultVariables={defaultVariables?.current}
        >
          <div className="quick-view-wrapper">
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <QuickView {...props} />
          </div>
        </QueryProvider>
      );
    }
  };
}));