import React, { useEffect } from 'react';
import { Alert, Link } from '@one-thd/sui-atomic-components';
import { setErrorEvents } from '../../track';

const BuyboxError = () => {

  useEffect(() => {
    setErrorEvents({
      message: 'We\'re sorry, something went wrong on our end. Please try refreshing.'
    });
  }, []);

  return (
    <div className="sui-w-80" data-component="BuyboxError">
      <Alert status="warning">
        <span>
          {'We\'re sorry, something went wrong on our end.'}
          <br />
          {'Please try '}
          {/* eslint-disable-next-line */}
          <Link className="sui-text-sm" onClick={() => {
            if (typeof window !== 'undefined') {
              window.location.reload(false);
            }
          }}
          >
            <u>refreshing</u>
          </Link>
          .
        </span>
      </Alert>

    </div>
  );
};

BuyboxError.propTypes = {};

BuyboxError.defaultProps = {};

export { BuyboxError };
