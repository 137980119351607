import React, {
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { bool, string, element } from 'prop-types';
import { Popover, Typography } from '@one-thd/sui-atomic-components';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { MOBILE } from '@thd-olt-functional/utils';
import WhatToExpect from './WhatToExpect';

const ProductAddonsTooltip = ({
  checked,
  tooltipContent,
  title,
  label,
  isInstallVirtualAssistance,
  isGarbageDisposalVirtualAssistance,
  isProReferral
}) => {
  const { channel } = useContext(ExperienceContext);
  const isMobile = channel === MOBILE;
  const [anchorEl, setAnchorEl] = useState(null);
  const buttonRef = useRef(null);

  const recentlyCheckedRef = useRef(false);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    // Assumes the firstChild is the info icon after "What to Expect"
    setAnchorEl(
      (isInstallVirtualAssistance || isGarbageDisposalVirtualAssistance || isProReferral)
      && event.currentTarget.firstChild
        ? event.currentTarget.firstChild : event.currentTarget
    );
  };

  const handleClose = () => {
    // If checkbox was recently checked, DO NOT close the tooltip
    if (!(recentlyCheckedRef.current && checked)) {
      setAnchorEl(null);
    } else {
      recentlyCheckedRef.current = false;
    }
  };

  useEffect(() => {
    if (!isMobile) {
      if (checked) {
        if (open) {
          recentlyCheckedRef.current = true;
        } else {
          buttonRef.current.click();
          recentlyCheckedRef.current = false;
        }
      } else {
        recentlyCheckedRef.current = false;
        handleClose();
      }
    }
  }, [checked]);

  const placement = (
    isInstallVirtualAssistance || isGarbageDisposalVirtualAssistance || isProReferral
  ) ? 'left-start' : 'bottom-start';
  const id = open ? `${placement}-add-ons-popover` : undefined;

  return (
    <>
      <Popover
        open={open}
        id={id}
        anchorEl={anchorEl}
        placement={placement}
        title={title}
        onClose={handleClose}
        className="sui-z-800"
      >
        <Typography variant="body-base">
          {tooltipContent}
        </Typography>
      </Popover>
      <WhatToExpect
        onClick={handleClick}
        label={label}
        ref={buttonRef}
      />
    </>
  );
};

ProductAddonsTooltip.displayName = 'ProductAddOns-tooltip';

ProductAddonsTooltip.propTypes = {
  checked: bool,
  title: string,
  tooltipContent: element,
  label: string,
  isInstallVirtualAssistance: bool,
  isProReferral: bool,
  isGarbageDisposalVirtualAssistance: bool
};

ProductAddonsTooltip.defaultProps = {
  checked: false,
  title: '',
  tooltipContent: null,
  label: '',
  isInstallVirtualAssistance: false,
  isProReferral: false,
  isGarbageDisposalVirtualAssistance: false
};

export { ProductAddonsTooltip };