/* global newrelic */
import { podFulFillmentUtils } from '@thd-olt-component-react/fulfillment';

/**
 * To build the logic to collapse overlapping hotspots, there are three distances that need to be measured:
 *
 * 1. Radius of the hotspot icon (in rems)
 * 2. Distance between two hotspot coordinates (in decimals normalized to each axis)
 * 3. Minimum distance allowed between two hotspot coordinates before collapsing (in pixels)
 *
 * The common unit of measurement will be pixels. Hotspots rendered today are <RadioButtonChecked /> icons styled with a
 * set width and height of 2.4rem which can be converted to 24px in our Stencil Design System. Hotspot coordinates today
 * are given in decimals so these will need to be denormalized and converted to pixels. And the minimum distance allowed
 * between two hotspots will be arbitrary but should be a ratio of the hotspot radius and converted to pixels for use.
 */
const HOTSPOT_RADIUS = 12; // 2.4rem * 0.5 * 10px/rem
const RADIUS_MULTIPLE = 4; // arbitrary value agreed upon with UX
const HOTSPOT_COLLAPSE_DISTANCE = RADIUS_MULTIPLE * HOTSPOT_RADIUS;

const denormalizeHotspot = (hotspot, boundingWidth, boundingHeight) => {
  const { xCoordinate, yCoordinate } = hotspot.coordinate;
  return {
    xCoordinate: boundingWidth * parseFloat(xCoordinate),
    yCoordinate: boundingHeight * parseFloat(yCoordinate)
  };
};

const getDistance = (hotspotA, hotspotB, boundingWidth, boundingHeight) => {
  const { xCoordinate: x1, yCoordinate: y1 } = denormalizeHotspot(hotspotA, boundingWidth, boundingHeight);
  const { xCoordinate: x2, yCoordinate: y2 } = denormalizeHotspot(hotspotB, boundingWidth, boundingHeight);
  return Math.hypot(x2 - x1, y2 - y1);
};

const maybeCollapseHotspot = (hotspots, hotspotToCheck, boundingWidth, boundingHeight) => {
  const hotspotToCollapse = hotspots.find((hotspot) => {
    const distance = getDistance(hotspot, hotspotToCheck, boundingWidth, boundingHeight);
    return distance < HOTSPOT_COLLAPSE_DISTANCE;
  });

  return hotspotToCollapse || null;
};

const maybeCollapseTableHotspots = (sortedHotspots) => {
  if (!sortedHotspots || sortedHotspots.length <= 1) return null;

  const xCoordinates = sortedHotspots.map((hotspot) => parseFloat(hotspot.coordinate.xCoordinate));
  const isColumn = Math.max(...xCoordinates) - Math.min(...xCoordinates) < 0.01; // tolerance of 0.01% of bounding width
  if (isColumn) {
    return [{
      coordinate: sortedHotspots[0]?.coordinate,
      omsIDs: [...new Set(sortedHotspots.reduce((acc, hotspot) => acc.concat(hotspot.omsIDs), []))],
      collapsedCount: sortedHotspots.length,
    }];
  }

  return null;
};

const maybeCollapseNonTableHotspots = (sortedHotspots, boundingWidth, boundingHeight) => {
  let tempCollapsedHotspotsList = [...sortedHotspots];
  let finalCollapsedHotspotsList = [];

  while (tempCollapsedHotspotsList.length !== finalCollapsedHotspotsList.length) {
    finalCollapsedHotspotsList = tempCollapsedHotspotsList;

    tempCollapsedHotspotsList = finalCollapsedHotspotsList.reduceRight((acc, nextHotspot) => {
      if (acc.length === 0) return [nextHotspot];

      const hotspotToCollapse = maybeCollapseHotspot(acc, nextHotspot, boundingWidth, boundingHeight);
      if (hotspotToCollapse) {
        const matchIndex = acc.findIndex(
          ({ coordinate: { xCoordinate, yCoordinate } }) => xCoordinate === hotspotToCollapse.coordinate.xCoordinate
            && yCoordinate === hotspotToCollapse.coordinate.yCoordinate
        );
        if (matchIndex !== -1) {
          const mergedHotspot = {
            coordinate: nextHotspot.coordinate,
            omsIDs: [...new Set([...hotspotToCollapse.omsIDs, ...nextHotspot.omsIDs])],
            collapsedCount: (hotspotToCollapse.collapsedCount || 1) + 1,
          };
          acc.splice(matchIndex, 1, mergedHotspot);
        }
      } else {
        acc.unshift(nextHotspot);
      }
      return acc;
    }, []);
  }

  return finalCollapsedHotspotsList;
};

export const getUniqueSortedHotspots = (hotspots = []) => {
  const uniqueHotspotsMap = new Map();

  hotspots.forEach((hotspot) => {
    const { coordinate: { xCoordinate, yCoordinate }, omsIDs } = hotspot;
    const sortedOmsIDs = omsIDs.slice().sort().join();
    const key = `${xCoordinate}|${yCoordinate}|${sortedOmsIDs}`;

    if (!uniqueHotspotsMap.has(key)) {
      uniqueHotspotsMap.set(key, hotspot);
    }
  });

  return Array.from(uniqueHotspotsMap.values()).sort((hotspotA, hotspotB) => {
    const byYCoordinateDesc = parseFloat(hotspotB.coordinate.yCoordinate) - parseFloat(hotspotA.coordinate.yCoordinate);
    const byXCoordinateDesc = parseFloat(hotspotB.coordinate.xCoordinate) - parseFloat(hotspotA.coordinate.xCoordinate);
    return byYCoordinateDesc || byXCoordinateDesc;
  });
};

export const getUniqueCollapsedHotspots = (hotspots, boundingWidth, boundingHeight) => {
  if (!hotspots || !hotspots.length) return [];

  const sortedHotspots = getUniqueSortedHotspots(hotspots);

  const collapsedTableHotspots = maybeCollapseTableHotspots(sortedHotspots);
  if (collapsedTableHotspots) return collapsedTableHotspots;

  const collapsedHotspots = maybeCollapseNonTableHotspots(sortedHotspots, boundingWidth, boundingHeight);
  return collapsedHotspots;
};

export const getUniqueHotspots = (hotspots = []) => {
  const uniqueHotspots = new Map();

  hotspots.forEach((hotspot) => {
    if (!uniqueHotspots.has(hotspot.itemId)) {
      uniqueHotspots.set(hotspot.itemId, hotspot);
    }
  });

  return Array.from(uniqueHotspots.values());
};

export const imageStyle = (hsXPosition, hsYPosition) => {
  return {
    cursor: 'pointer',
    left: `${hsXPosition * 100}%`,
    top: `${hsYPosition * 100}%`
  };
};

export const triggerAnalytics = (name, value) => {
  LIFE_CYCLE_EVENT_BUS.trigger(name, value);
};

export const getProductInfo = (container, productPrice) => {
  const itemId = container?.itemId;
  const brandName = container?.identifiers?.brandName;
  const productLabel = container?.identifiers?.productLabel;
  const canonicalUrl = container?.identifiers?.canonicalUrl;
  const averageRating = container?.reviews?.ratingsReviews?.averageRating || 0;
  const totalReviews = container?.reviews?.ratingsReviews?.totalReviews || 0;
  const images = container?.media?.images;
  const [productImage] = images || [];

  return {
    itemId,
    averageRating,
    brandName,
    imageUrl: productImage?.url || '',
    productLabel,
    productUrl: canonicalUrl,
    totalReviews,
    price: productPrice ? `${productPrice}`.split('.') : ''
  };
};
export const isProductOutOfStockOnline = (product = {}) => {
  const isBODFS = podFulFillmentUtils.isBODFS(product);
  const isBOPIS = podFulFillmentUtils.isBOPIS(product);
  const isOutOfStock = podFulFillmentUtils.isOutOfStockOnline(product);
  const isOOS = isOutOfStock && !(isBOPIS || isBODFS);
  return isOOS;
};

export const getProductAvailability = (product) => {
  let unfulfillable = false;
  const productLoaded = product && Object.keys(product)?.length !== 0;
  if (productLoaded) {
    const isBODFS = podFulFillmentUtils.isBODFS(product);
    const isBOPIS = podFulFillmentUtils.isBOPIS(product);
    const isSTH = podFulFillmentUtils.isSTH(product) && !podFulFillmentUtils.getExcludedStateSth(product);
    const isBOSS = podFulFillmentUtils.isBOSS(product);
    const isOOS = isProductOutOfStockOnline(product);
    const isFulfillable = podFulFillmentUtils.isFulfillable(product);
    const noFulfillment = !isBODFS && !isSTH && !isBOPIS && !isBOSS;
    const isCurrentlyUnavailable = podFulFillmentUtils.isCurrentlyUnavailable(product);
    const isDiscontinued = podFulFillmentUtils.isDiscontinued(product);

    if (!isFulfillable
      || isOOS
      || noFulfillment
      || isCurrentlyUnavailable
      || isDiscontinued
    ) {
      unfulfillable = true;
    }
  }

  return {
    unfulfillable
  };
};

export const getFilteredProduct = (products, imageURL) => {
  const inStockProducts = (products || []).filter((product) => {
    return !getProductAvailability(product).unfulfillable;
  });
  if (!inStockProducts.length) {
    // Trigger a newrelic event when all the products are unfulfillable.
    if (typeof window !== 'undefined' && typeof window.newrelic !== 'undefined' && window.newrelic.addPageAction) {
      const omsIDs = products.map((product) => product.itemId).join();
      window.newrelic.addPageAction('PIPOOSHotspots', {
        omsIDs,
        imageURL
      });
    }
    return [products[0]];
  }
  return inStockProducts;
};
