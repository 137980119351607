import React from 'react';
import { copy } from '@thd-olt-functional/utils';

function getDimension(suppDimensions, entryGroup, dimension) {
  const entry = (suppDimensions || []).filter(({ specTitle }) => specTitle === entryGroup);
  let spec;
  if (entry.length) {
    spec = entry[0].specifications
      .sort((specA, specB) => {
        if (specA.specName < specB.specName) {
          return -1;
        }
        if (specA.specName > specB.specName) {
          return 1;
        }
        return 0;
      })
      .find(({ specName = '' }) => specName.toLowerCase().indexOf(dimension) > -1) || {};
  }
  return spec?.specValue || '';
}

function checkEnergyConsumption(specs) {
  const certification = getDimension(specs, 'Warranty / Certifications', 'certifications and listings').split(',');
  return certification.indexOf('Energy Star') > -1;
}

function generateReturnablePolicy(returnable) {
  const template = {
    '@type': 'MerchantReturnPolicy',
    applicableCountry: 'US',
  };
  const regex = /\d+/;
  const regexResult = regex.exec(returnable);

  if (regexResult) {
    return {
      ...template,
      returnPolicyCategory: 'https://schema.org/MerchantReturnFiniteReturnWindow',
      merchantReturnDays: Number(regexResult[0])
    };
  }

  return {
    ...template,
    returnPolicyCategory: 'https://schema.org/MerchantReturnNotPermitted'
  };
}

export const getProductStructuredData = ({
  aggregateReviews = {},
  availabilityType,
  identifiers,
  media,
  details,
  reviews,
  pricing,
  specifications,
  schemaExclude,
  ppq,
  returnable
}) => {
  const {
    itemId,
    productLabel = '',
    brandName = '',
    upcGtin13 = '',
    storeSkuNumber = '',
    canonicalUrl = '',
    modelNumber = ''
  } = identifiers || {};
  const { value, promotion, original } = pricing || {};
  const { description } = details || {};
  const today = new Date(Date.now());

  const specs = copy(specifications || []).data;
  const hasAggregateReviews = Object.keys(aggregateReviews).length > 0;

  return {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    name: (schemaExclude?.includes('name')) ? undefined : productLabel,
    image: (schemaExclude?.includes('image'))
      ? undefined : media?.images?.map(({ url }) => url.replace('<SIZE>', '100')),
    description: (schemaExclude?.includes('description')) ? undefined : description,
    productID: (schemaExclude?.includes('productID')) ? undefined : itemId,
    sku: (schemaExclude?.includes('sku')) ? undefined : storeSkuNumber,
    gtin13: (schemaExclude?.includes('gtin13')) ? undefined : upcGtin13,
    model: (schemaExclude?.includes('model')) ? undefined : modelNumber,
    depth: (schemaExclude?.includes('depth')) ? undefined : getDimension(specs, 'Dimensions', 'depth'),
    height: (schemaExclude?.includes('height')) ? undefined : getDimension(specs, 'Dimensions', 'height'),
    width: (schemaExclude?.includes('width')) ? undefined : getDimension(specs, 'Dimensions', 'width'),
    color: (schemaExclude?.includes('color')) ? undefined : getDimension(specs, 'Details', 'color'),
    weight: (schemaExclude?.includes('width')) ? undefined : getDimension(specs, 'Details', 'weight'),
    brand: (schemaExclude?.includes('brand')) ? undefined : {
      '@type': 'Brand',
      name: brandName,
    },
    ...(checkEnergyConsumption(specs) && {
      hasEnergyConsumptionDetails: {
        hasEnergyEfficiencyCategory: 'https://schema.org/EnergyStarCertified'
      },
    }),
    ...(hasAggregateReviews && {
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: aggregateReviews?.averageRating,
        reviewCount: aggregateReviews?.totalReviews,
      },
    }),
    offers: (schemaExclude?.includes('offers') || !(original || value)) ? undefined : {
      '@type': 'Offer',
      url: `https://www.homedepot.com${canonicalUrl}`,
      priceCurrency: 'USD',
      price: value || original,
      priceValidUntil:
        (promotion?.dates?.end) || `${today.getMonth() + 1}/${today.getDate()}/${today.getFullYear()}`,
      ...(original && value !== original && {
        priceSpecification: {
          '@type': 'UnitPriceSpecification',
          priceType: 'https://schema.org/ListPrice',
          price: original,
          priceCurrency: 'USD'
        },
      }),
      availability: availabilityType?.status ? 'https://schema.org/InStock' : undefined,
      hasMerchantReturnPolicy: generateReturnablePolicy(returnable)
    },
    ...(!ppq && {
      review: (schemaExclude?.includes('review')) ? undefined : (reviews || []).map((review) => ({
        '@type': 'Review',
        reviewRating: {
          '@type': 'Rating',
          ratingValue: review.Rating,
          bestRating: '5',
        },
        author: {
          '@type': 'Person',
          name: review.UserNickname,
        },
        headline: review.Title,
        reviewBody: review.ReviewText,
      })),
    }),
  };
};
