const getBreadcrumbObject = (breadcrumbs = []) => {
  if (breadcrumbs.length === 0) return {};

  if (breadcrumbs && breadcrumbs.length && breadcrumbs?.[0]?.label !== 'Home') {
    breadcrumbs.unshift({ label: 'Home', url: '' });
  }

  return {
    '@type': 'BreadcrumbList',
    itemListElement: breadcrumbs.map(({ label, url }, i) => ({
      '@type': 'ListItem',
      position: i + 1,
      item: {
        '@type': 'Thing',
        name: label,
        '@id': `https://www.homedepot.com${url}`,
      },
    })),
  };
};

const getFAQObject = (faqs = []) => {
  return faqs?.map(({ Answer, Question }) => ({
    '@type': 'Question',
    name: Question,
    acceptedAnswer: {
      '@type': 'Answer',
      text: Answer,
    },
  }));
};

const generateFAQSchema = ({ breadcrumbs = [], canonical = '', title = '', faqs = [] }) => {
  return {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    name: title,
    url: canonical,
    breadcrumb: getBreadcrumbObject(breadcrumbs),
    mainEntity: getFAQObject(faqs),
  };
};

const generateWebPageStructuredData = ({ breadcrumbs = [], canonical = '', skus = [], title = '' }) => {
  return {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    name: title,
    url: canonical,
    breadcrumb: getBreadcrumbObject(breadcrumbs),
    mainEntity: {
      '@type': 'WebPageElement',
      offers: {
        '@type': 'Offer',
        itemOffered: (skus || [])
          .map((sku) => (sku.toJSON ? sku.toJSON() : sku))
          .map(({
            identifiers = {},
            reviews = {},
            pricing = {},
            media = {}
          }) => ({
            '@type': 'Product',
            ...(Number(reviews?.ratingsReviews?.totalReviews) && {
              aggregateRating: {
                '@type': 'AggregateRating',
                ratingValue: reviews?.ratingsReviews?.averageRating,
                reviewCount: reviews?.ratingsReviews?.totalReviews,
                bestRating: '5',
                worstRating: '1',
              },
            }),
            brand: {
              '@type': 'Thing',
              name: identifiers.brandName,
            },
            description: identifiers.productLabel,
            image: media.images?.[0]?.url?.replace('<SIZE>', '100'),
            name: identifiers.productLabel,
            offers: {
              '@type': 'Offer',
              availability: 'http://schema.org/InStock',
              price: pricing?.value || pricing?.original,
              priceCurrency: 'USD',
              url: `https://www.homedepot.com${identifiers.canonicalUrl}`,
            },
            sku: identifiers.itemId
          })),
      },
    },
  };
};

const getBrowseSearchStructuredData = ({
  breadcrumbs = [], canonical = '', faqs = {}, skus = [], title = ''
}) => {
  const schemas = [];
  const hasFAQs = faqs?.Questions?.length > 0;

  if (hasFAQs) {
    schemas.push(generateFAQSchema({ breadcrumbs, canonical, faqs: faqs?.Questions, title }));
  }

  schemas.push(generateWebPageStructuredData({ breadcrumbs, canonical, skus, title }));

  return schemas;
};

const getEmtStructuredData = ({ faqs = {}, schema, breadcrumbs = [] }) => {
  const isSchemaAnArray = Array.isArray(schema);
  const arraySchema = isSchemaAnArray ? schema : [schema];
  const newSchemas = [];

  const hasFAQSchema = arraySchema.some((schemaEl) => schemaEl['@type'] === 'FAQPage');
  const hasFaqs = faqs?.length > 0;

  if (!hasFAQSchema && hasFaqs) {
    newSchemas.push({
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      mainEntity: getFAQObject(faqs)
    });
  }

  newSchemas.push({
    '@context': 'https://schema.org',
    ...getBreadcrumbObject(breadcrumbs),
  });

  return [...arraySchema, ...newSchemas];
};

export {
  getBrowseSearchStructuredData,
  getEmtStructuredData
};