import React, { useEffect, useContext } from 'react';
import { shape as shapeProp, arrayOf as arrayProp, object, string as stringType } from 'prop-types';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { Typography, Card, CardMedia } from '@one-thd/sui-atomic-components';
import { SpecificationsAccordionBody } from '@thd-olt-component-react/specifications';
import {
  params, string, arrayOf,
  extend, shape, QueryProvider, QueryContext
} from '@thd-nucleus/data-sources';
import useSetSelectedItemId from '../hooks/useSetSelectedItemId';
import { ProductDetailsButton } from './subcomponents/ProductDetailsButton';
import {
  tiggerAnalyticsCustomEvent, getDdo, getIsMobile, getProductImage, filterRemovedProducts
} from '../utils/product-bundle-utils';

/**
 * Props
 *
 * @typedef {object} Props
 * @property {array} products - list of products that are in the bundle
 * @property {string} selectedSpecProductBundleItemId - Id selected from the product bundle list
 */

/**
 * Properties of a product
 *
 * @typedef {object} Product
 * @property {string} itemId The identifier of the element
 * @property {string} media Contains the image to show on the product list
 * @property {string} identifiers Contains the title to show on the product list
 */

/**
 * Returns a formated Product List of the provided data showing title and a related-table
 * @param {Props} Props
 * @returns {React.ReactElement} JSX
 */

const BundleSpecifications = ({ product: bundleProduct, products, itemId }) => {
  const experienceContext = useContext(ExperienceContext);
  const { channel } = experienceContext;
  const isMobile = getIsMobile(channel);
  const { defaultVariables } = useContext(QueryContext) || {};
  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('bundle-specifications.ready');
  }, []);

  const { selectedItemId, changeSelectedItem } = useSetSelectedItemId({ products });

  const visibleProducts = filterRemovedProducts(products);

  const selectedProduct = visibleProducts?.find((item) => item.itemId === selectedItemId);

  /**
  * Handles the click on an item and updates the state of the selected item
   *
   * @param {Object} product - information of the selected item
   * @returns {void}
   */
  const handleItemClick = (product) => {
    changeSelectedItem(product?.itemId);
    tiggerAnalyticsCustomEvent('zone-b.click', getDdo(product, 'specifications-bip'));
  };

  if (!visibleProducts.length) {
    return null;
  }

  return (
    <div className="sui-max-w-full" data-component="BundleSpecifications" data-testid="bundle-specifications">
      <div className={`${isMobile ? 'sui-grid-flow-col' : 'sui-grid-cols-5'} sui-grid sui-grid-rows-1 sui-gap-4`}>
        {visibleProducts?.map((product, index) => (
          <Card
            key={product.itemId}
            className={`${selectedItemId === product.itemId ? 'sui-border-2 sui-border-strongest' : 'sui-border-1'
              } sui-max-w-xs`}
            onClick={() => handleItemClick(product)}
          >
            <CardMedia
              data-testid={`bundle-spec-button-${index}`}
              component="img"
              height={{ sm: '40px', md: '116px', lg: '253px' }}
              width={{ sm: '40px', md: '116px', lg: '253px' }}
              src={getProductImage(product?.media?.images)}
              alt={product?.title}
            />
          </Card>
        ))}
      </div>
      {selectedItemId ? (
        <div data-component="BodyBundleSpecifications">
          {selectedProduct && selectedProduct !== null ? (
            <div className="sui-grid sui-mt-4">
              <Typography variant="body-lg" weight="bold" data-testid="tile-product-title">
                {selectedProduct?.identifiers?.productLabel}
              </Typography>
            </div>
          ) : null}
          <div className="sui-mt-4" data-testid="specs-accordion-body">
            <QueryProvider cacheKey="bundle-specification-accordion-body" defaultVariables={defaultVariables?.current}>
              <SpecificationsAccordionBody itemId={selectedItemId} isWhiteSpecTitle />
            </QueryProvider>
          </div>
          <div className="sui-mt-6 sui-ml-2" id="specificationPipLink">
            <ProductDetailsButton
              canonicalUrl={selectedProduct?.identifiers?.canonicalUrl}
              product={bundleProduct}
              itemId={itemId}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

BundleSpecifications.propTypes = {
  products: arrayProp(shapeProp()),
  product: shapeProp({ object }),
  itemId: stringType.isRequired
};

BundleSpecifications.defaultProps = {
  products: null,
  product: null
};

BundleSpecifications.displayName = 'BundleSpecifications';

BundleSpecifications.dataModel = extend(
  {
    product: params({ itemId: string().isRequired() }).shape({
      bundleSpecificationDetails: shape({
        type: string(),
        experienceType: string()
      })
    })
  },
  {
    products: params({ itemIds: arrayOf(string().isRequired()).isRequired() }).arrayOf({
      itemId: string(),
      media: {
        images: arrayOf({
          sizes: arrayOf(string()),
          subType: string(),
          type: string(),
          url: string()
        })
      },
      identifiers: shape({
        canonicalUrl: string()
      })
    })
  },
  SpecificationsAccordionBody
);

export { BundleSpecifications };
