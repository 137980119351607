import React, { useEffect } from 'react';
import { string, func } from 'prop-types';
import { extend } from '@thd-nucleus/data-sources';
import { useStoreId } from '@thd-nucleus/experience-context';
import { ProductDetails } from '@thd-olt-component-react/product-details';

import { useBundleProducts } from '../hooks/useBundleProducts';
import { BundleMiniContent } from '../subcomponents/BundleMiniContent';
import { BundleMiniPlaceholder } from '../subcomponents/BundleMiniPlaceholder';
import { BundleDataModel, BundleProductsDataModel } from '../models/dataModel';

const BundleMini = ({ bundleId, onRender }) => {
  const storeId = useStoreId();
  const {
    bundleProduct, bundleProducts, brand, image, label, url, loading, hasBundleData, hasErrorMessage
  } = useBundleProducts({ bundleId, storeId });

  const renderBundleMini = hasBundleData && !hasErrorMessage;

  useEffect(() => {
    if (!loading) {
      onRender({ isElementRendered: renderBundleMini });
    }
  }, [renderBundleMini, loading]);

  if (loading) {
    return <BundleMiniPlaceholder />;
  }

  if (!renderBundleMini) {
    return null;
  }

  return (
    <BundleMiniContent
      product={bundleProduct}
      products={bundleProducts}
      brand={brand}
      image={image}
      label={label}
      url={url}
      bundleId={bundleId}
      removeBackgroundImage
    />
  );
};

BundleMini.propTypes = {
  bundleId: string.isRequired,
  onRender: func
};

BundleMini.defaultProps = {
  onRender: () => {}
};

BundleMini.displayName = 'BundleMini';

BundleMini.dataModel = extend(
  BundleDataModel,
  BundleProductsDataModel,
  ProductDetails
);

export { BundleMini };