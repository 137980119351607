import { call } from 'redux-saga/effects';
import getLocalOrNationalPricing from './_getLocalOrNationalPricing';

function* getThdProductDetailsAndPricing() {
  const updatedPricing = yield call(getLocalOrNationalPricing);

  return updatedPricing;
}

export default getThdProductDetailsAndPricing;
