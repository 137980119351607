import { alias, params, client } from '@thd-nucleus/data-sources';
import {
  arrayOf as arrayOfType,
  bool as boolType, number as numberType,
  shape as shapeType,
  string as stringType
} from '@thd-nucleus/data-sources/react/dataModel/prop-types';

export const dataModel = {
  clientOnlyProduct: alias('product').params({
    itemId: stringType().isRequired()
  }).shape({
    itemId: stringType(),
    dataSources: stringType(),
    identifiers: shapeType({
      productLabel: stringType(),
      parentId: stringType()
    }),
    info: shapeType({
      hasSubscription: boolType(),
      minimumOrderQuantity: numberType(),
      projectCalculatorEligible: boolType(),
      productDepartment: stringType(),
      classNumber: stringType(),
      subClassNumber: stringType(),
      isInStoreReturnMessageEligible: boolType(),
      quantityLimit: numberType()
    }),
    pricing: params({ storeId: stringType() }).shape({
      alternatePriceDisplay: boolType(),
      unitOfMeasure: stringType(),
      alternate: shapeType({
        bulk: {
          thresholdQuantity: numberType(),
          value: numberType({ float: true })
        },
        unit: {
          unitsPerCase: numberType({ float: true }),
          value: numberType({ float: true })
        }
      }),
      original: numberType({ float: true }),
      value: numberType({ float: true }),
      conditionalPromotions: arrayOfType(shapeType({
        experienceTag: stringType(),
        reward: shapeType({
          tiers: arrayOfType(shapeType({
            minPurchaseQuantity: numberType({ float: true }),
            minPurchaseAmount: numberType({ float: true }),
            rewardPercent: numberType({ float: true })
          }))
        })
      }))
    }),
    taxonomy: shapeType({
      breadCrumbs: arrayOfType(shapeType({
        label: stringType()
      }))
    }),
    availabilityType: shapeType({
      discontinued: boolType(),
      status: boolType(),
      buyable: boolType(),
      type: stringType()
    }),
    fulfillment: client(params({ storeId: stringType(), zipCode: stringType(), quantity: numberType() }).shape({
      fallbackMode: boolType(),
      fulfillmentOptions: arrayOfType(shapeType({
        type: stringType(),
        fulfillable: boolType(),
        services: arrayOfType(shapeType({
          locations: arrayOfType(shapeType({
            inventory: shapeType({
              quantity: numberType(),
              maxAllowedBopisQty: numberType(),
              minAllowedBopisQty: numberType()
            }),
            storeTimeZone: stringType(),
            isAnchor: boolType(),
            locationId: stringType(),
            storeName: stringType(),
            type: stringType()
          })),
          type: stringType(),
          optimalFulfillment: boolType(),
          hasSameDayCarDelivery: boolType(),
          shipFromFastestLocation: boolType()
        }))
      }))
    })),
    specificationGroup: arrayOfType(shapeType({
      specTitle: stringType(),
      specifications: arrayOfType(shapeType({
        specName: stringType(),
        specValue: stringType()
      }))
    }))
  })
};