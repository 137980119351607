import React, { useContext } from 'react';
import { useWatch, useFormState } from 'react-hook-form';
import { number } from 'prop-types';
import { Button } from '@one-thd/sui-atomic-components';
import { CalculatorContext } from './subcomponents/CalculatorContext';

export const CalculatorFooter = ({ selectedArea }) => {
  const {
    addElement,
    control,
    calculateTotal,
    lineItemName,
  } = useContext(CalculatorContext);

  const areas = useWatch({
    control,
    name: 'entries'
  });
  const filteredAreas = selectedArea !== null ? [areas[selectedArea]] : areas;
  const { isValid, isDirty, touchedFields } = useFormState({ control });

  return (
    <>
      <Button
        variant="text"
        onClick={() => addElement({ calcByArea: filteredAreas[0].calcByArea })}
        data-testid="add-area"
      >
        + Add {lineItemName}
      </Button>
      <Button
        variant="primary"
        type="submit"
        disabled={!(isValid || isDirty || Object.keys(touchedFields).length === 0)}
      >
        Calculate
      </Button>
    </>
  );
};

CalculatorFooter.displayName = 'CalculatorFooter';

CalculatorFooter.propTypes = {
  selectedArea: number
};

CalculatorFooter.defaultProps = {
  selectedArea: null
};

export default CalculatorFooter;