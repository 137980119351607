import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, Typography, Button } from '~/@one-thd/sui-atomic-components';
import { InfoFilled } from '~/@one-thd/sui-icons';

export const RightRailMessage = ({
  cardImage,
  title,
  description,
  viewDetails,
  applyNow,
  applyClick,
}) => {
  const applyNowClick = () => {
    applyClick();
  };

  return (
    <>
      <div>
        <div className="sui-pb-6">{title ? <Typography variant="h5">{title}</Typography> : null}</div>
        <div className="sui-flex sui-items-center sui-mb-2">
          <Typography variant="body-base">
            <span>{description}</span>
            <span className="sui-pl-1 sui-cursor-pointer sui-relative sui-top-1">
              <InfoFilled color="info" size="small" onClick={viewDetails} />
            </span>
          </Typography>
        </div>
        <div className="sui-flex sui-items-center">
          <img className="sui-mr-2" src={cardImage} alt="Credit Card Icon" width="70" height="44" />
          <Button href={applyNow} onClick={applyNowClick} variant="secondary" fullWidth>
            Apply Now
          </Button>
        </div>
      </div>
    </>
  );
};

RightRailMessage.propTypes = {
  cardImage: PropTypes.string,
  title: PropTypes.node,
  description: PropTypes.node,
  viewDetails: PropTypes.func,
  applyNow: PropTypes.string,
  applyClick: PropTypes.func,
};

RightRailMessage.defaultProps = {
  cardImage: '',
  title: '',
  /*  eslint-disable-next-line max-len */
  description:
    '<p>$XXX.XX per month* suggested payments with 6 months financing on this $XXX.XX qualifying purchase.<p>',
  applyNow: '',
  viewDetails: () => { },
  applyClick: () => { },
};
