import { useLocalStorage } from '@thd-olt-global/thd-storage-utils';
import { getCoverage, getQuantity } from '../helpers/helpers';
import { formatStorageKey } from '../helpers/localStorageUtil';

/* TODO: Look into the potential of lumping in a good bit of the localStorageUtil functions
        into this hook and cleaning up where those functions are exported from. */
export const useCalculatorLocalStorage = ({ uomObj, toggleOn = false, label, pipCalc }) => {
  const { sizeValue, caseUnitOfMeasure } = uomObj || {};
  const { publisher, display, additionalCoveragePercentage } = pipCalc || {};
  const [localStorageValue, _] = useLocalStorage(formatStorageKey(label));

  const { entries } = localStorageValue?.form || {};
  const areaLength = entries?.length || 0;
  const unitsPerCase = publisher || sizeValue;
  let coverage = 0;
  if (areaLength) {
    coverage = getCoverage({
      entries,
      calculatorType: 'Area',
    });
  }
  const uomCheck = (unitsPerCase && (caseUnitOfMeasure === 'sq. ft.' || display));
  let quantity;
  if (!toggleOn || !uomCheck) quantity = null;
  else {
    quantity = additionalCoveragePercentage
      ? getQuantity({
        coverage,
        sizeValue: unitsPerCase,
        info: { pipCalculator: pipCalc },
        addAdditionalCoverage: true,
        allowAdditionalCoverage: true,
      })
      : getQuantity({ coverage, sizeValue: unitsPerCase });
  }

  return { projectQuantity: quantity, coverage, calculatorData: localStorageValue };
};
