import React from 'react';
import {
  arrayOf, bool, number, shape, string, func
} from 'prop-types';
import className from 'classnames';
import {
  List, MenuItem, IconButton, Typography
} from '@one-thd/sui-atomic-components';
import { DownArrowLeft, Close } from '@one-thd/sui-icons';
import HTMLElementType from '../utils/HTMLElementType';
import { PrimaryResult } from './PrimaryResult';
import { CUSTOM_SUBMIT_EVENTS } from '../utils/typeahed-element-events';
import { useComboboxContext } from './ComboboxContext';

const Results = ({
  onListItemRemoval,
  searchInputElement,
  formattedResults,
  showDeleteListButton,
  onSubmit
}) => {

  const { comboboxMotionState } = useComboboxContext();

  const hasResultsToShow = formattedResults?.length > 0;

  const closeIconClasses = 'sui-flex sui-justify-center sui-self-center sui-w-3 sui-h-3';

  const listClasses = 'sui-list-none sui-m-0 sui-px-0 sui-relative';

  const filteredCategories = ({ categories }) => {
    return categories?.length
      ? categories.filter((result) => typeof result.cTitle === 'string')
      : [];
  };

  const ResultsDeleteIcon = () => {
    return (
      <div className={closeIconClasses}>
        <Close color="medium" size="small" />
      </div>
    );
  };

  const handleResultClick = (event, resultTitle, resultLink, source) => {
    event.preventDefault();
    if (typeof onSubmit === 'function') {
      onSubmit(event,
        {
          type: CUSTOM_SUBMIT_EVENTS.MENU_ITEM_CLICK,
          selectedSearchTerm: resultTitle,
          url: resultLink,
          results: formattedResults,
          source
        });
    }
  };

  const handleListItemRemoval = (event, searchTerm) => {
    event.stopPropagation();
    event.preventDefault();
    if (typeof onListItemRemoval !== 'function') return;
    onListItemRemoval(searchInputElement.value, searchTerm);
  };

  return (
    <List
      role="listbox"
      className={listClasses}
      tabIndex={-1}
      id="results-listbox"
      data-testid="results-listbox"
      key="results-list"
    >
      {hasResultsToShow && formattedResults?.map(({
        title, link, categories, icon: Icon, source, removable = false
      }, index) => {
        const filteredCategoryResults = filteredCategories({ categories });
        const showCategories = filteredCategoryResults?.length > 0;
        const selected = comboboxMotionState?.active?.index === index;

        //  Classes
        const deleteIconButtonRootClasses = className('sui-flex sui-items-center sui-gap-x-1', {
          'sui-invisible group-hover:sui-visible': !showDeleteListButton
        });

        return (
          <div className="sui-group" key={`ta-results-${title}-${index}`}>
            <MenuItem
              component="a"
              selected={selected}
              aria-selected={selected}
              role="option"
              href={link}
              key={title}
              onClick={(event) => handleResultClick(event, title, link, source)}
              data-testid="typeahead-results-item"
              id={`typeahead-results-item-${index}`}
            >
              <div
                className="sui-w-full sui-flex sui-justify-between sui-items-center sui-my-1"
              >
                <div className="sui-flex sui-items-center sui-gap-x-2 sui-w-full sui-min-w-0 sui-mt-1">
                  {Icon && <span className="sui-shrink-0 sui-flex"><Icon color="medium" size="small" /></span>}
                  <PrimaryResult resultTitle={title} resultLink={link} searchTerm={searchInputElement?.value} />
                </div>
                { removable
                  && (
                    <div className={deleteIconButtonRootClasses}>
                      <IconButton
                        key={title + ' delete-icon'}
                        icon={ResultsDeleteIcon}
                        aria-label="Search Results Item Delete Icon"
                        iconSize="small"
                        onClick={(event) => handleListItemRemoval(event, title)}
                      />
                    </div>
                  )}
              </div>
            </MenuItem>

            {showCategories && filteredCategoryResults.map(({ cLink, cTitle }) => {
              return (
                <MenuItem
                  component="a"
                  href={cLink}
                  key={cTitle}
                  data-testid="typeahead-category-result-item"
                  onClick={(event) => handleResultClick(event, cTitle, cLink, source)}
                  tabIndex={0}
                >
                  <div className="sui-ml-7 sui-flex sui-justify-center sui-items-center sui-h-5">
                    <Typography key={cTitle} decoration="underline" variant="body-base" color="primary">
                      {'in ' + cTitle}
                    </Typography>
                  </div>
                </MenuItem>
              );
            })}
          </div>
        );
      })}
    </List>
  );
};

Results.displayName = 'Results';

Results.propTypes = {
  searchInputElement: HTMLElementType,
  formattedResults: arrayOf(shape({
    link: string,
    title: string,
    categories: arrayOf(shape({
      cLink: string,
      cTitle: string,
    }))
  })),
  showDeleteListButton: bool,
  onSubmit: func,
  onListItemRemoval: func,
};

Results.defaultProps = {
  searchInputElement: undefined,
  formattedResults: null,
  showDeleteListButton: false,
  onSubmit: undefined,
  onListItemRemoval: undefined,
};

export { Results };
