/* eslint-disable max-len */
import React from 'react';

const HeaderB2BAccountIcon = () => {
  return (
    <>
      <svg fill="none" className="sui-h-4 lg:sui-h-6" width="38" height="24" viewBox="0 0 38 24" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M2.3494 22H35.6506C36.9367 22 38 21.0704 38 19.915V2.08496C38 0.928544 36.9372 0 35.6506 0H2.3494C1.06278 0 0 0.928544 0 2.08496V19.915C0 21.0704 1.0633 22 2.3494 22ZM36.8125 19.915C36.8125 20.4482 36.2957 20.9 35.6506 20.9H2.3494C1.70428 20.9 1.1875 20.4482 1.1875 19.915V2.08493C1.1875 1.55088 1.70359 1.09998 2.3494 1.09998H35.6506C36.2964 1.09998 36.8125 1.55088 36.8125 2.08493V19.915Z" className="sui-fill-current" />
        <path fillRule="evenodd" clipRule="evenodd" d="M5 4H9.02713C11.0764 4 12 5.33058 12 7.08135C12 9.12608 10.802 10.1904 8.62266 10.1904H7.6842V14H5V4ZM7.6842 8.31367H8.18965C8.86797 8.31367 9.22881 7.93556 9.22881 7.09546C9.22881 6.26894 8.86797 5.87671 8.18965 5.87671H7.6842V8.31367Z" className="sui-fill-current" />
        <path fillRule="evenodd" clipRule="evenodd" d="M13 6.20171H15.6691V7.44127H15.6995C16.0062 6.50454 16.7426 6 17.5553 6H18V8.42184C17.7548 8.37827 17.5404 8.36374 17.31 8.36374C16.3282 8.36374 15.761 8.72468 15.761 9.74773V14H13V6.20171Z" className="sui-fill-current" />
        <path fillRule="evenodd" clipRule="evenodd" d="M22.2499 14C19.2689 14 19 12.1863 19 9.99252C19 7.99652 19.3815 6 22.2499 6C25.2311 6 25.5 7.81369 25.5 10.0067C25.5 12.0035 25.1183 14 22.2499 14ZM22.2499 12.3689C22.8293 12.3689 22.9564 11.9193 22.9564 10.0067C22.9564 8.08073 22.8293 7.63058 22.2499 7.63058C21.6847 7.63058 21.5431 8.08073 21.5431 10.0067C21.5431 11.9193 21.6847 12.3689 22.2499 12.3689Z" className="sui-fill-current" />
        <path fillRule="evenodd" clipRule="evenodd" d="M33.3308 4H31.789L30.049 7.50238L28.3215 4H26.698L29.1943 8.91673L26.5 14H28.0433L29.9777 10.232L31.8728 14H33.5L30.8047 8.91673L33.3308 4Z" className="sui-fill-current" />
        <path fillRule="evenodd" clipRule="evenodd" d="M5 18H26V16H5V18Z" className="sui-fill-current" />
        <rect x="1" y="1" width="36" height="20" rx="1" strokeWidth="2" strokeLinejoin="bevel" />
      </svg>
    </>

  );
};

export { HeaderB2BAccountIcon };