/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from 'react';
import { arrayOf, shape, string, bool } from 'prop-types';
import { Price } from '@thd-olt-component-react/price';
import { extend, QueryContext } from '@thd-nucleus/data-sources';
import { CustomSkeletonLine } from './subcomponents/CustomSkeletonLine';
import { getProductsDefaultValue, updatePriceComponent, mapPriceExistsInBundle } from '../utils/product-bundle-utils';

export const BundlePrice = ({
  ...props
}) => {
  const { itemId, products, product } = props;
  const { priceUnitOfMeasure } = product?.features || {};
  const { defaultVariables } = useContext(QueryContext) || {};
  const { isBrandPricingPolicyCompliant: isCustomerIdentified } = defaultVariables?.current || {};
  const mapDetail = mapPriceExistsInBundle(products, isCustomerIdentified);
  const prices = getProductsDefaultValue(products);
  if (mapDetail?.mapPolicy) {
    updatePriceComponent(
      prices?.value || prices.original,
      0,
      0,
      mapDetail,
      prices?.original,
      itemId,
      priceUnitOfMeasure);
  }

  if (!products?.length) {
    return <CustomSkeletonLine height="75px" />;
  }

  return (
    <Price
      {...props}
      product={{
        pricing: {
          ...prices
        }
      }}
    />
  );
};

BundlePrice.propTypes = {
  itemId: string.isRequired,
  products: arrayOf(shape()).isRequired,
  product: shape({
    features: shape({ priceUnitOfMeasure: string.isRequired })
  }).isRequired
};

BundlePrice.displayName = 'BundlePrice';

BundlePrice.dataModel = extend(Price);
