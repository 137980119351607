import {
  string,
  arrayOf,
  shape,
  number,
  bool,
  extend,
  params,
  customType, typename,
} from '@thd-nucleus/data-sources';
import { CustomProductPod } from './CustomProductPod';

const Media = shape({
  image: shape({
    url: string()
  }).client(),
  images: arrayOf(shape({
    url: string(),
    type: string(),
    subType: string(),
    sizes: arrayOf(string())
  }))
});

const Badges = params({
  storeId: string()
}).shape({
  name: string(),
  message: string(),
  label: string(),
  creativeImageUrl: string(),
  timerDuration: string(),
  color: string(),
  endDate: string(),
});

const Pricing = params({
  storeId: string()
}).shape({
  value: number({ float: true }),
  original: number({ float: true }),
  unitOfMeasure: string(),
  promotion: shape({
    percentageOff: number({ float: true }),
    dollarOff: number({ float: true })
  })
});

const Reviews = shape({
  ratingsReviews: shape({
    averageRating: string(),
    totalReviews: string(),
  })
});

const Subscription = shape({
  defaultfrequency: string(),
  discountPercentage: string(),
  subscriptionEnabled: bool()
});

export const Product = CustomProductPod.dataModel.product;

export const dataModel = extend({
  endCap: params({
    pageUrl: string().isRequired(),
    storeId: string(),
    personalizedDealsUserid: string(),
    customerType: customType('CustomerType').enum(['B2C', 'B2B', 'B2X'], 'B2C'),
    categoryGuid: string(),
    isABTesting: bool(),
    skipInstallServices: bool()
  }).shape({
    // TODO removing this id for now which prevents the products from updating when clicking a previously-clicked tab
    // id: string(),
    eventsMetadata: shape({
      apiType: string(),
      title: string(),
      start: string(),
      end: string(),
      bannerImage: string(),
      mobileAppBannerImg: string(),
      headerLinkText: string(),
      headerLinkUrl: string(),
      timerDuration: number({ float: true }),
      topGradientValues: arrayOf(string()),
      badgeUrl: string(),
      categories: arrayOf(
        shape({
          category: string(),
          nValue: string(),
          imageUrl: string(),
          url: string(),
          shopAllLink: string(),
          exclude: arrayOf(string()),
          displayImageOnMobileWeb: bool(),
        })
      ),
      nameOfEventAttribute: string(),
      header: string(),
      iconImage: string(),
      subHeader: string(),
      timer: shape({
        timeBombThreshold: string(),
        daysLeftThreshold: string(),
        dateDisplayThreshold: string(),
        message: string(),
      }),
    }).skip('shouldSkipMetadata', false),
    topDealsEndCap: params({
      navParam: string(),
      recsAlgorithm: string(),
      maxProducts: number({ int: true }),
    }).shape({
      catImage: string(),
      catName: string(),
      catURL: string(),
      nvalue: string(),
      products: arrayOf(shape(Product)),

    }),
    personalizedDealsEndCap: shape({
      catImage: string(),
      catName: string(),
      catURL: string(),
      nvalue: string(),
      products: arrayOf(shape(Product)),
    }),
  }),
  CustomProductPod
});
