import React, { useContext } from 'react';
import { QuantityAdjustmentsContext } from '@thd-olt-component-react/bundling-product-tabs';
import { Col, Row, Image, Heading } from '@thd-olt-component-react/core-ui';
import { PackagesMiniContext } from '../PackagesMiniContext';
import { getPackageTitle, getPackageUrl, getImageUrl, containsOOSItems } from '../../utils';
import './transactional-package-info.scss';

const TransactionalPackageInfo = () => {
  const { packageModel } = useContext(PackagesMiniContext);
  const { itemMap, fulfillmentFetched } = useContext(QuantityAdjustmentsContext);
  const packageImageUrl = getImageUrl(packageModel);
  const packageUrl = getPackageUrl(packageModel);
  const packageTitle = getPackageTitle(packageModel);
  const isMajorAppliancePackage = packageModel.isMajorAppliancePackage;
  const hasOutOfStockItems = fulfillmentFetched && containsOOSItems(itemMap);
  const displayViewBundleDetails = !hasOutOfStockItems || isMajorAppliancePackage;

  const PackageHeading = () => (
    <Row>
      <Col className="package-info__heading-container">
        <Heading
          title={packageTitle}
          underline
          flatten
          noMargin
        />
        {displayViewBundleDetails && (
          <div className="package-info__heading-url">
            <a href={packageUrl}>View Bundle Details</a>
          </div>
        )}
      </Col>
    </Row>
  );

  const PackageImage = () => (
    <Col className="package-info__package-image-container" xs="5" sm="4">
      <Image
        className="package-info__package-image"
        src={packageImageUrl}
        alt="Package Bundle"
      />
    </Col>
  );

  return (
    <>
      <PackageHeading />
      <PackageImage />
    </>
  );
};

TransactionalPackageInfo.displayName = 'TransactionalPackageInfo';

export { TransactionalPackageInfo };
