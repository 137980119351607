/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

const aspectRatioClasses = {
  '1:1': 'sui-aspect-square',
  '3:2': 'sui-aspect-[3/2]'
};

const BundleImage = ({
  src, alt, aspectRatio
}) => {

  const aspectRatioClass = aspectRatioClasses[aspectRatio] || '';
  return (
    <img
      className={`sui-block sui-object-contain ${aspectRatioClass}`}
      src={src}
      alt={alt}
      width="100%"
      height="100%"
    />
  );
};

BundleImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  aspectRatio: PropTypes.string
};

BundleImage.defaultProps = {
  aspectRatio: '1:1'
};

export { BundleImage };