import React, { useState, useEffect, useRef, useContext } from 'react';
import { useConfigService, ExperienceContext } from '@thd-nucleus/experience-context';
import { GlobeIcon } from '../assets/icons/GlobeIcon';
import { ControllerPopover } from './ControllerPopover';
import { useLanguagePreference } from '../commons/hooks/useLanguagePreference';
import { TranslationLogic } from './TranslationLogic';
import { ENGLISH_LANG_CODE } from '../commons/constants';

const TranslatorWidget = () => {
  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('translator_widget.ready');
  }, []);

  const [language, setLanguage] = useLanguagePreference();
  const [popoverAnchorElement, setPopoverAnchorElement] = useState(null);
  const globeIconRef = useRef(null);

  const openPopover = () => {
    setPopoverAnchorElement(globeIconRef.current);
  };

  const closePopover = () => {
    setPopoverAnchorElement(null);
  };

  const handleChange = (event) => {
    setLanguage(event.target.value);

    if (event.target.value === ENGLISH_LANG_CODE) {
      /*
        Explanation: This code is to manipulate the Google Widget directly,
        triggering the "revert Translation" button when we select english
      */
      const translateWidgetIframe = document.getElementById(':1.container');
      const showOriginalButton = translateWidgetIframe.contentWindow.document.getElementById(':1.restore');
      showOriginalButton.click();
    } else {
      const translateElement = document.querySelector('.goog-te-combo');
      translateElement.value = event.target.value;
      translateElement.dispatchEvent(new Event('change', { bubbles: true }));
    }
  };

  return (
    <div translate="no">
      <TranslationLogic />

      <button
        type="button"
        className="sui-flex sui-justify-center sui-items-center sui-gap-1 sui-underline"
        onClick={openPopover}
        ref={globeIconRef}
      >
        <GlobeIcon />
        {language === ENGLISH_LANG_CODE ? 'English' : 'Español'}
      </button>

      <ControllerPopover
        anchor={popoverAnchorElement}
        close={closePopover}
        preferredLanguage={language}
        handleChange={handleChange}
      />
    </div>
  );
};

TranslatorWidget.propTypes = {};
TranslatorWidget.displayName = 'TranslatorWidget';
TranslatorWidget.dataModel = {};

const ConnectedTranslatorWidget = () => {
  const { isConsumerApp } = useContext(ExperienceContext);
  const isTranslatorWidgetEnabled = useConfigService('fs:isTranslatorWidgetEnabled') && !isConsumerApp;

  if (!isTranslatorWidgetEnabled) {
    return null;
  }

  return <TranslatorWidget />;
};

ConnectedTranslatorWidget.displayName = 'ConnectedTranslatorWidget';

export { ConnectedTranslatorWidget };
