import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, Typography, } from '~/@one-thd/sui-atomic-components';
import { Info } from '~/@one-thd/sui-icons';

export const Banner = ({
  cardImage, title, description, viewDetails, applyNow, applyClick, isMobile
}) => {

  const applyNowClick = () => {
    applyClick();
    return isMobile ? window.open(applyNow, 'self') : window.open(applyNow, '_blank');
  };

  return (
    <>
      <div className="sui-flex sui-items-center sui-border-solid sui-border-1
          sui-border-primary sui-p-4"
      >
        <div className="sui-ml-4 sui-mr-8 sui-w-20">
          <img
            src={cardImage}
            alt="Credit Card Icon"
            width="72"
            height="44"
          />
        </div>
        <div>
          <div className="sui-pt-1">
            <Typography variant="h5">
              {title}
            </Typography>
          </div>
          <div className="sui-pt-1 sui-flex sui-items-center">
            <Typography variant="body-base">
              <span>{description}</span>
              <span className="sui-pl-1 sui-cursor-pointer sui-relative sui-top-1">
                <Info color="info" size="small" onClick={viewDetails} />
              </span>
            </Typography>
          </div>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link onClick={applyNowClick} color="brand">Apply Now</Link>
        </div>
      </div>
    </>
  );
};

Banner.propTypes = {
  cardImage: PropTypes.string,
  title: PropTypes.node,
  description: PropTypes.node,
  viewDetails: PropTypes.func,
  applyNow: PropTypes.string,
  applyClick: PropTypes.func,
  isMobile: PropTypes.bool
};

Banner.defaultProps = {
  cardImage: '',
  title: '<p>Pay $XXX.XX per Month When You Use Your Card<p>',
  /*  eslint-disable-next-line max-len */
  description: '<p>$XXX.XX per month* suggested payments with 6 months financing on this $XXX.XX qualifying purchase.<p>',
  applyNow: '',
  viewDetails: () => { },
  applyClick: () => { },
  isMobile: false
};
