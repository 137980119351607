const aggregateRatingObject = (reviews) => {
  const ratingValue = reviews?.Includes?.Products?.store?.FilteredReviewStatistics?.AverageOverallRating;
  const reviewCount = reviews?.TotalResults;
  const baseObject = {
    '@type': 'AggregateRating',
  };

  if (!ratingValue || !reviewCount) return {};
  if (ratingValue) baseObject.ratingValue = ratingValue;
  if (reviewCount) baseObject.reviewCount = reviewCount;

  return {
    aggregateRating: baseObject
  };
};

const reviewsObject = (reviews) => {
  const reviewsResults = reviews?.Results || [];

  if (!reviewsResults.length) return {};

  const object = reviewsResults.map((review) => ({
    '@type': 'Review',
    reviewRating: {
      '@type': 'Rating',
      ratingValue: review?.Rating || 0,
      bestRating: '5',
    },
    author: {
      '@type': 'Person',
      name: review.UserNickname || '',
    },
    headline: review.Title || '',
    reviewBody: review.ReviewText || '',
  }));

  return { reviews: object };
};

const getProductGroupSchema = ({ product, reviews, groupName }) => {
  return {
    '@context': 'http://schema.org',
    '@type': 'ProductGroup',
    name: groupName,
    description: product?.details?.description || '',
    brand: {
      '@type': 'Brand',
      name: product?.identifiers?.brandName || ''
    },
    ...aggregateRatingObject(reviews),
    productGroupID: product?.identifiers?.parentId || '',
    ...reviewsObject(reviews),
  };
};

export { getProductGroupSchema };