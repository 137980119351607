import React, { useContext } from "react";
import { shape, arrayOf, bool } from "prop-types";
import { ConsumerCardMessaging } from "@thd-olt-component-react/consumer-card-messaging";
import { QuantityAdjustmentsContext } from "@thd-olt-component-react/bundling-product-tabs";
import { calculateTotal } from "../../utils";

export const FinancingOffer = ({ isAppliance, products }) => {
  const { itemMap } = useContext(QuantityAdjustmentsContext);
  const introOfferPrice = "100.00";
  const { totalValue, showMapMessage } = calculateTotal(products, itemMap);
  const calculatedTotal = `${totalValue?.dollars}.${totalValue?.cents}`;

  return (
    <ConsumerCardMessaging
      bundlePrice={showMapMessage ? introOfferPrice : calculatedTotal}
      paymentEstimatorVersion="v1.0.44"
      isAppliance={isAppliance}
    />
  );
};

FinancingOffer.displayName = "FinancingOffer";

FinancingOffer.propTypes = {
  isAppliance: bool,
  products: arrayOf(shape({})),
};

FinancingOffer.defaultProps = {
  isAppliance: true,
  products: [],
};
