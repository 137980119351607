/* eslint-disable tailwindcss/no-arbitrary-value */
import React from 'react';
import { bool } from 'prop-types';
import { Skeleton, SkeletonLine, SkeletonBlock } from '@one-thd/sui-atomic-components';

const BuyboxLoader = ({ lite }) => {
  if (lite) {
    return (
      <div
        data-component="BuyboxLoaderLite"
      >
        <div>
          <div>
            <Skeleton
              data-component="BuyboxLoaderLiteSkeleton"
              component="span"
              grow
            >
              <SkeletonLine variant="multi" numberOfLines={2} />
            </Skeleton>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="sui-grid sui-ml-[-10px] sui-mr-[-10px] sui-w-auto">
      <div
        className="sui-bg-subtle sui-pb-32"
        data-testid="buybox-placeholder-test-id"
        data-component="BuyboxLoaderTitle"
      >

        <div className="sui-grid sui-grid-cols-3 sui-gap-4">
          <div className="sui-max-w-[278px]">
            <Skeleton
              data-component="FulfillmentPlaceholder"
              component="span"
              grow
            >
              <SkeletonLine variant="multi" numberOfLines={3} />
            </Skeleton>
          </div>
          <div className="sui-max-w-[278px]">
            <Skeleton
              data-component="FulfillmentPlaceholder"
              component="span"
              grow
            >
              <SkeletonLine variant="multi" numberOfLines={3} />
            </Skeleton>
          </div>
        </div>
      </div>
      <div
        className="sui-bg-subtle"
        data-testid="buybox-qty-atc-placeholder-test-id"
        data-component="BuyboxLoader"
      >

        <div className="sui-grid sui-grid-rows-3 sui-grid-flow-col sui-gap-4">
          <div className="sui-max-w-[278px] sui-row-span-3">
            <Skeleton
              data-component="QuantityLoader"
              component="span"
              grow
            >
              <SkeletonLine variant="single" numberOfLines={3} />
            </Skeleton>
          </div>
          <div className="sui-row-span-3 sui-row-span-2 sui-col-span-2">
            <Skeleton
              data-component="ATCLoader"
              component="span"
              grow
            >
              <SkeletonLine variant="single" numberOfLines={3} />
            </Skeleton>
          </div>
        </div>
        <div className="sui-bg-subtle sui-p-2 sui--ml-1" data-component="buybox-divider" />
        <div>
          <div>
            <Skeleton
              data-component="PayPalLoader"
              component="span"
              grow
            >
              <SkeletonLine variant="multi" numberOfLines={2} />
            </Skeleton>
          </div>
        </div>
      </div>
      <div className="sui-bg-subtle sui-p-2 sui--ml-1" data-component="buybox-divider" />
    </div>
  );
};

BuyboxLoader.propTypes = {
  lite: bool
};

BuyboxLoader.defaultProps = {
  lite: false
};

export { BuyboxLoader };