/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { FrequentlyBoughtTogether } from '@thd-olt-component-react/frequently-bought-together';
import {
  PackagesMiniVisual, PackagesMini,
  PackagesMiniLite,
  PackagesMiniTransactional
} from '@thd-olt-component-react/packages-mini';
import { ThdRecsFbt } from '@thd-olt-component-react/thd-recs-fbt';
import { string } from 'prop-types';
import { Row } from '@thd-olt-component-react/core-ui';
import { extend } from '@thd-nucleus/data-sources';
import {
  RENDER_FBT, RENDER_THD_RECS, RENDER_TRANSACTIONAL, RENDER_VISUAL, RENDER_CO_WORK
} from '../constants';
import ZoneCard from './zone-card';

const PackagesMiniAbTest = ({ itemId, renderComponent, ...props }) => {
  switch (renderComponent) {
  case RENDER_THD_RECS:
    return (
      <Row>
        <ThdRecsFbt
          apiName="fbt_test"
          itemId={itemId}
          errorBoundary
          hydrator={{
            className: 'grid',
            tag: ZoneCard,
            id: 'thd-recs-fbt'
          }}
        />
      </Row>
    );
  case RENDER_VISUAL:
    return (
      <PackagesMiniVisual
        itemId={itemId}
        dynamic={{
          pageType: 'pip'
        }}
        hydrator={{
          className: 'grid',
          tag: ZoneCard
        }}
        {...props}
      />
    );

  case RENDER_FBT:
    return (
      <FrequentlyBoughtTogether
        itemId={itemId}
        layoutGroup={{
          tag: Row
        }}
        dynamic={{
          pageType: 'pip'
        }}
        hydrator={{
          className: 'grid',
          tag: ZoneCard
        }}
        {...props}
      />
    );

  case RENDER_TRANSACTIONAL:
    return (
      <PackagesMiniTransactional
        itemId={itemId}
        dynamic={{
          pageType: 'pip'
        }}
        hydrator={{
          className: 'grid',
          tag: ZoneCard
        }}
        {...props}
      />
    );

  case RENDER_CO_WORK:
    return (
      <PackagesMiniLite
        itemId={itemId}
        dynamic={{
          pageType: 'pip'
        }}
        hydrator={{
          className: 'grid',
          tag: ZoneCard
        }}
        {...props}
      />
    );

  default:
    return (
      <PackagesMini
        itemId={itemId}
        dynamic={{
          pageType: 'pip'
        }}
        hydrator={{
          className: 'grid',
          tag: ZoneCard
        }}
        {...props}
      />
    );
  }
};

PackagesMiniAbTest.dataModel = extend(
  FrequentlyBoughtTogether,
  PackagesMiniVisual,
  ThdRecsFbt,
  PackagesMini,
  PackagesMiniLite,
  PackagesMiniTransactional
);
PackagesMiniAbTest.propTypes = {
  itemId: string,
  renderComponent: string
};

PackagesMiniAbTest.defaultProps = {
  itemId: '',
  renderComponent: ''
};

PackagesMiniAbTest.displayName = 'PackagesMiniAbTest';

export { PackagesMiniAbTest };