import {
  params, shape, string, arrayOf, bool, extend
} from '@thd-nucleus/data-sources';
import { Price, PriceClearanceDataModel } from '@thd-olt-component-react/price';

const Media = shape({
  images: arrayOf(shape({
    url: string(),
    subType: string()
  }))
});

const Identifiers = shape({
  productLabel: string(),
  brandName: string(),
  productType: string()
});

const AvailabilityType = shape({
  discontinued: bool()
});

const Fulfillment = params({ storeId: string() }).shape({
  fulfillmentOptions: arrayOf(shape({
    type: string(),
    fulfillable: bool(),
    services: arrayOf(shape({
      type: string(),
      locations: arrayOf(shape({
        inventory: shape({
          isInStock: bool(),
          isLimitedQuantity: bool()
        })
      }))
    }))
  }))
});

const Product = extend({
  itemId: string(),
  dataSources: string(),
  media: Media,
  identifiers: Identifiers,
  availabilityType: AvailabilityType,
  fulfillment: Fulfillment
}, Price.dataModel.product);

export const dataModel = extend({
  products: params({
    itemIds: arrayOf(string().isRequired()).isRequired()
  }).arrayOf(
    Product
  )
}, Price, PriceClearanceDataModel);
