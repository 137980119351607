import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Alert, ConfigProvider, Drawer, FloatButton } from 'antd';
import antTheme from '@thd-eux/theme-ant/dist/v5/theme/theme';
import { ExclamationCircleOutlined, IssuesCloseOutlined } from '@ant-design/icons';
import { errorGroupsSelector } from '../../selectors';
import ErrorMessages from './components/ErrorMessages';
import toggleLayout from './utils/toggleLayout';
import { rootStyles } from './styles/styles';
import './styles/styles.scss';

const PreviewErrorsDrawer = (props) => {
  const { errorGroups } = props;
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (errorGroups.length > 0) {
      setVisible(true);
      toggleLayout(true);
    }
  }, [errorGroups]);

  const showDrawer = () => {
    toggleLayout(true);
    setVisible(true);
  };

  const closeDrawer = () => {
    toggleLayout(false);
    setVisible(false);
  };

  return (
    <ConfigProvider theme={antTheme}>
      <FloatButton
        className="product-configurator-preview-fab"
        shape="circle"
        tooltip={<div>Preview Alerts</div>}
        badge={{ count: errorGroups.length }}
        onClick={showDrawer}
        icon={<IssuesCloseOutlined />}
        type="primary"
      />
      <div>
        <Drawer
          title="Preview Alerts"
          placement="right"
          onClose={closeDrawer}
          open={visible}
          mask={false}
          getContainer="#root"
          rootStyle={rootStyles}
        >
          {
            errorGroups.length
              ? errorGroups.map((error) => (
                <div className="sui-pb-2">
                  <Alert
                    message={<span className="boldMessage">{`${error.type} Error`}</span>}
                    description={<ErrorMessages errorMessages={error.errorMessages} />}
                    type="error"
                    icon={<ExclamationCircleOutlined />}
                    showIcon
                  />
                </div>
              ))
              : (<p>No alerts or errors to show here.</p>)
          }
        </Drawer>
      </div>
    </ConfigProvider>
  );
};

const mapStatetoProps = (state) => {
  const errorGroups = errorGroupsSelector(state);
  return {
    errorGroups
  };
};

PreviewErrorsDrawer.propTypes = {
  errorGroups: PropTypes.array.isRequired,
};

export default connect(mapStatetoProps)(PreviewErrorsDrawer);
