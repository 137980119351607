/* eslint-disable no-plusplus */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useEffect } from 'react';
import {
  func,
  bool as boolType,
  string as stringType,
  number as numberType,
  shape
} from 'prop-types';
import { useDataModel } from '@thd-nucleus/data-sources';
import { useStore, ExperienceContext, useConfigService } from '@thd-nucleus/experience-context';
import className from 'classnames';
import { ImpressionProvider } from '@thd-olt-component-react/impression';
import { Online } from '@one-thd/sui-icons';
import { useThdCustomer } from '@thd-olt-functional/customer-information';
import {
  shouldLoadFromStorage,
} from '../utils/recently-viewed';
import { isLoading, getAppId } from '../utils/helpers';
import { ProductPodV7Carousel } from '../core/ProductPodV7Carousel';
import { rvDataModel } from '../dataModel';
import { FallbackCard } from '../core/FallbackCard';

const RecentlyViewed = (props) => {
  const {
    brandTitleMaxLine,
    hideRating,
    hideTitle,
    schemaName,
    showLoading,
    hideATC,
    slidesToShow,
    showFallback,
    entryId,
    hideCarouselArrows,
    hideComponentCallback,
    hidePrice,
    name,
  } = props;

  const { storeId, storeZip: zipCode, isLocalized } = useStore();
  const { channel, isConsumerApp } = useContext(ExperienceContext);

  const THDCustomer = useThdCustomer() || {};
  const userId = THDCustomer.svocID || THDCustomer.userID || THDCustomer.guestUserID;
  const pAPIKey = '2zSR74pj5Rq6zDtHENHkAScT4RWsl0pg'; // TODO - THIS IS TEMP - This will change to 4 diff keys
  const isRVPriceEnabled = useConfigService('fs:isRVPriceEnabled');
  const chkRVPrice = !!((isRVPriceEnabled && !hidePrice));

  const opts = {
    variables: {
      storeId,
      key: pAPIKey,
      dataSource: schemaName,
      userId,
      unique: true,
      zipCode,
      apiName: 'views',
      appId: getAppId(channel, isConsumerApp),
      skipInstallServices: true,
      showPrice: true
    },
    persist: {
      storage: 'sessionStorage',
      expirationInSeconds: 5 * 60
    },
    skip: !isLocalized || !userId,
    ssr: false,
    // Force new request if we should not load from storage/cache
    fetchPolicy: isLocalized && !shouldLoadFromStorage() ? 'network-only' : 'cache-first'
  };

  const { loading: recsLoading, data: recsData, error: recsError } = useDataModel('uds', opts);

  useEffect(() => {
    if (typeof LIFE_CYCLE_EVENT_BUS !== 'undefined' && !recsLoading && recsData && !recsError) {
      LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('RecentlyViewed.ready', { data: recsData?.uds });
    }
  }, [recsLoading]);

  const wrapperClasses = className(
    'sui-w-full', 'sui-pt-0', 'sui-pb-0',
    'sui-p-4',
    schemaName,
    // {
    //   loading: showLoading && recsLoading,
    // }

  );

  const fallbackInfo = {
    header: 'No Items Recently Viewed',
    subheader: 'Get Back to Shopping-check out our weekly ad for the latest sales',
    footerUrl: 'https://www.homedepot.com/c/localad',
    footer: 'Browse Weekly Ad'
  };

  if (recsError || (recsData !== undefined && !recsData?.uds?.products?.length && !recsLoading)) {
    if (hideComponentCallback) {
      hideComponentCallback();
    }
    if (showFallback) {
      return (
        <FallbackCard
          header="No Items Recently Viewed"
          subheader="Get back to shopping—check out our weekly ad for the latest sales"
          footerUrl="https://www.homedepot.com/c/localad"
          footer="Browse Weekly Ad"
          icon={Online}
          componentName="RecentlyViewed"
        />
      );
    }
    return null;
  }

  if (!isLocalized || !userId) {
    return null;
  }

  return (
    <ImpressionProvider
      data={{
        id: entryId || '',
        component: RecentlyViewed.displayName,
        name: RecentlyViewed.displayName,
        type: 'product',
        recType: 'views',
        position: '',
        category: '',
        categoryPostion: '',
        ...(name ? { sectionSlotID: name } : {}),
      }}
    >
      <div id={schemaName} className={wrapperClasses} data-type="container" data-component="RecentlyViewed">
        <meta data-prop="name" content={schemaName} />
        <ProductPodV7Carousel
          brandTitleMaxLine={brandTitleMaxLine}
          storeId={storeId}
          zipCode={zipCode}
          scheme={schemaName}
          showLoading={showLoading}
          parent="recently-viewed"
          hideRating={hideRating}
          hideFavorites
          hideATC={!chkRVPrice}
          hidePrice={!chkRVPrice}
          hideTitle={hideTitle}
          data={recsData?.uds}
          loading={isLoading(recsData, recsLoading)}
          slidesToShow={slidesToShow}
          showFallback={showFallback}
          fallbackInfo={fallbackInfo}
          userId={userId}
          hideCarouselArrows={hideCarouselArrows}
        />
      </div>
    </ImpressionProvider>
  );
};

RecentlyViewed.dataModel = rvDataModel;

RecentlyViewed.displayName = 'RecentlyViewed';

RecentlyViewed.propTypes = {
  brandTitleMaxLine: numberType,
  hideRating: boolType,
  showLoading: boolType,
  schemaName: stringType.isRequired,
  hideATC: boolType,
  hideTitle: boolType,
  slidesToShow: shape({
    sm: numberType,
    md: numberType,
    lg: numberType,
  }),
  showFallback: boolType,
  entryId: stringType,
  hideCarouselArrows: boolType,
  hideComponentCallback: func,
  hidePrice: boolType,
  name: stringType
};

RecentlyViewed.defaultProps = {
  brandTitleMaxLine: 4,
  hideRating: false,
  showLoading: true,
  hideATC: false,
  hideTitle: false,
  slidesToShow: null,
  showFallback: false,
  entryId: null,
  hideCarouselArrows: false,
  hideComponentCallback: () => { },
  hidePrice: false,
  name: ''
};

export default RecentlyViewed;