/* eslint-disable tailwindcss/no-custom-classname */
/* eslint-disable tailwindcss/no-arbitrary-value */
/* eslint-disable max-len */
import React, {
  useContext, useCallback, useRef, useEffect, useState
} from 'react';
import PropTypes, {
  bool, func, shape, string
} from 'prop-types';
import { extend } from '@thd-nucleus/data-sources';
import { ExperienceContext, useStore, useConfigService } from '@thd-nucleus/experience-context';
import { AddToQuote } from '@thd-olt-component-react/add-to-quote';
import { AddToList } from '@thd-olt-component-react/add-to-list';
import { PIPCalculator, useCalculatorLocalStorage } from '@thd-olt-component-react/calculator';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { InstantCheckout } from '@thd-olt-component-react/instant-checkout';
import { Fulfillment, isBackorderedSelection } from '@thd-olt-component-react/fulfillment';
import { ProductAddOns, VirtualAssistance } from '@thd-olt-component-react/product-add-ons';
import { SubscribeAndSave } from '@thd-olt-component-react/subscribe-and-save';
import { useDomPath, useLifeCycleEventBus } from '@thd-olt-functional/utils';
import { useThdCustomer } from '@thd-olt-functional/customer-information';
import classNames from 'classnames';
import {
  getDeliveryZip,
  isFFMAvailable,
  addNewRelicCustomTag,
  fencingItemsGroup,
  setVirtualAssistanceOnly,
  checkIfUserIsInStore,
  getAvailableQuantities
} from '../util';
import { BuyboxError } from './subcomponents/BuyboxError';
import { BuyboxUnavailable } from './subcomponents/BuyboxUnavailable';
import { BuyboxLite } from './subcomponents/BuyboxLite';
import { BuyboxActions } from './subcomponents/BuyboxActions';
import { BuyboxLoader } from './subcomponents/BuyboxLoader';
import { BuyboxMessage } from './subcomponents/BuyboxMessage';
import { useBuyBox, useBuyboxGetData } from './hooks/useBuyBox';
import * as helper from '../BuyboxHelper';
import { track } from '../track';
import { dataModel } from './dataModel';
import { FULFILLMENT_METHOD_STH, CUSTOM_PRICE_VSO, FULFILLMENT_METHOD_BOPIS } from '../constants';
import useCustomAddToCartFromFulfillmentStoreSelectorDrawer from './hooks/useCustomAddToCartFromFulfillmentStoreSelectorDrawer';
import useConfiguratorEvents from './hooks/useConfiguratorEvents';

/**
 * Buybox container
 */
const Buybox = (props) => {
  const {
    channel,
    isServer
  } = useContext(ExperienceContext);

  const buyboxTimerRef = useRef(new Date());

  // use this ref to prevent showing loading placeholder after initial render
  const initialLoadingRef = useRef(true);

  const {
    stash,
    disableATC: buyboxDisableATC,
    disableSTH,
    disableBODFS,
    disableBOSS,
    disablePickupNearBy,
    onClickPassThrough,
    lite,
    sticky,
    hideInstantCheckout,
    hidePaypalCheckout,
    hideProReferral,
    noTopPadding,
    showCards,
    directData,
    showFavoriteAndQuote,
    noGrid,
    renderCalculator,
    paintConfigErrorMessage,
    originalConfigId
  } = props;

  let {
    itemId
  } = props;

  // convert these to use useConfigService() also
  const covidAlert = useConfigService('fs-prop:buy-area__buybox--covidAlert');
  const bopisMinimumThreshold = useConfigService('fs-prop:buy-area__buybox--bopisMinimumThreshold');
  const bopisMinimumThresholdStores = useConfigService('fs-prop:buy-area__buybox--bopisMinimumThresholdStores');
  const bodfsMinimumThreshold = useConfigService('fs-prop:buy-area__buybox--bodfsMinimumThreshold');
  const bopisHolidayTiming = useConfigService('fs-prop:buy-area__buybox--bopisHolidayTiming');
  const isTwoTileEnabledGlobal = useConfigService('isTwoTileEnabled');
  const isTwoTileQuantityUpdate = useConfigService('isTwoTileQuantityUpdate');
  const isRisMessageEnabled = useConfigService('fs:isRisMessageEnabled');
  const isWrenCustomerEnabled = useConfigService('fs:isWrenCustomerEnabled');
  const { isWrenCustomer } = useThdCustomer() || {};
  const isWren = isWrenCustomer && isWrenCustomerEnabled;

  const isTwoTileEnabledFS = isTwoTileEnabledGlobal;

  const getItemIdFromConfigurator = (items) => {
    if (items) {
      return items.find((item) => item.isAnchor)?.itemId;
    }
    return itemId;
  };

  const { storeId, storeZip, storeName } = useStore();
  const [buyboxState, setBuyboxState] = useBuyBox({ deliveryZipCode: getDeliveryZip() || storeZip });
  const [updatedQuantity, setQuantityToQuery] = useState(buyboxState.quantity || 1);
  const [subscriptionFrequency, setSubscriptionFrequency] = useState(null);
  const [isVirtualAssistanceOnly, setIsVirtualAssistanceOnly] = useState(false);
  // Configurator Events + Cartable Items/Data
  const {
    configuratorUnavailable,
    isPreview,
    isDecking,
    customLoading,
    isSpecialOrder,
    configuratorHideQuantity,
    initialQuantity,
    leadTime,
    disableTimer
  } = useConfiguratorEvents();
  const { customATC } = useLifeCycleEventBus('configurator.isSpecialOrderATC');
  const { isConfiguratorEditMode, removeLineItem } = useLifeCycleEventBus('configurator.configurator_edit');
  const { items } = useLifeCycleEventBus('configurator.configurator_available_items');
  const { configuratorData } = useLifeCycleEventBus('configurator.configurator_data');

  useCustomAddToCartFromFulfillmentStoreSelectorDrawer(customATC);

  const selectedFulfillment = useLifeCycleEventBus('fulfillment.selectedFulfillment');
  let currentSelectedFulfillment = '';
  if (typeof selectedFulfillment === typeof '') {
    currentSelectedFulfillment = selectedFulfillment;
  }

  if (items) {
    itemId = getItemIdFromConfigurator(items) || itemId;
  }

  const atcStash = helper.useATCLocationDDOStash({ stash, itemId });

  useEffect(() => {
    // call to set quantity with a delay
    const timer = setTimeout(() => {
      const isQuantityUpdateActivate = !buyboxState.fallbackMode && isTwoTileEnabledFS && isTwoTileQuantityUpdate;
      // Update the quantity after a wait so as to avoid unnecessary re-renders.
      if (isQuantityUpdateActivate && !buyboxState.quantityExceeded) {
        setQuantityToQuery(buyboxState.quantity);
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [buyboxState.quantity, buyboxState.quantityExceeded, buyboxState.fallbackMode]);

  const { data, loading, error } = useBuyboxGetData({
    itemId,
    storeId,
    directData: configuratorData?.product || directData,
    quantity: isTwoTileEnabledFS && isTwoTileQuantityUpdate ? updatedQuantity : 1,
    zipCode: buyboxState.zipCode
  });

  const isConfigurableBlinds = helper.getIsConfigurableBlinds({ data });

  const {
    fulfillment = {},
    info = {},
    availabilityType = {},
    pricing = {},
    specificationGroup = [], // not being used should i remove ???
    identifiers = {},
    taxonomy = {}
  } = configuratorData?.product || data?.product || {};

  const { toggleOn } = useLifeCycleEventBus('flooringPicker.flooringPicker_toggle', true);

  const { projectQuantity } = useCalculatorLocalStorage({
    label: taxonomy?.breadCrumbs?.[0]?.label,
    pipCalc: info?.pipCalculator,
    toggleOn
  });

  useEffect(() => {
    if (data?.product && !isConfigurableBlinds) {
      if (typeof LIFE_CYCLE_EVENT_BUS !== 'undefined') {
        const start = buyboxTimerRef.current.getTime();
        const end = new Date().getTime();
        LIFE_CYCLE_EVENT_BUS.trigger('buybox.loaded', { start, end });
      }
    }
  }, [data?.product]);

  useEffect(() => {
    if (configuratorData && isConfigurableBlinds) {
      if (typeof LIFE_CYCLE_EVENT_BUS !== 'undefined') {
        const start = buyboxTimerRef.current.getTime();
        const end = new Date().getTime();
        LIFE_CYCLE_EVENT_BUS.trigger('buybox.loaded', { start, end });
        const virtualAssistanceOnly = setVirtualAssistanceOnly(configuratorData, CUSTOM_PRICE_VSO);
        setIsVirtualAssistanceOnly(virtualAssistanceOnly);
      }
    }
  }, [configuratorData]);

  const { fallbackMode = false } = fulfillment || {};
  const parentId = identifiers?.parentId || '';
  const { hasSubscription, minimumOrderQuantity, quantityLimit = 0 } = info || {};

  useEffect(() => {
    if (fulfillment?.fulfillmentOptions) {
      const isBackorderedApplied = isBackorderedSelection(fulfillment?.backordered, buyboxState.fulfillmentMethod);
      const maximumInventoryQuantity = isBackorderedApplied ? 9999
        : helper.getFulfillmentMaxQuantity({ fulfillment, isTwoTileEnabledFS, qty: buyboxState.quantity });
      setBuyboxState((prevState) => {
        return {
          ...prevState,
          quantityExceeded: buyboxState.quantity > maximumInventoryQuantity,
          fallbackMode: fulfillment?.fallbackMode || false
        };
      });
    }
  }, [fulfillment]);

  useEffect(() => {
    if (fulfillment?.fulfillmentOptions && buyboxState.fulfillmentMethod === FULFILLMENT_METHOD_BOPIS) {
      const serviceLocations = (
        fulfillment.fulfillmentOptions.find((option) => option.type === 'pickup')?.services || [])
        .reduce((accum, current) => {
          if (current?.locations) {
            accum.push(...(current?.locations || []));
          }

          return accum;
        }, []);

      const { inventory = {} } = serviceLocations.find((location) => location?.locationId === storeId) || {};
      if (inventory?.maxAllowedBopisQty || inventory?.minAllowedBopisQty) {
        setBuyboxState((prevState) => {
          return {
            ...prevState,
            minimumBopisQuantity: inventory.minAllowedBopisQty,
            maximumBopisQuantity: inventory.maxAllowedBopisQty
          };
        });
      }
    } else if (buyboxState.minimumBopisQuantity || buyboxState.maximumBopisQuantity) {
      setBuyboxState((prevState) => {
        return {
          ...prevState,
          minimumBopisQuantity: null,
          maximumBopisQuantity: null
        };
      });
    }
  }, [fulfillment, buyboxState.fulfillmentMethod]);

  useEffect(() => {
    const fencingItemMessage = fencingItemsGroup.some((skuclassification) => {
      return skuclassification.test(info?.productDepartment + '-' + info?.classNumber + '-' + info?.subClassNumber);
    });
    if (fencingItemMessage) {
      checkIfUserIsInStore().then((isUserInStore) => {
        setBuyboxState((prevState) => {
          return {
            ...prevState,
            showFencingMessage: isUserInStore
          };
        });
      });
    }
  }, [info]);

  const onQuantityChange = useCallback(({ quantity }) => {
    const qty = parseInt(quantity, 10);

    const isBackorderedApplied = isBackorderedSelection(fulfillment?.backordered, buyboxState.fulfillmentMethod);
    // get the inventory available quantity for selected fulfillment
    const maximumInventoryQuantity = isBackorderedApplied ? 9999
      : helper.getFulfillmentMaxQuantity({ fulfillment, isTwoTileEnabledFS, qty });

    if (!Number.isNaN(qty) && qty > 0) {
      LIFE_CYCLE_EVENT_BUS.trigger('configurator.configurator_quantity', { qty });
      setBuyboxState((prevState) => {
        const newState = {
          ...prevState,
          quantity: qty,
          quantityExceeded: qty > maximumInventoryQuantity
        };
        if (buyboxState.calculatorQuantity) {
          newState.calculatorQuantity = quantity;
        }
        return newState;
      });
    }
  }, [buyboxState]);

  let availableQuantity = getAvailableQuantities(fulfillment);

  useEffect(() => {
    if (!Number.isNaN(initialQuantity) && Number(initialQuantity) > 1) {
      onQuantityChange({ quantity: initialQuantity });
    }
  }, [initialQuantity]);

  const onFulfillmentChange = ({
    currentFulfillment,
    method,
    isShowATC,
    disableATC,
    isOutOfStockOnline,
    quantityLimitExceed,
    availableFulfillments,
    isItemOOS
  }) => {
    const isBackorderedApplied = isBackorderedSelection(fulfillment?.backordered, method);
    setBuyboxState((prevState) => {
      LIFE_CYCLE_EVENT_BUS.trigger('configurator.fulfillment_changed', { method });
      return {
        ...prevState,
        fulfillmentMethod: method,
        availableFulfillments,
        isShowATC,
        disableATC,
        isOutOfStockOnline,
        currentFulfillment,
        quantityLimitExceed,
        // Reset Subscribe & save on fulfillment change
        productAddOns: {
          ...prevState.productAddOns,
          subscriptions: {
            ...prevState.productAddOns.subscriptions,
            frequency: method === FULFILLMENT_METHOD_STH ? subscriptionFrequency : null
          }
        },
        quantityExceeded: isBackorderedApplied ? quantityLimitExceed : prevState.quantityExceeded,
        isItemOOS
      };
    });
  };

  const onProductAddOnChange = (addOnData) => {
    const { addOn, flags } = helper.getSelectedAddOnInfo(addOnData, buyboxState.fulfillmentMethod);
    setBuyboxState((prevState) => {
      return {
        ...prevState,
        ...flags,
        productAddOns: {
          ...prevState.productAddOns,
          ...addOn
        }
      };
    });
  };

  const onSubscribeAndSaveChange = ({ frequency, isOptIn }) => {
    // isOptIn will be true if they have selected to subscribe
    LIFE_CYCLE_EVENT_BUS.trigger('configurator.configurator_subscribe', { configuratorSubscribe: !!isOptIn, frequency });
    // set STH if subscription enabled
    LIFE_CYCLE_EVENT_BUS.trigger(
      'attach-subscription.change',
      {
        isOptedIn: !!isOptIn,
        itemId,
        currentFulfillment: buyboxState?.fulfillmentMethod || '',
        nextFulfillment: isOptIn ? 'ShipToHome' : buyboxState?.fulfillmentMethod
      }
    );
    setBuyboxState((prevState) => {
      return {
        ...prevState,
        productAddOns: {
          ...prevState.productAddOns,
          subscriptions: {
            ...prevState.productAddOns.subscriptions,
            frequency: isOptIn ? frequency : null
          }
        }
      };
    });
    setSubscriptionFrequency(isOptIn ? frequency : null);
  };

  useEffect(() => {
    const { coverage, addAdditional } = buyboxState;
    if (!coverage) {
      return;
    }
    const updatedCoverage = addAdditional ? +coverage + (+coverage * 0.10) : coverage;
    const quantity = Math.ceil(updatedCoverage / +pricing?.alternate?.unit?.unitsPerCase);
    const isBackorderedApplied = isBackorderedSelection(fulfillment?.backordered, buyboxState.fulfillmentMethod);
    const maximumInventoryQuantity = isBackorderedApplied ? 9999
      : helper.getFulfillmentMaxQuantity({ fulfillment, isTwoTileEnabledFS, qty: quantity });

    setBuyboxState((prevState) => {
      const newState = {
        ...prevState,
        quantity: quantity > 9999 ? 9999 : quantity,
        quantityExceeded: quantity > maximumInventoryQuantity,
      };
      if (buyboxState.calculatorQuantity) {
        newState.calculatorQuantity = quantity;
      }
      return newState;
    });
  }, [pricing?.alternate?.unit?.unitsPerCase]);

  useEffect(() => {
    const qty = projectQuantity || 1;
    const isBackorderedApplied = isBackorderedSelection(fulfillment?.backordered, buyboxState.fulfillmentMethod);
    const maximumInventoryQuantity = isBackorderedApplied ? 9999
      : helper.getFulfillmentMaxQuantity({ fulfillment, isTwoTileEnabledFS, qty });

    setBuyboxState((prevState) => {
      const newState = {
        ...prevState,
        quantity: qty > 9999 ? 9999 : qty,
        quantityExceeded: qty > maximumInventoryQuantity
      };
      if (buyboxState.calculatorQuantity) {
        newState.calculatorQuantity = qty;
      }
      return newState;
    });
  }, [projectQuantity]);

  const onChangeCalculator = ({ addAdditionalCoverage, quantity, coverage }) => {
    const isBackorderedApplied = isBackorderedSelection(fulfillment?.backordered, buyboxState.fulfillmentMethod);
    const maximumInventoryQuantity = isBackorderedApplied ? 9999
      : helper.getFulfillmentMaxQuantity({ fulfillment, isTwoTileEnabledFS, qty: quantity });
    setBuyboxState((prevState) => {
      return {
        ...prevState,
        quantity: quantity > 9999 ? 9999 : quantity,
        calculatorQuantity: quantity,
        coverage,
        quantityExceeded: quantity > maximumInventoryQuantity,
        addAdditional: addAdditionalCoverage
      };
    });
  };
  // ######## End of Calculator methods ##########
  const onDeliveryOptionsZipCodeChange = useCallback((shippingDataModel) => {
    const { zipCode } = shippingDataModel;
    if (window?.LIFE_CYCLE_EVENT_BUS) {
      window.LIFE_CYCLE_EVENT_BUS.trigger('buybox-zipcode-updated', zipCode);
    }

    setBuyboxState((prevState) => {
      return {
        ...prevState,
        zipCode
      };
    });
  }, []);

  // call back on add to cart click
  const onATCClick = useCallback(() => {
    if (window?.LIFE_CYCLE_EVENT_BUS) {
      window.LIFE_CYCLE_EVENT_BUS.trigger('@@ADD-TO-CART/LOCATION', { ...atcStash, parentId });
      window.LIFE_CYCLE_EVENT_BUS.trigger('configurator.rules', { fireRules: true });
    }
    if (isConfiguratorEditMode) {
      removeLineItem();
    }
  }, [parentId, isConfiguratorEditMode]);

  const unavailable = configuratorUnavailable || buyboxDisableATC; // From prop
  let hideQuantity = configuratorUnavailable || isDecking === true;
  const {
    isOutOfStockOnline,
    isShowATC,
    quantity,
    quantityLimitExceed,
    zipCode,
    fulfillmentMethod,
    productAddOns,
    availableFulfillments,
    assembleItemAtStore,
    assembleItemAtHome,
    quantityExceeded,
    isItemOOS
  } = buyboxState;
  const paypalEligible = isDecking || isPreview ? false : buyboxState.paypalEligible;

  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.trigger('configurator.assembleItemAtStore',
      { assembleItemAtStore, assembleItemAtHome, productAddOns });
  }, [assembleItemAtStore, assembleItemAtHome, productAddOns]);
  const isATCDisabled = unavailable || isPreview || buyboxState?.disableATC;
  const showSubscription = (hasSubscription && !isTwoTileEnabledFS)
    || (hasSubscription && isTwoTileEnabledFS && isFFMAvailable(fulfillment?.fulfillmentOptions, 'sth', 'delivery', quantity)
    && !isOutOfStockOnline);

  const { dualPath = false, virtualAssistance = false, category } = productAddOns;

  const addOnsAssemblyAndMore = helper.getAddOnsFlagsForFssDrawer({
    productAddOns,
    assembleItemAtStore,
    assembleItemAtHome,
    fulfillmentMethod,
    availableFulfillments
  });

  const [pathForAdvisoryMessage] = useDomPath();

  if (error && !data) {
    return (
      <BuyboxError />
    );
  }

  const risMessageEligible = info?.isInStoreReturnMessageEligible;

  const twoTilesWithQtyIsLoading = !!(isTwoTileEnabledFS && isTwoTileQuantityUpdate && initialLoadingRef.current);
  const regular2or3TilesIsLoading = (!isTwoTileQuantityUpdate && loading);

  const configurableProductDetails = {
    isSpecialOrder,
    configuratorHideQuantity,
    isConfigurableBlinds,
    disableTimer
  };

  if (twoTilesWithQtyIsLoading || regular2or3TilesIsLoading || isServer || storeId === '8119' || customLoading === true) {
    // only for 2tile + qty update: if graphql data is loaded after first page render, stop showing loaders
    if (twoTilesWithQtyIsLoading && data?.product && !loading) {
      initialLoadingRef.current = false;
    }

    // Do Not remove this as we want to show the loader when loading state is true
    if (loading || customLoading) {
      return (
        <BuyboxLoader
          lite={lite}
        />
      );
    }
  }

  if (!data?.product && !initialLoadingRef.current) {
    addNewRelicCustomTag('isBuyBoxNull', true);
  }

  if (!data?.product) {
    return null;
  }

  if (unavailable) {
    return (
      <BuyboxUnavailable
        hideQuantity={hideQuantity}
        itemId={itemId}
        quantity={quantity}
        quantityLimitExceed={quantityLimitExceed}
        onQuantityChange={onQuantityChange}
        onFulfillmentChange={onFulfillmentChange}
        onDeliveryOptionsZipCodeChange={onDeliveryOptionsZipCodeChange}
        directData={directData || configuratorData?.product}
        configurableProductDetails={configurableProductDetails}
        isTwoTileEnabledFS={isTwoTileEnabledFS}
      />
    );
  }

  if (lite) {
    return (
      <BuyboxLite
        itemId={itemId}
        configurableItems={items}
        unavailable={unavailable}
        covidAlert={covidAlert}
        storeId={storeId}
        isOutOfStockOnline={isOutOfStockOnline}
        isShowATC={isShowATC}
        storeZip={storeZip}
        isATCDisabled={isATCDisabled}
        channel={channel}
        zipCode={zipCode}
        fulfillmentMethod={fulfillmentMethod}
        fulfillment={fulfillment}
        paypalEligible={paypalEligible}
        availabilityType={availabilityType}
        noTopPadding={noTopPadding}
        onFulfillmentChange={onFulfillmentChange}
        onDeliveryOptionsZipCodeChange={onDeliveryOptionsZipCodeChange}
        onATCClick={onATCClick}
        configuratorData={configuratorData}
        bopisHolidayTiming={bopisHolidayTiming}
        hideInstantCheckout={hideInstantCheckout || isWren}
        hidePaypalCheckout={hidePaypalCheckout || isWren}
        directData={directData || configuratorData?.product}
        quantityLimit={quantityLimit}
        configurableProductDetails={configurableProductDetails}
        currentSelectedFulfillment={currentSelectedFulfillment}
        isTwoTileEnabledFS={isTwoTileEnabledFS}
      />
    );
  }

  const cardClasses = classNames({ 'sui-p-2 sui-bg-primary sui-overflow-hidden sui-mt-4 empty:sui-mt-0 empty:sui-p-0 ': showCards });

  return (
    <div className="sui-ml-[-10px] sui-mr-[-10px] sui-w-auto sui-flex-wrap">
      <div className="sui-w-full sui-bg-subtle">
        <div className="sui-grid-cols-12" data-component="Buybox">
          <div className={`${cardClasses} !sui-mt-0`}>
            {!unavailable && !virtualAssistance && !isVirtualAssistanceOnly && (
              /* eslint-disable-next-line no-sequences */
              <div className={showCards ? '' : 'sui-pt-5 sui-pb-5'}>
                <Fulfillment
                  itemId={itemId}
                  covidAlert={covidAlert}
                  quantity={quantity}
                  originalConfigId={originalConfigId}
                  onChange={onFulfillmentChange}
                  onDeliveryZipCodeChange={onDeliveryOptionsZipCodeChange}
                  dualPath={dualPath}
                  category={category}
                  bodfsMinimumThreshold={bodfsMinimumThreshold}
                  bopisMinimumThreshold={bopisMinimumThreshold}
                  bopisMinimumThresholdStores={bopisMinimumThresholdStores}
                  bopisHolidayTiming={bopisHolidayTiming}
                  directData={directData || configuratorData?.product}
                  quantityExceeded={quantityExceeded}
                  addOnsAssemblyAndMore={addOnsAssemblyAndMore}
                  configurableProductDetails={configurableProductDetails}
                  leadTime={leadTime}
                  hasCustomATC={!!customATC}
                  disableSTH={disableSTH}
                  disableBODFS={disableBODFS}
                  disableBOSS={disableBOSS}
                  disablePickupNearBy={disablePickupNearBy}
                  currentSelectedFulfillment={currentSelectedFulfillment}
                  isTwoTileEnabledFS={isTwoTileEnabledFS}
                />
              </div>
            )}
          </div>
          {/* Removed the isOutOfStockOnline here to display calculator section */}
          {!isItemOOS && !dualPath && renderCalculator && (
            <ErrorBoundary>
              <div className={cardClasses} data-testid={`pip-calculator-${itemId}`}>
                {renderCalculator(itemId, onChangeCalculator, buyboxState.calculatorQuantity)}
              </div>
            </ErrorBoundary>
          )}
          {isVirtualAssistanceOnly ? (
            <div className={cardClasses}>
              <VirtualAssistance channel={channel} activateRequestAppointment />
            </div>
          ) : (
            <div className={isItemOOS ? '' : 'sui-mt-4'}>
              <ProductAddOns
                itemId={itemId}
                fulfillment={buyboxState.fulfillmentMethod}
                onChange={onProductAddOnChange}
                availableFulfillments={availableFulfillments}
                hideProReferral={hideProReferral}
                showCards={showCards}
                defaultProps={buyboxState.productAddOns}
                isTwoTileEnabledFS={isTwoTileEnabledFS}
                zipCode={zipCode}
                // Please do not remove the below prop isTwoTileEnabled={isTwoTileEnabledFS}
                // as ProductAddOns has no prop mapping for isTwoTileEnabledFS
                // and still uses isTwoTileEnabled
                isTwoTileEnabled={isTwoTileEnabledFS}
                isShowATC={isShowATC}
              />

              {/* Removed the isOutOfStockOnline here to display subscribeAndSave section */}
              {showSubscription && isShowATC && (
                <div className={cardClasses} data-testid="subscribe-and-save-container">
                  <SubscribeAndSave
                    itemId={itemId}
                    quantity={buyboxState.quantity}
                    fulfillment="ShipToHome"
                    onChange={onSubscribeAndSaveChange}
                  />
                </div>
              )}
            </div>
          )}

          <div className={`${cardClasses} sui-mb-4`}>
            {
              !isOutOfStockOnline && isShowATC && !!minimumOrderQuantity && (
                <BuyboxMessage
                  onRender={() => {
                    if (pathForAdvisoryMessage.length) {
                      track('advisory-message', {
                        message: `This item can only be purchased in quantities of ${minimumOrderQuantity} or more`,
                        path: pathForAdvisoryMessage
                      });
                    }
                  }}
                  message={`This item can only be purchased in quantities of ${minimumOrderQuantity} or more`}
                  status="warning"
                />
              )
            }
            {isTwoTileEnabledFS && quantityLimit > 0 && quantity > (quantityLimit - 1) && (
              <BuyboxMessage
                onRender={() => {
                  if (pathForAdvisoryMessage.length) {
                    track('advisory-message', {
                      message: `This item has a limit of ${quantityLimit} per order.`,
                      path: pathForAdvisoryMessage
                    });
                  }
                }}
                message={`This item has a limit of ${quantityLimit} per order.`}
                status="warning"
              />
            )}
            {
              !!(buyboxState.minimumBopisQuantity) && (
                <BuyboxMessage
                  message={
                    `This item has a minimum quantity of
                    ${buyboxState.minimumBopisQuantity} for Pickup at ${storeName}.`
                  }
                  status="info"
                />
              )
            }
            {
              !!(buyboxState.maximumBopisQuantity) && (
                <BuyboxMessage
                  message={
                    `This item has a maximum quantity of
                    ${buyboxState.maximumBopisQuantity} for Pickup at ${storeName}.`
                  }
                  status="info"
                />
              )
            }
            { isRisMessageEnabled
              && risMessageEligible && !isItemOOS && isShowATC && !dualPath && !virtualAssistance && (
              <BuyboxMessage
                message="This item is only returnable in store."
                status="info"
              />
            )}
            { buyboxState.showFencingMessage && (
              <BuyboxMessage
                message="If unavailable, please see local Pro Desk for pricing and availability."
              />
            )}
            {paintConfigErrorMessage && (
              <BuyboxMessage
                message={paintConfigErrorMessage}
                status="error"
              />
            )}
            {/* Removed the isOutOfStockOnline here to display BuyboxActions section */}
            {!isItemOOS && isShowATC && !dualPath && !virtualAssistance && !isVirtualAssistanceOnly && (
              <BuyboxActions
                alterBrowserHistory
                disableATC={isATCDisabled}
                fulfillment={fulfillment}
                fulfillmentMethod={fulfillmentMethod}
                hideQuantity={hideQuantity}
                isAssembleItemAtStore={assembleItemAtStore}
                itemId={itemId}
                itemPrice={pricing?.value}
                configurableItems={items}
                onClickPassThrough={onClickPassThrough}
                minimumOrderQuantity={minimumOrderQuantity}
                onATCClick={onATCClick}
                onQuantityChange={onQuantityChange}
                paypalEligible={paypalEligible}
                productAddOns={productAddOns}
                quantity={quantity}
                originalConfigId={originalConfigId}
                quantityLimitExceed={quantityLimitExceed}
                sticky={sticky}
                storeId={storeId}
                storeZip={storeZip}
                zipCode={zipCode}
                showCards={showCards}
                showFavoriteAndQuote={showFavoriteAndQuote}
                maxQuantityExceeded={quantityExceeded}
                availableQuantity={availableQuantity}
                quantityLimit={quantityLimit}
                fallbackMode={isTwoTileEnabledFS ? fallbackMode : false}
                isTwoTileEnabledFS={isTwoTileEnabledFS}
                configurableProductDetails={{
                  isSpecialOrder: configurableProductDetails.isSpecialOrder,
                  configuratorHideQuantity: configurableProductDetails.configuratorHideQuantity,
                  configuratorData,
                  disableTimer: configurableProductDetails.disableTimer
                }}
                customATC={customATC}
                hidePaypalCheckout={hidePaypalCheckout || isWren}
                hideInstantCheckout={hideInstantCheckout || isWren}
                minimumBopisQuantity={buyboxState.minimumBopisQuantity}
                maximumBopisQuantity={buyboxState.maximumBopisQuantity}
                bulk={pricing?.alternate?.bulk}
                conditionalPromotions={pricing?.conditionalPromotions}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

Buybox.displayName = 'Buybox';
Buybox.propTypes = {
  /**
   * The number of clicks you are allowed before seeing a warning.
   */
  itemId: string,

  /** Pass ddo values to override the default */
  disableATC: bool,
  /**
   * if the buybox is wrapped in a "grid" class, defaults to false.
   * Fusion and sui will utilize this and set it to true.
   */
  noGrid: bool,
  onClickPassThrough: func,
  /**
   * Disable the STH tile regardless of fulfillment data.
   */
  disableSTH: bool,
  /**
   * Disable the BODFS tile regardless of fulfillment data.
   */
  disableBODFS: bool,
  disableBOSS: bool,
  disablePickupNearBy: bool,
  lite: bool,
  stash: shape({
    pageType: string,
    sharedSection: string,
    section: string,
    component: string
  }),
  sticky: bool,
  hideInstantCheckout: bool,
  hidePaypalCheckout: bool,
  hideProReferral: bool,
  noTopPadding: bool,
  showCards: bool,
  showFavoriteAndQuote: bool,
  /* eslint-disable-next-line react/forbid-prop-types */
  directData: PropTypes.objectOf(PropTypes.any),
  renderCalculator: func,
  paintConfigErrorMessage: string,
  originalConfigId: string
};

Buybox.defaultProps = {
  itemId: null,
  lite: false,
  disableATC: false,
  onClickPassThrough: null,
  noGrid: false,
  disableSTH: false,
  disableBODFS: false,
  disableBOSS: false,
  disablePickupNearBy: false,
  stash: {},
  sticky: false,
  hideInstantCheckout: false,
  hidePaypalCheckout: false,
  hideProReferral: false,
  noTopPadding: false,
  showCards: false,
  showFavoriteAndQuote: false,
  directData: null,
  renderCalculator: null,
  paintConfigErrorMessage: '',
  originalConfigId: null
};

Buybox.dataModel = extend(
  dataModel,
  Fulfillment,
  ProductAddOns,
  SubscribeAndSave,
  InstantCheckout,
  AddToQuote,
  AddToList,
  PIPCalculator
);

export default Buybox;