import React, { useState, useEffect } from 'react';
import {
  string, bool, number, arrayOf, shape
} from 'prop-types';
import { Tab, Tabs } from '@one-thd/sui-atomic-components';
import { Heading, Image } from '@thd-olt-component-react/core-ui';

import axios from 'axios';
import './UserProfile.scss';

const ContentTab = ({
  numberOfReviews,
  numberOfQuestions,
  numberOfAnswers,
  allReviews,
  allQuestions,
  allAnswers
}) => {
  const [value, setValue] = useState(0);
  const onChange = (event, index) => {
    setValue(index);
  };
  const noContributionsMessage = 'This contributor has not submitted any ';

  return (
    <div>
      <div>
        <Tabs value={value} onChange={onChange}>
          <Tab label={`Reviews (${numberOfReviews})`} data-testid="reviews-tab" />
          <Tab label={`Questions (${numberOfQuestions})`} data-testid="questions-tab" />
          <Tab label={`Answers (${numberOfAnswers})`} data-testid="answers-tab" />
        </Tabs>
        { value === 0 && (
          <div className="profile-content-tab">
            {numberOfReviews && numberOfReviews !== 0
              ? allReviews.map((review, index) => {
                return (
                  <div className="profile-content-text" key={index}>
                    {'• '}
                    {review.ReviewText !== null ? review.ReviewText : '-no content-'}
                  </div>
                );
              })
              : noContributionsMessage + 'reviews'}
          </div>
        )}
        { value === 1 && (
          <div className="profile-content-tab">
            {numberOfQuestions && numberOfQuestions !== 0
              ? allQuestions.map((question, index) => {
                return (
                  <div className="profile-content-text" key={index}>
                    {'• '}
                    {question.QuestionDetails || question.QuestionSummary}
                  </div>
                );
              })
              : noContributionsMessage + 'questions'}
          </div>
        )}
        { value === 2 && (
          <div className="profile-content-tab">
            {numberOfAnswers && numberOfAnswers !== 0
              ? allAnswers.map((answer, index) => {
                return (
                  <div className="profile-content-text" key={index}>
                    {'• '}
                    {answer.AnswerText !== null ? answer.AnswerText : '-no content-'}
                  </div>
                );
              })
              : noContributionsMessage + 'answers'}
          </div>
        )}
      </div>
    </div>
  );
};

export const UserProfile = ({
  userNickname,
  authorId,
  showUserOverlay
}) => {
  const key = 'cadi71Nt7f8KAkSzBuJC6VDVZa67bj7qfgHNGZsQ1xq3o';

  const [userProfileInfo, setUserProfileInfo] = useState({
    numberOfReviews: 0,
    numberOfQuestions: 0,
    numberOfAnswers: 0,
    helpfulVotes: 0,
    averageRating: 0,
    firstReview: 'None',
    lastReview: 'None'
  });

  useEffect(() => {
    if (showUserOverlay) {
      const BVFetch = async () => {
        // eslint-disable-next-line max-len
        const res = await axios.get(`https://api.bazaarvoice.com/data/authors.json?passkey=${key}&apiversion=5.4&filter=id:${authorId}&include=answers,questions,reviews&stats=reviews,questions`);

        const includes = res?.data?.Includes;
        const results = res?.data?.Results?.[0];

        const firstReview = results?.ReviewStatistics?.FirstSubmissionTime
          ? new Date(results.ReviewStatistics.FirstSubmissionTime).toLocaleDateString()
          : 'None';
        const lastReview = results?.ReviewStatistics?.LastSubmissionTime
          ? new Date(results.ReviewStatistics.LastSubmissionTime).toLocaleDateString()
          : 'None';
        const averageRating = Math.round(results?.ReviewStatistics?.AverageOverallRating * 10) / 10 || 0;

        const newProfileInfo = {
          numberOfReviews: results?.ReviewStatistics?.TotalReviewCount || 0,
          numberOfQuestions: results?.QAStatistics?.TotalQuestionCount || 0,
          numberOfAnswers: results?.QAStatistics?.TotalAnswerCount || 0,
          allReviews: Object.values(includes?.Reviews || []),
          allQuestions: Object.values(includes?.Questions || []),
          allAnswers: Object.values(includes?.Answers || []),
          firstReview,
          lastReview,
          helpfulVotes: results?.ReviewStatistics?.HelpfulVoteCount || 0,
          averageRating
        };
        setUserProfileInfo({
          ...newProfileInfo
        });
      };
      BVFetch();
    }
  }, [showUserOverlay]);

  return (
    <div className="profile-wrapper" data-testid="user-profile">
      <Image
        src="https://assets.thdstatic.com/images/v1/thd-logo-doers-right-twoline.svg"
        alt="Overlay Image"
        className="profile-logo"
      />
      <div className="header-container">
        <Heading>{userNickname || 'DEFAULT USER'}</Heading>
      </div>
      <div className="reviews-container">
        <h3 className="reviews-container__first">{`First Review: ${userProfileInfo.firstReview}`}</h3>
        <h3 className="reviews-container__last">{`Last Review: ${userProfileInfo.lastReview}`}</h3>
      </div>
      <Heading underline>Activity Summary</Heading>
      <div className="activity-wrapper">
        <div className="activity-container">
          <div className="user-activity">
            <div className="user-activity__number">
              {userProfileInfo.numberOfReviews}
            </div>
            <div className="user-activity__title">
              Reviews
            </div>
          </div>
          <div className="user-activity">
            <div className="user-activity__number">
              {userProfileInfo.helpfulVotes}
            </div>
            <div className="user-activity__title--line-adjust">
              Helpfulness Votes
            </div>
          </div>
          <div className="user-activity">
            <div className="user-activity__number">
              {userProfileInfo.averageRating}
            </div>
            <div className="user-activity__title--line-adjust">
              Average Rating
            </div>
          </div>
        </div>
        <div className="activity-container">
          <div className="user-activity">
            <div className="user-activity__number">
              {userProfileInfo.numberOfQuestions}
            </div>
            <div className="user-activity__title">
              Questions
            </div>
          </div>
          <div className="user-activity">
            <div className="user-activity__number">
              {userProfileInfo.numberOfAnswers}
            </div>
            <div className="user-activity__title">
              Answers
            </div>
          </div>
        </div>
      </div>
      <ContentTab
        numberOfReviews={userProfileInfo.numberOfReviews}
        numberOfQuestions={userProfileInfo.numberOfQuestions}
        numberOfAnswers={userProfileInfo.numberOfAnswers}
        allReviews={userProfileInfo.allReviews}
        allQuestions={userProfileInfo.allQuestions}
        allAnswers={userProfileInfo.allAnswers}
      />
    </div>
  );
};

UserProfile.displayName = 'UserProfile';

UserProfile.propTypes = {
  userNickname: string,
  authorId: string,
  showUserOverlay: bool,
};

UserProfile.defaultProps = {
  userNickname: '',
  authorId: '',
  showUserOverlay: false,
};

ContentTab.propTypes = {
  numberOfReviews: number,
  numberOfQuestions: number,
  numberOfAnswers: number,
  allReviews: arrayOf(shape({})),
  allQuestions: arrayOf(shape({})),
  allAnswers: arrayOf(shape({}))
};

ContentTab.defaultProps = {
  numberOfReviews: 0,
  numberOfQuestions: 0,
  numberOfAnswers: 0,
  allReviews: [],
  allQuestions: [],
  allAnswers: []
};