/* eslint-disable id-length */
import React, {
  useCallback, useEffect, useState, useRef
} from 'react';
import { TypeaheadAsync } from '@thd-olt-component-react/typeahead';
import { useHeaderObserver } from '@thd-olt-functional/utils';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';
import searchHistoryStore from './search/utils/searchHistory';
import { getUrlSearchTerm } from './search/utils/headerSearchUtils';
import { CONSTANTS, HOST_CONSTANTS } from '../../constants';
import { headerEvent } from '../../events/header.events';
import { useThemeContext } from '../generic-ui/ThemeContext';
import { searchSuggestions } from './search/utils/searchDataOrchestrator';
import { typeaheadApiCookieStore } from './search/utils/typeaheadAPI';
import { useHeaderContext } from '../generic-ui/HeaderContext';

const { DEFAULT_HOST } = HOST_CONSTANTS;
const { TYPEAHEAD_DATA_SOURCE } = CONSTANTS;

const HeaderSearch = () => {
  const { inverseTheme } = useThemeContext();
  const { featureSwitches } = useHeaderContext();
  const [entry] = useHeaderObserver() || [];
  const headerSearchRef = useRef();
  const latestSearchText = useRef(null);

  const searchSuggestionsConfigRef = useRef({ isSearchTermBoosted: false, typeaheadApiAbTest: false });

  const [orchestratorState, setOrchestratorState] = useState({ source: null, hasData: false, isActive: false });
  const isMobile = entry?.navigation?.positionBottom;

  const showSearchHistory = (
    orchestratorState.source === TYPEAHEAD_DATA_SOURCE.SEARCH_HISTORY
    && orchestratorState.hasData);

  const handleHeaderSearchRef = useCallback((node) => {
    headerSearchRef.current = node;

    const [searchInput] = node?.getElementsByTagName('input') || [];
    if (searchInput) {
      searchInput.value = getUrlSearchTerm();
    }
  }, []);

  const onSearch = async (searchText = '') => {
    latestSearchText.current = searchText;
    const {
      source, hasData, isActive, data, cta
    } = await searchSuggestions.fetch({ searchText, config: searchSuggestionsConfigRef.current });
    if (latestSearchText.current === searchText) {
      setOrchestratorState({ source, hasData, isActive, cta });
      return data;
    }
    return null;
  };

  const onSubmit = useCallback((event, customEvent) => {
    const {
      selectedSearchTerm, url, userSearchTerm, results, source = ''
    } = customEvent;

    if ((typeof selectedSearchTerm !== 'string' || selectedSearchTerm.length <= 0)) return;

    // the string replace protects against malformedURI errors
    const cleanEncodedSearchTerm = encodeURIComponent(selectedSearchTerm.replace(/\\u/i, ' '));
    const cleanEncodedUserSearchTerm = encodeURIComponent(userSearchTerm.replace(/\\u/i, ' '));
    const redirectURL = url || `/s/${cleanEncodedSearchTerm}?NCNI-5`;
    // presence of the URL field determines whether the search is a Typeahead result or user text search.
    // We only want to capture analytics for a Typeahead result click
    if (url) {
      headerEvent.publish({
        eventName: 'header-typeahead.result-click',
        payload: {
          selectedSearchTerm, userSearchTerm, results, type: source, cta: orchestratorState.cta
        }
      });
    }

    searchHistoryStore.updateLastSearchTerm(cleanEncodedUserSearchTerm);
    searchHistoryStore.update(cleanEncodedSearchTerm);

    window.location.assign(`${DEFAULT_HOST}${redirectURL}`);

  }, [orchestratorState.source]);

  const onFocusOut = () => {
    if (orchestratorState.isActive) {
      setOrchestratorState({ source: null, hasData: false, isActive: false });
    }
  };

  const handleSingleHistoryDelete = async (searchText, selectedSearchTerm) => {
    if (!selectedSearchTerm) return;
    const encodedHistory = encodeURIComponent(selectedSearchTerm);
    searchHistoryStore.deleteSingleHistory(encodedHistory);
  };

  const searchFieldProps = {
    formProps: {
      action: '/s/',
      method: 'GET',
      encType: 'application/x-www-form-urlencoded'
    },
    inputProps: {
      placeholder: !isMobile ? 'What can we help you find today?' : 'Search',
      name: 'keyword',
      type: 'search',
      autoComplete: 'off'
    },
    customClasses: {
      inputContainer: !inverseTheme ? 'focus-within:sui-border-input-active' : null
    }
  };

  const deleteAllHistory = ({ bypassFocusOut = false }) => {
    searchHistoryStore.deleteAllHistory();
    if (!bypassFocusOut) onFocusOut();
  };

  const openMenu = entry?.visible && orchestratorState.isActive;

  const suggestionsMenuProps = {
    /* open empty menu always on Mobile, and only when there is data to show on DKT */
    open: openMenu,
    anchorElement: headerSearchRef.current,
    fullScreenHeight: isMobile,
    fullScreenWidth: isMobile,
    suggestionsMenuHeader: {
      headerTitle: showSearchHistory ? 'Recent Searches' : '',
      showClearAllButton: showSearchHistory,
      onClearAllClicked: deleteAllHistory
    },
    backdropOffset: {
      top: openMenu ? entry?.root?.clientRect?.height : 0,
    },
    isMobile,
    isRecentlyViewedEnabled: featureSwitches?.isRecentlyViewedEnabled,
  };

  const resultsListProps = {
    showDeleteListButton: isMobile,
    numberOfResultsToShow: isMobile ? 6 : 10
  };

  useEffect(() => {
    searchSuggestionsConfigRef.current = {
      isSearchTermBoosted: typeaheadApiCookieStore.getBoosting(),
      typeaheadApiAbTest: typeaheadApiCookieStore.getABTest()
    };
  }, []);

  return (
    <ErrorBoundary id="header-search" name="header-search">
      <div className="sui-w-full" ref={handleHeaderSearchRef}>
        <TypeaheadAsync
          onSearch={onSearch}
          onSubmit={onSubmit}
          onFocusOut={onFocusOut}
          onListItemRemoval={handleSingleHistoryDelete}
          searchFieldProps={searchFieldProps}
          suggestionsMenuProps={suggestionsMenuProps}
          resultsListProps={resultsListProps}
        />
      </div>
    </ErrorBoundary>
  );
};

HeaderSearch.displayName = 'HeaderSearch';
HeaderSearch.propTypes = {};

export { HeaderSearch };
