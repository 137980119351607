/* eslint-disable react/forbid-prop-types */
import React, { useContext, useEffect, useRef } from 'react';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { StickyNav } from '@thd-olt-component-react/sticky-nav';
import PropTypes from 'prop-types';
import { extend } from '@thd-nucleus/data-sources';
/**
 * Props
 *
 * @typedef {object} Props
 * @property {object} product - product information
 * @property {string} itemId - Id selected from the product
 */

/**
 * isHDPPSku - Indicates whether the SKU is an HDPP SKU.
 * @type {boolean}
 */

/**
 * hideRatingsIfHDPPSku - Indicates whether ratings are hidden or not
 * @type {boolean}
 */

/**
 * accordionsRefs - Object containing references to various DOM elements.
 * @typedef {Object} Refs
 * @property {React.RefObject} productOverviewRef - Reference to the product overview element.
 * @property {React.RefObject} specificationsRef - Reference to the specifications element.
 * @property {React.RefObject} questionsRef - Reference to the questions element.
 * @property {React.RefObject} ratingsRef - Reference to the ratings element.
 * @property {React.RefObject} stickyNavRef - Reference to the sticky Nav
 */

const BundleStickyNav = ({
  itemId, isHDPPSku, hideRatingsIfHDPPSku, product, accordionsRefs: accordionsRefsProp
}) => {

  const { starRatingStickyNavTitle, questionStickyNav, starRatingStickyNav } = product?.features ?? {};

  const ctx = useContext(ExperienceContext);
  const { channel } = ctx;
  const productOverviewRef = useRef(null);
  const specificationsRef = useRef(null);
  const questionsRef = useRef(null);
  const ratingsRef = useRef(null);
  const stickyNavRef = useRef(null);
  const setExpandedRef = useRef();

  const defaultAccordionsRefs = {
    productOverviewRef,
    specificationsRef,
    questionsRef,
    ratingsRef,
    stickyNavRef
  };

  let accordionsRefs = {};

  if (Object.keys(accordionsRefsProp).length > 0) {
    accordionsRefs = accordionsRefsProp;
  } else {
    accordionsRefs = defaultAccordionsRefs;
  }

  const handleAccordionChange = (accordionId, index) => {
    if (Object.keys(accordionsRefsProp).length > 0) {
      LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('product-bundle.open-accordion', accordionId);
    } else {
      if (setExpandedRef.current) {
        // event is undefined here for the accordion
        setExpandedRef.current(index)(undefined, true);
      }
      window.LIFE_CYCLE_EVENT_BUS.trigger('sticky-nav.click', { accordionId });
    }
  };

  const updateStickyHeader = () => {
    const el = document.querySelector('.Header3');
    if (!el) {
      return;
    }
    el.classList.remove('Header3-sticky-desktop');
  };
  useEffect(() => {
    updateStickyHeader();
  }, []);

  useEffect(() => {
    window.LIFE_CYCLE_EVENT_BUS.lifeCycle.on('accordion.mount', ({ output }) => {
      const { wrapperIds, setExpanded } = output;
      setExpandedRef.current = setExpanded;
      accordionsRefs.productOverviewRef.current = document.querySelector('#' + wrapperIds[0]);
      accordionsRefs.specificationsRef.current = document.querySelector('#' + wrapperIds[1]);
      accordionsRefs.questionsRef.current = document.querySelector('#' + wrapperIds[2]);
      accordionsRefs.ratingsRef.current = document.querySelector('#' + wrapperIds[3]);
    });
  }, []);

  if (channel !== 'desktop') {
    return null;
  }

  return (
    <div data-testid="bundle-sticky-nav">
      <StickyNav
        itemId={itemId}
        ref={accordionsRefs.stickyNavRef}
        ratingsRef={isHDPPSku ? null : accordionsRefs.ratingsRef}
        onRatingsClick={() => handleAccordionChange('#product-section-rr', 3)}
        hideIfHDPPSku={hideRatingsIfHDPPSku}
        hideStarRatings={!starRatingStickyNavTitle}
      >
        <StickyNav.Links>
          <StickyNav.Link targetRef={accordionsRefs.productOverviewRef}>
            <div
              data-testid="navlink-pso"
              onClick={() => handleAccordionChange('#product-section-overview', 0)}
              role="button"
              tabIndex={0}
            >
              Product Details
            </div>
          </StickyNav.Link>
          <StickyNav.Link targetRef={accordionsRefs.specificationsRef}>
            <div
              data-testid="navlink-specs"
              onClick={() => handleAccordionChange('#product-section-key-feat', 1)}
              role="button"
              tabIndex={0}
            >
              Specifications
            </div>
          </StickyNav.Link>
          {questionStickyNav && (
            <StickyNav.Link targetRef={accordionsRefs.questionsRef}>
              <div
                data-testid="navlink-qa"
                onClick={() => handleAccordionChange('#product-section-qa', 2)}
                role="button"
                tabIndex={0}
              >
                Questions & Answers
              </div>
            </StickyNav.Link>
          )}
          {starRatingStickyNav && !isHDPPSku && (
            <StickyNav.Link targetRef={accordionsRefs.ratingsRef}>
              <div
                className="navlink-rr"
                onClick={() => handleAccordionChange('#product-section-rr', 3)}
                role="button"
                tabIndex={0}
                data-testid="navlink-reviews"
              >
                Customer Reviews
              </div>
            </StickyNav.Link>
          )}
        </StickyNav.Links>
      </StickyNav>
    </div>
  );
};
BundleStickyNav.propTypes = {
  itemId: PropTypes.string,
  isHDPPSku: PropTypes.bool,
  hideRatingsIfHDPPSku: PropTypes.bool,
  product: PropTypes.object,
  accordionsRefs: PropTypes.shape({
    productOverviewRef: PropTypes.shape({ current: PropTypes.shape({}) }),
    specificationsRef: PropTypes.shape({ current: PropTypes.shape({}) }),
    questionsRef: PropTypes.shape({ current: PropTypes.shape({}) }),
    ratingsRef: PropTypes.shape({ current: PropTypes.shape({}) }),
    stickyNavRef: PropTypes.shape({ current: PropTypes.shape({}) })
  })
};

BundleStickyNav.defaultProps = {
  itemId: null,
  isHDPPSku: false,
  hideRatingsIfHDPPSku: true,
  product: null,
  accordionsRefs: {}
};

BundleStickyNav.displayName = 'BundleStickyNav';

BundleStickyNav.dataModel = extend(StickyNav);

export { BundleStickyNav };