import * as target from "./target";
import * as tdo from "./tdo";
// import * as atperf from "./atperf";
import * as utilities from "./utilities";

( function () {
	
	// An indicator for build_local mappings 
	console.log( '%c' + LOG_PREFIX + ' -- Local Build Mapping: ' + CODE_VERSION + ' | 2024-12-11T23:24:40.666Z', 'background: orange; color: white; display: block; line-height:20px; padding:5px 30px; border: 1px solid red; font-size:1em;' );

	window._aape = ( window._aape ? _aape : {} );
	
	window._tt = { "utils": {}, "META": [], "TDO": tdo.init() };
	window._tt.deployment = {
		version: CODE_VERSION,
		ts: '2024-12-11T23:24:40.666Z'
	};
	
	window._tt.utils.sendTargetData = tdo.sendTargetData;

	_tt.mboxEnabled = ( function() { // defined here and also utilized in the third-parties deployement

			let isCashstar = window.location.host.indexOf( ".cashstar.com" ) !== -1;

			return ( !utilities.isConsumerApp() || ( utilities.isConsumerApp && !isCashstar ) );

	}() );

	if ( _tt.mboxEnabled ) {

		const MAX_POLL_COUNT = 20;
		// atperf.init();

		( function() {

			let alloyPoll;
			let pollCount = 0;

			/**
			 * Checks if the `window.alloy` object is available and initializes the `target` module.
			 * @function checkAlloy
			 */
			const checkAlloy = function() {

				if ( window.alloy ) {

					clearInterval( alloyPoll );
					target.init();

				} else if ( pollCount >= MAX_POLL_COUNT ) {

					clearInterval( alloyPoll );
					
				}

				pollCount = pollCount + 1;

			};

			if ( window.alloy ) {

				target.init();

			}
			else {

				alloyPoll = setInterval( checkAlloy, 100 );

			}

		})();

	}

} )();
